import { useState, useEffect, useMemo, useRef, MutableRefObject } from 'react'
import { useEditorContext } from '@/scenes/engine'
import Icons from '../Icons'
import { ObjectType } from '@/scenes/engine/common/constants'
import useAppContext from '@/hooks/useAppContext'
import { LabelLarge } from 'baseui/typography'
import { useTranslation } from 'react-i18next'
import { PopoverType } from '@/constants/app-options'
import { customAmplitude } from '@/utils/customAmplitude'
import TooltipMenuItem from './TooltipMenuItem'
import { lightTheme } from '@/customTheme'
import { StaticImage, StaticText } from 'fabric/fabric-impl'
import { fabric } from 'fabric'
import { InspectorPanelType } from '../Toolbox/InspectorDataProvider'
import { useInspector } from '../Toolbox/InspectorDataProvider'
import { styled } from 'baseui'

const GradientText = styled('span', {
  background: 'linear-gradient(125deg, #FF1866 2.53%, #FD0167 18.16%, #F00188 38.58%, #DD01A0 48.37%, #D203B4 61.09%, #C10EC4 77.43%, #99019C 98.21%)',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 600,
});

const TooltipMenu = () => {
  const { addInspector } = useInspector()
  const editor = useEditorContext().editor
  const { canvas, activeObject, contextMenuDetails, setContextMenuDetails } = useEditorContext()
  const {
    isOpenInspector,
    hasBgImage,
    isReplacingImage,
    setIsReplacingImage,
    disableEditor,
    setIsOpenInspector,
    popoverActive,
    setPopoverActive,
    isOpenPixelManipulationObject,
    isOpenTutorial,
    setIsOpenFontFamily,
    isOpenFontFamily,
    isOpenAiImageProcessor,
    setIsOpenAiImageProcessor
  } = useAppContext()
  const [numberObj, setNumberObj] = useState<number | null>(null)
  const [infoMaxIndex, setInfoMaxIndex] = useState({
    index: 0,
    objectMaxIndex: null,
  })
  const [infoMinIndex, setInfoMinIndex] = useState({
    index: 100,
    objectMinIndex: null,
  })
  const [flagChange, setFlagChange] = useState(false)
  const [visible, setVisible] = useState(true)
  const [isDotsActive, setIsDotsActive] = useState(false)
  const isOpenFontFamilyRef = useRef(isOpenFontFamily)
  const ADD_TOOLTIP_REPLACE_BUTTON = false

  const [tooltipTop, tooltipLeft] = useMemo(() => {

    if (!activeObject) {
      return [0, 0]
    }
    setVisible(!disableEditor)
  
    const {
      left: leftActiveObject,
      top: topActiveObject,
      width: widthActiveObject,
      height: heightActiveObject,
    } = activeObject.getBoundingRect()

    const { top: topFrame, height: heightFrame } = editor.handlers.frameHandler.get().getBoundingRect()
    
    // out bottom
    const paddingToActiveObject = 24
    let positionLeft = leftActiveObject + (widthActiveObject / 2)
    const toolTipHeight = 40
    const isOutOfBottom = topActiveObject + heightActiveObject + paddingToActiveObject + toolTipHeight > topFrame + heightFrame;
    const isOutOfTop = topActiveObject - topFrame - toolTipHeight - paddingToActiveObject >= 0;
    const isTopWithinFrame = topFrame > topActiveObject || topActiveObject < editor.handlers.frameHandler.FRAME_PADDING_H;

    let tooltipPosition, tooltipCoord;
    if(isOutOfBottom) {
      tooltipCoord = isOutOfTop ? [topActiveObject - toolTipHeight - paddingToActiveObject, positionLeft] : [topFrame, positionLeft]
    } else {
      tooltipCoord = isTopWithinFrame ? [topActiveObject + heightActiveObject + paddingToActiveObject, positionLeft] : [topActiveObject - paddingToActiveObject - toolTipHeight, positionLeft]
    }
    tooltipPosition = !isOutOfBottom && isTopWithinFrame ? 'bottom' : 'top'
    editor.handlers.personalizationHandler.reCalculateRotatePosition(tooltipPosition)
    return tooltipCoord
  }, [activeObject, numberObj, flagChange])

  useEffect(() => {
    if (editor) {
      const handleChanges = () => {
        setFlagChange(current => !current)
      }

      const handleChanging = () => {
        setIsDotsActive(false)
        setVisible(false)
      }

      const handleTextMoving = () => {
        setVisible(false)
      }

      const handleTextMoved = () => {
        setFlagChange(current => !current)
        setVisible(true)
      }

      editor.on('history:changed', handleChanges)
      editor.on('object:changing', handleChanging)
      editor.on('scroll:changing', handleChanging)
      editor.on('scroll:changed', handleChanges)
      editor.on('canvas:modified', handleChanges)
      editor.on('after:render', handleChanges)
      editor.on('text:moving', handleTextMoving)
      editor.on('text:moved', handleTextMoved)
      editor.on('object:replace', onReplaceImage)
      return () => {
        editor.off('history:changed', handleChanges)
        editor.off('object:changing', handleChanging)
        editor.off('scroll:changing', handleChanging)
        editor.off('scroll:changed', handleChanges)
        editor.off('canvas:modified', handleChanges)
        editor.off('after:render', handleChanges)
        editor.off('text:moving', () => handleTextMoving)
        editor.off('text:moved', () => handleTextMoved)
        editor.off('object:replace', onReplaceImage)
        if(!activeObject) {
          setContextMenuDetails(null)
        }
        setIsDotsActive(false)
      }
    }
  }, [editor, activeObject])

  useEffect(() => {
    if(!contextMenuDetails) {
      setIsDotsActive(false)
    }
  }, [contextMenuDetails])

  useEffect(() => {
    if (canvas) {
      caculateInfoIndex()
      setNumberObj(() => canvas._objects.length)
    }
  }, [canvas && Object.values(canvas)])
  useEffect(() => {
    if (canvas && hasBgImage) {
      caculateInfoIndex()
    }
  }, [hasBgImage])

  useEffect(() => {
    isOpenFontFamilyRef.current = isOpenFontFamily;
  }, [isOpenFontFamily]);

  useEffect(() => {
    if(!canvas) {return }
    const handleDeselection = (event, eventType) => {
      setIsOpenFontFamily(eventType === 'selection:updated' && event.selected[0].type === ObjectType.BAZAART_TEXT && isOpenFontFamilyRef.current);  // Set flag back to false
    };
    canvas.on('selection:updated', (e) => handleDeselection(e, 'selection:updated'));
    canvas.on('before:selection:cleared', (e) => handleDeselection(e, 'before:selection:cleared'));
    canvas.on('selection:created', (e) => handleDeselection(e, 'selection:created'));

    // Cleanup event listeners when the component unmounts
    return () => {
      canvas.off('selection:updated', (e) => handleDeselection(e, 'selection:updated'));
      canvas.off('before:selection:cleared', (e) => handleDeselection(e, 'before:selection:cleared'));
      canvas.off('selection:created', (e) => handleDeselection(e, 'selection:created'));
    };
  },[canvas])

  const caculateInfoIndex = () => {
    let maxIndex = 0
    let objectMaxIndex = null
    let hasBgImage = !!canvas._objects.filter(o => o.type === ObjectType.BAZAART_BG).length

    let minIndex = hasBgImage ? 2 : 1

    let objectMinIndex = null
    canvas._objects.forEach(o => {
      if (getIndexObject(o) > maxIndex) {
        maxIndex = canvas.getObjects().indexOf(o)
        objectMaxIndex = o
      }
      if (getIndexObject(o) === minIndex) {
        objectMinIndex = o
      }
    })
    if (infoMaxIndex.index !== maxIndex || infoMaxIndex.objectMaxIndex !== objectMaxIndex) {
      setInfoMaxIndex({
        index: maxIndex,
        objectMaxIndex: objectMaxIndex,
      })
    }
    if (infoMinIndex.objectMinIndex !== objectMinIndex || infoMinIndex.index !== minIndex) {
      setInfoMinIndex({
        index: minIndex,
        objectMinIndex: objectMinIndex,
      })
    }
  }

  const getIndexObject = (obj: fabric.Object): number => {
    if (canvas && obj) {
      return canvas.getObjects().indexOf(obj)
    }
  }
  useEffect(() => {
    var timeoutId

    window.addEventListener('resize', () => {
      clearTimeout(timeoutId)
      setVisible(false)
      timeoutId = setTimeout(() => {
        setFlagChange(pre => !pre)
        setVisible(true)
      }, 300)
    })

    return window.removeEventListener('resize', () => {
      setFlagChange(pre => !pre)
    })
  }, [window])

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      setFlagChange(pre => !pre)
    }, 700)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [isOpenInspector])

  useEffect(() => {
    setFlagChange(pre => !pre)
  }, [disableEditor])

  const onReplaceImage = () => {
    const eventProperties = {}
    customAmplitude('Template Tap to Replace', eventProperties)
    setPopoverActive(PopoverType.PHOTOS)
    setIsReplacingImage(true)
  }
  const onCropImage = () => {
    const eventProperties = {}
    customAmplitude('Floating Crop', eventProperties)
    editor.handlers.cropHandler.toggleEditingMode()
  }
  const onHandleGroup = () => {
    // @ts-ignore
    activeObject.type === ObjectType.GROUP ? editor.handlers.objectsHandler.ungroup() : editor.handlers.objectsHandler.group()
  }
  const onRemoveBgButton = async () => {
    let removeBgButton = document.getElementById('btn-remove-bg')
    if(removeBgButton) {
      removeBgButton.click()
      customAmplitude('Floating Remove background')
    }
  }

  const onMagicBgButton = async () => {
    const magicBgButton = document.getElementById('btn-magic-background')
    customAmplitude('Floating Magic background')
    magicBgButton?.click()
  }

  const onEditWithAiButton = async () => {
    setIsOpenAiImageProcessor(!isOpenAiImageProcessor)
    customAmplitude('Floating AI Edit')
  }

  useEffect(() => {
    if (isReplacingImage) {
      if (popoverActive !== PopoverType.PHOTOS && popoverActive !== PopoverType.STOCK && popoverActive !== PopoverType.AI_IMAGE) {
        setIsReplacingImage(false)
        editor.handlers.objectsHandler.removeReplaceableOverlay()
      }
    } else {
      if (!popoverActive) {
        setIsOpenInspector(true)
      }
    }
  }, [isReplacingImage, popoverActive, activeObject])
  const { t } = useTranslation()

  const onChangeFont = () => {
    setIsOpenFontFamily(true)
    addInspector(InspectorPanelType.FontFamily)
    customAmplitude('Floating Change font')
    customAmplitude('Selected Tool', { Tool: 'bazaart.text.font'})
  }
  
  return (
    <>
      {activeObject && activeObject.type !== ObjectType.FRAME && !disableEditor && !isOpenPixelManipulationObject && !isOpenTutorial? (
        <div
          id="tooltip-menu"
          style={{
            boxSizing: 'border-box',
            position: 'absolute',
            top: Math.round(tooltipTop),
            left: `${tooltipLeft}px`,
            transform: 'translateX(-50%)',
            zIndex: 2,
            height: '40px',
            backgroundColor: '#ffffff',
            borderRadius: '8px',
            filter: 'drop-shadow(0 0 12px rgba(0, 0, 0, 0.15))',
            padding: '4px',
            display: visible ? 'flex' : 'none',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
          }}
        >
          {/* group */}
          {/* @ts-ignore */}
          {(activeObject.type === ObjectType.ACTIVE_SELECTION || activeObject.type === ObjectType.GROUP) && (
            <div style={{ display: 'flex', gap: '8px' }}>
              <TooltipMenuItem
                onClick={onHandleGroup}
                icon="Replace"
                label={activeObject.type === ObjectType.GROUP ? t('Ungroup') : t('Group')}
                style={{
                  position: 'relative',
                  width: 'fit-content',
                  padding: '4px 12px'
                }}
              >
                <LabelLarge>{activeObject.type === ObjectType.GROUP ? t('Ungroup') : t('Group')}</LabelLarge>
              </TooltipMenuItem>
              <div style={{ borderRight: '1px solid rgba(229, 229, 229, 1)' }}></div>
            </div>
          )}
          {/* remove bg for image */}
          {activeObject.type === ObjectType.BAZAART_IMAGE  && (
            // <div style={{ display: 'flex', gap: '8px' }}>
            //   <TooltipMenuItem
            //     onClick={() => (activeObject as unknown as StaticImage).hasTransparency ? onMagicBgButton() : onRemoveBgButton()}
            //     style={{
            //       position: 'relative',
            //       width: 'fit-content',
            //       display: 'flex',
            //       gap: '12px',
            //       flexDirection: 'row',
            //       padding: '0 12px !important'
            //     }}
            //   >
            //     {!(activeObject as unknown as StaticImage).hasTransparency ? <Icons.Scissors /> : <Icons.MagicBackgroundIcon />}
            //     <LabelLarge $style={{whiteSpace: 'nowrap'}}>{!(activeObject as unknown as StaticImage).hasTransparency ? t('Remove background') : t('Magic background')}</LabelLarge>
            //   </TooltipMenuItem>
            //   <div style={{ borderRight: '1px solid rgba(229, 229, 229, 1)' }}></div>
            // </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <TooltipMenuItem
                onClick={onEditWithAiButton}
                style={{
                  position: 'relative',
                  width: 'fit-content',
                  display: 'flex',
                  gap: '12px',
                  flexDirection: 'row',
                  padding: '0 12px !important'
                }}
                selected={isOpenAiImageProcessor}
                label={t('Edit with AI')}
              >
                <Icons.MagicStars size={24} />
                <GradientText $style={{whiteSpace: 'nowrap'}}>{t('Edit with AI')}</GradientText>
              </TooltipMenuItem>
              <div style={{ borderRight: '1px solid rgba(229, 229, 229, 1)' }}></div>
            </div>
          )}
          {/* Change font */}
          {activeObject.type === ObjectType.BAZAART_TEXT && (
            <div style={{ display: 'flex', gap: '8px' }}>
              <TooltipMenuItem
                onClick={() => onChangeFont()}
                style={{
                  position: 'relative',
                  width: 'fit-content',
                  display: 'flex',
                  gap: '12px',
                  flexDirection: 'row',
                  padding: '0 12px !important',
                }}
              >
              <LabelLarge $style={{whiteSpace: 'nowrap'}}>{t('Change font')}</LabelLarge>
              </TooltipMenuItem>
              <div style={{ borderRight: '1px solid rgba(229, 229, 229, 1)' }}></div>
            </div>
          )}

          {/* remove */}
          <TooltipMenuItem
            onClick={() => {
              editor.handlers.objectsHandler.remove()
            }}
            icon="Delete"
            label={t("Delete")}
          >
            <Icons.Delete size={24} />
          </TooltipMenuItem>
          {/* edit for text */}
          {activeObject.type === ObjectType.BAZAART_TEXT && (
            <TooltipMenuItem
              onClick={() => {
                let activeTextObject = activeObject as StaticText 
                if (activeTextObject.isEditing) {
                  activeTextObject.selectAll()
                }
                else {
                  activeTextObject.enterEditing()
                  const eventProperties = {
                    Tool: 'bazaart.edit.text',
                    'Layer Type': ObjectType.BAZAART_TEXT,
                  }
                  customAmplitude('Selected tool', eventProperties)
                }
              }}
              icon="Edit"
              label={t("Edit")}
            >
              <Icons.Edit size={24} />
            </TooltipMenuItem>
          )}
          {/* duplicate */}
          <TooltipMenuItem
            onClick={() => {
              editor.handlers.objectsHandler.clone()
            }}
            icon="Duplicate"
            label={t("Duplicate")}
          >
            <Icons.Duplicate size={24} />
          </TooltipMenuItem>
          {
            ADD_TOOLTIP_REPLACE_BUTTON && activeObject.type === ObjectType.BAZAART_IMAGE && 
            <TooltipMenuItem
              onClick={() => onReplaceImage()}
              icon="Replace"
              label="Replace"
              style={{
                background: isReplacingImage ? 'rgb(229, 229, 229)' : 'transparent',
              }}
            >
              <Icons.Replace />
            </TooltipMenuItem>
          }
          {
            activeObject.type === ObjectType.BAZAART_IMAGE && 
            <TooltipMenuItem
              onClick={() => onCropImage()}
              icon="Crop"
              label="Crop"
              style={{
                background: 'transparent',
              }}
            >
              <Icons.Crop />
            </TooltipMenuItem>
          }
          {/* more */}
          <TooltipMenuItem
            onClick={(e) => {
              if(isDotsActive) {
                setIsDotsActive(false)
                setContextMenuDetails(null)
                return
              }
              setIsDotsActive(true)
              const tooltipMenu = document.getElementById('tooltip-menu')          
              const contextMenu = document.getElementById('context-menu')    
              const renderBlow = tooltipMenu.offsetTop + tooltipMenu.offsetHeight + contextMenu.offsetHeight < window.innerHeight
              setContextMenuDetails({
                left: renderBlow ? tooltipMenu.offsetLeft + tooltipMenu.offsetWidth / 2 - 40 : tooltipMenu.offsetLeft + tooltipMenu.offsetWidth / 2,
                top: renderBlow ? tooltipMenu.offsetTop + tooltipMenu.offsetHeight : tooltipMenu.offsetTop - contextMenu.offsetHeight / 2 ,
              })
            }}
            icon="More"
            label="More"
            style={{
              background: isDotsActive ? `${lightTheme.colors.grayScale100} !important` : 'transparent',
            }}
          >
            <Icons.DotsIcon />
          </TooltipMenuItem>
        </div>
      ) : null}
    </>
  )
}

export default TooltipMenu