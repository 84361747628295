import face_0 from '@assets/images/faces_styles_demo/original.jpeg';
import face_1 from '@assets/images/faces_styles_demo/StudioGhibli.jpeg';
import face_2 from '@assets/images/faces_styles_demo/3D Pixar.jpeg';
import face_3 from '@assets/images/faces_styles_demo/Muppet-Show.jpeg';
import face_4 from '@assets/images/faces_styles_demo/Stop-Motion.jpeg';
import face_5 from '@assets/images/faces_styles_demo/Chibi.jpeg';
import face_6 from '@assets/images/faces_styles_demo/Ligne Claire.jpeg';
import face_7 from '@assets/images/faces_styles_demo/South Park.jpeg';
import face_8 from '@assets/images/faces_styles_demo/Comic Book.jpeg';
import face_9 from '@assets/images/faces_styles_demo/Bande Dessinee.jpeg';
import face_10 from '@assets/images/faces_styles_demo/Kawaii.jpeg';
import face_11 from '@assets/images/faces_styles_demo/Simpsons.jpeg';
import face_12 from '@assets/images/faces_styles_demo/Classic Disney.jpeg';

export interface FaceSwapDemoDataObject {
  style: string,
  image: string,
  isOriginal?: boolean;
}
export const FaceSwapDemoData: FaceSwapDemoDataObject[] = [
    {
      style: "Original",
      image: face_0,
      isOriginal: true
    },
    {
      style: "Mystical Worlds",
      image: face_1
    },
    {
      style: "3D Animation",
      image: face_2
    },
    {
      style: "Puppet Follies",
      image: face_3
    },
    {
      style: "Handcrafted",
      image: face_4
    },
    {
      style: "Cute Miniatures",
      image: face_5
    },
    {
      style: "Clean Lines",
      image: face_6
    },
    {
      style: "Cutout Satire",
      image: face_7
    },
    {
      style: "Sequential Art",
      image: face_8
    },
    {
      style: "Pinocchio",
      image: face_9
    },
    {
      style: "Adorable Charm",
      image: face_10
    },
    {
      style: "Yellow Satire",
      image: face_11
    },
    {
      style: "Timeless Magic",
      image: face_12
    }
  ];