import { lightTheme } from '@/customTheme'
import useAppContext from '@/hooks/useAppContext'
import Icons from '@/scenes/Editor/components/Icons'
import { ShowModalType } from '@/scenes/engine/common/constants'
import { Modal, ROLE, SIZE } from 'baseui/modal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ButtonCustom from '../ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { KIND } from 'baseui/button'
import { useAppDispatch } from '@/store/store'
import { setOpenModalTryPremium } from '@/store/slices/user/actions'
import { useHistory } from 'react-router-dom'
import { useTour } from '@reactour/tour'
import { selectUser } from '@/store/slices/user/selectors'
import { useSelector } from 'react-redux'
import { useEditorContext, useRemoveEditorContext } from '@/scenes/engine'
import { clearTutorial } from '@/utils/tutorial'
// @ts-ignore
import lottieAnimation from '@/assets/lottie/great_work.lottie';
import { DotLottiePlayer } from '@dotlottie/react-player'
import { customAmplitude } from '@/utils/customAmplitude'

const ModalGoodWork: React.FC<{user: any, history: any, dispatch}> = ({user, history, dispatch}) => {
  const { showModalType, setShowModalType, setIsOpenTutorial, cancelPixelManipulation } = useAppContext()
  const editor = useEditorContext().editor
  const removeEditor = useRemoveEditorContext().editor
  const { t } = useTranslation()
  const { setIsOpen, setSteps } = useTour()

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      zIndex: '10000',
      transition: 'none',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <DotLottiePlayer
        src={lottieAnimation}
        autoplay
        // loop
        style={{
          height: '100px',
          width: '140px',
        }}
      >
      </DotLottiePlayer>
      <h2 className="good-work-text" style={{ marginTop: '24px' }}>
        {t('Good work!')}
      </h2>
      <p
        style={{
          ...lightTheme.typography.Small14regular,
          marginTop: '16px',
          textAlign: 'center',
          color: '#666',
        }}
      >
        {t('Now, try to remove people and objects from your own photos.')}
      </p>
      {!user?.userIsPremium ? (
        <ButtonCustom
          type={SizeButton.LARGE}
          kind={KIND.primary}
          style={{
            width: '100%',
            gap: '6px',
            marginTop: '24px',
            backgroundColor: lightTheme.colors.buttonPrimaryFill,
            ':hover': {backgroundColor: lightTheme.colors.buttonPrimaryHover},
            ':active': {backgroundColor: lightTheme.colors.buttonPrimaryActive}
          }}
          onClick={() => {
            setIsOpen(false)
            dispatch(
              setOpenModalTryPremium({
                isOpen: true,
                source: 'Tutorial',
                opened: true,
              })
            )
            const eventProperties = {
              Source: 'Tutorial',
            }
            customAmplitude('Premium Prompt', eventProperties)
            // @ts-ignore
            window.dataLayer.push({ event: 'premium_prompt', ...eventProperties })
            customAmplitude('Tutorial Step Completed', { 'Tutorial Step Number' : 4 })
          }}
        >
          <Icons.PremiumIcon />
          <p style={{ ...lightTheme.typography.Small14Semibold, color: '#fff' }}>{t('Try Premium')}</p>
        </ButtonCustom>
      ) : null}
      <ButtonCustom
        type={SizeButton.LARGE}
        kind={KIND.secondary}
        style={{ width: '100%', gap: '6px', marginTop: '12px' }}
        onClick={() => {
          let showedRemoveObjectTutorial = localStorage.getItem('showedRemoveObjectTutorial') ? JSON.parse(localStorage.getItem('showedRemoveObjectTutorial')) : false
          if(showedRemoveObjectTutorial && showedRemoveObjectTutorial.from !== '/') {
            history.push(showedRemoveObjectTutorial.from)
          } else {
              removeEditor.handlers.pixelManipulationObjectHandler.FinishToolState().then(() => {
                removeEditor.handlers.pixelManipulationObjectHandler.reset()
                removeEditor.handlers.canvasRemoveHandler.canvas.clear()
                const cursorPreview = document.querySelector('.custom-cursor') as HTMLElement;
                cursorPreview.style.display = 'none'
                editor.handlers.scrollbarHandler.updateScrollPosition()
                cancelPixelManipulation()
                clearTutorial(editor.handlers)
              })
          }
          setIsOpenTutorial(false)
          setShowModalType(null)
          setIsOpen(false)
          setSteps([])
          customAmplitude('Tutorial Step Completed', { 'Tutorial Step Number' : 4 })
        }}
      >
        <p style={{ ...lightTheme.typography.Small14Semibold }}>{t('Close')}</p>
      </ButtonCustom>
    </div>
  )
}

export default ModalGoodWork