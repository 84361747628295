// @ts-nocheck

import { fabric } from 'fabric'
import { controlPositionIcons } from './controls'
import * as ControlsCrop from './controlsCrop';
import { loadImageFromURL } from '../../utils/image-loader'
import { MediaImageRepository } from '@scenes/engine/objects/media-repository/media_image_repository'
import { MediaImageType } from '@scenes/engine/objects/media-repository/media_image_type'
import { Rectangle } from '@scenes/engine/objects/media-repository/rectangle'
import { Inset } from '@scenes/engine/objects/media-repository/inset'
import { Size } from '@scenes/engine/objects/media-repository/size'
import { nanoid } from 'nanoid'
import CanvasImageRenderer from '@scenes/engine/utils/canvasImageRenderer'
import store from '@/store/store'
import { ObjectType } from '../../common/constants'
import { customAmplitude } from '@/utils/customAmplitude'

export class StaticImageObject extends fabric.Image {
  static type = 'StaticImage'
  public _editingMode = false
  public __editingImage = null
  private _inset: Inset = null
  public boundingBox: Rectangle | null = null;

  cornerLengthEditing = 5
  effects: {
    [key: string]: {
      [key: string]: any
    }
  } = {}
  effects_from_template: {
    [key: string]: {
      [key: string]: any
    }
  } = {}
  
  useBzrtBgMask = false
  isLatest = false

  /**
   * Color of the corner stroke in editing mode.
   */
  cornerStrokeColorEditing = 'black'

  /**
   * Size of the corner stroke in editing mode.
   */
  cornerSizeEditing = 2

  hasTransparency = true

  layerAssetStateId = null

  sizeOnCanvas = {
    width: 0.5,
    height: 0.5,
  }

  isTemplateLayer = false

  _filterScalingX = 1
  _filterScalingY = 1

  cacheCounter = 0

  isIntializedNormalizeMask = true
  isIntializedNormalizedImage = true
  
  isIntialized () {
    return this.isIntializedNormalizeMask && this.isIntializedNormalizedImage;
  };

  useNewTextureNextTime() {
    this.cacheCounter ++
  }

  getTextureKey(){
    return `${this.cacheKey}_${this.layerAssetStateId}_${this.cacheCounter}`;
  }

  useTextureOf(sourceObject:StaticImage)
  {
    this.cacheKey = sourceObject.cacheKey
    this.layerAssetStateId = sourceObject.layerAssetStateId
    this.cacheCounter = sourceObject.cacheCounter
  }

  async replaceImage(
    image: base64Image,
    withResize: boolean,
    magicLayer?: boolean = false
  ): Promise<boolean> {
    let self = this
    return loadImageFromURL(image).then(img => {
      //@ts-ignore

      let imageScaleX = self.width / img.width
      let imageScaleY = self.height / img.height

      !magicLayer && self.setElement(img)

      if (withResize) {
        let newScale = Math.max(imageScaleX, imageScaleY)
        self._originalScaleX *= self.type === ObjectType.BAZAART_IMAGE ? imageScaleX : newScale
        self._originalScaleY *= self.type === ObjectType.BAZAART_IMAGE ? imageScaleY : newScale
        self.scaleX *= self.type === ObjectType.BAZAART_IMAGE ? imageScaleX : newScale
        self.scaleY *= self.type === ObjectType.BAZAART_IMAGE ? imageScaleY : newScale
      }
      
      self.set('isLatest', false);
      self.set('dirty', true)
      self.useNewTextureNextTime();
        //@ts-ignore
      self.applyFilters(self.filters)
    })
  }

  async replaceImageUrl(url: string, withResize: boolean, magicLayer?: boolean = false) {
    let assetStateId = nanoid()
    let prevMask = await MediaImageRepository.getInstance().getImage(this.id ,this.layerAssetStateId, MediaImageType.mask)
    const originMask = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.extractMask(url)
    if (this.hasTransparency) {
      
      let originHtmlMask = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(originMask.blob);
      let prevHtmlMask = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(prevMask);
      let aspectFitMaskWithoutCrop = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.aspectFitMaskToImage(originHtmlMask, prevHtmlMask)
      await MediaImageRepository.getInstance().storeImageBlobString(this.id, assetStateId, MediaImageType.maskWithoutCrop, aspectFitMaskWithoutCrop.src)
    } else {
      await MediaImageRepository.getInstance().storeImageBlobString(this.id, assetStateId, MediaImageType.maskWithoutCrop, originMask.blob)

    }

    await MediaImageRepository.getInstance().storeImageUrl(
      this.id,
      assetStateId,
      MediaImageType.original,
      url
    )

    await MediaImageRepository.getInstance().storeImageBlobString(
      this.id,
      assetStateId,
      MediaImageType.mask,
      prevMask
    )
    

    let latestImageInfo = await MediaImageRepository.getInstance().generateLatestImageInfo(this.id, assetStateId)
    await MediaImageRepository.getInstance().storeLatestImageInfo(this.id, assetStateId, latestImageInfo)
    
    this.boundingBox = latestImageInfo.boundingBox;
    await this.replaceImage(latestImageInfo.latestImage.toDataURL(), withResize, magicLayer)

    this.set('layerAssetStateId', assetStateId)
    if(!magicLayer) {
      this.set('dirty', true)
      this.applyFilters()
      this.canvas?.requestRenderAll()
    }
  }

  registerEditingEvents() {
    // replace image :
    var input = document.createElement('input')
    input.type = 'file'
    input.onchange = e => {
      var file = e.target.files[0]
      if (file) {
        var reader = new FileReader()
        reader.onload = async event => {
          await this.replaceImageUrl(event.target.result)
        }

        reader.readAsDataURL(file)
      }
    }
    this.on('deselected', () => {
      if (!this._editingMode) {
      this.perPixelTargetFind = true
      }
    })
    this.on('selected',()=>{
      // on selected state we want that transpaernt pixels will be selectable too, for batter dragging (using on _checkTarget func)
      this.perPixelTargetFind = false
    })
  }

  __updateBoundingBox() {
    this.boundingBox = new Rectangle(
      this.cropX / this.__editingImage.width,
      this.cropY / this.__editingImage.height,
      this.width / this.__editingImage.width,
      this.height / this.__editingImage.height,
    )
  }

  //@ts-ignore
  initialize(element, options) {
    // @ts-ignore
    this.registerEditingEvents()
    this._inset = new Inset(0, 0, 0, 0)
    this.boundingBox = options.boundingBox ?? new Rectangle(0, 0, 1, 1)
    
    if (options._originalScaleX && options._originalScaleY) {
      options.scaleX = options._originalScaleX / (options._filterScalingX ? options._filterScalingX : 1)
      options.scaleY = options._originalScaleY / (options._filterScalingY ? options._filterScalingY : 1)
    } else {
      options._originalScaleX = options.scaleX
      options._originalScaleY = options.scaleY
    }
    
    super.initialize(element, options)
    
    let that = this
    this.on('scaling', function (e) {
      that._originalScaleX = that.scaleX * that._filterScalingX
      that._originalScaleY = that.scaleY * that._filterScalingY
    })

    return this
  }  

  static fromObject(options: any, callback: Function) {
    fabric.util.loadImage(
      options.src,
      async function (img) {
        let state = store.getState()
        options.filters = await CanvasImageRenderer.getInstance().getFilters(options, state.editor?.imageElements?.imageElements);
        let staticImage = new fabric.StaticImage(img, options)

        return callback && callback(staticImage)
      },
      null,
      // @ts-ignore
      { crossOrigin: 'anonymous' }
    )
  }

  toObject(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude)
  }

  toJSON(propertiesToInclude = []) {
    return super.toObject(propertiesToInclude)
  }

  applyFilters(inputFilters?, filterBackend?, optimize? = true) {
    if (!this.dirty || this.isLatest) {
      return this;
    }
    let filters = inputFilters || this.filters || []
    this.filters = filters;
    
    let imgElement = this._originalElement
    let inputSize = new Size(imgElement.width, imgElement.height)
    
    let bb = new Rectangle(0, 0, 1, 1)
    let roi = bb.multiply(inputSize);
    // let roi = this.boundingBox.multiply(inputSize);

    roi.round();
    

    let paddedSize = filters.reduce(
      (total, filter) => (filter.padSize ? filter.padSize(total) : total),
      roi.size()
    )
    let scaleX = roi.width / paddedSize.width
    let scaleY = roi.height / paddedSize.height

    let inputInset = new Inset(0, 0, 0, 0)
    let inset = filters.reduce(
      (total, filter) => (filter.applyInset ? filter.applyInset(total, inputSize) : total),
      inputInset
    )

    filters = filters.filter(function (filter) {
      return filter && !filter.isNeutralState()
    })

    let backend = filterBackend ?? fabric.filterBackend;
    this._element = backend.applyFiltersWithBoundingBox(
      filters,
      this._originalElement,
      roi.size(),
      inset,
      scaleX,
      scaleY,
      this.getTextureKey(),
      bb, // this.boundingBox
      optimize
    )

    this.filters = filters;
    this._filterScalingX = scaleX
    this._filterScalingY = scaleY
    this._inset = inset

    this.scaleX = this._originalScaleX / this._filterScalingX
    this.scaleY = this._originalScaleY / this._filterScalingY


    return this
  }
  
  _calculateCurrentDimensions() {
    let wh = super._calculateCurrentDimensions()
    wh.x *= this._filterScalingX
    wh.y *= this._filterScalingY
    return wh
  }

  toLocalPoint(point, originX, originY) {
    point.x /= this._filterScalingX
    point.y /= this._filterScalingY

    var center = this.getCenterPoint(),
      p,
      p2

    center.x /= this._filterScalingX
    center.y /= this._filterScalingY

    if (typeof originX !== 'undefined' && typeof originY !== 'undefined') {
      p = this.translateToGivenOrigin(center, 'center', 'center', originX, originY)
    }
    else {
      p = new fabric.Point(this.left, this.top)
    }

    p2 = new fabric.Point(point.x, point.y)
    if (this.angle) {
      p2 = fabric.util.rotatePoint(p2, center, -fabric.util.degreesToRadians(this.angle))
    }
    return p2.subtractEquals(p)
  }

  _calcYOffset(): number {
    return this._inset.top - this._inset.bottom
  }

  _calcXOffset(): number {
    return this._inset.left - this._inset.right
  }

  _getCacheCanvasDimensions() {
    let dims = super._getCacheCanvasDimensions()
    if(!this._originalElement) { return dims }
    let insetOffsetX =
      (this._filterScalingX * Math.abs(this._calcXOffset()) * dims.width) / this._originalElement.width
    let insetOffsetY =
      (this._filterScalingY * Math.abs(this._calcYOffset()) * dims.height) / this._originalElement.height
    dims.width += insetOffsetX
    dims.height += insetOffsetY
    return dims
  }

  _renderFill(ctx) {
    var elementToDraw = this._element
    if (!elementToDraw) {
      return
    }

    let insetOffsetX = this.isLatest || this._editingMode ? 0 : this._calcXOffset() * this._filterScalingX
    let insetOffsetY = this.isLatest || this._editingMode ? 0 : this._calcYOffset() * this._filterScalingY

    var min = Math.min,
        max = Math.max;

    var scaleX = this._filterScalingX,
      scaleY = this._filterScalingY,
      w = this.width,
      h = this.height,
      // crop values cannot be lesser than 0.
      cropX = (this._editingMode || this.filters.length == 0) ? max(this.cropX, 0) : 0,
      cropY = (this._editingMode || this.filters.length == 0) ? max(this.cropY, 0) : 0,
      elWidth = elementToDraw.naturalWidth || elementToDraw.width,
      elHeight = elementToDraw.naturalHeight || elementToDraw.height,
      sX = cropX * scaleX,
      sY = cropY * scaleY,
      // the width height cannot exceed element width/height, starting from the crop offset.
      sW = min(w * 1.0, elWidth - sX),
      sH = min(h * 1.0, elHeight - sY),
      x = -(w + insetOffsetX) / 2,
      y = -(h + insetOffsetY) / 2,
      maxDestW = min(w, elWidth / scaleX - cropX),
      maxDestH = min(h, elHeight / scaleY - cropY)

    elementToDraw && ctx.drawImage(elementToDraw, sX, sY, sW, sH, x, y, maxDestW, maxDestH)
  }


}

fabric.StaticImage = fabric.util.createClass(StaticImageObject, {
  type: StaticImageObject.type,
  perPixelTargetFind: true,
  useBzrtBgMask: false,
  isLatest: false,
  cacheCounter: 0
})
fabric.StaticImage.fromObject = StaticImageObject.fromObject

export interface StaticImageOptions extends fabric.IImageOptions {
  id: string
  name?: string
  description?: string
  subtype: string
  src: string
  useBzrtBgMask: boolean,
  isLatest: boolean,
  cacheCounter: number
}

declare module 'fabric' {
  namespace fabric {
    class StaticImage extends StaticImageObject {
      registerEditingEvents: () => void
      constructor(element: any, options: any)
    }

    interface IUtil {
      isTouchEvent(event: Event): boolean
      getPointer(event: Event, a?: any): Point
    }
    interface Image {
      useNewTextureNextTime: () => void
      isIntialized: () => void
      _editingMode: boolean
      __editingImage: Image | null
      isLatest: boolean
      hasTransparency: boolean
      cornerLengthEditing: number
      cornerSizeEditing: number
      cornerStrokeColorEditing: string
    }
  }
}
