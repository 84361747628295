import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <rect width={32} height={32} fill="#fff" rx={16} />
    <g fill="#111" fillRule="evenodd" clipPath="url(#premium_icon)" clipRule="evenodd">
      <path d="M17.438 10.72a1.44 1.44 0 0 1-.839 1.31 2.11 2.11 0 0 0 0 .13c0 1.913 1.425 3.459 3.185 3.459 1.459 0 2.679-1.062 3.067-2.503a1.44 1.44 0 1 1 1.069.276c-.25 1.046-.488 2.109-.72 3.174a2.199 2.199 0 0 0-.129.147l-2.25 2.805-.401-.364a2.2 2.2 0 1 0-2.96 3.255l.342.311h-5.698a2.505 2.505 0 0 1-2.452-1.924c-.321-1.38-.613-2.769-.905-4.157-.228-1.086-.456-2.171-.698-3.251a1.44 1.44 0 1 1 1.065-.245c.406 1.417 1.62 2.457 3.056 2.457 1.762 0 3.186-1.546 3.186-3.46a1.994 1.994 0 0 0 0-.13 1.44 1.44 0 0 1-.656-1.915c.216-.484.71-.82 1.278-.815H16.007a1.44 1.44 0 0 1 1.431 1.44Z" />
      <path d="M25.287 17.465a.8.8 0 0 1 .124 1.125l-3.61 4.498a.96.96 0 0 1-1.394.11l-2.006-1.825a.8.8 0 1 1 1.077-1.183l1.502 1.365 3.183-3.966a.8.8 0 0 1 1.124-.124Z" />
    </g>
    <defs>
      <clipPath id="premium_icon">
        <path fill="#fff" d="M6.4 6.4h19.2v19.2H6.4z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
