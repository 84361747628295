import { StaticImage } from 'fabric/fabric-impl'
import { ObjectType } from '../common/constants'
import { HandlerOptions } from '../common/interfaces'
import BaseHandler from './BaseHandler'
import { Rectangle } from '@scenes/engine/objects/media-repository/rectangle'
import { MediaImageRepository } from '@scenes/engine/objects/media-repository/media_image_repository'
import { MediaImageType } from '@scenes/engine/objects/media-repository/media_image_type'
import CanvasImageRenderer from '@scenes/engine/utils/canvasImageRenderer'
import { customAmplitude } from '@/utils/customAmplitude'
import { controlPositionIcons } from '../objects/static-image/controls'
import { Size } from '@scenes/engine/objects/media-repository/size'
import store from '@/store/store'
import * as ControlsCrop from '../objects/static-image/controlsCrop'
import { fabric } from 'fabric'
import { nanoid } from 'nanoid'
import { lightTheme } from '@/customTheme'
import { TransactionScopeState } from './TransactionHandlerScoped'

export enum ControlPositions {
  TOP_LEFT = 'tl',
  TOP = 't',
  TOP_RIGHT = 'tr',
  RIGHT = 'r',
  BOTTOM_RIGHT = 'br',
  BOTTOM = 'b',
  BOTTOM_LEFT = 'bl',
  LEFT = 'l',
  TOP_LEFT_SOURCE = 'tlS',
  TOP_RIGHT_SOURCE = 'trS',
  BOTTOM_RIGHT_SOURCE = 'brS',
  BOTTOM_LEFT_SOURCE = 'blS',
}

class CropHandler extends BaseHandler {
  // private __editingImage = null
  // private boundingBox: Rectangle | null = null;
  private _duringEnterEditingMode = false
  private _duringExitEditingMode = false
  private _stackIndexBeforeEditingMode = -1;
  private _listenersBeforeEditingMode = []
  private aspectRatio = null
  private object = null
  private gridLines = []
  private _overlay?: fabric.Rect
  private _overlayClick: (event)=>void = (event)=>{}
  private startPointer = null
  private _filterScalingXBeforCrop = 1;
  private _filterScalingYBeforCrop = 1;
  private _originalLeft = 0
  private _originalTop = 0
  private _showSourceControls = null
  private _beforeCrop = null
  private _updateCallback = null
  private _isTemplateLayerBeforeMouseDown = false
  private _minCropSize = 100
  private _mouseUpFunction = null
  private _canvasJsonBeforeCrop = null
  private _editingImageStrokeWidth = 1.5
  private _transactionHandlerState = 'crop'

  constructor(props: HandlerOptions) {
    super(props)
    this.initialize()
  }

  initialize() {
    this._registerEvent()
  }

  // Register Event
  private _registerEvent = () => {
    this.canvas.on('mouse:dblclick', this.dblClick)
    this.canvas.on('mouse:down', this.handleMouseDown)
  }

  private handleMouseDown = (event) => {
    const { target } = event;
    if (target) {
      this._isTemplateLayerBeforeMouseDown = target.isTemplateLayer || false
    }
  }

  dblClick = event => {
    const { target } = event
    if (target && target.type === ObjectType.BAZAART_IMAGE && !this._isTemplateLayerBeforeMouseDown) {
      if (!this.object) {
        return this.enterEditingMode(target)
      } else {
        this.exitEditingMode()
      }
    }
  }

  destroy() {
    this.canvas.off({
      'mouse:dblclick': this.dblClick
    })
    this.canvas.off('mouse:down', this.handleMouseDown)
  }

  toggleEditingMode() {
    if (this.object) {
      this.exitEditingMode()
    } else {
      this.enterEditingMode(this.canvas.getActiveObject() as fabric.Image)
    }
  }

  async loadOriginImage(cb: ()=>void) {
    const loadAssetAsImage = (type: MediaImageType): Promise<HTMLImageElement> => {
      return MediaImageRepository.getInstance()
        .getImage(this.object.bazaartGuid, this.object.layerAssetStateId, type)
        .then((base64) => {
          if (!base64) {
            return null;
          }
          return MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(base64);
        });
    };
    // Load original image
    let promises = [loadAssetAsImage(MediaImageType.original), loadAssetAsImage(MediaImageType.maskWithoutCrop), loadAssetAsImage(MediaImageType.mask)]
    Promise.all(promises).then(async (images) => {
        let original = images[0];
        let maskWithoutCrop = images[1]
        let regularMask = images[2]
       
        let mask = maskWithoutCrop ?? regularMask
        if (!original || !mask) {
          console.log((!original ? "{Original}" : "") + (!mask ? " {Mask}" : "") + " not loaded")
          return
        }
        let maskedBase64Image = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.applyMaskToImage(original, mask);
        let imageToShowAsOriginal = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(maskedBase64Image);
        
        this.object.setElement(imageToShowAsOriginal)
        
        this._filterScalingXBeforCrop = this.object._filterScalingX;
        this._filterScalingYBeforCrop = this.object._filterScalingY;
  
        this.object.scaleX *= this.object._filterScalingX;
        this.object.scaleY *= this.object._filterScalingY;
        
        this.object._filterScalingX = 1;
        this.object._filterScalingY = 1;


        let cropX = this.object.boundingBox.x * original.width
        let cropY = this.object.boundingBox.y * original.height
        this.object.set({
          cropX: cropX,
          cropY: cropY,
          width: this.object.boundingBox.width * original.width,
          height: this.object.boundingBox.height * original.height,
        })
        cb()
    })
  }

  async setAndLoadLatest() {
    let layerId = this.object.id
    let currentAssetStateId = this.object.layerAssetStateId
    let newAssetStateId = nanoid()
    let absolueBoundingBox = new Rectangle(
      this.object.cropX ?? 0,
      this.object.cropY ?? 0,
      this.object.width,
      this.object.height
    )

    let original = await MediaImageRepository.getInstance().getImage(layerId, currentAssetStateId, MediaImageType.original)
    let latest = await MediaImageRepository.getInstance().getImage(layerId, currentAssetStateId, MediaImageType.latest)
    await MediaImageRepository.getInstance().storeImageBlobString(layerId, newAssetStateId, MediaImageType.original, original)
    
    let currentMask = await MediaImageRepository.getInstance().getImage(layerId, currentAssetStateId, MediaImageType.maskWithoutCrop)
    if (!currentMask) {
      currentMask = await MediaImageRepository.getInstance().getImage(layerId, currentAssetStateId, MediaImageType.mask)
    }
    
    await MediaImageRepository.getInstance().storeImageBlobString(layerId, newAssetStateId, MediaImageType.maskWithoutCrop, currentMask)
    let currentMaskImage = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(currentMask);  

    let croppedMask = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.cropHtmlImage(currentMaskImage, absolueBoundingBox);
    let paddedMask = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.padImage(croppedMask, new Size(currentMaskImage.width, currentMaskImage.height,), absolueBoundingBox, "#ffffff");
    let greyMask = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.extractGreyMaskFromColorImage(paddedMask.src);
    await MediaImageRepository.getInstance().storeImageBase64(layerId, newAssetStateId, MediaImageType.mask, greyMask.src)


    let latestImageInfo = await MediaImageRepository.getInstance().generateLatestImageInfo(layerId, newAssetStateId, null, absolueBoundingBox)
    await MediaImageRepository.getInstance().storeLatestImageInfo(layerId, newAssetStateId, latestImageInfo)

    this.object._filterScalingX = this._filterScalingXBeforCrop;
    this.object._filterScalingY = this._filterScalingYBeforCrop;

    this.object._originalScaleX = this.object.scaleX
    this.object._originalScaleY = this.object.scaleY

    this.object.scaleX *= this.object._filterScalingX;
    this.object.scaleY *= this.object._filterScalingY;
    
    this._filterScalingXBeforCrop = 1.0;
    this._filterScalingYBeforCrop = 1.0;

    this.object.set('layerAssetStateId', newAssetStateId) // should be call before replaceImage because texture using it, and its should be updated.

    const dataURL = latestImageInfo.latestImage.toDataURL()
    await this.object.replaceImage(dataURL, true)
    this.object.set({
      cropX: 0,
      cropY: 0
    })
    
    this.object.set('isLatest', false);
    this.canvas?.renderAll()
    this.object.setCoords()

  }

  restartAfterObjectInitialized(object) {
    if (object.isIntialized()) {
      return
    }

    this.editorEventManager.on('object:Intialized', async (initializedObject) => {
      if(initializedObject.bazaartGuid != object.bazaartGuid){
        return
      }
      this.editorEventManager.off('object:Intialized')
      await this.enterEditingMode(initializedObject)
    })
  }

  isRenderingLatest = false;
  async renderLatest(object) {
    if (!object.isLatest || this.isRenderingLatest) {
      return
    }
    this.isRenderingLatest = true;

    let renderer = CanvasImageRenderer.getInstance();
    // TODO: change this to parameter size instead of square size.
    let canvasSize = this.root.frameHandler.getSize()

    // let canvasSize = new Size(beforeCrop.width, beforeCrop.height);
    let filtersColorCube = store.getState().editor.imageElements.imageElements;
    await renderer.render(object, canvasSize, filtersColorCube);

    // we need to render the layer twice, we need the first one to set the correct image size and then the second time to be relative to the correct size.
    // this is a more basic bug we should fix sometime
    await renderer.render(object, canvasSize, filtersColorCube);

    this.canvas.renderAll()
    await this.enterEditingMode(object)

    this.isRenderingLatest = false;
  }

  async enterEditingMode(activeObject: fabric.Image, updateStage: boolean = true) {
    if (this.object) {
      console.log("Object is already set")
      return
    }

    if (!(activeObject as unknown as StaticImage).isIntialized()) {
      this.restartAfterObjectInitialized(activeObject)
      return
    }

    if (activeObject.isLatest) {
      this.renderLatest(activeObject)
      return
    }
    this.object = activeObject
    if (this.object._editingMode || this._duringEnterEditingMode || this._duringExitEditingMode) {
      return
    }
    this._duringEnterEditingMode = true
    this._canvasJsonBeforeCrop = this.root.canvasHandler.exportToCanvasJSON()
    
    let edit = async ()=> {
      if (this.object.selectable && this.canvas) {
        this.setAnchorToTopLeft(this.object)
        this._originalLeft = this.object.left
        this._originalTop = this.object.top
        
        this.object.clone(async (image: fabric.Image) => {
          let original = this.object._originalElement.src;

          const element = image.getElement()
          this.object.__editingImage = image
          this.object.__editingImage.set({
            scaleX: this.object.scaleX,
            scaleY: this.object.scaleY,
          })
          this.setEditingImage()
          this.object.__editingImage.set({
            height: element.height,
            width: element.width,
            opacity: 0.5,
            selectable: true,
            evented: true,
            excludeFromExport: true,
            clipPath: null,
            _editingMode: true,
            hasBorders: false,
            hasControls: false,
            perPixelTargetFind: false,
            stroke: lightTheme.colors.pink,
            strokeWidth: this._editingImageStrokeWidth / this.canvas.getZoom(),
            strokeUniform: true
          })
          this.root.transactionHandler.save(this.captureCurrentState())

          this.object.__editingImage.on('mouseover', function() {
            this.canvas.renderAll()
          });
  
          this.canvas!.add(this.object.__editingImage)
          this.object.__editingImage.on('moving', this.__editingOnMoving.bind(this))
          this.object.__editingImage.on('mousedown', (()=>{
            this.canvas.setActiveObject(this.object)
            this.canvas.renderAll()
            this._originalLeft = this.object.left
            this._originalTop = this.object.top
          }))
          this._mouseUpFunction = (event) => {
            this.startPointer = null;
            this.removeGrid()
            this.root.transactionHandler.save(this.captureCurrentState())
          }
          this.object.on('mouseup', this._mouseUpFunction)
          this.object.__editingImage.on('mouseup', this._mouseUpFunction);
          this.editorEventManager.on('canvas:modified', this.setEditingImageStrokeWidthHandler)
          this.__setEditingControls()
          this.__setSourceControlsVisibility()
          this.object.__editingImage.__setSourceControlsVisibility = this.__setSourceControlsVisibility.bind(this)
        })
      }
    }

    if (updateStage) {
      this.editorEventManager.emit("enter-crop-state")
      this.root.transactionHandler.openScope<CropState>(this._transactionHandlerState, this.applyState.bind(this), "CropState")

      const onClick = (event) => {
        if (event.target === this._overlay) {
            this.exitEditingMode()
        }
    }
      // Save z-index and bring to front
      this.isolateImage(onClick)
    }

    // fire event in order to add to history
    // this.canvas.fire('object:modified', { target: this.object })
    // this.object.disableObjectModifiedEvents()
    customAmplitude('Selected tool', {
      Tool: 'bazaart.cutout.crop',
    })

    this._beforeCrop = {
      top: this.object.top,
      left: this.object.left,
      cropX: this.object.cropX,
      cropY: this.object.cropY,
      width: this.object.width,
      height: this.object.height,
      boundingBox: this.object.boundingBox,
      element: this.object.getElement(),
      flipX: this.object.flipX,
      flipY: this.object.flipY,
      originX: this.object.originX,
      originY: this.object.originY,
    }
    
    let startCropAfterLoadImage = async ()=>{
      await edit()
      this.object._editingMode = true
      this._duringEnterEditingMode = false
    }
    await this.loadOriginImage(startCropAfterLoadImage)
  }

  public async restartEditingMode() {
    if (this.object) {
      const currentObject = this.object
      const beforeCropValues = this._beforeCrop
      await this.exitEditingMode(()=>{
        this.enterEditingMode(currentObject, false)
        this._beforeCrop = beforeCropValues
      }, false, false, false)
    }
  }

  // @ts-ignore
  __setEditingControls() {
    const controls = Object.values(ControlPositions)
    const controlDict = controls.reduce((acc, control) => {
      acc[control] = this.__createEditingControl(control)
      return acc;
    }, {});

    let sourceControls;
    if (this.object.flipX && this.object.flipY) {
      sourceControls = ControlsCrop.flipXYCropControls
    }
    else if (this.object.flipX) {
      sourceControls = ControlsCrop.flipXCropControls
    }
    else if (this.object.flipY) {
      sourceControls = ControlsCrop.flipYCropControls
    }
    else {
      sourceControls = ControlsCrop.croppingControlSet
    }

    this.object.controls = {
      ...controlDict,
      ...sourceControls
    }
    for (let control in this.object.controls) {
      this.object.setControlVisible(control, true)
    }
    this.object.setCoords()
    this.canvas.requestRenderAll()
  }

  async exitEditingMode(cb?: ()=>void, discard?: boolean, updateStage: boolean = true, setLatest: boolean = true) {
    if (!this.object || !this.object._editingMode || this._duringExitEditingMode || this._duringEnterEditingMode) {
      return
    }
    this._duringExitEditingMode = true
    
    // restore zIndex position on the canvas
    this.object.off('mouseup', this._mouseUpFunction);
    this.editorEventManager.off('canvas:modified', this.setEditingImageStrokeWidthHandler)
    if (this.object.selectable && this.canvas) {
      if (this.object.__editingImage) {
        this.object.__editingImage.off('moving', this.__editingOnMoving)
        this.object.__editingImage.off('mouseup', this._mouseUpFunction)

        this.canvas.remove(this.object.__editingImage)
        if (!discard) {
          this.object.__updateBoundingBox();
        }
        this.object.__editingImage = null
        this.setAnchorToCenter(this.object)
      }
      this.object.setCoords()
      this._showSourceControls = null
      this.removeGrid()

      this.canvas?.requestRenderAll()

      if (updateStage) {
        this.root.transactionHandler.closeScope(this._transactionHandlerState)
        this.removeIsolation()
        this.editorEventManager.emit("exit-crop-state")
      }

      this.restoreControls()
      if (discard) {
        this.object.setElement(this._beforeCrop.element)
        delete this._beforeCrop.element
        this.object.set({...this._beforeCrop})
        this.setAnchorToCenter(this.object)
        this._beforeCrop = null
        await this.restoreEffects()
      }
      else if (setLatest){
        this.canvas.setActiveObject(this.object)
        this.canvas.renderAll()
        await this.setAndLoadLatest();
      }
      this.object._editingMode = false
      
      if (!discard) {
        this.root.transactionHandler.save(this._canvasJsonBeforeCrop)
      }

      this.object.applyFilters()
      this.canvas.renderAll()
    }
    this._duringExitEditingMode = false
    
    this.object = null
    cb?.()
    // this.object.restoreObjectModifiedEvents()
    // this.canvas.fire('object:modified', { target: this.object })
  }

  async restoreEffects() {
    // Render to re- add effects
    let renderer = CanvasImageRenderer.getInstance()
    let canvasSize = this.root.frameHandler.getSize()
    let filtersColorCube = store.getState().editor.imageElements.imageElements
    await renderer.render(this.object, canvasSize, filtersColorCube)
    // we need to render the layer twice, we need the first one to set the correct image size and then the second time to be relative to the correct size.
    // this is a more basic bug we should fix sometime
    await renderer.render(this.object, canvasSize, filtersColorCube)
  }

  restoreControls() {
    const controls = [
      ControlPositions.TOP,
      ControlPositions.BOTTOM,
      ControlPositions.LEFT,
      ControlPositions.RIGHT,
      ControlPositions.TOP_RIGHT_SOURCE,
      ControlPositions.TOP_LEFT_SOURCE,
      ControlPositions.BOTTOM_RIGHT_SOURCE,
      ControlPositions.BOTTOM_LEFT_SOURCE
    ]
    controls.forEach(element => {
      this.object.setControlVisible(element, false)
      delete fabric.Object.prototype.controls[element]
    })
    delete this.object.controls
    this.object.setCoords()
    this.canvas.renderAll()
  }

  drawGrid() {
    const { left, top, width, height, scaleX, scaleY } = this.object
    const gridColor = 'rgba(255, 255, 255, 1)'
    const gridWidth = 1
    // Clear previous grid lines if any exist
    this.removeGrid()
  
    const scaledWidth = width * scaleX
    const scaledHeight = height * scaleY
  
    this.gridLines = []
  
    // Draw vertical grid lines
    for (let i = 1; i < 3; i++) {
      const x = left + (i * scaledWidth) / 3

      const rotatedA = fabric.util.rotatePoint(new fabric.Point(x, top), new fabric.Point(left, top), fabric.util.degreesToRadians(this.object.angle))
      const rotatedB = fabric.util.rotatePoint(new fabric.Point(x, top + scaledHeight), new fabric.Point(left, top), fabric.util.degreesToRadians(this.object.angle))

      const verticalLine = new fabric.Line([rotatedA.x, rotatedA.y, rotatedB.x, rotatedB.y], {
        stroke: gridColor,
        strokeWidth: gridWidth / this.canvas.getZoom(),
        selectable: false,
        evented: false,
      })
      this.gridLines.push(verticalLine)
      this.canvas.add(verticalLine)
      verticalLine.bringToFront()
    }
  
    // Draw horizontal grid lines
    for (let i = 1; i < 3; i++) {
      const y = top + (i * scaledHeight) / 3;

      const rotatedA = fabric.util.rotatePoint(new fabric.Point(left, y), new fabric.Point(left, top), fabric.util.degreesToRadians(this.object.angle))
      const rotatedB = fabric.util.rotatePoint(new fabric.Point(left + scaledWidth, y), new fabric.Point(left, top), fabric.util.degreesToRadians(this.object.angle))

      const horizontalLine = new fabric.Line([rotatedA.x, rotatedA.y, rotatedB.x, rotatedB.y], {
        stroke: gridColor,
        strokeWidth: gridWidth / this.canvas.getZoom(),
        selectable: false,
        evented: false,
      });
      this.gridLines.push(horizontalLine)
      this.canvas.add(horizontalLine)
      horizontalLine.bringToFront()
    }
  }

  removeGrid() {
    if (this.gridLines) {
      // Remove all grid lines from the canvas
      this.gridLines.forEach((line) => this.canvas.remove(line));
  
      // Clear the gridLines array
      this.gridLines = [];
    }
  }

  isolateImage(onClick: (event)=>void) {
    if (!this.canvas) {
      console.error('A valid Fabric canvas instance is required.')
      return;
    }

    if (this._overlay) {
      console.warn('Overlay already exists for this object.')
      return;
    }
    this.canvas.selection = false
    this._stackIndexBeforeEditingMode = this.canvas.getObjects().indexOf(this.object)

    const canvasElement = this.canvas.getElement() as HTMLCanvasElement
    const canvasRect = canvasElement.getBoundingClientRect()
    const canvasWidth = canvasRect.width / this.canvas.getZoom() * 10
    const canvasHeight = canvasRect.height / this.canvas.getZoom() * 10
    // Create a dark overlay that fills the visible canvas
    const overlay = new fabric.Rect({
      left: -canvasWidth / 2,
      top: -canvasHeight / 2,
      width: canvasWidth, // Use the visible width
      height: canvasHeight, // Use the visible height
      fill: 'rgba(0, 0, 0, 0.3)', // Semi-transparent overlay
      selectable: false,
      evented: true, // Prevent interactions with the overlay
      excludeFromExport: true,
      clipPath: null,
      hasControls: false,
      hoverCursor: 'default',
    })
    
    this.canvas.add(overlay)

    // Add event listener for window resize to re-render the overlay
    window.addEventListener('resize', () => {
      this._resizeOverlay()
    })
    
    // this.canvas.sendToBack()

    this.object.bringToFront()
    this.object.__editingImage?.bringToFront()
    this._overlay = overlay
  
    this._overlayClick = onClick
    this.canvas.on("mouse:down", this._overlayClick)
    this.canvas.renderAll()
  }

  removeIsolation() {
    if (!this._overlay) {
      console.warn('No overlay exists for this object.')
      return;
    }
    this.canvas.off("mouse:down", this._overlayClick)
    this.object.moveTo(this._stackIndexBeforeEditingMode)
    // Remove the overlay from the canvas
    this.canvas.remove(this._overlay)
    this._overlay = undefined
    window.removeEventListener('resize', this.object._resizeHandler);
    this.canvas.selection = true
    this.canvas.renderAll()
  }

  _resizeOverlay() {
    if (this._overlay) {
      setTimeout(() => {
        // save callbacks from before overlay restart
        const overlayClick = this._overlayClick
        this.removeIsolation()
        this.isolateImage(overlayClick)
      })
    }
  }

  setEditingImage() {
    const angleInRadians = fabric.util.degreesToRadians(this.object.__editingImage.angle);
  
    // Crop offsets in the rotated frame
    let cropOffsetX = 0
    let cropOffsetY = 0
    if (this.object.flipX) {
      cropOffsetX = (this.object.__editingImage._element.naturalWidth - this.object.width - this.object.cropX) * this.object.__editingImage.scaleX
    }
    else {
      cropOffsetX = this.object.__editingImage.cropX * this.object.__editingImage.scaleX
    }

    if (this.object.flipY) {
      cropOffsetY = (this.object.__editingImage._element.naturalHeight - this.object.height - this.object.cropY) * this.object.__editingImage.scaleY
    }
    else {
      cropOffsetY = this.object.__editingImage.cropY * this.object.__editingImage.scaleY
    }
    
    const rotationAnchor = new fabric.Point(this.object.__editingImage.left, this.object.__editingImage.top)
    const unrotatedTopLeft = fabric.util.rotatePoint(new fabric.Point(this.object.__editingImage.left, this.object.__editingImage.top), rotationAnchor, -fabric.util.degreesToRadians(this.object.angle))
    const unrotatedTopLeftWithOffset = new fabric.Point(unrotatedTopLeft.x - cropOffsetX, unrotatedTopLeft.y - cropOffsetY)
    const rotatedTopLeftWithOffset = fabric.util.rotatePoint(unrotatedTopLeftWithOffset, rotationAnchor, fabric.util.degreesToRadians(this.object.angle))
  
    // Update the position to remove the crop offsets
    this.object.__editingImage.set({
      left: rotatedTopLeftWithOffset.x,
      top: rotatedTopLeftWithOffset.y,
      cropX: 0,
      cropY: 0,
    });
  
    // Ensure coordinates are updated
    this.object.__editingImage.setCoords();
  }

  setAnchorToTopLeft(inputObject: fabric.Image) {
    if (inputObject.originX === 'left' || inputObject.originY === 'top') {
      return
    }
    // Get the center of the image
    const center = inputObject.getCenterPoint();
    const originOffsetX = (inputObject.width * inputObject.scaleX) / 2;
    const originOffsetY = (inputObject.height * inputObject.scaleY) / 2;
  
    // Calculate top and left positions
    const unrotatedTopLeft = new fabric.Point(center.x - originOffsetX, center.y - originOffsetY)
    const rotatedTopLeft = fabric.util.rotatePoint(unrotatedTopLeft, center, fabric.util.degreesToRadians(inputObject.angle));

    // Update the position to switch to top-left anchor
    inputObject.set({
      originX: 'left',
      originY: 'top',
      left: rotatedTopLeft.x,
      top: rotatedTopLeft.y,
    });
  
    // Update the coordinates in Fabric.js
    inputObject.setCoords();
  }

  setAnchorToCenter(inputObject: fabric.Image) {
    if (inputObject.originX === 'center' || inputObject.originY === 'center') {
      return
    }
    // Get the current top-left point of the image
    const topLeft = new fabric.Point(inputObject.left, inputObject.top);
    const originOffsetX = (inputObject.width * inputObject.scaleX) / 2;
    const originOffsetY = (inputObject.height * inputObject.scaleY) / 2;

    // Calculate center point position
    const unrotatedCenter = new fabric.Point(topLeft.x + originOffsetX, topLeft.y + originOffsetY)
    const rotatedCenter = fabric.util.rotatePoint(unrotatedCenter, topLeft, fabric.util.degreesToRadians(inputObject.angle));

    // Update the position to switch to center anchor
    inputObject.set({
      originX: 'center',
      originY: 'center',
      left: rotatedCenter.x,
      top: rotatedCenter.y,
    });
  
    // Update the coordinates in Fabric.js
    inputObject.setCoords();
  }

  // @ts-ignore
  __editingOnMoving(event: fabric.IEvent) {
    // Reset move of active object
    this.object.left = this._originalLeft
    this.object.top = this._originalTop
    this.drawGrid()
    if (!this.startPointer) {
      this.startPointer = { x: event.pointer.x, y: event.pointer.y };
    }

    if (event.pointer) {
      const zoom = this.canvas.getZoom()
      const deltaX = (event.pointer.x - this.startPointer.x) * zoom
      const deltaY = (event.pointer.y - this.startPointer.y) * zoom

      // Save pointer position for next frame
      this.startPointer = { x: event.pointer.x, y: event.pointer.y };

      if (this.object._editingMode && event.pointer) {
          this.__editingMoveCrop(ControlPositions.TOP_LEFT, deltaX, deltaY)
          this.__setSourceControlsVisibility()
          if (this._updateCallback) {
            this._updateCallback()
          }
      }
    }
  }
  
  __editingMoveCrop(
    position: ControlPositions,
    deltaX: number,
    deltaY: number
  ) {
    if (this.object.__editingImage) {
      // Convert top and left into unrotated space
      const rotationAnchor = new fabric.Point(this.object.left, this.object.top)
      const topLeftEditingImage = new fabric.Point(this.object.__editingImage.left + deltaX, this.object.__editingImage.top + deltaY)
      const topLeftEditingImageWithoutDelta = new fabric.Point(this.object.__editingImage.left, this.object.__editingImage.top)

      const unrotatedTopLeft = fabric.util.rotatePoint(topLeftEditingImage, rotationAnchor, -fabric.util.degreesToRadians(this.object.angle))
      const unrotatedTopLeftWithoutDelta = fabric.util.rotatePoint(topLeftEditingImageWithoutDelta, rotationAnchor, -fabric.util.degreesToRadians(this.object.angle))

      // Perform cropping calculations in unrotated space
      let unrotatedTop = unrotatedTopLeft.y
      let minUnrotatedTop = this.object.top + ((this.object.height - this.object.__editingImage.height) * this.object.__editingImage.scaleY)
      let maxUnrotatedTop = this.object.top
      unrotatedTop = Math.max(unrotatedTop, minUnrotatedTop)
      unrotatedTop = Math.min(unrotatedTop, maxUnrotatedTop)

      let unrotatedLeft = unrotatedTopLeft.x
      const minUnrotatedLeft = this.object.left + ((this.object.width - this.object.__editingImage.width) * this.object.__editingImage.scaleX)
      const maxUnrotatedLeft = this.object.left
      unrotatedLeft = Math.max(unrotatedLeft, minUnrotatedLeft)
      unrotatedLeft = Math.min(unrotatedLeft, maxUnrotatedLeft)

      // Update cropY in unrotated space
      let unrotatedCropY = 0
      if (this.object.flipY) {
        unrotatedCropY = this.object.__editingImage.height - this.object.height - Math.abs(this.object.top - unrotatedTop) / this.object.__editingImage.scaleY
      }
      else {
        unrotatedCropY = Math.abs(unrotatedTop - this.object.top) / this.object.__editingImage.scaleY
      }

      let unrotatedCropX = 0
      if (this.object.flipX) {
        unrotatedCropX = this.object.__editingImage.width - this.object.width - Math.abs(this.object.left - unrotatedLeft) / this.object.__editingImage.scaleX
      }
      else {
        unrotatedCropX = Math.abs(unrotatedLeft - this.object.left) / this.object.__editingImage.scaleX
      }
      

      // Convert back to rotated space
      const rotatedTopLeft = fabric.util.rotatePoint(
        new fabric.Point(unrotatedLeft, unrotatedTop),
        rotationAnchor,
        fabric.util.degreesToRadians(this.object.angle)
      );

      // Update crop values
      if (position.includes('t')) {
        this.object.__editingImage.top = rotatedTopLeft.y
        this.object.cropY = unrotatedCropY
      }
      if (position.includes('l')) {
        this.object.__editingImage.left = rotatedTopLeft.x
        this.object.cropX = unrotatedCropX
      }
    }
  }

  __constrainTop(unrotatedEditingImageTopLeft: fabric.Point, unrotatedXY: fabric.Point) {
    const { height = 0, scaleY = 1 } = this.object.__editingImage
    const top = unrotatedEditingImageTopLeft.y
    let maxTop = top + height * scaleY - (this._minCropSize)
    let minTop = Math.min(unrotatedXY.y, maxTop, this.object.top! + this.object.getScaledHeight())
    this.object.top = Math.max(minTop, top)
  }

  __constrainLeft(unrotatedEditingImageTopLeft: fabric.Point, unrotatedXY: fabric.Point) {
    const { width = 0, scaleX = 1 } = this.object.__editingImage
    const left = unrotatedEditingImageTopLeft.x

    let maxLeft = left + width * scaleX  - (this._minCropSize)
    let minLeft = Math.min(unrotatedXY.x, maxLeft, this.object.left! + this.object.getScaledWidth())
    this.object.left = Math.max(minLeft, left)
  }

  __editingSetCropResize(
    position: ControlPositions,
    x: number,
    y: number
  ) {
    if (!this.object.__editingImage) {
      return
    }
    const leftBefore = this.object.left
    const topBefore = this.object.top
    const cropXBefore = this.object.cropX
    const cropYBefore = this.object.cropY
    const widthBefore = this.object.width
    const heightBefore = this.object.height

    const { width = 0, height = 0, scaleX = 1, scaleY = 1 } = this.object.__editingImage
    const rotationAnchor = new fabric.Point(this.object.left, this.object.top)
  
    // Calculate unrotated points
    const unrotatedXY = this.__getUnrotatedPoint(x, y, rotationAnchor)
    const unrotatedEditingImageTopLeft = this.__getUnrotatedPoint(
      this.object.__editingImage.left,
      this.object.__editingImage.top,
      rotationAnchor
    )
    const top = unrotatedEditingImageTopLeft.y
    const left = unrotatedEditingImageTopLeft.x
  
    if (position == ControlPositions.TOP) {
      this.__handleTopEdge(unrotatedXY, top, height, scaleY, unrotatedEditingImageTopLeft)
      this.__rotateAndApplyTopLeft(rotationAnchor)
    }
    else if (position == ControlPositions.BOTTOM) {
      this.__handleBottomEdge(unrotatedXY, top, height, scaleY)
    }
    else if (position == ControlPositions.LEFT) {
      this.__handleLeftEdge(unrotatedXY, left, width, scaleX, unrotatedEditingImageTopLeft)
      this.__rotateAndApplyTopLeft(rotationAnchor)
    }
    else if (position == ControlPositions.RIGHT) {
      this.__handleRightEdge(unrotatedXY, left, width, scaleX)
    }
    else if (position == ControlPositions.TOP_LEFT) {
      this.handleTopLeftEdge(rotationAnchor, unrotatedXY, top, height, scaleY, left, width, scaleX, unrotatedEditingImageTopLeft)
  }
    else if (position == ControlPositions.TOP_RIGHT) {
      this.__handleTopEdge(unrotatedXY, top, height, scaleY, unrotatedEditingImageTopLeft)
      this.__handleRightEdge(unrotatedXY, left, width, scaleX)
      this.__rotateAndApplyTopLeft(rotationAnchor)
    }
    else if (position == ControlPositions.BOTTOM_LEFT) {
      this.__handleBottomEdge(unrotatedXY, top, height, scaleY)
      this.__handleLeftEdge(unrotatedXY, left, width, scaleX, unrotatedEditingImageTopLeft)
      this.__rotateAndApplyTopLeft(rotationAnchor)
    }
    else if (position == ControlPositions.BOTTOM_RIGHT) {
      this.__handleBottomEdge(unrotatedXY, top, height, scaleY)
      this.__handleRightEdge(unrotatedXY, left, width, scaleX)
    }

    if (this.aspectRatio) {
      this.cropWithAspectRatio(position)
    }
    this._originalLeft = this.object.left
    this._originalTop = this.object.top
  }
  
  private __getUnrotatedPoint(x: number, y: number, anchor: fabric.Point) {
    return fabric.util.rotatePoint(
      new fabric.Point(x, y),
      anchor,
      -fabric.util.degreesToRadians(this.object.angle)
    )
  }
  
  private __rotateAndApplyTopLeft(rotationAnchor: fabric.Point) {
    const rotatedTopLeft = fabric.util.rotatePoint(
      new fabric.Point(this.object.left, this.object.top),
      rotationAnchor,
      fabric.util.degreesToRadians(this.object.angle)
    )
    this.object.top = rotatedTopLeft.y
    this.object.left = rotatedTopLeft.x
  }
  
  private __handleTopEdge(
    unrotatedXY: fabric.Point,
    top: number,
    height: number,
    scaleY: number,
    unrotatedEditingImageTopLeft: fabric.Point
  ) {
    if (this.object.flipY) {
      this.object.height = (height - this.object.cropY - ((unrotatedXY.y - top)/scaleY))
      this.object.height = this.__clampValue(this.object.height, this.getMinSize('y'), height - this.object.cropY)
      this.object.top = top + (height - this.object.height - this.object.cropY) * scaleY
    } else {
      const lastTop = this.object.top
      this.__constrainTop(unrotatedEditingImageTopLeft, unrotatedXY)
      const cropY = Math.min((Math.min(Math.max(unrotatedXY.y, top), this.object.top) - top) / scaleY, height)
      const newHeight = Math.max(this.getMinSize('y'), Math.min(this.object.height! + (this.object.cropY! - cropY), height))

      if (newHeight > this.getMinSize('y')) {
        this.object.height = newHeight
        this.object.cropY = cropY
      }
      else {
        this.object.top = lastTop
      }
    }
  }
  
  private __handleBottomEdge(
    unrotatedXY: fabric.Point,
    top: number,
    height: number,
    scaleY: number
  ) {
    if (this.object.flipY) {
      let leading_area = (this.object.top - top) / scaleY
      let trailing_area = Math.max(0, (top/scaleY) + height - (unrotatedXY.y/scaleY))
      let newHeight = height - leading_area - trailing_area
      newHeight = Math.max(this.getMinSize('y'), newHeight)
    
      const maxCropY = Math.max(0, height - leading_area - newHeight)
      this.object.cropY = Math.min(maxCropY, trailing_area)
      this.object.height = Math.max(this.getMinSize('y'), height - leading_area - this.object.cropY)
    } else {
      const minHeight = Math.min(
        (unrotatedXY.y - top) / scaleY - this.object.cropY!,
        height - this.object.cropY!
      )
      this.object.height = Math.max(this.getMinSize('y'), minHeight)
    }
  }
  
  private __handleLeftEdge(
    unrotatedXY: fabric.Point,
    left: number,
    width: number,
    scaleX: number,
    unrotatedEditingImageTopLeft: fabric.Point
  ) {
    if (this.object.flipX) {
      this.object.width = (((width - this.object.cropX)) - ((unrotatedXY.x - left)/scaleX))
      this.object.width = this.__clampValue(this.object.width, this.getMinSize('x'), width - this.object.cropX)
      this.object.left = left + (width - this.object.width - this.object.cropX) * scaleX

    } else {
      const lastLeft = this.object.left
      this.__constrainLeft(unrotatedEditingImageTopLeft, unrotatedXY)
      const cropX = Math.min((Math.min(Math.max(unrotatedXY.x, left), this.object.left) - left) / scaleX, width)
      const newWidth = Math.max(this.getMinSize('x'), Math.min(this.object.width! + (this.object.cropX! - cropX), width))

      if (newWidth > this.getMinSize('x')) {
        this.object.width = newWidth
        this.object.cropX = cropX
      }
      else {
        this.object.left = lastLeft
      }
    }
  }
  
  private __handleRightEdge(
    unrotatedXY: fabric.Point,
    left: number,
    width: number,
    scaleX: number
  ) {
    if (this.object.flipX) {
      let leading_area = (this.object.left - left) / scaleX
      let trailing_area = Math.max(0, (left/scaleX) + width - (unrotatedXY.x/scaleX))
      let newWidth = width - leading_area - trailing_area
      newWidth = Math.max(this.getMinSize('x'), newWidth)
    
      const maxCropX = Math.max(0, width - leading_area - newWidth)
      this.object.cropX = Math.min(maxCropX, trailing_area)
      this.object.width = Math.max(this.getMinSize('x'), width - leading_area - this.object.cropX)
    } else {
      const minWidth = Math.min(
        (unrotatedXY.x - left) / scaleX - this.object.cropX!,
        width - this.object.cropX!
      )
      this.object.width = Math.max(this.getMinSize('x'), minWidth)
    }
  }

  private handleTopLeftEdge(
    rotationAnchor: fabric.Point,
    unrotatedXY: fabric.Point,
    top: number,
    height: number,
    scaleY: number,
    left: number,
    width: number,
    scaleX: number,
    unrotatedEditingImageTopLeft: fabric.Point
  ) {
    this.__handleTopEdge(unrotatedXY, top, height, scaleY, unrotatedEditingImageTopLeft)
    this.__handleLeftEdge(unrotatedXY, left, width, scaleX, unrotatedEditingImageTopLeft)
  
    // Rotate and apply the final top-left position
    const rotatedTopLeft = fabric.util.rotatePoint(
      new fabric.Point(this.object.left, this.object.top),
      rotationAnchor,
      fabric.util.degreesToRadians(this.object.angle)
    );
    this.object.top = rotatedTopLeft.y;
    this.object.left = rotatedTopLeft.x;
  }
  
  private __clampValue(value: number, min: number, max: number): number {
    return Math.min(Math.max(value, min), max)
  }

  __editingControlPositionHandler(this: fabric.Image, position: ControlPositions, minSize: number) {
    const width = Math.max(this.width!, minSize / this.scaleX);
    const height = Math.max(this.height!, minSize / this.scaleY);

    const xMultiplier = position.includes('l') ? -1 : position.length > 1 || position === ControlPositions.RIGHT ? 1 : 0
    const yMultiplier = position.includes('t') ? -1 : position.length > 1 || position === ControlPositions.BOTTOM ? 1 : 0
    const x = (width! / 2) * xMultiplier
    const y = (height! / 2) * yMultiplier

    return fabric.util.transformPoint(
      new fabric.Point(x, y),
      fabric.util.multiplyTransformMatrices(this.canvas!.viewportTransform!, this.calcTransformMatrix())
    )
  }

  __renderEditingControl(
    position: ControlPositions,
    ctx: CanvasRenderingContext2D,
    left: number,
    top: number
  ) {
    ctx.save()
    ctx.strokeStyle = this.object.cornerStrokeColorEditing
    ctx.lineWidth = this.object.cornerSizeEditing
    ctx.translate(left, top)
    if (this.object.angle) {
      ctx.rotate(fabric.util.degreesToRadians(this.object.angle))
    }
    ctx.beginPath()
    const x = position.includes('l') ? -ctx.lineWidth : position.includes('r') ? ctx.lineWidth : 0
    const y = position.includes('t') ? -ctx.lineWidth : position.includes('b') ? ctx.lineWidth : 0
    if (position === ControlPositions.BOTTOM || position === ControlPositions.TOP) {
      ctx.moveTo(x - this.object.cornerLengthEditing / 2, y)
      ctx.lineTo(x + this.object.cornerLengthEditing, y)
    } else if (position === ControlPositions.RIGHT || position === ControlPositions.LEFT) {
      ctx.moveTo(x, y - this.object.cornerLengthEditing / 2)
      ctx.lineTo(x, y + this.object.cornerLengthEditing)
    } else {
      if (position.includes('b')) {
        ctx.moveTo(x, y - this.object.cornerLengthEditing)
      } else if (position.includes('t')) {
        ctx.moveTo(x, y + this.object.cornerLengthEditing)
      }
      ctx.lineTo(x, y)
      if (position.includes('r')) {
        ctx.lineTo(x - this.object.cornerLengthEditing, y)
      } else if (position.includes('l')) {
        ctx.lineTo(x + this.object.cornerLengthEditing, y)
      }
    }
    ctx.stroke()
    ctx.restore()
  }

  public setAspectRatio(aspectRatio: number | null) {
    console.log("setAspectRatio", aspectRatio)
    this.aspectRatio = aspectRatio;  // Set aspect ratio: 16/9, 9/16, 1, or null (freeform)
    
    if (this && this.aspectRatio) {
      // Original image aspect ratio
      const originalAspectRatio = this.object.__editingImage.width / this.object.__editingImage.height;
  
      let newWidth = this.object.width
      let newHeight = this.object.height
      // Determine new dimensions
      if (this.object.width / this.object.height > this.aspectRatio) {
          // Width is too large, fit to height
          newHeight = Math.min(this.object.height, this.object.__editingImage.height);
          newWidth = newHeight * this.aspectRatio;
      } else {
          // Height is too large, fit to width
          newWidth = Math.min(this.object.width, this.object.__editingImage.width);
          newHeight = newWidth / this.aspectRatio;
      }
      this.object.width = newWidth
      this.object.height = newHeight
    }

    // After setting the new aspect ratio, you might want to trigger a re-render of the object
    this.canvas?.renderAll();  // Update the canvas to reflect the changes
  }

  public setUpdateCallback(cb: ()=>void) {
    this._updateCallback = cb
  }

  cropWithAspectRatio(position: ControlPositions) {
    if (this && this.aspectRatio) {
      let newHeight = this.object.height
      let newWidth = this.object.width
  
      if (position == ControlPositions.BOTTOM_RIGHT) {
        // Determine new dimensions
        if (this.object.width / this.object.height > this.aspectRatio) {
          // Width is too large, fit to height
          // newHeight = Math.min(this.height, this.__editingImage.height - this.cropY);
          newWidth = newHeight * this.aspectRatio;
          newWidth = Math.min(newWidth, this.object.__editingImage.width - this.object.cropX);
          console.log("width is larger")
        } else {
          // Height is too large, fit to width
          // newWidth = Math.min(this.width, this.__editingImage.width - this.cropX);
          newHeight = newWidth / this.aspectRatio;
          newHeight = Math.min(newHeight, this.object.__editingImage.height - this.object.cropY);
          console.log("height is larger")
        }
      }
      if (position == ControlPositions.TOP_RIGHT) {
          // newHeight = Math.min(this.height, this.__editingImage.height - this.cropY);
          newWidth = newHeight * this.aspectRatio;
          newWidth = Math.min(newWidth, this.object.__editingImage.width - this.object.cropX);
          console.log("width is larger")
      }
      if (position == ControlPositions.BOTTOM_LEFT) {
        // newWidth = Math.min(this.width, this.__editingImage.width - this.cropX);
        newHeight = newWidth / this.aspectRatio;
        newHeight = Math.min(newHeight, this.object.__editingImage.height - this.object.cropY);
      }
      if (position === ControlPositions.LEFT) {
          newHeight = newWidth / this.aspectRatio;
          newHeight = Math.min(newHeight, this.object.__editingImage.height - this.object.cropY);
      }
      if (position === ControlPositions.TOP) {
        newWidth = newHeight * this.aspectRatio;
        if (newWidth > this.object.__editingImage.width - this.object.cropX) {
          newWidth = this.object.__editingImage.width - this.object.cropX
          newHeight = newWidth / this.aspectRatio
        }

      }
      if (position == ControlPositions.TOP_LEFT) {
        if (this.object.width / this.object.height > this.aspectRatio) {
          newHeight = newWidth / this.aspectRatio;
          newHeight = Math.min(newHeight, this.object.__editingImage.height - this.object.cropY);
          console.log("TL: wider")
        }
        else {
          newWidth = newHeight * this.aspectRatio;
          newWidth = Math.min(newWidth, this.object.__editingImage.width - this.object.cropX);
          console.log("TL: higher")
        }

      }

    
      this.object.width = newWidth// + ((this.width - newWidth)/2)
      this.object.height = newHeight// + ((this.height - newHeight)/2)
      
    }
  }

  __createEditingControl(position: ControlPositions) {
    // Flip the position dynamically based on flipX and flipY
    
    let flippedPosition = position as string
    if (this.object.flipX) {
      flippedPosition = flippedPosition.replace(/l|r/g, (m) => (m === ControlPositions.LEFT ? ControlPositions.RIGHT : ControlPositions.LEFT));
    }
  
    if (this.object.flipY) {
      flippedPosition = flippedPosition.replace(/t|b/g, (m) => (m === ControlPositions.TOP ? ControlPositions.BOTTOM : ControlPositions.TOP));
    }
  
    const positionMap: Partial<Record<ControlPositions, string>> = {
      [ControlPositions.TOP_RIGHT]: 'nesw',
      [ControlPositions.BOTTOM_LEFT]: 'nesw',
      [ControlPositions.BOTTOM_RIGHT]: 'nwse',
      [ControlPositions.TOP_LEFT]: 'nwse',
      [ControlPositions.TOP]: 'ns',
      [ControlPositions.BOTTOM]: 'ns',
      [ControlPositions.RIGHT]: 'ew',
      [ControlPositions.LEFT]: 'ew'
    }

    let cursor = positionMap[flippedPosition]
  
    let rotateCursorIfNeeded = (cursor)=> {
      const angle = (this.object.angle ?? 0) % 360;

      // Rotation mapping based on 90° increments
      const rotationMap: Record<string, string[]> = {
        'ns': ['ns', 'ew', 'ns', 'ew'],   // 0°, 90°, 180°, 270°
        'ew': ['ew', 'ns', 'ew', 'ns'],
        'nesw': ['nesw', 'nwse', 'nesw', 'nwse'],
        'nwse': ['nwse', 'nesw', 'nwse', 'nesw']
      };

      const rotationIndex = Math.round(angle / 90) % 4;
      return rotationMap[cursor]?.[rotationIndex] ?? cursor;
    }
    cursor = rotateCursorIfNeeded(cursor)

    // Create and return the control
    return new fabric.Control({
      cursorStyle: `${cursor}-resize`,
      actionName: `edit_${this.object.type}`,
      render: controlPositionIcons[flippedPosition],
      positionHandler: this.__editingControlPositionHandler.bind(this.object, position, this._minCropSize),
      actionHandler: this.__editingActionHandlerWrapper(flippedPosition as ControlPositions),
    })
  }

  __editingActionHandlerWrapper(position: ControlPositions) {
    return (_event: MouseEvent, _transform: any, x: number, y: number) => {
      this.drawGrid()
      this.__editingSetCropResize(position, x, y)
      this.__setSourceControlsVisibility()

      if (this._updateCallback) {
        this._updateCallback()
      }
      return true
    }
  }

  __areControlsOverlapping(obj, control1, control2, threshold = 7) {
    // Ensure the object's controls are updated
    obj.setCoords();
    
    // Get control positions
    const coords = obj.oCoords;
    
    if (!coords[control1] || !coords[control2]) {
        console.error(`Invalid control names: ${control1}, ${control2}`);
        return false;
    }

    const p1 = coords[control1]; // Position of first control
    const p2 = coords[control2]; // Position of second control

    // Check if control positions are within the threshold
    return Math.abs(p1.x - p2.x) < threshold && Math.abs(p1.y - p2.y) < threshold
}

  __setSourceControlsVisibility() {
    if (this.object.flipX && this.object.flipY) {
      this.__areControlsOverlapping(this.object, ControlPositions.BOTTOM_RIGHT, ControlPositions.TOP_LEFT_SOURCE) ? this.object.setControlVisible(ControlPositions.TOP_LEFT_SOURCE, false) : this.object.setControlVisible(ControlPositions.TOP_LEFT_SOURCE, true)
      this.__areControlsOverlapping(this.object, ControlPositions.BOTTOM_LEFT, ControlPositions.TOP_RIGHT_SOURCE) ? this.object.setControlVisible(ControlPositions.TOP_RIGHT_SOURCE, false) : this.object.setControlVisible(ControlPositions.TOP_RIGHT_SOURCE, true)
      this.__areControlsOverlapping(this.object, ControlPositions.TOP_RIGHT, ControlPositions.BOTTOM_LEFT_SOURCE) ? this.object.setControlVisible(ControlPositions.BOTTOM_LEFT_SOURCE, false) : this.object.setControlVisible(ControlPositions.BOTTOM_LEFT_SOURCE, true)
      this.__areControlsOverlapping(this.object, ControlPositions.TOP_LEFT, ControlPositions.BOTTOM_RIGHT_SOURCE) ? this.object.setControlVisible(ControlPositions.BOTTOM_RIGHT_SOURCE, false) : this.object.setControlVisible(ControlPositions.BOTTOM_RIGHT_SOURCE, true)
    }
    else if (this.object.flipX) {
      this.__areControlsOverlapping(this.object, ControlPositions.TOP_RIGHT, ControlPositions.TOP_LEFT_SOURCE) ? this.object.setControlVisible(ControlPositions.TOP_LEFT_SOURCE, false) : this.object.setControlVisible(ControlPositions.TOP_LEFT_SOURCE, true)
      this.__areControlsOverlapping(this.object, ControlPositions.TOP_LEFT, ControlPositions.TOP_RIGHT_SOURCE) ? this.object.setControlVisible(ControlPositions.TOP_RIGHT_SOURCE, false) : this.object.setControlVisible(ControlPositions.TOP_RIGHT_SOURCE, true)
      this.__areControlsOverlapping(this.object, ControlPositions.BOTTOM_RIGHT, ControlPositions.BOTTOM_LEFT_SOURCE) ? this.object.setControlVisible(ControlPositions.BOTTOM_LEFT_SOURCE, false) : this.object.setControlVisible(ControlPositions.BOTTOM_LEFT_SOURCE, true)
      this.__areControlsOverlapping(this.object, ControlPositions.BOTTOM_LEFT, ControlPositions.BOTTOM_RIGHT_SOURCE) ? this.object.setControlVisible(ControlPositions.BOTTOM_RIGHT_SOURCE, false) : this.object.setControlVisible(ControlPositions.BOTTOM_RIGHT_SOURCE, true)
    }
    else if (this.object.flipY) {
      this.__areControlsOverlapping(this.object, ControlPositions.BOTTOM_LEFT, ControlPositions.TOP_LEFT_SOURCE) ? this.object.setControlVisible(ControlPositions.TOP_LEFT_SOURCE, false) : this.object.setControlVisible(ControlPositions.TOP_LEFT_SOURCE, true)
      this.__areControlsOverlapping(this.object, ControlPositions.BOTTOM_RIGHT, ControlPositions.TOP_RIGHT_SOURCE) ? this.object.setControlVisible(ControlPositions.TOP_RIGHT_SOURCE, false) : this.object.setControlVisible(ControlPositions.TOP_RIGHT_SOURCE, true)
      this.__areControlsOverlapping(this.object, ControlPositions.TOP_LEFT, ControlPositions.BOTTOM_LEFT_SOURCE) ? this.object.setControlVisible(ControlPositions.BOTTOM_LEFT_SOURCE, false) : this.object.setControlVisible(ControlPositions.BOTTOM_LEFT_SOURCE, true)
      this.__areControlsOverlapping(this.object, ControlPositions.TOP_RIGHT, ControlPositions.BOTTOM_RIGHT_SOURCE) ? this.object.setControlVisible(ControlPositions.BOTTOM_RIGHT_SOURCE, false) : this.object.setControlVisible(ControlPositions.BOTTOM_RIGHT_SOURCE, true)
    }
    else {
      this.__areControlsOverlapping(this.object, ControlPositions.TOP_LEFT, ControlPositions.TOP_LEFT_SOURCE) ? this.object.setControlVisible(ControlPositions.TOP_LEFT_SOURCE, false) : this.object.setControlVisible(ControlPositions.TOP_LEFT_SOURCE, true)
      this.__areControlsOverlapping(this.object, ControlPositions.TOP_RIGHT, ControlPositions.TOP_RIGHT_SOURCE) ? this.object.setControlVisible(ControlPositions.TOP_RIGHT_SOURCE, false) : this.object.setControlVisible(ControlPositions.TOP_RIGHT_SOURCE, true)
      this.__areControlsOverlapping(this.object, ControlPositions.BOTTOM_LEFT, ControlPositions.BOTTOM_LEFT_SOURCE) ? this.object.setControlVisible(ControlPositions.BOTTOM_LEFT_SOURCE, false) : this.object.setControlVisible(ControlPositions.BOTTOM_LEFT_SOURCE, true)
      this.__areControlsOverlapping(this.object, ControlPositions.BOTTOM_RIGHT, ControlPositions.BOTTOM_RIGHT_SOURCE) ? this.object.setControlVisible(ControlPositions.BOTTOM_RIGHT_SOURCE, false) : this.object.setControlVisible(ControlPositions.BOTTOM_RIGHT_SOURCE, true)
    }
    this.object.setCoords()
  }

  setEditingImageStrokeWidth() {
    this.object.__editingImage.set('strokeWidth', this._editingImageStrokeWidth / this.canvas.getZoom())
  }

  setEditingImageStrokeWidthHandler = this.setEditingImageStrokeWidth.bind(this)

  getMinSize(axis: string) {
    if (axis == 'x') {
      return this._minCropSize / this.object.scaleX
    }
    else if (axis = 'y') {
      return this._minCropSize / this.object.scaleY
    }
    else {
      return null
    }
  }
  
  private captureCurrentState(): CropState {
    return {
      dataType: "CropState",
      object: {
        cropX: this.object.cropX,
        cropY: this.object.cropY,
        top: this.object.top,
        left: this.object.left,
        flipX: this.object.flipX,
        flipY: this.object.flipY,
        width: this.object.width,
        height: this.object.height,
        scaleX: this.object.scaleX,
        scaleY: this.object.scaleY
      },
      editingImage: {
        top: this.object.__editingImage.top,
        left: this.object.__editingImage.left,
        flipX: this.object.__editingImage.flipX,
        flipY: this.object.__editingImage.flipY,
        scaleX: this.object.__editingImage.scaleX,
        scaleY: this.object.__editingImage.scaleY,
        width: this.object.__editingImage.width,
        height: this.object.__editingImage.height
      }
    }
  }

  private applyState(state: CropState) {
    // Restore object properties
    this.object.set(state.object)

    // Restore editing image properties
    this.object.__editingImage.set(state.editingImage)
    this.__setEditingControls()
    this.__setSourceControlsVisibility()
    this.canvas.renderAll()
  }
}

interface CropState extends TransactionScopeState {
  dataType: string;
  object: {
    cropX: number;
    cropY: number;
    top: number;
    left: number;
    flipX: boolean;
    flipY: boolean;
    width: number;
    height: number;
    scaleX: number;
    scaleY: number;
  };

  editingImage: {
    top: number;
    left: number;
    flipX: boolean;
    flipY: boolean;
    scaleX: number;
    scaleY: number;
    width: number;
    height: number;
  };
}


export default CropHandler
