import { useEffect, useState } from 'react'
import { styled } from 'baseui'
import { useStyletron } from 'baseui'
import { ACCESSIBILITY_TYPE, StatefulPopover } from 'baseui/popover'
import { Block } from 'baseui/block'
import PanelItems from '../../Panels/PanelItems'
import { panelListItems, PanelType, PopoverType } from '@/constants/app-options'
import PanelListItem from '../../Panels/PanelListItem'
import useAppContext from '@/hooks/useAppContext'
import api from '@/services/api'
import Stock from '../../Panels/PanelItems/Stock'
import { customAmplitude } from '@/utils/customAmplitude'

function AddStock() {
  const { activePanel, setPopoverActive, popoverActive } = useAppContext()

  const panelListItem = panelListItems.find(x => x.name === 'Stock')

  return (
    <div>
      <div
        onClick={() => {
          if(popoverActive !== PopoverType.STOCK) {
            customAmplitude('Click', {
              Source: 'add.stock'
            })
          }
          setPopoverActive(popoverActive === PopoverType.STOCK ? null : PopoverType.STOCK)
        }}
        id="popover-stock"
      >
        <PanelListItem
          disable={panelListItem.disable}
          label={panelListItem.name}
          name={panelListItem.name}
          key={panelListItem.name}
          icon={panelListItem.name}
          activePanel={activePanel}
          panelSelected={popoverActive === PopoverType.STOCK}
        />
      </div>
      <Stock
        setIsOpen={() => setPopoverActive(null)}
        isOpen={popoverActive === PopoverType.STOCK}
      />
    </div>
  )
}

export default AddStock
