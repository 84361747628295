import * as React from 'react'

const SvgComponent = props => (
<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.01976 3.99609H5.02295L5.02304 1.99975C5.02304 1.43244 5.49462 0.975613 6.06671 1.00101C6.6115 1.02482 7.01985 1.51879 7.01985 2.06515L7.01976 3.99609Z" fill="#666666"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M1 5.99609C1 5.44381 1.447 4.99609 1.9984 4.99609H17.8989C18.5054 4.99609 18.9971 5.48858 18.9971 6.09609V15.9922H17.0003V6.99609H1.9984C1.447 6.99609 1 6.54838 1 5.99609Z" fill="#666666"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M23 17.9922C23 18.5445 22.553 18.9922 22.0016 18.9922L6.1011 18.9922C5.49455 18.9922 5.00285 18.4997 5.00285 17.8922L5.00285 7.99609L6.99966 7.99609L6.99966 16.9922L22.0016 16.9922C22.553 16.9922 23 17.4399 23 17.9922Z" fill="#666666"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0144 20.0039L19.0112 20.0039L19.0111 22.0002C19.0111 22.5676 18.5396 23.0244 17.9675 22.999C17.4227 22.9752 17.0143 22.4812 17.0143 21.9349L17.0144 20.0039Z" fill="#666666"/>
</svg>
)

export default SvgComponent
