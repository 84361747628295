import { MediaImageType } from './media_image_type'
import { ImageFilesystem, ImageFileSystemRecord } from './image_filesystem'
import { LatestImageInfo } from './latest_image_info'
import { MediaImageRepositoryProcessing } from './media_image_repository_processing'
import ApiService from '@services/api'
import { nanoid } from 'nanoid'
import { FileSystemRecord } from './VirtualFileSystem'
import { MediaImageFallbackHandler } from './media_image_fallback_handler'
import { Rectangle } from './rectangle'

export class MediaImageRepository {

  _filesystem: ImageFilesystem
  _mediaImageRepositoryProcessing: MediaImageRepositoryProcessing

  private static instance: MediaImageRepository

  public static getInstance(): MediaImageRepository {
    if (!MediaImageRepository.instance) {
      let udid = nanoid()
      let filesystem = new ImageFilesystem(udid)
      MediaImageRepository.instance = new MediaImageRepository(filesystem)
    }
    return MediaImageRepository.instance
  }

  private constructor(filesystem: ImageFilesystem) {
    this._filesystem = filesystem
    this._mediaImageRepositoryProcessing = new MediaImageRepositoryProcessing()
  }

  async storeLatestImageInfo(layer: string, layerStateId: string, latestImageInfo: LatestImageInfo): Promise<boolean> {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        let success = true
        success =
          success && (await this.storeImageBase64(layer, layerStateId, MediaImageType.fitted, latestImageInfo.fittedImage.toDataURL()))
        success =
          success && (await this.storeImageBase64(layer, layerStateId, MediaImageType.fittedMask, latestImageInfo.fittedMask.toDataURL()))
        success =
          success && (await this.storeImageBase64(layer, layerStateId, MediaImageType.latest, latestImageInfo.latestImage.toDataURL()))
        success =
          success && (await this.storeImageBase64(layer, layerStateId, MediaImageType.mask, latestImageInfo.mask.toDataURL()))
        resolve(success)
      })
    })
  }

  async storeImageUrl(layer: string, layerStateId: string, imageType: MediaImageType, imageUrl: URL | null, abortSignal?:any): Promise<boolean> {
    if (!imageUrl) {
      return Promise.resolve(false)
    }
    let base64 = await this.getDataBlob(imageUrl,abortSignal)
    return await this.storeImageBlobString(layer, layerStateId, imageType, base64)
  }

  
  async storeImageBase64(layer: string, layerStateId: string, imageType: MediaImageType, imageBase64: string, abortSignal?:any): Promise<boolean> {
    return await this.storeImageBlobString(layer, layerStateId, imageType, this._mediaImageRepositoryProcessing.base64ToBlobUrl(imageBase64))
  }

  async storeImageBlobString(layer: string, layerStateId: string, imageType: MediaImageType, imageBase64: string, abortSignal?:any): Promise<boolean> { 
    return this._filesystem.setImage(layer, layerStateId, imageType, imageBase64)
  }

  async getImage(layer: string, layerStateId: string, imageType: MediaImageType): Promise<string> {
    let record = await this.getRecord(layer, layerStateId, imageType);
    return Promise.resolve(record?.blobUrl);
  }

  async getRecord(layerId: string, layerStateId: string, imgType: MediaImageType): Promise<FileSystemRecord | null> {
    let record = await this._filesystem.getRecord(layerId, layerStateId, imgType);
    if (record && record.blobUrl) {
      return record;
    }
    let fallbackRecord = await MediaImageFallbackHandler.getInstance().fallback(layerId, layerStateId, imgType);
    return fallbackRecord;
  }

  async hasRecord(layerId: string, layerStateId: string, imgType: MediaImageType): Promise<boolean> {
    let record = await this._filesystem.getRecord(layerId, layerStateId, imgType);
    return !!(record && record.blobUrl);
  }

  async getImagePath(layer: string, layerStateId: string, imageType: MediaImageType): Promise<string> {
    return this._filesystem.getImagePath(layer, layerStateId, imageType);
  }

  async restoreUndo(layer: string, layerStateId: string, imageType: MediaImageType) {
    let record = await this._filesystem.getRecord(layer, layerStateId, imageType);
    if (record){
      record.undoDate = new Date()
    }
  }

  async removeImage(layer: string, layerStateId: string, imageType: MediaImageType) {
    this._filesystem.removeImage(layer, layerStateId, imageType)
  }

  async generateLatestImageInfo(layer: string, layerStateId: string,
                                threshold: number | null = null,
                                absolueBoundingBox?: Rectangle
                              ): Promise<LatestImageInfo> {
    let original = await this.getImage(layer, layerStateId, MediaImageType.original)
    let mask = await this.getImage(layer, layerStateId, MediaImageType.mask)

    let result = await this._mediaImageRepositoryProcessing.generateLatestImageInfo(original, mask, threshold, absolueBoundingBox)

    return result
  }

  async duplicate(originalLayerId: string, newLayerId: string, layerStateId: string) {
    await this._filesystem.duplicate(originalLayerId, newLayerId, layerStateId);
  }

  async getDataBlob(url: URL, signal?:AbortSignal): Promise<string> {
    try {
      let blob = await ApiService.downloadAsset(url, {
        responseType: 'blob',
        signal
      });
      if (signal?.aborted) {
        return null;
      }

      let imageFormats = ['image/png', 'image/jpeg', 'image/jpg'];
      let base64img = URL.createObjectURL(blob.data)

      if (blob.data.size > 2097152 && imageFormats.indexOf(blob.data.type) !== -1) { // 2 MB
        return this._mediaImageRepositoryProcessing.resizeBlobToMaxEdgeSize(base64img, 1280, signal);
      } else {
        return base64img
      }
    } catch(err) {
      return null;
    }
  }
}