//@ts-nocheck
import { fabric } from 'fabric'
import ApiService from '@services/api'

export function loadFabricImageFromURL(src) {
  return new Promise(resolve => {
    fabric.Image.fromURL(src, function (img) {
      resolve(img)
    })
  })
}

export function loadImageFromURL(src): Promise<typeof Image> {
  return new Promise((resolve, reject) => {
    const image = new Image()
    // image.src = src.replace('https://d3m8xwj68a8dxg.cloudfront.net','/cloudfront')
    // image.src = src.replace('https://media.bazaart.com','/media')
    image.onload = () => {
      resolve(image)
    }
    image.onerror = (err) => {
      if (err.code === "ERR_CANCELED") {
        resolve(null);
      } else {
        reject(err);
      }
    }
    image.crossOrigin = 'Anonymous'
    image.src = ApiService.prepareUrl(src)
  })
}

export function dataURLtoBlob(dataURL) {
  let array, binary, i, len
  binary = atob(dataURL.split(',')[1])
  array = []
  i = 0
  len = binary.length
  while (i < len) {
    array.push(binary.charCodeAt(i))
    i++
  }
  return new Blob([new Uint8Array(array)], {
    type: 'image/png',
  })
}
