import { ColorMap } from "../common/interfaces";
import { Size } from "../objects/media-repository/size";
import canvasImageRenderer from "./canvasImageRenderer";

export class LayerFilterRenderer {
    private static instance: LayerFilterRenderer
    
    filterEdgeSize: number;
    private filterBackend: fabric.WebglFilterBackend;

    private queue: Array<() => Promise<void>> = [];
    private processing = false;

    processQueue = async () => {
    if (this.processing) return; // Prevent multiple executions

    this.processing = true;
    while (this.queue.length > 0) {
        const action = this.queue.shift(); // Get the first action from the queue
        if (action) await action(); // Process the action if it exists
    }
    this.processing = false;
    }

    addActionToQueue = (action: () => Promise<void>): void => {
        this.queue.push(action); // Add the action to the queue
        this.processQueue(); // Start processing if not already processing
    }

    public static getInstance(): LayerFilterRenderer {
        if (!LayerFilterRenderer.instance) {
            LayerFilterRenderer.instance = new LayerFilterRenderer()
        }

        return LayerFilterRenderer.instance
    }

    private constructor() {
        this.filterEdgeSize = 256;
        // @ts-ignore
        this.filterBackend = new fabric.PixiFilterBackend({ tileSize: this.filterEdgeSize })
    }

    async render(imageElement, canvasSize: Size, colorMaps: ColorMap[]) {
        return new Promise(async (resolve, reject) => {
            // this.addActionToQueue(async () => {
                await canvasImageRenderer.getInstance().render(
                    imageElement, 
                    canvasSize,
                    colorMaps,
                    this.filterBackend,
                    false
                )
                resolve(imageElement)
            })
        // })
    }

    clear() {
        this.processing = false;
        this.queue = [];
    }
}