import api from '@/services/api'
import { fabric } from 'fabric'
import { IBaseFilter, StaticImage, StaticText } from 'fabric/fabric-impl'
import { DARK_OVERLAY_EXPOSURE_VALUE, ObjectType, PROPERTIES_TO_EXPORT, PROPERTIES_TO_INCLUDE, ScaleType, TEXT_ALIGN } from '../common/constants'
import { dataURLtoBlob, loadImageFromURL } from '../utils/image-loader'
import objectToFabric from '../utils/objectToFabric'
import BaseHandler from './BaseHandler'
import { MediaImageRepository } from '@scenes/engine/objects/media-repository/media_image_repository'
import { MediaImageType } from '@scenes/engine/objects/media-repository/media_image_type'
import { Rectangle } from '@scenes/engine/objects/media-repository/rectangle'
import { nanoid } from 'nanoid'
import Watermark from '@assets/images/watermark_2024_black.png'
import * as _ from 'lodash'
import Icons from '@/scenes/Editor/components/Icons'
import CanvasImageRenderer from '../utils/canvasImageRenderer'
import store from '@/store/store'
import React from 'react'
import ReactDOMServer from 'react-dom/server';
import { setHasClipboardImage, setIsOpenErrorModalRemoveBg } from '@/store/slices/editor/action'
import { customAmplitude } from '@/utils/customAmplitude'
import uniqueId from '@/utils/unique'
import { findSizeId } from '@/scenes/Editor/components/Navbar/components/Resize'
import { lightTheme } from '@/customTheme'
import { Size } from "@/scenes/engine/objects/media-repository/size";
import { CanvasLayerShadowEffect } from '@/interfaces/CanvasLayerShadowEffect'
import { CanvasLayerOutlineEffect } from '@/interfaces/CanvasLayerOutlineEffect'


class ObjectHandler extends BaseHandler {
  public clipboard
  public isCut
  public replaceRefLayer
  public removeReplaceRefLayer
  private isClickingReplace = false

  public addElement = (element: fabric.Object, isUpdate = true) => {
    isUpdate && this.root.transactionHandler.save()
    element.clipPath = this.root.frameHandler.get()
    element.lockScalingFlip = true

    this.canvas.add(element)
    element.setCoords()

    if (element.type === ObjectType.BAZAART_TEXT && !(element as StaticText).isPresetText) {
      // @ts-ignore
      element.enterEditing()
      // @ts-ignore
      element.selectAll()
      element.minScaleLimit = 0.9
    }

    this.notifyAnalyticAddItem(element)

    this.canvas.setActiveObject(element)
    this.canvas.requestRenderAll()
  }

  public add = async (item, isUpdate = true) => {
    isUpdate && this.root.transactionHandler.save()
    const options = this.root.frameHandler.getOptions()
    const object: fabric.Object = await objectToFabric.run(item, options)
    object.clipPath = this.root.frameHandler.get()
    object.lockScalingFlip = true
    this.canvas.add(object)
    object.setCoords()
    if (item.type === ObjectType.BAZAART_TEXT && !item.isPresetText) {
      // @ts-ignore
      object.enterEditing()
      // @ts-ignore
      object.selectAll()
      object.minScaleLimit = 0.9
    }

    if (item.type !== ObjectType.BAZAART_SHAP) {
      // @ts-ignore
      object.sizeOnCanvas.height = object.sizeOnCanvas.height ?? object.getScaledHeight() / object.clipPath.height
    }
    this.notifyAnalyticAddItem(object)

    this.canvas.setActiveObject(object)
    this.canvas.requestRenderAll()

    return object
  }

  public addImageToCanvasByUrl = async (url, resizeFrame?, removeBg?) => {
    let guid = nanoid()
    let assetStateId = nanoid()

    let imageProcessing = MediaImageRepository.getInstance()._mediaImageRepositoryProcessing

    let resizedImage = await imageProcessing.resizeBlobToMaxEdgeSize(url, 1280)

    let maskInfo = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.extractMask(resizedImage)

    await MediaImageRepository.getInstance().storeImageBlobString(guid, assetStateId, MediaImageType.latest, resizedImage)
    await MediaImageRepository.getInstance().storeImageBlobString(
      guid,
      assetStateId,
      MediaImageType.original,
      resizedImage
    )
    await MediaImageRepository.getInstance().storeImageBlobString(
      guid,
      assetStateId,
      MediaImageType.mask,
      maskInfo.blob
    )

    let frame = this.root.frameHandler.get()
    let layerSize = maskInfo.size

    let canvasAspectRatio = frame.width / frame.height
    let layerAspectRatio = layerSize.width / layerSize.height
    let width = 0.6

    if (layerAspectRatio < canvasAspectRatio) {
      width = (width * layerAspectRatio) / canvasAspectRatio
    }

    const item = {
      type: ObjectType.BAZAART_IMAGE,
      centerPoint: {
        x: 0.5,
        y: 0.5,
      },
      sizeOnCanvas: {
        width: resizeFrame ? 1 : width,
      },
      transformation: {
        horizontalFlip: false,
        verticalFlip: false,
      },
      boundingBox: { y: 0, width: 1, height: 1, x: 0 },
      absoluteRotation: 0,
      bazaartGuid: guid,
      layerAssetStateId: assetStateId,
      hasTransparency: maskInfo.hasTransparency,
    }
    if (resizeFrame) {
      item['scaleX'] = 1
      item['scaleY'] = 1
    }
    const options = this.root.frameHandler.getOptions()
    const object: fabric.Object = await objectToFabric.run(item, options)
    object.lockScalingFlip = true
    object.setCoords()
    object.clipPath = this.root.frameHandler.get()
    this.root.frameHandler.update({ width: object.width, height: object.height, sizeId: findSizeId(object.width, object.height) }, false)
    if (removeBg) {
      await this.removeBg(object)
    }
    this.canvas.add(object)
    // if (removeBg) {
    //   this.root.frameHandler.handleTransparentBgAfterRemoveBg()
    // }
    setTimeout(() => {
      // const value = removeBg ? {strokeWidth: 0.5} : {
      //   fill: lightTheme.colors.white,
      //   strokeWidth: 0.5
      // }
      const value = {
        fill: lightTheme.colors.white,
        strokeWidth: 0.5
      }
      frame.set(value)
      this.canvas.setActiveObject(object)
      this.canvas.requestRenderAll()
    })
    this.root.transactionHandler.isFlushed = false
  }

  public update = options => {
    // TODO: fix duplicate functions
    this.updateActive(options)
  }
  /**
   * Get canvas object by id
   */

  public updateActive = (options, object?: any, sliderOptions?) => {
    let refObject = this.canvas.getActiveObject()
    if (object) {
      refObject = object
    }
    const canvas = this.canvas
    if (refObject) {
      sliderOptions ? this.handleUndo(sliderOptions, refObject) : this.root.transactionHandler.save()
      for (const property in options) {
        if (refObject.type === 'activeSelection') {
          // canvas.fire('object:changed', {name: property, value: options[property]})
          // @ts-ignore
          refObject.forEachObject(o => {
            o.set(property as keyof fabric.Object, options[property])
          })
          if (sliderOptions) {
            refObject.set(property as keyof fabric.Object, options[property])
          }
        }
        else {
          refObject.set(property as keyof fabric.Object, options[property])
        }
        canvas.requestRenderAll()
      }
    }
  }

  private handleUndo = (sliderOptions, object?: any) => {
    if (!sliderOptions.isSliderUpdate) {
      return
    }
    const canvasJSON: any = this.root.canvasHandler.exportToCanvasJSON()
    const activeObject: any = object ? object : this.root.canvasHandler.canvas.getActiveObject()
    if (object.type === 'activeSelection') {
      // @ts-ignore
      object.forEachObject(obj => {
        const editObject = canvasJSON.objects.find(o => o.id === obj.id)
        if (editObject) {
          for (let i = 0; i < sliderOptions.property.length; i++) {
            editObject[sliderOptions.property[i]] = sliderOptions.value[i]
          }
        }
      })
      this.root.transactionHandler.save(canvasJSON)
    } else {
      const editObject = canvasJSON.objects.find(o => o.id === activeObject.id)
      if (editObject) {
        for (let i = 0; i < sliderOptions.property.length; i++) {
          editObject[sliderOptions.property[i]] = sliderOptions.value[i]
        }

      }
      this.root.transactionHandler.save(canvasJSON)
    }
  }
  public delete = () => {
    // TODO: fix duplicate functions
    this.remove()
  }
  /**
   * Remove active object
   */

  public clear = (includeFrame = false, fillFrame = true) => {
    if (includeFrame) {
      this.canvas.clear()
    } else {
      const frame = this.root.frameHandler.get()
      this.canvas.getObjects().forEach(object => {
        if (object.type !== ObjectType.FRAME) {
          this.canvas.remove(object)
        }
      })
      fillFrame && frame.set('fill', '#ffffff')
    }
    this.canvas.renderAll()
  }

  public moveVertical = value => {
    const activeObject = this.canvas.getActiveObject()
    if (!activeObject) return
    const top = activeObject.top + value
    this.updateActive({
      top: top,
    })
  }

  public moveHorizontal = value => {
    const activeObject = this.canvas.getActiveObject()
    if (!activeObject) return
    const left = activeObject.left + value
    this.updateActive({
      left: left,
    })
  }

  public updateLineHeight = value => {
    const activeObject = this.canvas.getActiveObject() as fabric.ITextOptions
    if (activeObject.type === 'StaticText' || activeObject.type === "bazaart.item.text") {
      const lineHeight = activeObject.lineHeight + value
      this.updateActive({
        lineHeight: lineHeight,
      })
    }
  }

  public updateCharSpacing = value => {
    const activeObject = this.canvas.getActiveObject() as fabric.ITextOptions
    if (activeObject.type === 'StaticText' || activeObject.type === "bazaart.item.text") {
      const charSpacing = activeObject.charSpacing + value
      this.updateActive({
        charSpacing: charSpacing,
      })
    }
  }

  private notifyAnalyticAddItem(object: fabric.Object) {
    let type

    switch (object.type.slice(13)) {
      case 'image':
        type = 'photos'
        break
      case 'text':
        type = 'text'
        break

      case 'sticker':
        type = 'graphics'
        break
      default:
        type = 'photos'
    }
    if (type !== 'text') {
      const eventProperties = {
        Tool: `bazaart.add.${type}`,
        Type: object.type,
      }
      customAmplitude('Selected tool', eventProperties)
    }
  }

  public scale(type: ScaleType, isUpdate = true) {
    let refObject = this.canvas.getActiveObject()
    const { width, height } = this.root.frameHandler.getOptions()
    if (refObject) {
      isUpdate && this.root.transactionHandler.save()
      let scaleX = width / refObject.width
      let scaleY = height / refObject.height
      const scaleMax = Math.max(scaleX, scaleY)
      const scaleMin = Math.min(scaleX, scaleY)

      if (type === ScaleType.FIT) {
        refObject.set({
          scaleX: scaleMin,
          scaleY: scaleMin,
          // @ts-ignore
          _originalScaleX: scaleMin * refObject._filterScalingX,
          // @ts-ignore
          _originalScaleY: scaleMin * refObject._filterScalingY,
          angle: 0
        })
      }
      if (type === ScaleType.FILL) {
        refObject.set({
          scaleY: scaleMax,
          scaleX: scaleMax,
          // @ts-ignore
          _originalScaleX: scaleMax * refObject._filterScalingX,
          // @ts-ignore
          _originalScaleY: scaleMax * refObject._filterScalingY,
          angle: 0
        })
      }
      refObject.center()
      this.updateSizeOnCanvas(refObject)
    }
  }

  public updateSizeOnCanvas(object?: fabric.Object, canvasSize?: Size) {
    let refObject = this.canvas.getActiveObject()
    if (object) {
      refObject = object
    }
    if (!refObject) { return }
    const frame = this.root.frameHandler.get()

    const calculateSizeOnCanvas = (object) => {
      const canvasWidth = canvasSize ? canvasSize.width : frame.width
      const canvasHeight = canvasSize ? canvasSize.height : frame.height
      let baseWidth = object.width
      let baseHeight = object.height

      if (object.isLatest) {
        let w = object.width;
        let h = object.height;
    
        let sourceBounds = new Rectangle(0, 0, w, h);
        let shadowEffects = new CanvasLayerShadowEffect();
        const originalCanvasSize = this.root.frameHandler.getSize()
        let shadowRect = shadowEffects.revertEffectBounds(object, originalCanvasSize, false, sourceBounds);
    
        let outlineEffects = new CanvasLayerOutlineEffect();
        let outlineRect = outlineEffects.revertEffectBounds(object, originalCanvasSize, false, shadowRect);
        baseWidth = outlineRect.width
        baseHeight = outlineRect.height
      }
      return {
        // @ts-ignore
        width: baseWidth * object.scaleX * object._filterScalingX / canvasWidth,
        // @ts-ignore
        height: baseHeight * object.scaleY * object._filterScalingY / canvasHeight
      }
    }
    if (refObject instanceof fabric.Group) {
      refObject._objects.forEach(object => {
        // @ts-ignore
        object.set('sizeOnCanvas', calculateSizeOnCanvas(object))
      })
    } else {
      // @ts-ignore
      refObject.set('sizeOnCanvas', calculateSizeOnCanvas(refObject))
    }
  }

  public alignMiddle = () => {
    let activeObject = this.canvas.getActiveObject()

    if (activeObject) {
      const currentObject = activeObject
      currentObject.set({
        top: 0,
      })
      this.canvas.requestRenderAll()
    }
  }

  public alignTop = () => {
    let activeObject = this.canvas.getActiveObject()

    if (activeObject) {
      const currentObject = activeObject
      currentObject.set({
        top: 0,
      })
      this.canvas.requestRenderAll()
    }
  }

  public alignBottom = () => {
    let activeObject = this.canvas.getActiveObject()
    const { height, top } = this.root.frameHandler.getOptions()

    if (activeObject) {
      const currentObject = activeObject
      currentObject.set({
        top: height + top - currentObject.getScaledHeight(),
      })
      this.canvas.requestRenderAll()
    }
  }

  public alignRight = () => {
    let activeObject = this.canvas.getActiveObject()
    const { width, left } = this.root.frameHandler.getOptions()

    if (activeObject) {
      const currentObject = activeObject
      currentObject.set({
        left: width + left - currentObject.getScaledWidth(),
      })
      this.canvas.requestRenderAll()
    }
  }

  public alignLeft = () => {
    let activeObject = this.canvas.getActiveObject()

    if (activeObject) {
      const currentObject = activeObject
      currentObject.set({
        left: 0,
      })
      this.canvas.requestRenderAll()
    }
  }

  public cut = () => {
    this.copy()
    this.isCut = true
    this.remove()
  }

  public copy = () => {
    const eventProperties = {
      Tool: 'bazaart.copy',
    }
    customAmplitude('Selected tool', eventProperties)
    const activeObject = this.canvas.getActiveObject()
    if (activeObject) {
      this.clipboard = _.cloneDeep(activeObject)
      store.dispatch(setHasClipboardImage('internal'))
    }
  }

  public clone = () => {
    if (this.canvas) {
      this.root.transactionHandler.save()
      const activeObject = _.cloneDeep(this.canvas.getActiveObject())
      const frame = this.root.frameHandler.get()

      this.canvas.discardActiveObject()

      this.duplicate(activeObject, frame, duplicates => {
        this.canvas.discardActiveObject()
        this.canvas.setActiveObject(duplicates[0])
        this.canvas.requestRenderAll()
      })
      const eventProperties = {
        Tool: 'bazaart.duplicate',
        'Layer Type': activeObject.type,
      }
      customAmplitude('Selected tool', eventProperties)
    }
  }

  public paste = async (cursorPointer?) => {
    const eventProperties = {
      Tool: 'bazaart.paste',
    }
    customAmplitude('Selected tool', eventProperties)
    const { isCut, clipboard } = this

    const padding = isCut ? 0 : 20
    if (clipboard) {
      if (cursorPointer) {
        clipboard.top = cursorPointer.y
        clipboard.left = cursorPointer.x
      } else {
        clipboard.top += padding
        clipboard.left += padding
      }
      clipboard.bazaartGuid = uniqueId()
      const frame = this.root.frameHandler.get()
      this.root.transactionHandler.save()
      this.duplicate(this.clipboard, frame, duplicates => {
        this.canvas.discardActiveObject()
        this.canvas.setActiveObject(duplicates[0])
        this.canvas.requestRenderAll()
      })
    }
  }

  async duplicate(
    object: fabric.Object,
    frame: fabric.Object,
    callback: (clones: fabric.Object[]) => void,
    addToCanvas = true,
    positionOffset = 30
  ): Promise<void> {
    if (object instanceof fabric.Group) {
      const objects: fabric.Object[] = (object as fabric.Group).getObjects()
      const style = {
        left: object.left! + positionOffset,
        top: object.top! + positionOffset,
        scaleX: object.scaleX,
        scaleY: object.scaleY,
        opacity: object.opacity,
        flipX: object.flipX,
        flipY: object.flipY
      }
      const duplicates: fabric.Object[] = []
      const isActiveSelection = object instanceof fabric.ActiveSelection
      const promise = objects.map(o => {
        return new Promise((resolve, reject) => {
          this.duplicate(o, frame, clones => {
            resolve(clones)
          }, false)
        })
      })
      const results = await Promise.all(promise)
      duplicates.push(...(results.flat() as fabric.Object[]))
      if (isActiveSelection) {
        duplicates.map(d => this.canvas.add(d))
      }
      const fabricInstanceType = isActiveSelection ? fabric.ActiveSelection : fabric.Group
      let options: any = {
        canvas: this.canvas,
        ...style
      };
      const instance = new fabricInstanceType(duplicates, options);
      if (!isActiveSelection) {
        const frame = this.root.frameHandler.get()
        instance.clipPath = frame
        this.canvas.add(instance);
      }
      callback([instance]);
    } else {
      object.clone(
        async (clone: fabric.Object) => {
          clone.clipPath = undefined
          let guid = nanoid()
          let obj
          if (this.canvas.getActiveObject() instanceof fabric.ActiveSelection) {
            // @ts-ignore
            obj = this.canvas.getObjects().filter(o => o.id === object.id)[0]
          } else {
            obj = object
          }

          clone.set({
            left: obj.left! + positionOffset,
            top: obj.top! + positionOffset,
            // @ts-ignore
            id: guid,
            bazaartGuid: guid,
            scaleX: obj.scaleX,
            scaleY: obj.scaleY,
          })
          // @ts-ignore
          await MediaImageRepository.getInstance().duplicate(obj.id, guid, obj.layerAssetStateId)
          if (this.config.clipToFrame) {
            const frame = this.root.frameHandler.get()
            clone.clipPath = frame
          }
          addToCanvas && this.canvas.add(clone)
          callback([clone])
        },
        [...new Set([...PROPERTIES_TO_INCLUDE, ...PROPERTIES_TO_EXPORT])]
      )
    }
  }

  public remove = () => {
    this.root.transactionHandler.save()
    const activeObjects = this.canvas.getActiveObjects()
    if (activeObjects) {
      activeObjects.forEach(obj => {
        // @ts-ignore
        if(obj.isTemplateLayer) {
          this.removeReplaceIcon(obj)
        }
        this.canvas.remove(obj)
        const eventProperties = {
          Tool: 'bazaart.delete',
          'Layer Type': obj.type,
        }
        customAmplitude('Selected tool', eventProperties)
      })
      this.canvas.discardActiveObject().renderAll()
    }

  }

  public selectAll = () => {
    this.canvas.discardActiveObject()
    const filteredObjects = this.canvas.getObjects().filter(object => {
      if (object.type === ObjectType.FRAME) {
        return false
      } else if (!object.evented) {
        return false
        //@ts-ignore
      } else if (object.locked) {
        return false
      }
      else if (!object.selectable) {
        return false
      }
      return true
    })
    if (!filteredObjects.length) {
      return
    }
    if (filteredObjects.length === 1) {
      this.canvas.setActiveObject(filteredObjects[0])
      this.canvas.renderAll()
      return
    }
    const activeSelection = new fabric.ActiveSelection(filteredObjects, {
      canvas: this.canvas,
      //@ts-ignore
      ...this.activeSelectionOption,
    })
    this.canvas.setActiveObject(activeSelection)
    this.canvas.renderAll()
  }

  public deselect = () => {
    this.canvas.discardActiveObject()
    this.canvas.requestRenderAll()
  }

  public selectDefaultTextObject = () => {
    const filteredObjects = this.canvas.getObjects().filter(object => object.type === 'StaticText')
    if (filteredObjects.length > 0) {
      this.canvas.setActiveObject(filteredObjects[0])
      this.canvas.renderAll()
    }
  }

  public getDefaultStaticImageObject = () => {
    const filteredObjects = this.canvas.getObjects().filter(object => object.type === 'StaticImage')
    if (filteredObjects.length > 0) {
      this.canvas.renderAll()
      return filteredObjects[0]
    }
  }

  public bringForward = () => {
    const eventProperties = {
      Tool: 'bazaart.up',
    }
    customAmplitude('Selected tool', eventProperties)
    const activeObject = this.canvas.getActiveObject()

    if (activeObject) {
      this.root.transactionHandler.save()
      this.canvas.bringForward(activeObject)
    }
  }

  public bringToFront = () => {
    const activeObject = this.canvas.getActiveObject()

    if (activeObject) {
      this.canvas.bringToFront(activeObject)
    }
  }
  public sendBackwards = (isUpdate = true) => {
    const eventProperties = {
      Tool: 'bazaart.down',
    }
    customAmplitude('Selected tool', eventProperties)
    const objects = this.canvas.getObjects()
    const activeObject = this.canvas.getActiveObject()
    if (activeObject) {
      const index = objects.findIndex(o => o === activeObject)
      const checkHasBg = this.canvas.getObjects().filter(o => o.type === ObjectType.BAZAART_BG || o.type === ObjectType.FRAME).length === 2
      if (activeObject && (checkHasBg ? index > 2 : index > 1)) {
        isUpdate && this.root.transactionHandler.save()
        this.canvas.sendBackwards(activeObject)
      }
      this.canvas.renderAll()
    }
  }
  public sendToBack = () => {
    const activeObject = this.canvas.getActiveObject()
    if (activeObject) {
      const checkHasBg = this.canvas.getObjects().filter(o => o.type === ObjectType.BAZAART_BG || o.type === ObjectType.FRAME).length === 2
      let objects: fabric.Object[] = [activeObject]
      if (activeObject instanceof fabric.Group) {
        objects = activeObject.getObjects()
      }
      if (activeObject.type === ObjectType.GROUP) {
        activeObject.moveTo(checkHasBg ? 2 : 1)
      }
      objects.forEach(object => {
        if (object) {
          object.moveTo(checkHasBg ? 2 : 1)
        }
      })
    }
  }

  public replaceImageSource = async (value, object?: fabric.Object) => {
    let refObject = this.canvas.getActiveObject() as unknown as StaticImage
    if (object) {
      refObject = object as unknown as StaticImage
    }
    if (refObject) {
      refObject.replaceImage(value, true).then(() => {
        refObject.useNewTextureNextTime()
        this.canvas.renderAll();
      })
    }
  }

  public replaceMask = async (newMask, newMaskWithoutCrop?) => {
    const activeObject = (this.canvas.getActiveObject() ? this.canvas.getActiveObject() : this.replaceRefLayer) as unknown as StaticImage
    if (!activeObject) {
      return
    }

    // @ts-ignore
    let layerId = activeObject.id

    let assetStateId = nanoid()

    let currecntOriginal = await MediaImageRepository.getInstance().getImage(layerId, activeObject.layerAssetStateId, MediaImageType.original)
    await MediaImageRepository.getInstance().storeImageBlobString(layerId, assetStateId, MediaImageType.original, currecntOriginal)

    await MediaImageRepository.getInstance().storeImageBlobString(layerId, assetStateId, MediaImageType.mask, newMask)
    let latestImageInfo = await MediaImageRepository.getInstance().generateLatestImageInfo(layerId, assetStateId)
    await MediaImageRepository.getInstance().storeLatestImageInfo(layerId, assetStateId, latestImageInfo)

    if (newMaskWithoutCrop){
      await MediaImageRepository.getInstance().storeImageBlobString(layerId, assetStateId, MediaImageType.maskWithoutCrop, newMaskWithoutCrop)
    }
    
    activeObject.set('layerAssetStateId', assetStateId) // should be call before replaceImage because texture using it, and its should be updated.
    activeObject.set('hasTransparency', true)

    this.repositionBoundingBoxChange(activeObject, latestImageInfo.boundingBox)

    await activeObject.replaceImage(latestImageInfo.latestImage.toDataURL(), false)

    let staticImage = activeObject as StaticImage;
    staticImage.set('dirty', true)
    staticImage.applyFilters()
    staticImage.canvas?.requestRenderAll()
  }

  public replaceImage = async (value, magicLayer: boolean, includeExtactMask: boolean = true) => {
    const activeObject = (this.canvas.getActiveObject() ? this.canvas.getActiveObject() : this.replaceRefLayer) as unknown as StaticImage
    if (activeObject) {
      this.resetTemplateLayerToNormal(activeObject)

      for (let key in activeObject.effects_from_template) {
        if (!activeObject.effects[key]) {
          activeObject.effects[key] = activeObject.effects_from_template[key]
        }
      }
      // @ts-ignore
      activeObject.adjustments = activeObject.adjustments_from_template
      if (this.replaceRefLayer) {
        this.canvas.setActiveObject((activeObject as any))
      }
      if (!magicLayer) {
        await activeObject.replaceImageUrl(value, true)
        await CanvasImageRenderer.getInstance().render(
          this.root.canvasHandler.canvas.getActiveObject(),
          this.root.frameHandler.getSize(),
          store.getState().editor.imageElements.imageElements)
  
        // @ts-ignore
        let layerId = activeObject.id

        let assetStateId = activeObject.layerAssetStateId


        let layerBzrtBgMask = await MediaImageRepository.getInstance().getImage(layerId, assetStateId, MediaImageType.bzrtBgMask)

        if (layerBzrtBgMask != undefined) {

          assetStateId = nanoid()
          let currecntOriginal = await MediaImageRepository.getInstance().getImage(layerId, activeObject.layerAssetStateId, MediaImageType.original)
          MediaImageRepository.getInstance().storeImageBlobString(layerId, assetStateId, MediaImageType.original, currecntOriginal)

          if (includeExtactMask) {
            let maskInfo = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.extractMask(value)
            await MediaImageRepository.getInstance().storeImageBlobString(layerId, assetStateId, MediaImageType.mask, maskInfo.blob)
            activeObject.set('hasTransparency', maskInfo.hasTransparency)
          }
          let latestImageInfo = await MediaImageRepository.getInstance().generateLatestImageInfo(layerId, assetStateId)

          await MediaImageRepository.getInstance().storeLatestImageInfo(layerId, assetStateId, latestImageInfo)

          activeObject.set('layerAssetStateId', assetStateId) // should be call before replaceImage because texture using it, and its should be updated.

          this.repositionBoundingBoxChange(activeObject, latestImageInfo.boundingBox)

          await activeObject.replaceImage(latestImageInfo.latestImage.toDataURL(), true)
          this.canvas.renderAll()
        }
      }
      if (this.replaceRefLayer) {
        this.replaceRefLayer.isTemplateLayer = false
      }
      this.replaceRefLayer = null
    }
  }

  resetTemplateLayerToNormal(activeObject) {
    if (!activeObject.isTemplateLayer) { return }
    activeObject.set({
      adjustments: activeObject.adjustments_from_template,
      effects: {
        hasFillImage: false
      },
      transformation: {
        horizontalFlip: false,
        verticalFlip: false,
      },
      lockMovementX: false,
      lockMovementY: false,
      hoverCursor: 'move',
      selectable: true,
      hasControls: true,
      hasBorders: true,
      hasReplaceableLayer: false
    })
    activeObject.off('mousedown')
    activeObject.setCoords()
    this.removeReplaceIcon(activeObject)
  }
  // public replaceImage = async value => {
  //   const paddedRatio = 1.5
  //   const activeObject = this.canvas.getActiveObject()

  //   const img: any = await loadImageFromURL(value.metadata.src)

  //   let paddingX = Math.ceil((paddedRatio * img.width * 10) / 100)
  //   let paddingY = Math.ceil((paddedRatio * img.height * 10) / 100)
  //   let paddig = Math.max(paddingX, paddingY)

  //   var image = document.createElement('canvas')
  //   image.width = img.width + paddingX
  //   image.height = img.height + paddingY
  //   var ctx = image.getContext('2d')
  //   ctx.drawImage(img, paddingX / 2, paddingY / 2, img.width, img.height)

  //   //@ts-ignore
  //   activeObject.setElement(image)
  //   //@ts-ignore
  //   let outlin_filter = this.getFilter(fabric.Image.filters.Outline)
  //   if (outlin_filter) {
  //     //@ts-ignore
  //     this.applyFilterValue(fabric.Image.filters.Outline, 'image', image)
  //   }

  //   //@ts-ignore
  //   activeObject.applyFilters()
  //   this.canvas.renderAll()
  //   activeObject.setCoords()
  // }

  public async removeBg(object?: any, abortSignal?: AbortSignal, resizedImage?: any, needToAdaptSize?: boolean, frame?: any): Promise<boolean> {
    let refObject = this.canvas.getActiveObject() as unknown as StaticImage
    if (object) {
      refObject = object
    }
    // @ts-ignore
    let id = refObject.id as string

    // @ts-ignore
    if (resizedImage) {
      await refObject.replaceImageUrl(resizedImage, true, true)
    }
    let assetStateId = refObject.layerAssetStateId as string

    let originalImage = await MediaImageRepository.getInstance().getImage(id, assetStateId, MediaImageType.original)

    let imageProcessing = MediaImageRepository.getInstance()._mediaImageRepositoryProcessing;
    let originalSize = await imageProcessing.getImageSize(originalImage)
    let resizedOriginal = await imageProcessing.preprocessForBazaartBg(originalImage)
    let targetSize = await imageProcessing.getImageSize(resizedOriginal)

    refObject.isLatest = false
    //
    const eventProperties = {
      Tool: 'bazaart.cutout.magic',
      'Layer Type': refObject.type,
    }
    customAmplitude('Selected tool', eventProperties)

    let bzrtBgMask = await MediaImageRepository.getInstance().getImage(id, assetStateId, MediaImageType.bzrtBgMask)
    if (bzrtBgMask) {
      return this.processBgRemoveResult(bzrtBgMask, refObject, id, frame, needToAdaptSize, originalImage, originalSize, false)
    }

    //@ts-ignore
    return api
      .bgRemove(
        dataURLtoBlob(resizedOriginal),
        targetSize.width.toString(),
        targetSize.height.toString(),
        abortSignal
      )
      .then(async res => {
        let data = res.data.replace('\n', '').replace('\\', '')
        let resizedMask = await imageProcessing.postProcessFromBazaartBg(data,
          originalSize.width / targetSize.width,
          originalSize.height / targetSize.height
        );

        return this.processBgRemoveResult(resizedMask, refObject, id, frame, needToAdaptSize, originalImage, originalSize)

      })
      .catch(err => {
        if (err.code !== "ERR_CANCELED") {
          store.dispatch(setIsOpenErrorModalRemoveBg(true))
          console.error(err)
        }
        return false
      })
  }

  async processBgRemoveResult(resizedMask: string, refObject, id, frame, needToAdaptSize, originalImage, originalSize, shouldFlipMask: boolean = true): Promise<any> {
    let assetStateId = nanoid()

    // @ts-ignore
    let currentBoundingBox = refObject.boundingBox as Rectangle
    let base64InvertedResizedMask = shouldFlipMask
      ? (await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.invertMask(resizedMask))
      : (await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.blobUrlToBase64(resizedMask));
    
    let invertedResizedMask = MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.base64ToBlobUrl(base64InvertedResizedMask)

    let maskWithoutCrop = await MediaImageRepository.getInstance().getImage(id, refObject.layerAssetStateId, MediaImageType.mask) 
    if (maskWithoutCrop) {
      let maskWithoutCropBoundingBox = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.extractBoundingBox(maskWithoutCrop, true);
      let invertedResizedHtmlMask = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(invertedResizedMask);
      let croppedInvertedResizedHtmlMask = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.cropHtmlImage(invertedResizedHtmlMask, maskWithoutCropBoundingBox)
      let mask = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.padImage(
        croppedInvertedResizedHtmlMask, 
        new Size(invertedResizedHtmlMask.width, invertedResizedHtmlMask.height), 
        maskWithoutCropBoundingBox, 
        '#ffffff'
      )
      await MediaImageRepository.getInstance().storeImageBlobString(id, assetStateId, MediaImageType.mask, mask.src)
    } else {
      await MediaImageRepository.getInstance().storeImageBlobString(id, assetStateId, MediaImageType.mask, invertedResizedMask)
    }

    await MediaImageRepository.getInstance().storeImageBlobString(id, assetStateId, MediaImageType.original, originalImage)
    await MediaImageRepository.getInstance().storeImageBlobString(id, assetStateId, MediaImageType.bzrtBgMask, invertedResizedMask)
    await MediaImageRepository.getInstance().storeImageBlobString(id, assetStateId, MediaImageType.maskWithoutCrop, invertedResizedMask)

    let latestImageInfo = await MediaImageRepository.getInstance().generateLatestImageInfo(id, assetStateId)
    await MediaImageRepository.getInstance().storeLatestImageInfo(id, assetStateId, latestImageInfo)

    this.repositionBoundingBoxChange(refObject, latestImageInfo.boundingBox, frame)

    let imageProcessing = MediaImageRepository.getInstance()._mediaImageRepositoryProcessing;
    refObject.boundingBox = latestImageInfo.boundingBox;
    await refObject.replaceImage(imageProcessing.base64ToBlobUrl(latestImageInfo.latestImage.toDataURL()), false)
    await CanvasImageRenderer.getInstance().render(
      refObject,
      this.root.frameHandler.getSize(),
      store.getState().editor.imageElements.imageElements)

    if (needToAdaptSize == true) {
      let oldAr = currentBoundingBox.width / currentBoundingBox.height;
      let newAr = latestImageInfo.boundingBox.width / latestImageInfo.boundingBox.height;

      let arRatio = Math.max(oldAr / newAr, 1);

      let sizeOnCanvas = {
        width: refObject.sizeOnCanvas.width / arRatio,
        height: refObject.sizeOnCanvas.height / arRatio
      };

      let frame = this.root.frameHandler.getOptions()

      let imageWidth = sizeOnCanvas.width * frame.width
      let imageHeight = (imageWidth / originalSize.width) * originalSize.height

      let imageScaleX = imageWidth / originalSize.width
      let imageScaleY = imageHeight / originalSize.height



      refObject.set('scaleX', imageScaleX)
      refObject.set('scaleY', imageScaleY)
    }

    // refObject.set('sizeOnCanvas', sizeOnCanvas)
    refObject.set('hasTransparency', true)
    refObject.set('layerAssetStateId', assetStateId)
    // @ts-ignore
    refObject.applyFilters()
    this.canvas.renderAll()
    // @ts-ignore
    window.dataLayer.push({ event: 'remove_bg_success' });
    return true
  }

  public removeById = (id: string) => {
    this.canvas.getObjects().forEach(o => {
      //@ts-ignore
      if (o.id === id) {
        this.canvas.remove(o)
      }
    })
    this.canvas.requestRenderAll()
  }

  public renderAll = () => {
    // var activeObject = this.canvas.getActiveObject()
    // this.update({padding:activeObject.padding + 20})
    this.canvas.renderAll()
  }

  public editText = () => {
    const activeObject = this.canvas.getActiveObject() as StaticText

    if (activeObject) {
      activeObject.enterEditing()
    }
  }

  public async addWatermark(canvas) {
    let guid = nanoid()
    let assetStateId = nanoid()
    let watermarkUrl = new URL(Watermark, window.location.href)

    const WATERMARK_WIDTH_FACTOR_PORTRAIT = 0.18
    const WATERMARK_WIDTH_FACTOR_LANDSCAPE = 0.12
    const WATERMARK_MARGIN = 0.022

    let imageWatermark = await loadImageFromURL(watermarkUrl)

    let originalCanvasSize = this.root.frameHandler.getOptions()

    const padding = Math.min(originalCanvasSize.width * WATERMARK_MARGIN, originalCanvasSize.height * WATERMARK_MARGIN);
    const bottomRightPoint = { x: originalCanvasSize.width - padding, y: originalCanvasSize.height - padding };

    // @ts-ignore
    const watermarkAspectRatio = imageWatermark.width / imageWatermark.height;
    const isPortrait = originalCanvasSize.width <= originalCanvasSize.height;
    const factor = isPortrait ? WATERMARK_WIDTH_FACTOR_PORTRAIT : WATERMARK_WIDTH_FACTOR_LANDSCAPE;
    const watermarkSize = { width: factor * originalCanvasSize.width, height: factor * originalCanvasSize.width / watermarkAspectRatio };

    const ROIscaled = {
      x: bottomRightPoint.x - watermarkSize.width / 2,
      y: bottomRightPoint.y - watermarkSize.height / 2,
    };

    await MediaImageRepository.getInstance().storeImageUrl(guid, assetStateId, MediaImageType.latest, watermarkUrl)
    const watermarkOption = {
      id: guid,
      type: ObjectType.BAZAART_STICKER,
      centerPoint: {
        x: ROIscaled.x / originalCanvasSize.width,
        y: ROIscaled.y / originalCanvasSize.height,
      },
      sizeOnCanvas: {
        width: factor,
      },
      transformation: {
        horizontalFlip: false,
        verticalFlip: false,
      },
      boundingBox: { y: 0, width: 1, height: 1, x: 0 },
      absoluteRotation: 0,
      bazaartGuid: guid,
      layerAssetStateId: assetStateId
    }

    const object: fabric.Object = await objectToFabric.run(watermarkOption, originalCanvasSize)
    object.lockScalingFlip = true
    canvas.add(object)
  }

  public getLayers() {
    const objects = this.canvas.getObjects()
    const filteredObjects = objects.filter(o => {
      return o.type !== ObjectType.FRAME && o.type !== 'BackgroundImage'
    })
    return filteredObjects
  }

  repositionBoundingBoxChange(object: any, boundingBox: Rectangle, frame?: any) {
    let refObject = this.canvas.getActiveObject() as unknown as StaticImage
    if (object) {
      refObject = object
    }
    // @ts-ignore
    let sizeOnCanvasWidth = refObject.sizeOnCanvas.width;
    // @ts-ignore
    let sizeOnCanvasHeight = sizeOnCanvasWidth / (refObject.width / refObject.height);
    // movement is calculated based on the size of the original image, as a way to normalize different trim sizes
    let originalSizeOnCanvas: { width: number, height: number } = {
      // @ts-ignore
      width: sizeOnCanvasWidth / refObject.boundingBox.width,
      // @ts-ignore
      height: sizeOnCanvasHeight / refObject.boundingBox.height
    };

    let currentBoxCenter: { x: number, y: number } = {
      // @ts-ignore
      x: refObject.boundingBox.x + refObject.boundingBox.width / 2,
      // @ts-ignore
      y: refObject.boundingBox.y + refObject.boundingBox.height / 2
    };

    let newBoxCenter: { x: number, y: number } = {
      x: boundingBox.x + boundingBox.width / 2,
      y: boundingBox.y + boundingBox.height / 2
    };

    let diff: { x: number, y: number } = {
      x: newBoxCenter.x - currentBoxCenter.x,
      y: newBoxCenter.y - currentBoxCenter.y
    };

    // @ts-ignore
    let originalImageSize = {
      // @ts-ignore
      width: refObject.width / refObject.boundingBox.width,
      // @ts-ignore
      height: refObject.height / refObject.boundingBox.height
    }

    let originalCanvasSize = frame ? frame : this.root.frameHandler.getOptions()

    let currentAr = originalImageSize.height / originalImageSize.width;
    let currentWidth = originalSizeOnCanvas.width * originalCanvasSize.width;
    let currentHeight = currentWidth * currentAr / originalCanvasSize.height;

    let move: { x: number, y: number } = {
      x: originalSizeOnCanvas.width * diff.x,
      y: currentHeight * diff.y
    };

    let newCenter: { x: number, y: number } = {
      x: refObject.left / originalCanvasSize.width + move.x,
      y: refObject.top / originalCanvasSize.height + move.y
    };

    // @ts-ignore
    let sizeRatio = boundingBox.width / refObject.boundingBox.width;
    // @ts-ignore
    refObject.sizeOnCanvas = {
      // @ts-ignore
      width: refObject.sizeOnCanvas.width * sizeRatio,
      // @ts-ignore
      height: refObject.sizeOnCanvas.width * currentAr * sizeRatio
    };

    let adjustNewCenter = this.adjustNewCenter(newCenter, refObject, frame);
    refObject.set({
      left: adjustNewCenter.x * originalCanvasSize.width,
      top: adjustNewCenter.y * originalCanvasSize.height,
      sizeOnCanvas: refObject.sizeOnCanvas,
      // @ts-ignore
      boundingBox: boundingBox,
      // @ts-ignore
      _originalScaleX: frame ? refObject.scaleX : refObject._originalScaleX,
      // @ts-ignore
      _originalScaleY: frame ? refObject.scaleY : refObject._originalScaleY
    })
  }

  adjustNewCenter(newCenter: { x: number, y: number }, layout: StaticImage, frame?: any): { x: number, y: number } {
    // @ts-ignore
    let angle = layout.angle;
    let radians = (angle / 180) * Math.PI;

    let size = {
      width: frame ? frame.width : this.root.frameHandler.get().width,
      height: frame ? frame.height : this.root.frameHandler.get().height
    }
    let anchorPoint: { x: number, y: number } = {
      x: layout.left,
      y: layout.top
    };

    let absoluteNewCenter: { x: number, y: number } = {
      x: newCenter.x * size.width,
      y: newCenter.y * size.height
    };

    newCenter = this.rotate(radians, absoluteNewCenter, anchorPoint);
    return {
      x: newCenter.x / size.width,
      y: newCenter.y / size.height
    };
  }

  rotate(rotation: number, point: { x: number, y: number }, anchorPoint: { x: number, y: number }): { x: number, y: number } {
    let cosAngle = Math.cos(rotation);
    let sinAngle = Math.sin(rotation);

    let divCenterX = anchorPoint.x
    let divCenterY = anchorPoint.y

    // Step 2: Translate mouse coordinates to be relative to the DIV's center
    let xRelativeToCenter = (point.x - divCenterX);
    let yRelativeToCenter = (point.y - divCenterY);

    let newX = xRelativeToCenter * cosAngle - yRelativeToCenter * sinAngle;
    let newY = xRelativeToCenter * sinAngle + yRelativeToCenter * cosAngle;

    return { x: divCenterX + newX, y: divCenterY + newY };
  }

  createReplaceableLayer() {
    const imageLayerList = this.canvas.getObjects().filter((l: any) => l.type === ObjectType.BAZAART_IMAGE && l.isTemplateLayer)
    if (!imageLayerList || imageLayerList.length === 0) { return }
    let hasReplaceableLayer = false
    this.canvas.forEachObject((layer: any) => {
      if (layer.type === ObjectType.BAZAART_IMAGE && layer.isTemplateLayer) {
        hasReplaceableLayer = true
        // @ts-ignore
        const placeholderObj = new fabric.Rect({ opacity: 0, imageLayerId: layer.id, id: `${layer.id}replace` })
        let objIdx = this.canvas.getObjects().indexOf(layer) + 1
        this.canvas.add(placeholderObj)
        placeholderObj.moveTo(objIdx)
      }
    })
    this.canvas.forEachObject(async (layer: any) => {
      if (layer.type === ObjectType.BAZAART_IMAGE && layer.isTemplateLayer) {
        this.handleDarkLayer(layer)
        const { icon, withAnimation } = await this.addReplaceIcon(layer)
        if (!withAnimation) { return }
        this.animateFadeIn(layer, icon)
      }
    })
    if(hasReplaceableLayer) {
      this.canvas.on('mouse:move', (e) => {
        if(this.isClickingReplace) {
          this.isClickingReplace = false
        }
      })
    }
  }

  async addReplaceIcon(layer, withAnimation = true) {
    const icon: any = await this.createReplaceIcon(layer)
    let objIdx = this.canvas.getObjects().indexOf(layer) + 1
    const placeholderElement = this.canvas.getObjects()[objIdx]
    this.canvas.remove(placeholderElement)
    this.canvas.add(icon)
    icon.moveTo(objIdx)
    this.handleReplaceIcon(icon)
    return { icon, withAnimation }
  }

  animateFadeIn(layer, replaceIcon) {
    const rangeValueMap = CanvasImageRenderer.getInstance().adjustmentFilterFactory.rangeValueForProperty
    layer.adjustments = layer?.adjustments
      ? { ...layer.adjustments, exposure: !layer.adjustments?.exposure || layer.adjustments.exposure === -101 ? rangeValueMap.get('exposure').defaultValue : layer.adjustments.exposure }
      : {
        contrast: rangeValueMap.get('contrast').defaultValue,
        temperature: rangeValueMap.get('temperature').defaultValue,
        vibrance: rangeValueMap.get('vibrance').defaultValue,
        sharpness: rangeValueMap.get('sharpness').defaultValue,
        brightness: 0,
        tint: rangeValueMap.get('tint').defaultValue,
        blur: rangeValueMap.get('blur').defaultValue,
        highlights: rangeValueMap.get('highlights').defaultValue,
        saturation: rangeValueMap.get('saturation').defaultValue,
        fade: rangeValueMap.get('fade').defaultValue,
        shadows: rangeValueMap.get('shadows').defaultValue,
        exposure: rangeValueMap.get('exposure').defaultValue,
      }
    const imageElements = store.getState().editor.imageElements.imageElements
    const step = 0.27
    const that = this
    const updateEffect = async () => {
      if (layer.adjustments.exposure <= DARK_OVERLAY_EXPOSURE_VALUE && replaceIcon.opacity >= 1) {
        layer.set('isLatest', true)
        // 🔹 When finished, trigger animation
        setTimeout(() => that.animateHeartBeat(replaceIcon), 0);
        return
      }
      layer.adjustments.exposure = Math.max(layer.adjustments.exposure - step, DARK_OVERLAY_EXPOSURE_VALUE)
      replaceIcon.opacity = Math.max(replaceIcon.opacity + step / 2, 1)
      await CanvasImageRenderer.getInstance().render(
        layer,
        that.root.frameHandler.getSize(),
        imageElements
      )
      that.canvas.renderAll()
      setTimeout(updateEffect, 50);
    }
    layer.set('isLatest', false)
    updateEffect()
  }

  async createReplaceIcon(layer) {

    let guid = `${layer.id}replace`
    let assetStateId = nanoid()

    const REPLACE_WIDTH_FACTOR_PORTRAIT = 0.14
    const REPLACE_WIDTH_FACTOR_LANDSCAPE = 0.08

    let originalCanvasSize = this.root.frameHandler.getOptions()

    // @ts-ignore
    const isPortrait = originalCanvasSize.width <= originalCanvasSize.height
    const factor = isPortrait ? REPLACE_WIDTH_FACTOR_PORTRAIT : REPLACE_WIDTH_FACTOR_LANDSCAPE
    const replaceIconOption = {
      id: guid,
      type: ObjectType.BAZAART_STICKER,
      originX: 'center',
      originY: 'center',
      sizeOnCanvas: {
        width: factor,
      },
      transformation: {
        horizontalFlip: false,
        verticalFlip: false,
      },
      boundingBox: { y: 0, width: 0, height: 0, x: 0 },
      absoluteRotation: 0,
      bazaartGuid: guid,
      layerAssetStateId: assetStateId,
      top: layer.top,
      left: layer.left,
      isTemplateLayer: true,
    }

    let replaceIcon;
    const iconElement = React.createElement(Icons.ReplaceImage)
    const svgString = ReactDOMServer.renderToString(iconElement)
    fabric.loadSVGFromString(svgString, (objects, options) => {
      const obj = fabric.util.groupSVGElements(objects, options);
      let imageWidth = factor * originalCanvasSize.width
      let imageHeight = (imageWidth / obj.width) * obj.height
      let imageScaleX = imageWidth / obj.width
      let imageScaleY = imageHeight / obj.height
      obj.set({
        ...replaceIconOption,
        scaleX: imageScaleX,
        scaleY: imageScaleY,
      })
      replaceIcon = obj
      console.log('replaceIcon', replaceIcon);

    })
    return replaceIcon
  }

  handleReplaceIcon(object) {
    object.set({
      lockScalingFlip: true,
      hoverCursor: 'pointer',
      lockMovementX: true,
      lockMovementY: true,
      selectable: false,
    })
    const objIndex = this.canvas.getObjects().indexOf(object)
    const layer = this.canvas.getObjects()[objIndex - 1]
    object?.on('mousedown', (e) => this.onIconMouseDown(e))
    object?.on('mouseup', (e) => this.onIconMouseUp(layer, e))
    object?.on('mouseover', e => {
      const target = e.target
      target._objects.forEach(obj => {
        if (obj.fill === '#fff') {
          obj.set({
            fill: '#f2f2f2',
          })
          this.canvas.renderAll()
        }
      })
    })
    object?.on('mouseout', e => {
      const target = e.target
      if (!target) return
      target._objects.forEach(obj => {
        if (obj.fill === '#f2f2f2') {
          obj.set({
            fill: '#fff',
          })
          this.canvas.renderAll()
        }
      })
    })
  }

  handleDarkLayer(layer) {
    layer.lockMovementX = true
    layer.lockMovementY = true
    layer.selectable = false
    layer.hoverCursor = 'pointer'
    layer.hasReplaceableLayer = true
    layer?.on('mousedown', () => this.onLayerMouseDown(layer))
    layer?.on('mouseup', () => this.onLayerMouseUp(layer))

  }

  onLayerMouseDown(layer) {
    this.isClickingReplace = true
  }

  onIconMouseDown(e) {
    this.isClickingReplace = true
    const target = e.target
    target._objects.forEach(obj => {
      if (obj.fill === '#f2f2f2') {
        obj.set({
          fill: lightTheme.colors.grayScale100
        })
        this.canvas.renderAll()
      }
    })
  }

  onLayerMouseUp(layer) {
    if (this.isClickingReplace) {
      this.isClickingReplace = false
      this.replaceImageEvent(layer)
    }
  }

  onIconMouseUp(layer, e) {
    if (this.isClickingReplace) {
      const target = e.target
      target._objects.forEach(obj => {
        if (obj.fill === lightTheme.colors.grayScale100) {
          obj.set({
            fill: '#f2f2f2'
          })
          this.canvas.renderAll()
        }
      })

      this.isClickingReplace = false
      this.replaceImageEvent(layer)
    }
  }

  replaceImageEvent(layer) {
    this.removeReplaceRefLayer = layer ? layer : this.replaceRefLayer
    this.replaceRefLayer = layer
    setTimeout(() => {
      this.editorEventManager.emit('object:replace', layer)
    }, 0);
  }

  animateHeartBeat(layer, step = 0.5, duration = 500) {
    const originScaleX = layer.scaleX
    const originScaleY = layer.scaleY

    fabric.util.animate({
      startValue: layer.scaleX,
      endValue: layer.scaleX + step,
      duration: duration,
      onChange: (value) => {
        layer.set({
          scaleX: value,
          scaleY: value
        })
        this.canvas.renderAll();
      },
      onComplete: () => {
        layer.set({
          scaleX: originScaleX,
          scaleY: originScaleY
        })
        this.canvas.renderAll();
      },
    });

  }

  /**
   * Remove replaceable overlays when no upload
   */
  async removeReplaceableOverlay() {
    if (!this.removeReplaceRefLayer) { return }
    await this.removeDarkOverlay()
    this.resetTemplateLayerToNormal(this.removeReplaceRefLayer)
    this.root.transactionHandler.deleteReplaceableLayerHistory(this.removeReplaceRefLayer)
    this.removeReplaceRefLayer = null
  }

  async removeDarkOverlay() {
    const imageElements = store.getState().editor.imageElements.imageElements
    this.removeReplaceRefLayer.adjustments = this.removeReplaceRefLayer.adjustments_from_template
    this.removeReplaceRefLayer.set('isLatest', false) // In case orginal media type fails to download
    await CanvasImageRenderer.getInstance().render(
      this.removeReplaceRefLayer,
      this.root.frameHandler.getSize(),
      imageElements
    )
    this.removeReplaceRefLayer.set('isLatest', true)
  }

  removeReplaceIcon(templateLayer) {
    templateLayer.isTemplateLayer = false
    const objIndex = this.canvas.getObjects().indexOf(templateLayer)
    this.canvas.remove(this.canvas.getObjects()[objIndex + 1])
  }

  public group = () => {
    const frame = this.root.frameHandler.get()
    const activeObject = this.canvas.getActiveObject() as fabric.ActiveSelection
    if (!activeObject) {
      return
    }
    if (activeObject.type !== ObjectType.ACTIVE_SELECTION) {
      return
    }
    this.root.transactionHandler.save()
    const clearClipPaths = (group: fabric.Group) => {
      group._objects.forEach(object => {
        if (object instanceof fabric.Group) {
          clearClipPaths(object)
        }
        object.clipPath = null
      })
    }
    clearClipPaths(activeObject)
    const group = activeObject.toGroup()
    group.clipPath = frame
    // @ts-ignore
    group.id = uniqueId()
    this.context.setActiveObject(group)
    this.canvas.renderAll()
  }

  public ungroup = (object?) => {
    const frame = this.root.frameHandler.get()
    let refObject = this.canvas.getActiveObject() as fabric.ActiveSelection
    if (object) {
      refObject = object
    }
    if (!refObject) {
      return
    }
    if (refObject.type !== ObjectType.GROUP) {
      return
    }
    this.root.transactionHandler.save()
    const activeSelection = refObject.toActiveSelection()
    activeSelection._objects.forEach(object => {
      object.clipPath = frame
    })
    activeSelection.clipPath = undefined
    this.canvas.renderAll()
    this.context.setActiveObject(activeSelection)
  }

  public lock = () => {
    const activeObject = this.canvas.getActiveObject() as fabric.Object | fabric.ActiveSelection
    if (!activeObject) {
      return
    }
    this.root.transactionHandler.save()
    // @ts-ignore
    if (activeObject._objects) {
      // @ts-ignore
      activeObject._objects.forEach(object => {
        object.set({ hasControls: false, lockMovementY: true, lockMovementX: true, locked: true })
      })
      // @ts-ignore
      activeObject.set({ hasControls: false, lockMovementY: true, lockMovementX: true, locked: true })
    } else {
      // @ts-ignore
      activeObject.set({ hasControls: false, lockMovementY: true, lockMovementX: true, locked: true })
    }
    this.canvas.renderAll()
  }
  public unlock = () => {
    const activeObject = this.canvas.getActiveObject() as fabric.Object | fabric.ActiveSelection
    if (!activeObject) {
      return
    }

    this.root.transactionHandler.save()
    // @ts-ignore
    if (activeObject._objects) {
      // @ts-ignore
      activeObject._objects.forEach(object => {
        object.set({ hasControls: true, lockMovementY: false, lockMovementX: false, locked: false })
      })
      // @ts-ignore
      activeObject.set({ hasControls: true, lockMovementY: false, lockMovementX: false, locked: false })
    } else {
      // @ts-ignore

      activeObject.set({ hasControls: true, lockMovementY: false, lockMovementX: false, locked: false })
    }
    this.canvas.renderAll()
  }
  public setIntializedNormalized = (object: fabric.Object) => {
    let refObject = object as unknown as StaticImage

    if (refObject) {
      refObject.isIntializedNormalizedImage = true
      if (refObject.isIntialized()) {
        this.editorEventManager.emit('object:Intialized', refObject)
      }
    }
  }
  public setIntializedNormalizeMask = (object: fabric.Object) => {
    let refObject = object as unknown as StaticImage

    if (refObject) {
      refObject.isIntializedNormalizeMask = true
      if (refObject.isIntialized()) {
        this.editorEventManager.emit('object:Intialized', refObject)
      }
    }
  }

  public getTextTransformation = (object: StaticText) => {
    const ctx = this.canvas.getContext()
    ctx.font = `${object.fontSize}px ${object.fontFamily}`;
    const lines = object.text.split('\n') // multi lines
    const frame = this.root.frameHandler.get()
    return lines.reduce((maxWidth, line) => {
      const rawWidth = ctx.measureText(line).width;
      const spaceAdjustment = (object.charSpacing / 1000) * line.length * object.fontSize; // Fabric.js uses 1/1000 em for charSpacing
      return Math.max(maxWidth, rawWidth + spaceAdjustment) > frame.width ? frame.width : Math.max(maxWidth, rawWidth + spaceAdjustment);
    }, 0);
  }

  public handleTextSizeOnChanged = (object: StaticText, changeFontFamily = false) => {
    const syncTopWithHeightChange = () => {
      // @ts-ignore
      if(!object.angle || object.isResized || object.textAlign === TEXT_ALIGN.CENTER) { 
        // @ts-ignore
        const originalHeight = object._originalHeight
        if (object.height !== originalHeight) {
          const heightDiff = object.height - originalHeight
          const radians = fabric.util.degreesToRadians(object.angle || 0);
          const offsetX = (heightDiff / 2) * Math.sin(radians); // Horizontal adjustment
          const offsetY = (heightDiff / 2) * Math.cos(radians); // Vertical adjustment/ Adjust top for rotation
          object.set({
            left: object.left - offsetX,
            top: object.top + offsetY,
            // @ts-ignore
            _originalHeight: object.height,
          })
        }
      }
    }
    // @ts-ignore
    if (object.isResized) {
      if(changeFontFamily) {
        object.set({
          // @ts-ignore
          width: object._originalWidth,
          scaleX: 1,
          scaleY: 1,
        })
      }
      syncTopWithHeightChange()
      return
    }
    const textTransformWidth = this.getTextTransformation(object)
    this.evaluateWidthExpansionForFrame(object, textTransformWidth)
    this.syncLeftWithWidthChange(object)
    syncTopWithHeightChange()
  }

  evaluateWidthExpansionForFrame = (textObject: StaticText, textTransformWidth: number): number => {
    if (!textObject) { return }
    const frame = this.root.frameHandler.get()
    const objectCoord = { ...this.getCoords(textObject, true) }
    const frameCoord = this.getCoords(frame, true)
    const objectCenterPoint = new fabric.Point((objectCoord.tl.x + objectCoord.br.x) / 2, (objectCoord.tl.y + objectCoord.br.y) / 2)
    // @ts-ignore
    const { initialObjectCoord } = textObject;
    let fixedWidth = textTransformWidth
    let multiplier = textObject.textAlign === TEXT_ALIGN.RIGHT || textObject.textAlign === TEXT_ALIGN.LEFT ? 1 : 2
    let splitByGrapheme = false
    // Determine if text has hit the frame boundaries
    // @ts-ignore
    // Case 1 & 2: Text initially inside the frame or at the center
    if (textObject.isInitiallyInFrame && !textObject.angle) {
      const adjustWidth = (availableWidth: number) => {
        fixedWidth = Math.min(frame.width, textObject.width + (availableWidth * multiplier));
        splitByGrapheme = true;
        // @ts-ignore
        textObject.hasHitFrameBoundary = true;
      };
      switch (textObject.textAlign) {
        case TEXT_ALIGN.CENTER:
          if (objectCenterPoint.x - textTransformWidth / 2 <= frameCoord.tl.x) { // Hit the left frame
            const widthAvailable = objectCoord.tl.x - frameCoord.tl.x
            adjustWidth(widthAvailable)
          } else if (objectCenterPoint.x + textTransformWidth / 2 >= frameCoord.tr.x) { //Hit the right frame
            const widthAvailable = frameCoord.tr.x - objectCoord.tr.x
            adjustWidth(widthAvailable)
          }
          break;
        case TEXT_ALIGN.LEFT:
          // @ts-ignore
          if (initialObjectCoord && textTransformWidth >= frameCoord.tr.x - initialObjectCoord.tl.x) { //Hit the right frame
            // @ts-ignore
            fixedWidth = frameCoord.tr.x - initialObjectCoord.tl.x
            splitByGrapheme = true;
            // @ts-ignore
            textObject.hasHitFrameBoundary = true
          } else {
            // Dynamically expand the text width until it hits the boundary
            fixedWidth = Math.min(frame.width, textTransformWidth);
          }
          break;
        case TEXT_ALIGN.RIGHT:
          // @ts-ignore
          if (initialObjectCoord && textTransformWidth > initialObjectCoord.tr.x - frameCoord.tl.x) { // Hit the left frame
            // @ts-ignore
            fixedWidth = initialObjectCoord.tr.x - frameCoord.tl.x
            splitByGrapheme = true;
            // @ts-ignore
            textObject.hasHitFrameBoundary = true
          } else {
            // Dynamically expand the text width until it hits the boundary
            fixedWidth = Math.min(frame.width, textTransformWidth);
          }
          break;
      }

    }
    // @ts-ignore
    // Case 3: Text initially outside the frame
    else {
      fixedWidth = Math.min(textTransformWidth, frame.width);
      if (fixedWidth === frame.width) {
        splitByGrapheme = true
      }
    }

    // Check if text is in frame after text changed
    // @ts-ignore
    textObject.hasHitFrameBoundary = textObject.isInitiallyInFrame && (objectCenterPoint.x - textTransformWidth / 2 <= frameCoord.tl.x || objectCenterPoint.x + textTransformWidth / 2 >= frameCoord.tr.x)
    // @ts-ignore
    textObject.isInitiallyInFrame = textObject.hasHitFrameBoundary || objectCenterPoint.x - textObject.width / 2 >= frameCoord.tl.x && objectCenterPoint.x + textObject.width / 2 <= frameCoord.tr.x
    // @ts-ignore
    textObject.set({
      width: fixedWidth,
      scaleX: 1,
      scaleY: 1,
      splitByGrapheme: splitByGrapheme,
    });
    textObject.setCoords()
    this.canvas.requestRenderAll()
  }

  syncLeftWithWidthChange = (object: StaticText) => {
    // @ts-ignore
    const { left, top, textAlign, width, initialObjectCoord, angle} = object;
    if(!initialObjectCoord) {
      return
    }
    const objectCoords = this.getCoords(object, true)
    let adjustedLeft = left;
    let adjustedTop = top;
    const objectCoordsCenterPoint = new fabric.Point((objectCoords.tl.x + objectCoords.br.x) / 2, (objectCoords.tl.y + objectCoords.br.y) / 2)
    let translationVector = null
    switch (textAlign) {
      case TEXT_ALIGN.LEFT:
        if(angle) {
          translationVector = new fabric.Point(initialObjectCoord.tl.x - objectCoords.tl.x, initialObjectCoord.tl.y - objectCoords.tl.y)
          adjustedLeft = objectCoordsCenterPoint.x + translationVector.x
          adjustedTop = objectCoordsCenterPoint.y + translationVector.y
        } else {
          adjustedLeft = initialObjectCoord.tl.x + width / 2
        }
        break
      case TEXT_ALIGN.RIGHT:
        if(angle) {
          translationVector = new fabric.Point(initialObjectCoord.tr.x - objectCoords.tr.x, initialObjectCoord.tr.y - objectCoords.tr.y)
          adjustedLeft = objectCoordsCenterPoint.x + translationVector.x
          adjustedTop = objectCoordsCenterPoint.y + translationVector.y
        } else {
          adjustedLeft = initialObjectCoord.tr.x - width / 2
        }
        break;
    }
    const objectToSet = angle && textAlign !== TEXT_ALIGN.CENTER ? {
      left: adjustedLeft,
      top: adjustedTop,
      // @ts-ignore
      _originalWidth: object.width,
      _originalHeight: object.height
    } : {
      left: adjustedLeft,
      // @ts-ignore
      _originalWidth: object.width,
    }
    object.set(objectToSet)
  }

  private getCoords(object: fabric.Object, absolute: boolean = false) {
    const [tl, tr, br, bl] = object.getCoords(absolute)
    return { tl, tr, br, bl }
  }

  public setTextInitialPosition = (textObject: StaticText) => {
    if (!textObject) { return }
    const frame = this.root.frameHandler.get()
    const objectCoord = { ...this.getCoords(textObject, true) }
    const frameCoord = this.getCoords(frame, true)
    const objectCenterPoint = new fabric.Point((objectCoord.tl.x + objectCoord.br.x) / 2, (objectCoord.tl.y + objectCoord.br.y) / 2)

    const isInitiallyInFrame = objectCenterPoint.x - textObject.width / 2 >= frameCoord.tl.x && objectCenterPoint.x + textObject.width / 2 <= frameCoord.tr.x
    textObject.set({
      // @ts-ignore
      isInitiallyInFrame: isInitiallyInFrame,
      // @ts-ignore
      hasHitFrameBoundary: false,
      // @ts-ignore
      initialObjectCoord: { ...objectCoord }
    })
  }

  destroy() {
    this.clear()
  }
}

export default ObjectHandler