import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size ? props.size : 24} 
    height={props.size ? props.size : 24}
    fill="none"
    {...props}
  >
    <path
      stroke="#666"
      strokeLinecap="square"
      strokeWidth={2}
      d="m13.414 7.596 2.829 2.829"
    />
    <path
      stroke="#666"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15.89 4.414a2 2 0 0 1 2.828 0l1.414 1.414a2 2 0 0 1 0 2.829L9.525 19.264l-4.95.707.708-4.95L15.889 4.414Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
