import React, { useRef, useState, useEffect } from 'react';

// Image-based services
import { describeImage, generateImageBaseOnImage } from '@/services/ai/imageService';
import { useEditorContext } from '@/scenes/engine';
import { ObjectType, ShowModalType } from '@/scenes/engine/common/constants';
import { MediaImageType } from '@/scenes/engine/objects/media-repository/media_image_type';
import { MediaImageRepository } from '@/scenes/engine/objects/media-repository/media_image_repository';
import CanvasImageRenderer from '@/scenes/engine/utils/canvasImageRenderer';
import { useSelector } from 'react-redux';
import { selectImageElements } from '@/store/slices/imageElement/selectors';
import { MediaImageRepositoryProcessing } from '@/scenes/engine/objects/media-repository/media_image_repository_processing';
import DynamicTextarea from '../Panels/PanelItems/AIImageSharedComponent.tsx/DynamicTextarea';
import { styled, Theme } from 'baseui';
import { Input } from 'baseui/input';
import useAppContext from '@/hooks/useAppContext';
import { lightTheme } from '@/customTheme'
import { t } from 'i18next';
import { StaticImage } from 'fabric/fabric-impl';
import { BasePixiFilter } from '@/scenes/engine/utils/PixijsFilters/BasePixiFilter';
import { Size } from '@/scenes/engine/objects/media-repository/size';
import { Inset } from '@/scenes/engine/objects/media-repository/inset';
import GradientLoader from './GradientLoader';
import ReactDOM from 'react-dom';
import { selectUser, selectUserIsPremium } from '@/store/slices/user/selectors';
import { customAmplitude } from '@/utils/customAmplitude';
import { setOpenModalTryPremium } from '@/store/slices/user/actions';
import { useAppDispatch } from '@/store/store';
import VideoIntroAIImage from '@assets/video/VideoIntroAIImage.mp4'
import VideoIntroAIImagePlaceHolder from '@assets/images/VideoIntroAIImage_frame_0.jpg'
import Icons from '../Icons';
import GradientSpinner from '@/components/GradientSpinner';
import ButtonCustom from '@/components/ButtonCustom';
import { KIND, SHAPE } from 'baseui/button';
import { SizeButton } from '@/constants/sizeButton';
import FaceSwapperGrid from './FaceSwapperGrid';
import { Faces, FaceSwapDataObject } from './faceSwapStaticData';
import { FaceSwapDemoData } from './faceSwapDemoData';
import api from '@/services/api';
import LoginOverlaySimple from './LoginOverlaySimple';
import AutoScroll from '@/components/AutoScroll';

type ActionItem = {
    title: string;
    isPlacehoder: boolean;
    actionToRun: () => void;
    icon?: React.ReactNode; // e.g. "🩹" or <SvgIcon />
};

type ImageInfoItem = {
  image: string;
  hasFace: boolean;
}

type ActionItemMap = {
    [key: string]: ActionItem[];
};

type ImageInfoItemMap = {
    [key: string]: ImageInfoItem;
};

interface ActionMenuProps {
    actions: ActionItem[];
    onSubmit?: (inputValue: string) => void;
    loading?: boolean;
    onActionSelect?: (action: ActionItem) => void;
    onFaceSelected: (face: FaceSwapDataObject) => void;
    onDemoFinished: () => void;
    hasFaces: boolean;
    hasPremium: boolean;
    isLoggedIn: boolean;
    isDemoMode: boolean;
    onShowMore?: () => void;
    onClose?: () => void;
    genImageLoading: boolean;
    genImageError: string;
    onClearError?: () => void;
    onSignIn?: () => void;
}

/** Container styled to mimic a pop-up card-like layout */
const Container = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '320px',
    height: '420px',
    paddingBottom: '16px',
    borderRadius: '16px',
    backgroundColor: $theme.colors.backgroundPrimary,
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)'
    // fontFamily: $theme.typography.FontFamily,
}));

const Content = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '330px',
    overflow: 'scroll'
}));

const HeaderRow = styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '8px',
});

/** Title at the top of the card */
const Title = styled('h2', ({ $theme }) => ({
    ...lightTheme.typography.Header16bold,
}));

/** "Suggested" label */
const SuggestedLabel = styled('span', ({ $theme }) => ({
    ...lightTheme.typography.Small11medium,
    marginBottom: '8px',
    display: 'inline-block'
}));

const Chevron = styled('i', ({ $theme }) => ({
    ...lightTheme.typography.Small11medium,
    marginBottom: '8px',
    marginLeft: '2px',
}));

const SuggestedSection = styled('div', ({ $theme }) => ({
    ...lightTheme.typography.Small11medium,
    marginBottom: '8px',
    display: 'flex',
    justifyContent: 'space-between'
}));

/** UL for the action items */
const ActionList = styled('ul', {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
});

/** LI for each action item */
const ActionItemRow = styled('li', ({ $theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '6px 8px 6px 8px',
    borderRadius: '8px',
    cursor: 'pointer',
    backgroundColor: lightTheme.colors.grayScale50,
    transition: 'background-color 0.15s ease',
    ':hover': {
        backgroundColor: lightTheme.colors.grayScale100,
    },
}));

/** Optional icon to show on each item */
const ActionItemIcon = styled('span', {
    width: '22px',
    height: '22px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.2rem',
    marginRight: '8px',
});

/** Text of the item */
const ActionItemTitle = styled('span', ({ $theme }) => ({
    ...lightTheme.typography.Small12medium,
}));
const ActionItemError = styled('span', ({ $theme }) => ({
    ...lightTheme.typography.Small12medium,
    lineHeight: '20px',
}));

/** SkeletonRow used when loading */
const SkeletonRow = styled('li', ({ $theme }) => ({
    backgroundColor: lightTheme.colors.grayScale100,
    height: '36px',
    borderRadius: '8px',
    animation: 'pulse 1.5s ease-in-out infinite',

}));

// Send button style
const SendButton = styled('button', (props: { $disabled?: boolean, $isActive?: boolean }) => ({
    position: 'relative',
    width: '30px',
    height: '30px',
    border: 'none',
    outline: 'none',
    background: props.$disabled ? lightTheme.colors.grayScale50 : lightTheme.colors.primary,
    borderRadius: '50%',
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: props.$disabled ? 'none' : 'auto',
    cursor: 'pointer',
    ':hover': {
        background: 'rgba(229, 5, 86, 1)'
    },
    ':active': {
        background: 'rgba(204, 4, 77, 1)'
    },
    filter: props.$disabled ? 'none' : 'drop-shadow(0px 0px 12px rgba(255, 5, 96, 0.25))'
}));

const GradientText = styled('span', {
    background: 'linear-gradient(125deg, #FF1866 2.53%, #FD0167 18.16%, #F00188 38.58%, #DD01A0 48.37%, #D203B4 61.09%, #C10EC4 77.43%, #99019C 98.21%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontWeight: 600,
});


const ActionMenu: React.FC<ActionMenuProps> = ({
    actions,
    onActionSelect,
    onFaceSelected,
    onDemoFinished,
    hasFaces,
    isLoggedIn,
    isDemoMode,
    loading,
    onSubmit,
    hasPremium,
    onShowMore,
    onClose,
    genImageLoading,
    genImageError,
    onClearError,
    onSignIn
}) => {
    const [inputValue, setInputValue] = useState('');
    const [isInFaceEditMode, setIsInFaceEditMode] = useState<boolean>(false);

    const isInputDisabled = genImageLoading || genImageError.length > 0 || loading === true;
    const isSubmitDisabled = inputValue.trim().length === 0 || isInputDisabled;

    useEffect(() => {
      setIsInFaceEditMode(isDemoMode);
    }, [isDemoMode])
    
    const handleItemClick = (action: ActionItem) => {
        if (onActionSelect) {
            onActionSelect(action);
        }
    };

    const handleSubmit = () => {
        if (onSubmit) {
            onSubmit(inputValue);
        }
    };

    // if (!isLoggedIn) {
    // return (
    //     <Container style={{ position: 'relative' }}>
    //         <LoginOverlaySimple onSignIn={onSignIn} />
    //     </Container>
    // );
    // }

    return (
        <Container>
            <style>{`
            @keyframes pulse {
                50% {
                    opacity: 0.5;
                }
            }
        `}</style>
            <HeaderRow style={{ marginLeft: '16px', marginRight: '16px', marginTop: '16px' }}>
                <Title>{t('What do you want to do?')}</Title>
                <ButtonCustom
                    shape={SHAPE.square}
                    kind={KIND.minimal}
                    type={SizeButton.SMALL}
                    onClick={onClose}
                    style={{ borderRadius: '360px' }}
                >
                    <Icons.Close size={24} />
                </ButtonCustom>
            </HeaderRow>
            {(!isLoggedIn && !isDemoMode) ? (
                <LoginOverlaySimple onSignIn={onSignIn} />
            ) : (
                <>
                    <div style={{ paddingLeft: '16px', paddingRight: '16px', paddingTop: '0px' }}>
                        <Input
                            value={inputValue}
                            onChange={(e) => setInputValue((e.target as HTMLInputElement).value)}
                            placeholder="Just type to edit (e.g. change sky)"
                            endEnhancer={() => (
                                <SendButton
                                    $disabled={isSubmitDisabled}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (!isSubmitDisabled) {
                                            handleSubmit();
                                        }
                                    }}
                                >
                                    <Icons.FullArrowRightIcon fill={isSubmitDisabled ? 'rgba(204, 204, 204, 1)' : '#FFF'} />
                                    {(!hasPremium) ? <div style={{ position: 'absolute', top: '-4px', right: '-5px' }}><Icons.PremiumIcon disabled={isSubmitDisabled} /></div> : null}
                                </SendButton>
                            )}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !isSubmitDisabled) {
                                    handleSubmit();
                                }
                            }}
                            overrides={{
                                Root: {
                                    style: ({ $theme, $isFocused }) => ({
                                        marginBottom: '16px',
                                        borderWidth: '1px !important',
                                        borderStyle: 'solid',
                                        // Show a slight border if focused; otherwise, use a neutral color
                                        borderColor: $isFocused ? lightTheme.colors.grayScale300 : lightTheme.colors.grayScale200,
                                        borderRadius: '12px !important',
                                        paddingRight: '4px',
                                        transition: 'border-color 0.2s ease',
                                        backgroundColor: lightTheme.colors.white,
                                    }),
                                },
                                InputContainer: {
                                    style: {
                                        outline: 'none',
                                        border: 'none',
                                    },
                                },
                                Input: {
                                    style: {
                                        fontSize: '14px',
                                    }
                                }
                            }}
                        />
                    </div>
                    <AutoScroll
                        style={{
                            marginTop: 0,
                            paddingRight: '4px',
                            paddingLeft: '16px',
                            paddingTop: '0px',
                        }}
                    >

                        {genImageLoading && (
                            <>
                                <SuggestedLabel>{t('Results for image')}</SuggestedLabel>
                                <ActionList>
                                    <ActionItemRow>
                                        <ActionItemIcon>
                                            <GradientSpinner />
                                        </ActionItemIcon>
                                        <ActionItemTitle>
                                            <GradientText>{t('Generate')}</GradientText>
                                        </ActionItemTitle>
                                    </ActionItemRow>
                                </ActionList>
                            </>
                        )}

                        {genImageError && !genImageLoading && (
                            <>
                                <div
                                    onClick={onClearError}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        marginBottom: '8px',
                                    }}
                                >
                                    <ActionItemIcon style={{ marginRight: '0px' }}>
                                        <Icons.Back size={16} />
                                    </ActionItemIcon>
                                    <span
                                        style={{ ...lightTheme.typography.Small11medium, }}
                                    >{t('Back to Suggested')}</span>
                                </div>
                                <ActionList>
                                    <ActionItemRow>
                                        <ActionItemIcon>
                                            <Icons.Info />
                                        </ActionItemIcon>
                                        <ActionItemError>
                                            {t('Oops. We couldn’t process this image due to content restrictions.')}
                                        </ActionItemError>
                                    </ActionItemRow>
                                </ActionList>
                            </>
                        )}

                      {!genImageError && (
                            <div style={{display: genImageLoading ? 'none' : 'block' }}>
                                {/* faces support */}
                                {hasFaces && (
                                    <>
                                        <div style={{ marginBottom: "16px" }}>
                                            {!isInFaceEditMode && (
                                                <>
                                                    <SuggestedSection>
                                                        <SuggestedLabel>
                                                            {t('Faces')}
                                                        </SuggestedLabel>
                                                        <SuggestedLabel style={{ 'cursor': 'pointer' }} onClick={() => setIsInFaceEditMode(true)}>
                                                            {t('Show more')}
                                                            <Chevron>
                                                                <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.84031 4.99996L0.65958 1.95232C0.299953 1.60774 0.299953 1.03294 0.65958 0.688356C0.998114 0.363986 1.53212 0.363986 1.87066 0.688355L5.73799 4.39387C5.90575 4.55461 6 4.77263 6 4.99996C6 5.22729 5.90575 5.44531 5.73799 5.60605L1.87066 9.31156C1.53212 9.63593 0.998115 9.63593 0.65958 9.31156C0.299953 8.96698 0.299954 8.39218 0.659581 8.0476L3.84031 4.99996Z" fill="#111111" />
                                                                </svg>
                                                            </Chevron>
                                                        </SuggestedLabel>
                                                    </SuggestedSection>
                                                </>
                                            )}
                                            {!isDemoMode && isInFaceEditMode && (
                                                <>
                                                    <SuggestedSection>
                                                        <SuggestedLabel style={{ 'cursor': 'pointer' }} onClick={() => setIsInFaceEditMode(false)}>
                                                            <Chevron style={{ "marginRight": "5px" }}>
                                                                <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.15969 4.99996L5.34042 1.95232C5.70005 1.60774 5.70005 1.03294 5.34042 0.688356C5.00189 0.363986 4.46788 0.363986 4.12934 0.688355L0.262014 4.39387C0.0942489 4.55461 -4.07782e-07 4.77263 -4.27655e-07 4.99996C-4.47529e-07 5.22729 0.0942488 5.44531 0.262014 5.60605L4.12934 9.31156C4.46788 9.63593 5.00189 9.63593 5.34042 9.31156C5.70005 8.96698 5.70005 8.39218 5.34042 8.0476L2.15969 4.99996Z" fill="#111111" />
                                                                </svg>
                                                            </Chevron>
                                                            <SuggestedLabel style={{ "marginRight": "5px" }}>
                                                                {t('Results of')}
                                                            </SuggestedLabel>
                                                            <GradientText>
                                                                {t('Face styles')}
                                                            </GradientText>
                                                        </SuggestedLabel>
                                                    </SuggestedSection>
                                                </>
                                            )}
                                            <FaceSwapperGrid
                                                data={(isDemoMode || isInFaceEditMode) ? Faces : Faces.slice(0, 3)}
                                                isDemoMode={isDemoMode}
                                                onFaceSelect={onFaceSelected}
                                                demoFinished={onDemoFinished}
                                                themeStyle={{}}
                                            >
                                            </FaceSwapperGrid>
                                        </div>
                                    </>
                                )}
                                {!isInFaceEditMode && (
                                  <>
                                <SuggestedLabel>{t('Suggested')}</SuggestedLabel>
                                <ActionList>
                                    {loading ? (
                                        <>
                                            <SkeletonRow />
                                            <SkeletonRow />
                                            <SkeletonRow />
                                            <SkeletonRow />
                                            <SkeletonRow />
                                            <SkeletonRow />
                                        </>
                                    ) : (
                                        <>
                                            {actions.map((action, index) => (
                                                <ActionItemRow key={index} onClick={() => handleItemClick(action)}>
                                                    {action.icon && <ActionItemIcon>{action.icon}</ActionItemIcon>}
                                                    <ActionItemTitle>{action.title}</ActionItemTitle>
                                                    {!hasPremium && !isDemoMode && (
                                                        <ActionItemIcon style={{ marginLeft: 'auto' }}>
                                                            <Icons.PremiumIcon />
                                                        </ActionItemIcon>
                                                    )}
                                                </ActionItemRow>
                                            ))}
                                            {onShowMore && (
                                                <ActionItemRow onClick={() => onShowMore()}>
                                                    <ActionItemIcon><Icons.MagicStars size={22} /></ActionItemIcon>
                                                    <ActionItemTitle>{t('Show more')}</ActionItemTitle>
                                                    {!hasPremium && !isDemoMode && (
                                                        <ActionItemIcon style={{ marginLeft: 'auto' }}>
                                                            <Icons.PremiumIcon />
                                                        </ActionItemIcon>
                                                    )}
                                                </ActionItemRow>
                                            )}
                                        </>
                                    )}
                                </ActionList>
                                </>
                                )}
                            </div>
                        )}
                    </AutoScroll>
                </>
            )}
        </Container>
    );
};

export interface ImageProcessorProps {
    isDemo: boolean
}

const ImageProcessor: React.FC<ImageProcessorProps> = ({
    isDemo
}) => {
    const panelRef = useRef<HTMLDivElement>(null);
    const cssLoaderContainer = useRef<HTMLDivElement>(null);

    const [dragging, setDragging] = useState(false);
    const [isDemoMode, setIsDemoMode] = useState(isDemo);
    const [didDemoFinish, setDidDemoFinish] = useState(false);
    const [demoLayerID, setDemoLayerID] = useState('');

    const [offset, setOffset] = useState({ x: 0, y: 0 });

    const [actionMap, setActionMap] = useState<ActionItemMap>({});
    const [imageInfoItemMap, setImageInfoItemMap] = useState<ImageInfoItemMap>({});

    const [loadingActions, setLoadingActions] = useState(true);

    const [genImageLoading, setGenImageLoading] = useState(false);
    const [genImageError, setGenImageError] = useState('');

    const { editor, activeObject } = useEditorContext()
    const {
        setDisableEditor,
        setRemoveBgSuccess,
        isOpenAiImageProcessor,
        setIsOpenAiImageProcessor,
        isOpenTutorial
    } = useAppContext()
    const filterPacks = useSelector(selectImageElements)
    const imageProcessing = new MediaImageRepositoryProcessing()
    const hasPremium = useSelector(selectUserIsPremium)
    const dispatch = useAppDispatch()
    const currentLanguage = localStorage.getItem('language')

    // const user = useSelector(selectUser)

    const addAction = (key: string, item: ActionItem) => {
        setActionMap(prev => ({
            ...prev,
            [key]: [...(prev[key] || []), item],
        }));
    };

    const setImageInfoItem = (key: string, item: ImageInfoItem) => {
      setImageInfoItemMap(prevMap => {
        const safeMap = prevMap ?? {};

        const existingItem: ImageInfoItem = safeMap[key] ?? { image: '', hasFace: false };
      
        const updatedItem: ImageInfoItem = {
          ...existingItem,
          ...item,
        };
      
        return {
          ...safeMap,
          [key]: updatedItem,
        };
      });
    };

    const getActionsForCurrentId = (filterPlaceholder: boolean): ActionItem[] => {
        let updatedActiveObject = editor.handlers.canvasHandler.canvas.getActiveObject();
        // @ts-ignore
        let layerId = updatedActiveObject?.id
        if (!layerId) {
            return []
        }
        return (actionMap[layerId] || []).filter(a => !(a.isPlacehoder && filterPlaceholder));
    };

    const getHasFaceForCurrentId = (): boolean => {
        let updatedActiveObject = editor.handlers.canvasHandler.canvas.getActiveObject();
        // @ts-ignore
        let layerId = updatedActiveObject?.id
        if (!layerId || !imageInfoItemMap) {
            return false
        }
        return imageInfoItemMap[layerId]?.hasFace;
    };

    useEffect(() => {
      if(!(isDemo && !demoLayerID)){
        return;
      }
      let updatedActiveObject = editor.handlers.canvasHandler.canvas.getActiveObject();
      // @ts-ignore
      let layerId = updatedActiveObject.id
      setDemoLayerID(layerId);

      let info: ImageInfoItem = {
        hasFace: true,
        image: FaceSwapDemoData.find(f => f.isOriginal).image
      }
      setImageInfoItem(layerId, info);
    }, [isDemo]);
    
    const handleReplacedLayer = async (): Promise<ImageInfoItem | null> => {
      if (isDemoMode && didDemoFinish && !showingDemoLayer()){
        setIsDemoMode(false);
        setDemoLayerID('');

        let activeImageObject = editor.handlers.canvasHandler.canvas.getActiveObject();
        if(!activeImageObject){
            return null;
        }
        // @ts-ignore
        let layerId = activeImageObject.id;
        // @ts-ignore
        let currentAssetStateId = activeImageObject.layerAssetStateId;

          let base64OriginalImage = await MediaImageRepository.getInstance().getImage(layerId, currentAssetStateId, MediaImageType.latest)
          let info: ImageInfoItem = {
            hasFace: true,
            image: base64OriginalImage
          }
          setImageInfoItem(layerId, info);
        return info
      } 
      return null;
    }

    useEffect(() => {
      setTimeout(async () => {
        let updatedActiveObject = editor.handlers.canvasHandler.canvas.getActiveObject();

        if (await handleReplacedLayer()){
          return;
        }
        
        // user deselected demo layer but hasn't replaced it yet
        if (isDemoMode && didDemoFinish && showingDemoLayer() && !updatedActiveObject){
          return;
        }

        if (demoLayerID){
          // @ts-ignore
          let didSelectDemoLayer = updatedActiveObject && updatedActiveObject.id == demoLayerID;
          setIsDemoMode(didSelectDemoLayer);
        }
  
        if (!isOpenAiImageProcessor || !hasPremium || isOpenTutorial) {
            setLoadingActions(false)
            return;
        }
  
          if (updatedActiveObject && updatedActiveObject.type === ObjectType.BAZAART_IMAGE) {
              setGenImageError('')
              if (getActionsForCurrentId(true).length > 0) {
                  setLoadingActions(false)
                  return;
              }
  
              if (getActionsForCurrentId(false).length > 0) {
                  setLoadingActions(true)
                  return;
              }
  
              // @ts-ignore
              let layerId = updatedActiveObject.id
              let placeholder: ActionItem = {
                  title: "",
                  isPlacehoder: true,
                  actionToRun: () => { }
              }
              addAction(layerId, placeholder)
              suggestEdits()
          }
          else {
              setLoadingActions(false)
          }
        })
    }, [activeObject, isOpenAiImageProcessor, hasPremium])

    const suggestEdits = () => {
        const extractJsonBlock = (text: string): string => {
            const startMarker = '```json';
            const endMarker = '```';

            const startIndex = text.indexOf(startMarker);
            if (startIndex === -1) return null;

            const endIndex = text.indexOf(endMarker, startIndex + startMarker.length);
            if (endIndex === -1) return null;

            const extracted = text.substring(startIndex + startMarker.length, endIndex);
            return extracted.trim();
        }
        setLoadingActions(true)

        let updatedActiveObject = editor.handlers.canvasHandler.canvas.getActiveObject();
        // @ts-ignore
        let layerId = updatedActiveObject.id
        // @ts-ignore
        let currentAssetStateId = updatedActiveObject.layerAssetStateId
        MediaImageRepository.getInstance().getImage(layerId, currentAssetStateId, MediaImageType.latest).then(async (image) => {
            let base64Image = await imageProcessing.blobUrlToBase64(image)

            base64Image = `data:image/png;base64,${base64Image}`;
            let resizedImage = await imageProcessing.resizeBase64ToMaxEdgeSize(base64Image, 256);

            let hasFacesResult = await api.askQuestion(resizedImage.split(',')[1], ['is there a face of a person in the image?'])
            let hasFaces = hasFacesResult && hasFacesResult[0].toLowerCase() == 'yes';
            let info: ImageInfoItem = {
              hasFace: hasFaces,
              image: image
            }
            setImageInfoItem(layerId, info)

            let prompt = `You are an expert image editor. Given the following image, suggest 5 image editing actions that would be appropripate.
                            Out of the 5, 1 should suggest adding an approperiate element and 1 should suggest removing an element and 1 should suggest something fun.
                            Don't suggest any color changes.
                            If there is a face in the image don't suggest anything that modifies the face.
                            return a title for each action that has only 2 words, an emoji that fits it and a description that has the entire prompt you should run for a consecutive image editing operation.
                            return in the following json format: {image_editing_actions: [{title: suggestedTitled, icon: suggestedIcon, actionToRun: actionToRun}]}.
                            return valid json only! You must return an icon! if you don't find a matching icon return a question mark icon. Be original in your editing suggestions and make sure they are relevant to this image.
                            `;
            if (currentLanguage && currentLanguage != '' && currentLanguage !== 'en') {
                prompt += ` suggestedTitled should be in language: ${currentLanguage}.`
            }

            const result = await describeImage(resizedImage, prompt, 'openai');
            const cleanResult = extractJsonBlock(result);;
            const jsonResult = JSON.parse(cleanResult)['image_editing_actions'];

            setActionMap(prev => ({ ...prev, [layerId]: [] }));
            jsonResult.forEach(item => {
                addAction(layerId, item)
            })

            setLoadingActions(false)
        })
    }

    const startLoading = () => {
        let activeImageObject = activeObject as unknown as StaticImage;
        // setDisableEditor(true)
        // setRemoveBgSuccess(true);
        animateCssLoader(activeImageObject);
        setGenImageLoading(true);
    }

    const stopLoading = () => {
        clearCssLoader();
        // setDisableEditor(false)
        // setRemoveBgSuccess(true);
        setGenImageLoading(false);
    }

    const runPrompt = async (prompt: string, options?: {overrideImage?: string, shouldShowLoader: boolean }) => {
        let activeImageObject = activeObject as unknown as StaticImage;

        setGenImageError('');
        if (options?.shouldShowLoader != false) {
            startLoading();
        }
        setTimeout(async () => {
            try {
                let base64OriginalImage = options?.overrideImage;
                if (!base64OriginalImage) {
                  // @ts-ignore
                  let layerId = activeImageObject.id
                  // @ts-ignore
                  let currentAssetStateId = activeImageObject.layerAssetStateId

                  base64OriginalImage = await MediaImageRepository.getInstance().getImage(layerId, currentAssetStateId, MediaImageType.latest)
                }

                base64OriginalImage = await imageProcessing.blobUrlToBase64(base64OriginalImage)
                base64OriginalImage = `data:image/png;base64,${base64OriginalImage}`; 

                const { success, image } = await generateImageBaseOnImage(base64OriginalImage, prompt, 'gemini');
                if (!success) {
                    setGenImageError(t('Oops. We couldn’t process this image due to content restrictions.'));
                    const eventProperties = {
                        Error: "Gemini Error",
                    }
                    customAmplitude('AI Edit Error', eventProperties)
                    stopLoading();
                    return;
                }

                let resizedImage = await imageProcessing.resizeBase64ToMaxEdgeSize(image, 1280)
                replaceLayer(resizedImage);
            } catch (err) {
                console.error(err);
                const eventProperties = {
                    Error: err.toString(),
                }
                customAmplitude('AI Edit Error', eventProperties)
                setGenImageError(t('Oops. We couldn’t process this image due to content restrictions.'));
                stopLoading();
            } finally {
            }
        }, 100)
    }

    const replaceLayer = (image: string) => {
      editor.handlers.transactionHandler.save()
      editor.handlers.objectsHandler.replaceImage(image, false, false).then(() => {
          let updatedActiveObject = editor.handlers.canvasHandler.canvas.getActiveObject();
          if (!updatedActiveObject) {
              stopLoading();
              return;
          }
          // @ts-ignore
          let layerId = updatedActiveObject.id
          // @ts-ignore
          let currentAssetStateId = updatedActiveObject.layerAssetStateId

          CanvasImageRenderer.getInstance().render(
              updatedActiveObject,
              editor.handlers.frameHandler.getSize(),
              filterPacks
          )

          MediaImageRepository.getInstance().getImage(layerId, currentAssetStateId, MediaImageType.latest).then(async (newLatestImage) => {
              setTimeout(() => {
                  stopLoading()
              }, 100)
          })
      })
    }

    const animateCssLoader = (activeImageObject: StaticImage) => {
        cssLoaderContainer.current = document.createElement('div');
        document.body.appendChild(cssLoaderContainer.current);

        const angle = activeImageObject.angle;

        activeImageObject.rotate(0);
        activeImageObject.setCoords()
        let { x, y } = activeImageObject.getCoords()[0];
        activeImageObject.rotate(angle);
        activeImageObject.setCoords()

        let canvas = editor.handlers.canvasHandler.canvas

        const zoom = canvas.getZoom();
        const width = activeImageObject.width * activeImageObject.scaleX * zoom;
        const height = activeImageObject.height * activeImageObject.scaleY * zoom;

        ReactDOM.render(<GradientLoader angle={angle} width={width} height={height} top={y + 64} left={x} />, cssLoaderContainer.current);
    }

    const clearCssLoader = () => {
        ReactDOM.unmountComponentAtNode(cssLoaderContainer.current);
        document.body.removeChild(cssLoaderContainer.current);
        cssLoaderContainer.current = null;
    }

    const runAction = async (actionItem) => {
        if (!hasPremium) {
            const eventProperties = {
                Source: 'BtSubscriptionAIEdit',
                Type: 'Standard',
            }
            customAmplitude('Premium Prompt', eventProperties)
            dispatch(setOpenModalTryPremium({
                isOpen: true,
                opened: true,
                source: 'BtSubscriptionAIEdit',
                callback: () => {
                    runPrompt(actionItem.actionToRun);
                    const eventProperties = {
                        Query: actionItem.title,
                    }
                    customAmplitude('AI Edit Suggested', eventProperties)
                },
                video: VideoIntroAIImage,
                placeholderImage: VideoIntroAIImagePlaceHolder
            }))
            return
        }
        runPrompt(actionItem.actionToRun);
        const eventProperties = {
            Query: actionItem.title,
        }
        customAmplitude('AI Edit Suggested', eventProperties)
    }

    const runSubmit = (inputValue: string) => {
        if (!hasPremium) {
            const eventProperties = {
                Source: 'BtSubscriptionAIEdit',
                Type: 'Standard',
            }
            customAmplitude('Premium Prompt', eventProperties)
            dispatch(setOpenModalTryPremium({
                isOpen: true,
                opened: true,
                source: 'BtSubscriptionAIEdit',
                callback: () => {
                    runPrompt(inputValue);
                    const eventProperties = {
                        Query: inputValue,
                    }
                    customAmplitude('AI Edit Custom', eventProperties)
                },
                video: VideoIntroAIImage,
                placeholderImage: VideoIntroAIImagePlaceHolder
            }))
            return
        }
        const eventProperties = {
            Query: inputValue,
        }
        customAmplitude('AI Edit Custom', eventProperties)
        runPrompt(inputValue);
    }

    // New function to load more actions from the backend.
    const loadMoreActions = async () => {
        if (!hasPremium) {
            const eventProperties = {
                Source: 'BtSubscriptionAIEdit',
                Type: 'Standard',
            }
            customAmplitude('Premium Prompt', eventProperties)
            dispatch(setOpenModalTryPremium({
                isOpen: true,
                opened: true,
                source: 'BtSubscriptionAIEdit',
                callback: () => {
                    suggestEdits()
                    const eventProperties = {
                        Query: t('Show more'),
                    }
                    customAmplitude('AI Edit Suggested', eventProperties)
                },
                video: VideoIntroAIImage,
                placeholderImage: VideoIntroAIImagePlaceHolder
            }))
            return
        }
        const eventProperties = {
            Query: t('Show more'),
        }
        customAmplitude('AI Edit Suggested', eventProperties)
        suggestEdits()
    };

    
    const renderReplaceableIndication = () => {
      // @ts-ignore
      let demoActiveObject = editor.handlers.canvasHandler.canvas.getObjects().find (o => o.id == demoLayerID);
      if (demoActiveObject) {
        
        // @ts-ignore
        demoActiveObject.isTemplateLayer = true
        
        editor.handlers.objectsHandler.createReplaceableLayer()
        editor.handlers.canvasHandler.canvas.renderAll();
      }
    }
    
    const showingDemoLayer = () => {
      const hasReplaceableIcon = editor.handlers.canvasHandler.canvas.getObjects().filter((l: any) => l.id.indexOf('replace') !== -1)
      return hasReplaceableIcon.length > 0;
    }
    
    const demoFinished = async () => {
      setDidDemoFinish(true);
      renderReplaceableIndication()
      setDisableEditor(false);
    }

    const faceSelected = async (face: FaceSwapDataObject) => {
        startLoading();
        
        let updatedActiveObject = editor.handlers.canvasHandler.canvas.getActiveObject();
        // @ts-ignore
        let layerId = updatedActiveObject.id
        
        let info = await handleReplacedLayer()
        if (!info && isDemoMode) {
          // // @ts-ignore
          // let demoActiveObject = editor.handlers.canvasHandler.canvas.getObjects().find (o => o.id == demoLayerID);
          // // @ts-ignore
          // let layerId = demoActiveObject.id
          // // @ts-ignore
          // let currentAssetStateId = demoActiveObject.layerAssetStateId
          let foundDemoFace = FaceSwapDemoData.find((f) => f.style.toLowerCase() == face.name.toLowerCase())
          setTimeout(() => {
            replaceLayer(foundDemoFace.image)
            if (didDemoFinish) {
              renderReplaceableIndication()
            }
          });
          return;
        } else if (!info) {
          info = imageInfoItemMap[layerId]
        }
        
        let base64Image = info.image;
        let resizedImage = await imageProcessing.resizeBase64ToMaxEdgeSize(base64Image, 256);
        let modificationPrompt = 'suggest how to apply the following prompt to this image in such a way that we keep the person\'s looks, clothing, posture, hair style and the exact same background ' + face.prompt;
        let finalPrompt = await describeImage(resizedImage, modificationPrompt, 'openai');
        finalPrompt += 'you are not allowed to change the background at all, only the person in the image!';

        await runPrompt(finalPrompt, {
          shouldShowLoader: false,
          overrideImage: base64Image
        });
    }

    const handleSignIn = () => {
        const eventProperties = {
            Source: 'BtSubscriptionAIEdit',
            Type: 'Standard',
        }
        customAmplitude('Premium Prompt', eventProperties)
        dispatch(setOpenModalTryPremium({
            isOpen: true,
            opened: true,
            source: 'BtSubscriptionAIEdit',
            callback: null,
            video: VideoIntroAIImage,
            placeholderImage: VideoIntroAIImagePlaceHolder
        }))
        // setShowModalType(ShowModalType.SIGN_IN)
        // dispatch(setOpenModalTryPremium({
        //     source: 'BtSubscriptionAIEdit',
        //     isOpen: false,
        //     callback: null,
        //     opened: false,
        // }))
    }

    const closePanel = () => {
        setIsOpenAiImageProcessor(false)
        if(isDemoMode){
            demoFinished()
        }
    }

    const handleMouseDown = (e: React.MouseEvent) => {
        if (panelRef.current) {
            const rect = panelRef.current.getBoundingClientRect();
            setOffset({ x: e.clientX - rect.left, y: e.clientY - rect.top });
            setDragging(true);
        }
    };
    const handleMouseMove = (e: MouseEvent) => {
        if (dragging && panelRef.current) {
            panelRef.current.style.left = `${e.clientX - offset.x}px`;
            panelRef.current.style.top = `${e.clientY - offset.y}px`;
        }
    };
    const handleMouseUp = () => setDragging(false);

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [handleMouseMove]);


    if (!isOpenAiImageProcessor || !activeObject || activeObject.type !== ObjectType.BAZAART_IMAGE || isOpenTutorial) {
        return null;
    }

    const initialLeft = window.innerWidth - 320 - 400;
    return (
        <div
            ref={panelRef}
            onMouseDown={handleMouseDown}
            onDragOver={(e) => e.preventDefault()}
            style={{
                position: 'fixed',
                top: 100,
                left: initialLeft,
                cursor: dragging ? 'grabbing' : 'move',
                zIndex: 100,
            }}
        >
            <div style={{ display: 'flex', gap: 8, flexWrap: 'wrap' }}>

                <ActionMenu
                    actions={getActionsForCurrentId(true)}
                    onActionSelect={runAction}
                    loading={loadingActions}
                    onSubmit={runSubmit}
                    hasFaces={getHasFaceForCurrentId()}
                    hasPremium={hasPremium}
                    isLoggedIn={hasPremium}
                    isDemoMode={isDemoMode}
                    onShowMore={loadMoreActions}
                    onFaceSelected={faceSelected}
                    onDemoFinished={demoFinished}
                    onClose={closePanel}
                    genImageLoading={genImageLoading}
                    genImageError={genImageError}
                    onClearError={() => setGenImageError('')}
                    onSignIn={handleSignIn} />

            </div>

        </div>
    );
};

export default ImageProcessor;
