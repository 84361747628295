export interface TransactionScopeState {
    dataType: string;
}

class TransactionHandlerScoped<T extends TransactionScopeState> {
    private scopeName: string
    private dataType: string
    private undos: any[]
    private redos: any[]
    private onChange: (state: T)=>void
    
    constructor(scopeName: string, onChange: (state: T)=>void, dataType: string) {
      this.undos = []
      this.redos = []
      this.onChange = onChange
      this.scopeName = scopeName
      this.dataType = dataType
    }
  
    getScopeName() {
      return this.scopeName
    }
  
    undo() {
      if (this.undos.length > 1) {
        // Remove the current state from undos
        const currentState = this.undos.pop()
        if (currentState) {
          // Add current state to redos for potential redo
          this.redos.push(currentState)
  
          // Restore the previous state
          if (this.undos.length > 0) {
            const previousState = this.undos[this.undos.length - 1];
            this.onChange(previousState)
          }
        }
      }
    }
  
    hasUndo() {
      return this.undos.length > 1
    }
  
    hasRedo() {
      return this.redos.length > 0
    }
  
    redo() {
      if (this.redos.length > 0) {
        // Get the most recent redo state
        const redoState = this.redos.pop();
        
        if (redoState) {
          // Add current state to undos
          this.undos.push(redoState)
  
          // Restore the redo state
          this.onChange(redoState)
        }
      }
    }
  
    save(data: T) {
      if (!data || data.dataType != this.dataType) {
        return
      }
      this.undos.push(data)
      this.redos = []
    }
  }

  export default TransactionHandlerScoped