import { lightTheme } from '@/customTheme'
import { CSSProperties } from 'react'

function GradientSpinner({ style, fill = lightTheme.colors.primary }: { style?: CSSProperties, fill?: string }) {
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        animation: 'rotation 2s infinite linear',
        ...style,
      }}
    >
      <style>{`
        @keyframes rotation {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(359deg);
          }
        }
        `}</style>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.1654 9.83945C19.1987 11.7496 18.6344 13.6225 17.5513 15.1963C16.4682 16.7701 14.9204 17.9661 13.1243 18.6173C11.3282 19.2684 9.37356 19.3421 7.53354 18.828C5.69352 18.3139 4.06011 17.2378 2.86159 15.75C1.66308 14.2622 0.959364 12.4372 0.84881 10.5299C0.738257 8.62259 1.22639 6.72843 2.24498 5.11214C3.26357 3.49584 4.76171 2.23819 6.53001 1.51497C8.29831 0.79174 10.2484 0.639085 12.1077 1.07834L11.7508 2.58906C10.2063 2.2242 8.5865 2.351 7.11765 2.95175C5.6488 3.5525 4.40436 4.59718 3.55826 5.93977C2.71216 7.28235 2.30669 8.85575 2.39852 10.44C2.49035 12.0243 3.0749 13.5404 4.07045 14.7762C5.06601 16.012 6.42282 16.9059 7.95124 17.3329C9.47966 17.76 11.1033 17.6988 12.5953 17.1579C14.0872 16.617 15.3729 15.6235 16.2725 14.3162C17.1722 13.009 17.641 11.4533 17.6133 9.86654L19.1654 9.83945Z" fill="url(#paint0_linear_6686_988)" />
        <defs>
          <linearGradient id="paint0_linear_6686_988" x1="19.0054" y1="0.67418" x2="19.3254" y2="19.0047" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF1866" />
            <stop offset="0.27027" stopColor="#FD0167" />
            <stop offset="0.496094" stopColor="#F00188" />
            <stop offset="0.649414" stopColor="#DD01A0" />
            <stop offset="0.754883" stopColor="#D203B4" />
            <stop offset="1" stopColor="#C811CB" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}

export default GradientSpinner
