import { lightTheme } from '@/customTheme'
import { useEffect, useState } from 'react'
import Icons from '../../../Icons'
import {Icon1, Icon2, Icon3, Icon4, Icon5, Icon6, Icon7, AiImage} from '../../../Icons/IconsModalPremium'
import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { KIND, SHAPE } from 'baseui/button'
import { selectOpenModalTryPremium, selectUser, selectUserIsPremium } from '@/store/slices/user/selectors'
import { useSelector } from 'react-redux'
import { styled, useStyletron } from 'baseui'
import { SignInManager } from '../SignInManager'
import BgTryPremiumSuccess from '@assets/images/premium_success.jpeg'
import { useAppDispatch } from '@/store/store'
import Loading from '@/components/Loading'
import { setOpenModalTryPremium } from '@/store/slices/user/actions'
import { useTranslation } from 'react-i18next'
import VideoIntroRemove from '@assets/video/intro-remove.mp4'
import { customAmplitude } from '@/utils/customAmplitude'
import { ShowModalType } from '@/scenes/engine/common/constants'
import useAppContext from '@/hooks/useAppContext'
import premiumPoster from "@assets/images/try_premium_frame_0.jpg"
import { t } from 'i18next'

const CONTENT_MODAL_PREMIUM = [
  {
    content: 'Remove people and objects from your photos',
    icon: <Icon1 />,
  },
  {
    content: 'Create stunning AI backgrounds',
    icon: <Icon7 />,
  },
  {
    content: 'Generate stunning images from text',
    icon: <AiImage />
  },
  {
    content: 'Find a design template for your every need',
    icon: <Icon2 />,
  },
  {
    content: 'Use a huge collection of graphics and fonts',
    icon: <Icon3 />,
  },
  // {
  //   content: 'Resize your designs in a click',
  //   icon: <Icon4 />,
  // },
  {
    content: 'Download without watermark',
    icon: <Icon5 />,
  },
  {
    content: 'Design on the go with Bazaart’s mobile apps',
    icon: <Icon6 />,
  },
]
const WrapAutoScroll = styled('div', (props: any) => ({
  boxSizing: 'border-box',
  height: '100%',
  overflowY: 'scroll',
  overflowX: 'hidden',
  marginRight: '4px',
  '::-webkit-scrollbar': {
    width: '8px',
    margin: '20px 0',
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '4px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: lightTheme.colors.grayScale100,
    minHeight: '100px ',
  },
  '::-webkit-scrollbar-thumb:hover': {
    cursor: 'pointer',
    backgroundColor: lightTheme.colors.grayScale150,
  },
  ...props.style,
}))

function ModalTryPremium({handleOpenTutorial, showPlanModal, setShowPlanModal}) {
  const user = useSelector(selectUser)
  const [isOpenPayment, setIsOpenPayment] = useState(false)
  const [isOpenChooseAPlan, setIsOpenChooseAPlan] = useState(true)
  const [planActive, setPlanActive] = useState(2)
  const hasPremium = useSelector(selectUserIsPremium)
  const dispatch = useAppDispatch()
  const openModalTryPremium = useSelector(selectOpenModalTryPremium)
  const { setShowModalType, isOpenPixelManipulationObject } = useAppContext()

  useEffect(() => {
    return () => {
      setIsOpenPayment(false)
    }
  }, [hasPremium])

  const { t } = useTranslation()

  const [planDetail, setPlanDetail] = useState(null)
  useEffect(() => {
      setTimeout(async () => {
        SignInManager.getInstance().setupPaddle()
        
        let monthlyPlan = await SignInManager.getInstance().getMonthlyPlan()
        
        let yearlyPlan = await SignInManager.getInstance().getYearlyPlan()
  
        setPlanDetail({
          month: {
            price: monthlyPlan?.recurring?.price?.gross,
            id: monthlyPlan.plan_id,
            canFreeTrial: monthlyPlan.recurring.subscription.trial_days > 0
          },
          year: {
            price: yearlyPlan?.recurring?.price?.gross,
            id: yearlyPlan.plan_id,
            canFreeTrial: yearlyPlan.recurring.subscription.trial_days > 0
          },
        })
      })
  }, [user?.userHadAFreeTrial])

  useEffect(() => {
    if((showPlanModal && user && openModalTryPremium.source) || openModalTryPremium.source === 'Comeback') {
      setIsOpenPayment(true)
      setIsOpenChooseAPlan(true)
    }
  }, [showPlanModal])

  const onClickPrimaryButton = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (user) {
      setIsOpenPayment(true)
      setIsOpenChooseAPlan(true)
    } else {
      setShowModalType(ShowModalType.SIGN_IN)
      dispatch(
        setOpenModalTryPremium({
          ...openModalTryPremium,
          isOpen: false,
          callback: () => {
            openModalTryPremium.callback && openModalTryPremium.callback()
            handleOpenTutorial()
          }
        })
      )
      setShowPlanModal(true)
    }

    if(!user?.userHadAFreeTrial) {
      customAmplitude('Sign up Prompt', {
        Source: 'Premium Prompt',
      })
    }
  }

  return (
    <>
      <div
        style={{
          width: '100vw',
          height: '100vh',
          maxWidth: '920px ',
          maxHeight: '652px',
          minWidth: '920px ',
          minHeight: '652px',
          flexDirection: 'row',
          background: 'rgba(255, 255, 255, 1)',
          borderRadius: '16px',
          overflow: 'hidden',
          position: 'absolute',
          display: 'flex'
        }}
        id="modal-try-premium"
      >
        {!user?.userIsPremium ? (
          <>
            {/* left */}
            {!isOpenPayment ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'left',
                  width: '50%',
                }}
              >
                <WrapAutoScroll
                  style={{
                    padding: '48px 40px 16px 48px',
                  }}
                >
                  <h2 style={{ margin: 0, ...lightTheme.typography.Header26bold }}>
                    {user?.userHadAFreeTrial ? t('Bazaart Premium') : t('Try Bazaart Premium')}
                  </h2>
                  <h4
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '8px',
                      margin: '8px 0 4px 0',
                      ...lightTheme.typography.Small16Bold,
                      fontFamily: 'ES-Rebond-Grotesque-Classic, Rubik, Roboto',
                    }}
                  >
                    {t('Give yourself extra design superpowers!')}
                  </h4>
                  {
                    CONTENT_MODAL_PREMIUM.map((item, index) => (
                      <h4
                        key={index}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '8px',
                          margin: '20px 0 0 0',
                          ...lightTheme.typography.Small14regular,
                        }}
                      >
                        <span style={{ height: lightTheme.typography.Small14regular.lineHeight}}>{item.icon}</span>
                        <span style={{ ...lightTheme.typography.Small14regular, marginLeft: index === 0 ? '-3px' : 0 }}>{t(item.content)}</span>
                      </h4>
                    ))
                  }
                </WrapAutoScroll>
                <div style={{ marginTop: 'auto', textAlign: 'center', marginBottom: user ? '32px' : '0', padding: '40px 48px 16px 48px' }}>
                  <ButtonCustom
                    type={SizeButton.LARGE}
                    kind={KIND.primary}
                    style={{
                      position: 'relative',
                      ...lightTheme.typography.Small14Semibold,
                      color: '#fff',
                      marginBottom: 0,
                    }}
                    onClick={e => onClickPrimaryButton(e)}
                  >
                    {user?.userHadAFreeTrial ? t('Continue') : t('Try it now!')}
                  </ButtonCustom>
                  {!user ? (
                    <h4
                      style={{
                        marginBottom: 0,
                        marginTop: '8px',
                        ...lightTheme.typography.Small12medium,
                        padding: '0 16px',
                        color: 'rgba(153, 153, 153, 1)',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                          setShowModalType(ShowModalType.SIGN_IN)
                          dispatch(
                            setOpenModalTryPremium({
                              ...openModalTryPremium,
                              isOpen: false,
                            })
                          )
                        }
                      }
                    >
                      <span style={{ fontWeight: '400' }}>{t('Already Premium')}?</span> {t('Sign in')}
                    </h4>
                  ) : null}
                </div>
              </div>
            ) : !isOpenChooseAPlan ? (
              <PaymentInfo setIsOpenChooseAPlan={setIsOpenChooseAPlan} />
            ) : (
              <ChooseAPlan
                onConfirmPlan={value => {
                  setPlanActive(value)
                  setIsOpenChooseAPlan(false)
                  setTimeout(() => {
                    const analytics = {
                      Source: openModalTryPremium.source,
                      Type: 'Standard',
                      Duration: planActive === 1 ? 'Monthly' : 'Yearly',
                      Email: user.email,
                    }
                    SignInManager.getInstance().pay(
                      user.platformType,
                      user.email,
                      user.userId,
                      success => {
                        setIsOpenPayment(false)
                        // if (user.userIsPremium) {
                        //   if (openModalTryPremium.callback) {
                        //     openModalTryPremium.callback()
                        //   }
                        // }
                      },
                      planActive === 1 ? planDetail?.month?.id : planDetail?.year?.id,
                      analytics
                    )
                    customAmplitude('Premium Selected Plan', {
                      Plan: planActive === 1 ? 'Monthly' : 'Yearly',
                      Type: 'Standard'
                    })
                  })
                }}
                onNavigate={() => {
                  setIsOpenPayment(false)
                }}
                planDetail={planDetail}
                planActive={planActive}
                setPlanActive={setPlanActive}
              />
            )}
            {/* right */}
            <div
              style={{
                width: '50%',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                paddingLeft: isOpenPayment ? '72px' : 0,
                background: 'rgba(249, 249, 249, 1)',
              }}
            >
              {!isOpenPayment ? (
                <video
                  width="100%"
                  height="100%"
                  style={{
                    objectFit: 'cover',
                  }}
                  loop
                  autoPlay
                  muted
                  playsInline
                  src={openModalTryPremium.video ? openModalTryPremium.video : VideoIntroRemove}
                  poster={openModalTryPremium.placeholderImage ? openModalTryPremium.placeholderImage : premiumPoster}
                >
                  {/* <source src={openModalTryPremium.video ? openModalTryPremium.video : VideoIntroRemove} type="video/mp4" /> */}
                </video>
              ) : (
                <ContentForPlan planActive={planActive} planDetail={planDetail} />
              )}
            </div>
            {/* clsoe icon */}
            <div
              style={{
                position: 'absolute',
                top: '16px',
                right: '16px',
                width: '32px',
                height: '32px',
                borderRadius: '50%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={e => {
                e.stopPropagation()
                setPlanActive(2)
                dispatch(
                  setOpenModalTryPremium({
                    isOpen: false,
                    source: null,
                    opened: openModalTryPremium.opened
                  })
                )
                setIsOpenPayment(false)
                setShowPlanModal(false)
                handleOpenTutorial()
              }}
            >
              <Icons.WhiteCloseIcon size={24} />
            </div>
          </>
        ) : (
          <TryPremiumSuccess
            close={() => {
              if (openModalTryPremium.source) {
                if (user.userIsPremium) {
                  if (openModalTryPremium.callback) {
                    openModalTryPremium.callback()
                  }
                }
              }
              dispatch(
                setOpenModalTryPremium({
                  ...openModalTryPremium,
                  source: null,
                  callback: null,
                  isOpen: false,
                })
              )
              setIsOpenPayment(false)
              handleOpenTutorial()
            }}
          />
        )}
      </div>
    </>
  )
}

export default ModalTryPremium

function TryPremiumSuccess({ close }) {
  const { t } = useTranslation()
  return (
    <>
      {/* left */}
      <div
        style={{
          padding: '48px 48px 16px 48px',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'left',
          width: '50%',
        }}
      >
        <h2 className="linear-gradient-text">{t('Congrats!')}</h2>
        <h3 style={{ margin: 0, marginTop: '8px', ...lightTheme.typography.Header26bold }}>
          {t('You’re now Premium.')}
        </h3>
        <h4
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
            marginBottom: 0,
            marginTop: '24px',
            ...lightTheme.typography.Small14regular,
          }}
        >
          {t('Time to explore your new design superpowers.')}
          <br />
          {t('Here are a few ideas on what to do next:')}
        </h4>
        <h4
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
            marginBottom: 0,
            marginTop: '24px',
            ...lightTheme.typography.Small14regular,
          }}
        >
          <Icons.CheckIcon fill="#FF0560" /> {t('Design your new profile pic')}
        </h4>
        <h4
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
            marginBottom: 0,
            marginTop: '20px',
            ...lightTheme.typography.Small14regular,
          }}
        >
          <Icons.CheckIcon fill="#FF0560" />
          {t('Create a logo for your business')}
        </h4>
        <h4
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
            marginBottom: 0,
            marginTop: '20px',
            ...lightTheme.typography.Small14regular,
          }}
        >
          <Icons.CheckIcon fill="#FF0560" />
          {t('Prepare multiple product photos')}
        </h4>
        <h4
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
            marginBottom: 0,
            marginTop: '20px',
            ...lightTheme.typography.Small14regular,
          }}
        >
          <Icons.CheckIcon fill="#FF0560" />
          {t('Make an event invitation')}
        </h4>
        <h4
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
            marginBottom: 0,
            marginTop: '20px',
            ...lightTheme.typography.Small14regular,
          }}
        >
          <Icons.CheckIcon fill="#FF0560" /> {t('Sync across all your devices')}
        </h4>

        <div style={{ marginTop: 'auto', textAlign: 'center' }}>
          <ButtonCustom
            type={SizeButton.LARGE}
            kind={KIND.primary}
            style={{
              position: 'relative',
              ...lightTheme.typography.Small14Semibold,
              color: '#fff',
              marginBottom: '32px',
              marginTop: '8px',
            }}
            onClick={() => close()}
          >
            {t("Let's Go!")}
          </ButtonCustom>
        </div>
      </div>
      {/* right */}
      <div style={{ width: '50%', position: 'relative' }}>
        <img src={BgTryPremiumSuccess} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        <div
          style={{
            position: 'absolute',
            top: '16px',
            right: '16px',
            width: '32px',
            height: '32px',
            borderRadius: '50%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => close()}
        >
          <Icons.WhiteCloseIcon size={24} />
        </div>
      </div>
    </>
  )
}

function ChooseAPlan({ onConfirmPlan, onNavigate, planDetail, planActive, setPlanActive }) {
  const [css] = useStyletron()
  const { t } = useTranslation()

  useEffect(() => {
    customAmplitude('Premium Choose Plan')
    // 
    // customAmplitude('Webapp_split_test_Onboarding_metsikon', {
    //   user: 'Baseline'
    // })
  }, [])
  return (
    <div
      style={{
        position: 'relative',
        padding: '48px',
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        textAlign: 'left',
      }}
    >
      <div
        style={{ display: 'flex', justifyContent: 'start', height: '32px', gap: '8px', marginBottom: '32px' }}
      >
        <ButtonCustom
          shape={SHAPE.square}
          kind={KIND.minimal}
          type={SizeButton.SMALL}
          onClick={() => {
            setPlanActive(2)
            onNavigate()
          }}
        >
          <Icons.Back size={24} fill={lightTheme.colors.blackGray} />
        </ButtonCustom>
        <span style={{ ...lightTheme.typography.Header26bold }}>{t('Choose a plan')}</span>
      </div>
      {/* monthly */}
      <div
        className={css({
          height: '81px',
          background: lightTheme.colors.grayScale50,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          borderRadius: '12px',
          padding: '24px',
          border: planActive === 1 ? '2px rgba(255, 5, 96, 1) solid' : '2px transparent solid',
          cursor: 'pointer',
          ':hover': {
            background: lightTheme.colors.grayScale100
          },
          ':active': {
            background: lightTheme.colors.grayScale150
          }
        })}
        onClick={() => setPlanActive(1)}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p style={{ ...lightTheme.typography.Small16Bold, lineHeight: '16px', margin: 0 }}>{t('Monthly')}</p>
          <p style={{ ...lightTheme.typography.Small12medium, margin: 0 }}>
            {t('%@ billed monthly', {arg: planDetail?.month?.price ?? '$4.99'})}
          </p>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ ...lightTheme.typography.Small16Bold, margin: 0 }}>{planDetail?.month?.price ?? '$11.99'}/</p>
          <span style={{ ...lightTheme.typography.Small12regular, margin: 0 }}>{t('month')}</span>
        </div>
      </div>
      {/* yearly */}
      <div
        className={css({
          height: '81px',
          background: lightTheme.colors.grayScale50,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          borderRadius: '12px',
          padding: '24px',
          border: planActive === 2 ? '2px rgba(255, 5, 96, 1) solid' : '2px transparent solid',
          cursor: 'pointer',
          marginTop: '16px',
          ':hover': {
            background: lightTheme.colors.grayScale100
          },
          ':active': {
            background: lightTheme.colors.grayScale150
          }
        })}
        onClick={() => setPlanActive(2)}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p
            style={{
              ...lightTheme.typography.Small16Bold,
              lineHeight: '16px',
              margin: 0,
              position: 'relative',
              width: 'fit-content',
            }}
          >
            {t('Yearly')}
            <div
              style={{
                position: 'absolute',
                left: 'calc(100% + 8px)',
                top: 0,
                ...lightTheme.typography.Small11medium,
                color: '#fff',
                padding: '0 10px',
                background: 'rgba(255, 5, 96, 1)',
                borderRadius: '10px',
                minWidth: '74px',
                width: 'max-content',
              }}
            >
              {t('Best value')}
            </div>
          </p>

          <p style={{ ...lightTheme.typography.Small12medium, margin: 0 }}>
            {t('%@ billed yearly', {arg: planDetail?.year?.price ?? '$49.99'})}
          </p>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ ...lightTheme.typography.Small16Bold, margin: 0 }}>{planDetail?.year?.price ? planDetail?.year?.price.match(/[^0-9.-]+/g,"")+(Number(planDetail?.year?.price.replace(/[^0-9.-]+/g,""))/12).toFixed(2) : '$71.99'}/</p>
          <span style={{ ...lightTheme.typography.Small12regular, margin: 0 }}>{t('month')}</span>
        </div>
      </div>
     <div style={{ marginTop: 'auto', textAlign: 'center' }}>
      <h4
          style={{
            marginBottom: 0,
            marginTop: '8px',
            ...lightTheme.typography.Small12medium,
            color: lightTheme.colors.blackGray,
          }}
        >
          {t('Cancel anytime.')}
        </h4>
        <ButtonCustom
          type={SizeButton.LARGE}
          kind={KIND.primary}
          style={{
            position: 'relative',
            marginBottom: 0,
            marginTop: '8px',
          }}
          onClick={e => onConfirmPlan(planActive)}
        >
          <p className={css({
            ...lightTheme.typography.Small14Semibold,
            color: '#fff'
          })}>
            {planActive === 1 ? planDetail?.month?.canFreeTrial ? t('Start free trial') : t('Continue') : planDetail?.year?.canFreeTrial ? t('Start free trial') : t('Continue') }
          </p>
        </ButtonCustom>
     </div>
    </div>
  )
}

function PaymentInfo({ setIsOpenChooseAPlan }) {

  useEffect(() => {
    customAmplitude('Premium Payment info', {
      type: 'Standard',
    })
  }, [])
  
  return (
    <WrapAutoScroll
      style={{
        position: 'relative',
        padding: '48px',
        paddingRight: '32px',
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        textAlign: 'left',
        overflowY: 'scroll',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'start', height: '32px', gap: '8px' }}>
        <ButtonCustom
          shape={SHAPE.square}
          kind={KIND.minimal}
          type={SizeButton.SMALL}
          onClick={() => {
            // setIsOpenPayment(false)
            setIsOpenChooseAPlan(true)
          }}
        >
          <Icons.Back size={24} fill={lightTheme.colors.blackGray} />
        </ButtonCustom>
        <span style={{ ...lightTheme.typography.Header26bold }}>{t('Payment info')}</span>
      </div>
      <div
        id="paddle-checkout-loader"
        style={{
          display: 'none',
          position: 'absolute',
          bottom: '50%',
          left: '50%',
          transform: 'translate(-50%, 0%)',
          paddingLeft: '12px',
        }}
      >
        <Loading fill="#BCBCBC" />
      </div>
      <section id="paddle-checkout" className="paddle-checkout"></section>
    </WrapAutoScroll>
  )
}
function ContentForPlan({planActive, planDetail}) {
  const { t } = useTranslation()
  return <div>
  <h4
    style={{
      margin: 0,
      ...lightTheme.typography.Header20semibold,
    }}
  >
    {planActive === 2
      ? planDetail?.year?.canFreeTrial
        ? t('%d days free', {arg: 7})
        : t('Bazaart Premium')
      : planDetail?.month?.canFreeTrial
      ? t('%d days free', {arg: 7})
      : t('Bazaart Premium')}
  </h4>
  <h5
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
      marginBottom: 0,
      marginTop: '22px',
      ...lightTheme.typography.Small16Bold,
      fontWeight: 600,
    }}
  >
    {t(`${planActive === 1
      ? planDetail?.month?.canFreeTrial
        ? 'then '
        : ''
      : planDetail?.year?.canFreeTrial
      ? 'then '
      : ''}%@ per ${planActive === 2 ? 'year' : 'month'}`,
      {arg: planActive === 2 ? planDetail?.year?.price : planDetail?.month?.price})}.
      {planActive === 1 ? ' ' + t('Cancel anytime.') :''}
  </h5>
  <p
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
      marginBottom: 0,
      marginTop: '22px',
      ...lightTheme.typography.Small14regular,
    }}
  >
    <Icons.CheckIcon fill="#FF0560" />
    <span style={{ ...lightTheme.typography.Small14regular }}>
      {planActive === 2
        ? planDetail?.year?.canFreeTrial
          ? t('Pay absolutely nothing today')
          : t('Unlock all tools and features')
        : planDetail?.month?.canFreeTrial
        ? t('Pay absolutely nothing today')
        : t('Unlock all tools and features')}
    </span>
  </p>
  <p
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
      marginBottom: 0,
      marginTop: '22px',
      ...lightTheme.typography.Small14regular,
    }}
  >
    <Icons.CheckIcon fill="#FF0560" />
    <span style={{ ...lightTheme.typography.Small14regular }}>
      {t('Cancel effortlessly in the app or online')}
    </span>
  </p>
  <p
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
      marginBottom: 0,
      marginTop: '22px',
      ...lightTheme.typography.Small14regular,
    }}
  >
    <Icons.CheckIcon fill="#FF0560" />
    <span style={{ ...lightTheme.typography.Small14regular }}>
      {t('Join 35 million+ people who tried Bazaart')}
    </span>
  </p>
</div>
}
