import { lightTheme } from '@/customTheme'
import { styled } from 'baseui'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ButtonCustom from '../ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { KIND } from 'baseui/button'
import VideoRemoveTutorial from '@assets/video/remove-tutorial.mp4'
import useAppContext from '@/hooks/useAppContext'
import { ShowModalType } from '@/scenes/engine/common/constants'
import { setCallbackFromCreationPage } from '@/store/slices/editor/action'
import { ActionType } from '@/store/slices/editor/reducer'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppDispatch } from '@/store/store'
import { handleCurrentState, setShowedRemoveObjectTutorial } from '@/utils/tutorial'
import { useEditorContext } from '@/scenes/engine'
import { customAmplitude } from '@/utils/customAmplitude'
import TUTORIAL_POSTER from '@assets/images/poster_tutorial.jpg'
import { useStyletron } from 'styletron-react'
import { Experiment } from '@amplitude/experiment-js-client'


const WrapAutoScroll = styled('div', (props: any) => ({
  boxSizing: 'border-box',
  height: '100%',
  overflowY: 'scroll',
  overflowX: 'hidden',
  '::-webkit-scrollbar': {
    width: '8px',
    margin: '20px 0',
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '4px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: lightTheme.colors.grayScale100,
    minHeight: '100px ',
  },
  '::-webkit-scrollbar-thumb:hover': {
    cursor: 'pointer',
    backgroundColor: lightTheme.colors.grayScale150,
  },
  ...props.style,
}))

function ModalTutorial() {
  const { t } = useTranslation()
  const { showModalType, setShowModalType, setIsOpenTutorial } = useAppContext()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { editor} = useEditorContext()
  const location = useLocation();
  const [css] = useStyletron()

  useEffect(() => {
    if(showModalType === ShowModalType.QUICK_TUTORIAL) {
      customAmplitude('Tutorial Started', {
        'Tutorial Name': 'Remove',
      })
    }
  }, [showModalType])

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        maxWidth: '920px ',
        maxHeight: '652px',
        display: 'flex',
        flexDirection: 'row',
        background: 'rgba(255, 255, 255, 1)',
        borderRadius: '16px',
        overflow: 'hidden',
        position: 'relative',
      }}
      id="modal-try-remove-object-tutorial"
    >
      {/* left */}
      <WrapAutoScroll
        style={{
          padding: '224px 32px 16px 32px',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'left',
          width: '50%',
        }}
      >
        <h3 style={{ ...lightTheme.typography.Header16bold }}>{t('Quick tutorial')}</h3>
        <h2 style={{ margin: '12px 0 0 0', ...lightTheme.typography.Title34Bold }}>{t("Remove unwanted objects from a photo")}</h2>
        {/* <h4
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
            margin: '4px 0 0 0',
            ...lightTheme.typography.Small14Semibold,
            color: '#000',
          }}
        >
          {t('Remove people and objects from your photos')}
        </h4> */}
        <ButtonCustom
            type={SizeButton.LARGE}
            kind={KIND.primary}
            style={{
              position: 'relative',
              ...lightTheme.typography.Small14Semibold,
              color: '#fff',
              width: '165px',
              marginTop: '32px',
            }}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              const isFromCreatePage = location.pathname === '/create'
              // No handlers is initialized yet when user is in create page
              if(!isFromCreatePage) {
                handleCurrentState(editor.handlers, isFromCreatePage)
              }
              dispatch(setCallbackFromCreationPage({
                action: ActionType.QUICK_TUTORIAL
              }))
              setIsOpenTutorial(true)
              setShowedRemoveObjectTutorial()
              location.pathname === '/' ? history.replace('/') : history.push('/')
              setShowModalType(null)
              customAmplitude('Tutorial Step Completed', { 'Tutorial Step Number' : 0 })
            }}
          >
            {t('Show me')}
          </ButtonCustom>
      </WrapAutoScroll>
      {/* right */}
      <div
        style={{
          width: '50%',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          background: 'rgba(249, 249, 249, 1)',
        }}
      >
        <video
          width="100%"
          height="100%"
          style={{
            objectFit: 'cover',
          }}
          loop
          autoPlay
          muted
          poster={TUTORIAL_POSTER}
          src={VideoRemoveTutorial}
        >
            <source src={VideoRemoveTutorial} type='video/mp4; codecs=hevc"' />
          <source src={VideoRemoveTutorial} type='video/mp4; codecs=hevc"' />
        </video>
        <div
          className={css({
            position: 'absolute',
            bottom: '16px',
            right: '16px',
            width: '50px',
            height: '24px',
            padding: '0px 8px',
            ...lightTheme.typography.Small12medium,
            lineHeight: 'unset',
            color: lightTheme.colors.white,
            background: 'rgba(0, 0, 0, 0.40)',
            borderRadius: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            zIndex: 1,
            opacity: 0.5,
            ':hover': {
              background: 'rgba(0, 0, 0, 0.50)',
              opacity: 1
            },
            ':active': {
              background: 'rgba(0, 0, 0, 0.60)',
              opacity: 1
            }
          })}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            setShowedRemoveObjectTutorial()
            setShowModalType(null)
            customAmplitude('Tutorial Skip')
          }}>{t('Skip')}
        </div>
      </div>
    </div>
  )
}

export default ModalTutorial