import { ColorMap, FabricCanvas } from "../../common/interfaces";
import CanvasImageRenderer from "../../utils/canvasImageRenderer";
import { loadImageFromURL } from "../../utils/image-loader";
import { MediaImageRepository } from "./media_image_repository";
import { MediaImageType } from "./media_image_type";
import { Rectangle } from "./rectangle";
import { Size } from "./size";
import { FileSystemRecord } from "./VirtualFileSystem";
import { fabric } from 'fabric'

export class MediaImageFallbackHandler {
    private canvas: FabricCanvas | null
    private sizeProvider: () => (Size);
    private filtersProvider: () => (ColorMap[]);
    private objects: any[];

    private constructor() { }

    private static instance: MediaImageFallbackHandler

    public static getInstance(): MediaImageFallbackHandler {
      if (!MediaImageFallbackHandler.instance) {
        MediaImageFallbackHandler.instance = new MediaImageFallbackHandler()
      }
      return MediaImageFallbackHandler.instance
    }
  
    setup(canvas: FabricCanvas, sizeProvider: () => (Size), filtersProvider: () => (ColorMap[])) {
        this.canvas = canvas;
        this.sizeProvider = sizeProvider;
        this.filtersProvider = filtersProvider;
        this.objects = this.canvas.getObjects();
    }

    async fallback(layerId: string, layerStateId: string, imgType: MediaImageType): Promise<FileSystemRecord | null> {
        switch (imgType) {
            case MediaImageType.original: {
                let retImage = await this.fallbackOriginal(layerId, layerStateId);
                return retImage;
            }
            case MediaImageType.fullRes: {
                let retImage = await this.fallbackFullRes(layerId, layerStateId);
                return retImage;
            }
            case MediaImageType.fitted: {
                let retImage = await this.fallbackFitted(layerId, layerStateId);
                return retImage;
            }
            case MediaImageType.mask: {
                let retImage = await this.fallbackMask(layerId, layerStateId);
                return retImage;
            }
            case MediaImageType.fittedMask: {
                let retImage = await this.fallbackFittedMask(layerId, layerStateId);
                return retImage;
            }
            case MediaImageType.latest: {
                let retImage = await this.fallbackLatest(layerId, layerStateId);
                return retImage;
            }
            return null;
          }
    }

    private getLayerForId(id: string): any {
        // @ts-ignore
        let layer = this.objects.find(o => o.id === id || o.bazaartGuid === id)
        if (!layer) {
            this.objects = this.canvas.getObjects();

            layer = this.objects.find(o => o.id === id || o.bazaartGuid === id);
        }
        return layer;
    }

    private async fallbackOriginal(layerId: string, layerStateId: string): Promise<FileSystemRecord | null>{
        return Promise.resolve(null);
    }

    private async fallbackFullRes(layerId: string, layerStateId: string): Promise<FileSystemRecord | null>{
        return MediaImageRepository.getInstance().getRecord(layerId, layerStateId,MediaImageType.original)
    }

    private async fallbackFitted(layerId: string, layerStateId: string): Promise<FileSystemRecord | null>{
        let fittedRecord = await this.crop(layerId, layerStateId, MediaImageType.fullRes, MediaImageType.fitted);
        return fittedRecord;
    }

    private async fallbackMask(layerId: string, layerStateId: string): Promise<FileSystemRecord | null>{
        // running this check first to prevent an endless recursion
        let hasFittedMask = await MediaImageRepository.getInstance().hasRecord(layerId, layerStateId, MediaImageType.fittedMask);
        let fullRes = await MediaImageRepository.getInstance().getRecord(layerId, layerStateId, MediaImageType.fullRes);
        if (!hasFittedMask || !fullRes?.blobUrl){
            return null;
        }
        let fittedMask = await MediaImageRepository.getInstance().getRecord(layerId, layerStateId, MediaImageType.fittedMask);
        let fittedMaskImage = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(fittedMask.blobUrl);
        let fullResImage = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(fullRes.blobUrl);
        
        let layer = this.getLayerForId(layerId);
        
        let maskSize = new Size(fullResImage.width, fullResImage.height);
        let bb = new Rectangle(layer.boundingBox.x, layer.boundingBox.y, layer.boundingBox.width, layer.boundingBox.height);
        let roi = bb.multiply(maskSize);
        
        let paddedImage = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.padImage(fittedMaskImage, maskSize, roi, '#ffffff');
        
        await MediaImageRepository.getInstance().storeImageBase64(layerId, layerStateId, MediaImageType.mask, paddedImage.src)
        
        let maskRecord = await MediaImageRepository.getInstance().getRecord(layerId, layerStateId, MediaImageType.mask);
        return maskRecord;
    }

    private async fallbackFittedMask(layerId: string, layerStateId: string): Promise<FileSystemRecord | null>{
        let fittedMaskRecord = await this.crop(layerId, layerStateId, MediaImageType.mask, MediaImageType.fittedMask);
        return fittedMaskRecord;
    }

    private async fallbackLatest(layerId: string, layerStateId: string): Promise<FileSystemRecord | null>{
        let fittedMaskRecord = await MediaImageRepository.getInstance().getRecord(layerId, layerStateId, MediaImageType.fittedMask);
        let fittedImageRecord = await MediaImageRepository.getInstance().getRecord(layerId, layerStateId, MediaImageType.fitted);
        if (!fittedMaskRecord?.blobUrl || !fittedImageRecord?.blobUrl) {
            return null;
        }
        let fittedMask = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(fittedMaskRecord.blobUrl);
        let fittedImage = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(fittedImageRecord.blobUrl);
        
        let layer = this.getLayerForId(layerId);
        let canvasSize = this.sizeProvider();
        let filters = this.filtersProvider();

        let maskedImageSrc = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.applyMaskToImage(fittedMask, fittedImage);
        const maskedImage: any = await loadImageFromURL(maskedImageSrc)
        
        const element = new fabric.StaticImage(maskedImage, {})
        Object.assign(element, layer);
        let renderedElement = await CanvasImageRenderer.getInstance().render(element, canvasSize, filters)
        let base64LatestImage = renderedElement._element.src;

        await MediaImageRepository.getInstance().storeImageBase64(layerId, layerStateId, MediaImageType.latest, base64LatestImage);
        let latestRecord = MediaImageRepository.getInstance().getRecord(layerId, layerStateId, MediaImageType.latest);
        return latestRecord;
    }
   
    private async crop(layerId: string, layerStateId: string, srcMediaImageType: MediaImageType, targetMediaImageType: MediaImageType){
        let srcRecord = await MediaImageRepository.getInstance().getRecord(layerId, layerStateId, srcMediaImageType);
        if (!(srcRecord?.blobUrl)){
            return null;
        }
        let srcImage = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(srcRecord.blobUrl);
        let layer = this.getLayerForId(layerId);
        if (!layer || !layer.boundingBox) {
            console.log('error no layer');
            return null;
        }
        let bb = new Rectangle(layer.boundingBox.x, layer.boundingBox.y, layer.boundingBox.width, layer.boundingBox.height);
        let roi = bb.multiply(new Size(srcImage.width, srcImage.height));
        let croppedImage = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.cropHtmlImage(srcImage, roi);
        let croppedImageSrc = croppedImage.src;
        await MediaImageRepository.getInstance().storeImageBlobString(layerId, layerStateId, targetMediaImageType, croppedImageSrc)
        
        let croppedRecord = await MediaImageRepository.getInstance().getRecord(layerId, layerStateId, targetMediaImageType);
        return croppedRecord;
    }
}