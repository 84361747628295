import { lightTheme } from '@/customTheme'
import { styled } from 'baseui'
import React from 'react'
import { StyleObject } from 'styletron-standard'

const WrapAutoScroll = styled('div', (props: any) => ({
  boxSizing: 'border-box',
  height: '100%',
  overflowY: 'scroll',
  overflowX: 'hidden',
  paddingTop:'12px',
  paddingLeft: '24px',
  marginRight: '4px',
  paddingRight: '12px',
  '::-webkit-scrollbar': {
    width: '8px',
    margin: '20px 0',
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '4px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: lightTheme.colors.grayScale100,
    minHeight: '100px ',
  },
  '::-webkit-scrollbar-thumb:hover': {
    cursor: 'pointer',
    backgroundColor: lightTheme.colors.grayScale150,
  },
  ...props.style,
}))

const AutoScroll = React.forwardRef(
  (props: { style?: StyleObject; children?: any; handleScroll?: Function }, ref) => {
    return (
      <WrapAutoScroll
        style={{ ...props.style }}
        onScroll={e => !!props.handleScroll && props.handleScroll(e)}
        ref={ref}
      >
        {props.children}
      </WrapAutoScroll>
    )
  }
)

export default AutoScroll
