import useAppContext from '@/hooks/useAppContext';
import Icons from '../../../Icons'
import { lightTheme } from '@/customTheme';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyletron } from 'styletron-react'
import { PopoverType } from '@/constants/app-options';
import { useAppDispatch } from '@/store/store';

const DynamicTextarea = ({ isLoadingSuggestImages, prompt, setPrompt, wrapScrollRef, isCollapsed, setIsCollapsed, handleKeyDown }) => {
    const { t } = useTranslation()
    const [css] = useStyletron()
    const textareaRef = useRef(null);
    const { popoverActive } = useAppContext()
    const dispatch = useAppDispatch()

    const getActualTextareaScrollHeight = () => {
        const textarea = textareaRef.current;
        let scrollHeight = textarea.scrollHeight
        const wrapTextAreas = document.getElementById('wrapper-textarea')
        const clone = textarea.cloneNode(true);
        clone.style.position = 'absolute';
        clone.style.visibility = 'hidden';
        clone.style.overflow = 'hidden';
        clone.style.height = 'auto';
        clone.style.width = textarea.offsetWidth + 'px';
        clone.style.whiteSpace = 'pre-wrap'; // Keep formatting the same
        clone.style.wordWrap = 'break-word'; // Prevent horizontal overflow
        clone.value = textarea.value;
        wrapTextAreas.appendChild(clone);
        scrollHeight = clone.scrollHeight;
        wrapTextAreas.removeChild(clone); // Clean up clone
        return scrollHeight
    }
    const handleChange = (e) => {
        const textarea = textareaRef.current;
        const lineHeight = 22; // Match the `lineHeight` in your CSS
        const maxRows = 6;
        const minRows = 2;
        const wrapperDefaultHeight = 124
        const wrapper = document.getElementById('wrapper-textarea')
        const actualScrollHeight = getActualTextareaScrollHeight();

        // Reset height to auto to correctly calculate scrollHeight
        textarea.style.height = 'auto';

        // Calculate the new height and rows based on content
        const rows = Math.min(maxRows, Math.floor(actualScrollHeight / lineHeight));
        const newHeight = rows * lineHeight;

        // If rows exceed maxRows, allow scrolling
        if (rows >= maxRows) {
            textarea.style.overflowY = 'auto';
        } else {
            textarea.style.overflowY = 'hidden'; // Hide scrollbar if within range
        }

        // Apply the calculated height
        textarea.style.height = `${newHeight}px`;
        if(wrapper) {
            wrapper.style.height = `${wrapperDefaultHeight + ((rows - minRows) * lineHeight)}px`;
        }
        // Update the value in the parent state
        setPrompt(e.target.value);
    };

    useEffect(() => {
        if(popoverActive === PopoverType.AI_IMAGE && !isCollapsed) {
            textareaRef.current.focus()
        }
    }, [popoverActive])

    useEffect(() => {
        if(isLoadingSuggestImages || isCollapsed) {
            textareaRef.current.blur()
        } else {
            if(isCollapsed) return
            textareaRef.current.focus()
        }
    }, [isLoadingSuggestImages, isCollapsed])

    return (
        <>
            {prompt && !isLoadingSuggestImages ? (
                <div
                  className={css({
                    position: 'absolute',
                    top: isCollapsed ? '12px' : '16px',
                    right: '40px',
                    zIndex: 1,
                    width: '24px',
                    height: '24px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    background: isCollapsed ? lightTheme.colors.white : lightTheme.colors.grayScale50,
                    ':hover': {
                      background: isCollapsed ? lightTheme.colors.white : lightTheme.colors.grayScale100,
                    },
                    ':active': {
                      background: isCollapsed ? lightTheme.colors.white : lightTheme.colors.grayScale150,
                    },
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  })}
                  onClick={() => {
                    if(!isCollapsed) {
                        setPrompt('')
                        textareaRef.current.value = ''; // Ensure value updates
                        handleChange({ target: textareaRef.current });
                    } else {
                        textareaRef.current?.focus()
                        setIsCollapsed(false)
                        wrapScrollRef.current.scrollTop = 0
                    }
                  }}
                >
                    {isCollapsed ? <Icons.CollapseIcon /> : <Icons.Close size={20}/>}
                </div>
            ) : null}
            <input
            style={{
              position: 'relative',
              width: '220px',
              height: '20px',
              border: 'none',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '20px',
              color: isLoadingSuggestImages ? lightTheme.colors.grayScale200 : lightTheme.colors.blackGray,
              overflow: 'hidden!important',
              whiteSpace: 'wrap',
              textOverflow: 'ellipsis',
              cursor: 'pointer',
              opacity: isCollapsed ? 1 : 0,
              display: isCollapsed ? 'block' : 'none',
              transition: 'opacity 0.3s',
            }}
            value={prompt}
            onClick={() => {
                setIsCollapsed(false)
                wrapScrollRef.current.scrollTop = 0
            }}
          />
            <textarea
                id="ai-image-textarea"
                ref={textareaRef}
                className={css({
                position: 'relative',
                width: '100%',
                height: 'auto',
                minHeight: `${2 * 22}px`, // Min height for 2 rows
                maxHeight: `${6 * 22}px`, // Max height for 6 rows
                border: 'none',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '22px',
                color: isLoadingSuggestImages ? lightTheme.colors.grayScale200 : lightTheme.colors.blackGray,
                letterSpacing: '-0.084px',
                padding: `0 ${prompt ? '40px' : 0} 0 0`,
                resize: 'none',
                '::-webkit-scrollbar': {
                    width: '8px',
                    margin: '0 auto',
                },
                '::-webkit-scrollbar-thumb': {
                    background: lightTheme.colors.grayScale100,
                    borderRadius: '10px',
                },
                overflow: 'auto',
                whiteSpace: 'wrap',
                textOverflow: 'ellipsis',
                cursor: 'auto',
                opacity: isCollapsed ? 0 : 1,
                display: isCollapsed ? 'none' : 'block',
                transition: 'opacity 0.3s',
                })}
                placeholder={t('Describe the image you are imagining.')}
                value={prompt}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                rows={isCollapsed ? 1 : 2} // Default rows
            ></textarea>
        </>
    );
};

export default DynamicTextarea;