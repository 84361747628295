import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { IStorePack } from '@/interfaces/editor'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/store/store'
import { getPack } from '@/store/slices/packs/actions'
import { selectPack } from '@/store/slices/packs/selectors'
import { LabelLarge, ParagraphXSmall } from 'baseui/typography'
import { styled } from 'styletron-react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import useAppContext from '@/hooks/useAppContext'
import { ObjectType } from '@/scenes/engine/common/constants'
import Icons from '@/scenes/Editor/components/Icons'
import { selectUserIsPremium } from '@/store/slices/user/selectors'
import { setOpenModalTryPremium } from '@/store/slices/user/actions'
import { useTranslation } from 'react-i18next'
import { customAmplitude } from '@/utils/customAmplitude'
import { useStyletron } from 'baseui'
import { lightTheme } from '@/customTheme'
// @ts-ignore
import ArrowAnimation from '@assets/lottie/arrow_animation.lottie'
import { DotLottiePlayer } from '@dotlottie/react-player'
import LottiePlayerWrapper from '@/libs/dotLottiePlayer/LottiePlayerWrapper'


const WrapPackItem = styled('div', (props: any) => {
  return {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    borderRadius: '8px',
    position: 'relative',
    background: '#fff',
    ':hover': {
      background: '#F2F2F2',
    },
    ':active': {
      background: lightTheme.colors.grayScale100,
    },
  }
})
interface props {
  pack: IStorePack
  style?: React.CSSProperties
  onItemSelected: Function
  closePopover?: Function
  isFree: boolean
  triggerSubAction?: boolean
}

const DEFAULT_ITEMS = 8

function Gallery({ pack, onItemSelected, style, closePopover, isFree, triggerSubAction = false }: props) {
  const hasPremium = useSelector(selectUserIsPremium) || isFree
  const packObjects = useSelector(selectPack(pack && pack.id))
  const dispath = useAppDispatch()
  const { objDragging, setObjDragging } = useAppContext()
  const { t } = useTranslation()

  const [defaultItemTotal, setDefaultItemTotal] = useState(DEFAULT_ITEMS)
  const [nameSubAction, setNameSubAction] = useState('Show more')
  const [showToggleBtn, setShowToggleBtn] = useState(false)

  useEffect(() => {
    if (!packObjects) {
      dispath(getPack(pack && pack.id))
    }
  }, [])

  const handleClickSubAction = () => {
    if(defaultItemTotal === DEFAULT_ITEMS && !packObjects?.length) {
      return
    }
    setNameSubAction(pre => (pre === 'Show more' ? 'Show less' : 'Show more'))
    setDefaultItemTotal(pre => (pre === DEFAULT_ITEMS ? packObjects.length : DEFAULT_ITEMS))
  }
  
  useEffect(() => {
    if(triggerSubAction) {
      handleClickSubAction()
    }
  }, [triggerSubAction, packObjects])

  const [css] = useStyletron()

  return (
    <div style={{ boxSizing: 'border-box', width: '100%', height: 'fit-content', ...style }} onMouseEnter={() => setShowToggleBtn(true)} onMouseLeave={() => setShowToggleBtn(false)}>
      <div
        style={{
          height: '26px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '8px',
        }}
      >
        <p style={{ ...lightTheme.typography.Small14Semibold, color:lightTheme.colors.text.text_black_title }}>{pack && t(pack.name)}</p>
        <ParagraphXSmall
          onClick={() => handleClickSubAction()}
          $style={{
            boxSizing: 'border-box',
            cursor: 'pointer',
            padding: '0 8px',
            borderRadius: '12px',
            opacity: defaultItemTotal === DEFAULT_ITEMS && !packObjects?.length ? 0.5 : 1,
            pointerEvents: defaultItemTotal === DEFAULT_ITEMS && !packObjects?.length ? 'none' : 'auto',
            ':hover': {
              background: 'rgb(229, 229, 229)',
            },
            ...lightTheme.typography.Small12medium
          }}
        >
          {t(nameSubAction)}
        </ParagraphXSmall>
      </div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          columnGap: '8px',
          rowGap: 0,
        }}
      >
        {packObjects &&
          packObjects.map((p, index) => {
            if (index < DEFAULT_ITEMS) {
              return (
                <div style={{ display: 'flex', position: 'relative' }} key={p.id}>
                  <PackItem
                    onClick={() => onItemSelected(p)}
                    style={{
                      wrap: {
                        width: '67px',
                        height: '67px',
                        padding: '8px 8px 9px 9px',
                      },
                    }}
                    pack={p}
                    onDrag={(e: any) => {
                      if (!objDragging.item || objDragging.item.image !== p.image) {
                        setObjDragging({
                          item: p,
                          type: ObjectType.BAZAART_STICKER,
                        })
                      }
                      !!closePopover && closePopover()
                    }}
                    isLocked={!hasPremium && [0, 3, 4].includes((index+1) % 5)}
                    closePopover={closePopover}
                    CTA="BtSubscriptionStickers"
                  ></PackItem>
                </div>
              )
            } else {
              if (defaultItemTotal === packObjects.length) {
                return (
                  <div style={{ display: 'flex', position: 'relative' }} key={p.id}>
                    <PackItem
                      onClick={() => onItemSelected(p)}
                      style={{
                        wrap: {
                          width: '67px',
                          height: '67px',
                          padding: '8px 8px 9px 9px',
                        },
                      }}
                      pack={p}
                      onDrag={() => {
                        if (!objDragging.item || objDragging?.item.image !== p.image) {
                          setObjDragging({
                            item: p,
                            type: ObjectType.BAZAART_STICKER,
                          })
                        }
                        !!closePopover && closePopover()
                      }}
                      isLocked={!hasPremium && [0, 3, 4].includes((index+1) % 5)}
                      CTA="BtSubscriptionStickers"
                    ></PackItem>
                  </div>
                )
              }
            }
          })}

        {!packObjects &&
          [...Array(DEFAULT_ITEMS)].map((item, index) => (
            <div style={{ display: 'flex' }} key={index}>
              <PackItem
                style={{
                  wrap: {
                    width: '67px',
                    height: '67px',
                    padding: '8px 8px 9px 9px',
                  },
                }}
                onClick={() => {}}
                pack={{}}
                onDrag={() => {}}
                CTA="BtSubscriptionStickers"
              ></PackItem>
            </div>
          ))}
      </div>
      <div
        className={css({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '12px auto 16px auto',
          gap: '6px',
          background: lightTheme.colors.grayScale50,
          ':hover': {
            background: lightTheme.colors.grayScale100,
          },
          width: 'fit-content',
          height: '24px',
          borderRadius: '360px',
          padding: '0 8px',
          opacity: showToggleBtn ? 1 : 0,
          transition: 'opacity 0.3s',
          pointerEvents: defaultItemTotal === DEFAULT_ITEMS && !packObjects?.length ? 'none' : 'auto',
        })}
      >
        <ParagraphXSmall
          onClick={() => handleClickSubAction()}
          $style={{
            boxSizing: 'border-box',
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            ...lightTheme.typography.Small12medium,
          }}
        >
          {t(nameSubAction)}
        </ParagraphXSmall>
        <LottiePlayerWrapper
          play={showToggleBtn}
          src={ArrowAnimation}
          autoplay={false}
          loop
          style={{
            width: '10px',
            height: '14px',
            transform: defaultItemTotal === DEFAULT_ITEMS ? 'none' : 'rotate(180deg)',
          }}
        ></LottiePlayerWrapper>
      </div>
    </div>
  )
}

export default Gallery

export const PackItem = ({
  style,
  onClick,
  pack,
  onDrag,
  children,
  isSelected,
  isLocked = false,
  closePopover,
  CTA,
}: {
  style?: {
    wrap?: CSSProperties
    image?: CSSProperties
    placehoder?: CSSProperties
  }
  onClick?: Function
  pack?: any
  onDrag?: Function
  children?: JSX.Element | JSX.Element[]
  isSelected?: boolean
  isLocked?: boolean
  closePopover?: Function
  CTA: string
}) => {
  const dispatch = useAppDispatch()
  const [loaded, setIsLoaded] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const [css, theme] = useStyletron()
  return (
    <WrapPackItem
      style={{
        ...style.wrap,
      }}
      onClick={() => {
        if (isLocked) {
          // !!closePopover && closePopover()
          dispatch(
            setOpenModalTryPremium({
              isOpen: true,
              source: CTA,
              callback: () => onClick(),
            })
          )
          const eventProperties = {
            Source: CTA,
            Type: 'Standard',
          }
          customAmplitude('Premium Prompt', eventProperties)
          // @ts-ignore
          window.dataLayer.push({ event: 'premium_prompt', ...eventProperties })
          return
        }
        onClick()
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <LazyLoadImage
        width={'100%'}
        height={'100%'}
        src={pack.thumbnail}
        alt={pack.id}
        style={{ borderRadius: '8px', objectFit: 'contain', ...style.image }}
        onDrag={e => onDrag(e)}
        afterLoad={() => {
          setIsLoaded(true)
        }}
      />
      <div
        className={css({
          position: 'absolute',
          inset: 0,
          borderRadius: '8px',
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          display: isHover && !isSelected ? 'block' : 'none',
          pointerEvents: 'none',
          padding: '4px',
        })}
      >
        {pack.name ? (
          <span style={{ color: '#fff', fontSize: '10px', lineHeight: '12px', position: 'absolute', bottom: '4px'}}>
            {pack.name} / Unsplash
          </span>
        ) : null}
      </div>
      {children}
      {!loaded && (
        <div
          style={{
            position: 'absolute',
            inset: '8px',
            background: '#f2f2f2',
            zIndex: 10,
            borderRadius: '6px',
            pointerEvents: 'none',
            ...style.placehoder,
          }}
        ></div>
      )}
      {isLocked ? (
        <div style={{ position: 'absolute', top: '4px', right: '4px', zIndex: 10 }}>
          <Icons.PremiumIcon />
        </div>
      ) : null}
    </WrapPackItem>
  )
}
