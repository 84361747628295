export default function extractBoundingBoxFromChannel(options = {}) {
  // @ts-ignore
  const { threshold = this.maxValue, channels = this.channels, shouldFlip = false, signal = {} } = options;
  const channel = channels - 1;
  // Find bounding box coordinates
  let left = findLeft(this, threshold, channel, shouldFlip, signal);
  let top = findTop(this, threshold, channel, shouldFlip, signal);
  let right = findRight(this, threshold, channel, shouldFlip, signal);
  let bottom = findBottom(this, threshold, channel, shouldFlip, signal);

  // Create the bounding box
  const rect = {
    x: left,
    y: top,
    width: right - left,
    height: bottom - top,
  };
  
  return rect;
}

/**
 * Compare a pixel value against threshold, considering whether to flip the comparison
 * @param {number} value - The pixel value to compare
 * @param {number} threshold - The threshold value
 * @param {boolean} shouldFlip - Whether to flip the comparison logic
 * @returns {boolean} - Whether the pixel meets the criteria
 */
function compare(image, x, y, channel, threshold, shouldFlip) {
  
  let min = image.min[0];
  let max = image.max[0];
  let range = Math.max(1, max - min);

  let value = Math.floor((255 * (image.getPixelXY(x, y)[channel] - min)) / (range))
  value = shouldFlip ? 255 - value : value;
  return shouldFlip ? value >= threshold : value > threshold;
}

/**
 * Find the leftmost x-coordinate where pixels meet the threshold
 */
function findLeft(image, threshold, channel, shouldFlip, signal) {
  for (let x = 0; x < image.width; x++) {
    for (let y = 0; y < image.height; y++) {
      if (signal?.aborted) return -1;
      if (compare(image, x, y, channel, threshold, shouldFlip)) {
        return x;
      }
    }
  }
  return 0;
}

/**
 * Find the topmost y-coordinate where pixels meet the threshold
 */
function findTop(image, threshold, channel, shouldFlip, signal) {
  for (let y = 0; y < image.height; y++) {
    for (let x = 0; x < image.width; x++) {
      if (signal?.aborted) return -1;
      if (compare(image, x, y, channel, threshold, shouldFlip)) {
        return y;
      }
    }
  }
  return 0;
}

/**
 * Find the bottommost y-coordinate where pixels meet the threshold
 */
function findBottom(image, threshold, channel, shouldFlip, signal) {
  for (let y = image.height - 1; y >= 0; y--) {
    for (let x = 0; x < image.width; x++) {
      if (signal?.aborted) return -1;
      if (compare(image, x, y, channel, threshold, shouldFlip)) {
        return y+1;
      }
    }
  }
  return image.height;
}

/**
 * Find the rightmost x-coordinate where pixels meet the threshold
 */
function findRight(image, threshold, channel, shouldFlip, signal) {
  for (let x = image.width - 1; x >= 0; x--) {
    for (let y = 0; y < image.height; y++) { // Fixed height boundary condition
      if (signal?.aborted) return -1;
      if (compare(image, x, y, channel, threshold, shouldFlip)) {
        return x+1;
      }
    }
  }
  return image.width;
}