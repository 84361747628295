import React, { useEffect, useRef } from 'react';

export interface GradientProps {
  angle: number
  top: number
  left: number
  width: number
  height: number
}

// Component with scoped CSS
const GradientLoader: React.FC<GradientProps> = ({
  angle,
  top,
  left,
  width,
  height,
}) => {
  // Create a unique ID for this component instance to scope the CSS
  const uniqueId = useRef(`gradient-loader-${Math.random().toString(36).substr(2, 9)}`);
  const componentId = uniqueId.current;
  const scale = 4;
  
  // Inject scoped CSS on mount
  useEffect(() => {
    const styleId = `${componentId}-styles`;
    if (!document.getElementById(styleId)) {
      const styleElement = document.createElement('style');
      styleElement.id = styleId;
      styleElement.innerHTML = `
        #${componentId} * { padding: 0; margin: 0; }
        @keyframes ${componentId}-animation {
          0% {
            transform: translate(0%, 0%);
          }
          16% {
            transform: translate(calc(-16 * ${scale}%), calc(-16 * ${scale}%));
          }
          37% {
            transform: translate(calc(-37 * ${scale}%), calc(-37 * ${scale}%));
          }
          48% {
            transform: translate(calc(-48 * ${scale}%), calc(-48 * ${scale}%));
          }
          61% {
            transform: translate(calc(-61 * ${scale}%), calc(-61 * ${scale}%));
          }
          78% {
            transform: translate(calc(-78 * ${scale}%), calc(-78 * ${scale}%));
          }
          90% {
            transform: translate(calc(-90 * ${scale}%), calc(-90 * ${scale}%));
          }
          100% {
            transform: translate(calc(-100 * ${scale}%), calc(-100 * ${scale}%));
          }
        }
        #${componentId} .gradient-wrap {
            position: fixed;
            opacity: 0.8;
            z-index: 8;
      }
      #${componentId} .gradient-rotation {
            width: 100%;
            height: 100%;
            overflow:hidden;
      }
      #${componentId} .animated-gradient {
            box-sizing: content-box;
            width: 100%;
            height: 100%;
            animation: ${componentId}-animation 6s linear infinite;
      }
      #${componentId} .gradient {
          box-sizing: content-box;
          width: calc(100 * ${scale}%);
          height: calc(100 * ${scale}%);
          background: linear-gradient(-45deg, rgb(255, 255, 255),rgba(244, 156, 192, 1), rgba(189, 255, 204, 1),rgba(186, 245, 255, 0.64), rgba(251, 133, 255, 1), rgba(220, 238, 121, 1),rgba(119, 177, 247, 1),  rgb(255, 255, 255));
      }
      `;
      document.head.appendChild(styleElement);
    }
    
    // Cleanup on unmount
    return () => {
      const styleElement = document.getElementById(styleId);
      if (styleElement && styleElement.parentNode) {
        styleElement.parentNode.removeChild(styleElement);
      }
    };
  }, [componentId]);

  return (
      <div id={componentId}>
        <div className="gradient-wrap" style={{ 
            width:`${width}px`, 
            height:`${height}px`, 
            transform: `translate(${left}px, ${top}px)`,
            top: 0,
            left: 0
          }}>
            <div className="gradient-rotation" style={{ 
              position:'relative',
              transform: `rotate(${angle}deg)` 
            }} >
              
              <div className="animated-gradient" style={{
                    position:'absolute',
               }} >
                  <div className="gradient" style={{ 
                    top:`${height}px`, 
                    left:`${width}px`,
                    paddingLeft: `${width}px`,
                    paddingTop: `${height}px`,
                  }}> </div>
              </div>
            </div>
        </div>
      </div>
  );
}

export default GradientLoader;