import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { lightTheme } from '@/customTheme';
import { useTranslation } from 'react-i18next';
import { useStyletron } from 'styletron-react';
import { FaceSwapDataObject } from './faceSwapStaticData';
import { styled } from 'baseui';

export interface FaceSwapperGridProps {
  data: FaceSwapDataObject[];
  isDemoMode: boolean;
  themeStyle: CSSProperties;
  demoFinished: () => void;
  onFaceSelect: (action: FaceSwapDataObject) => void;
}

const FaceSwapperGrid: React.FC<FaceSwapperGridProps> = ({
  data,
  themeStyle,
  isDemoMode,
  onFaceSelect,
  demoFinished
}) => {
  const uniqueId = useRef(`gradient-loader-${Math.random().toString(36).substr(2, 9)}`);
  const componentId = uniqueId.current;
  const { t } = useTranslation();
  const [css] = useStyletron();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const cursorRef = useRef<HTMLDivElement | null>(null);
  const [didDemoFinish, setDidDemoFinish] = useState(false);
  
  // Inject styles including the fake cursor
  useEffect(() => {
    const styleId = `${componentId}-styles`;
    if (!document.getElementById(styleId)) {
      const styleElement = document.createElement('style');
      styleElement.id = styleId;
      styleElement.innerHTML = `
        #${componentId} * { padding: 0; margin: 0; }
        .fake-cursor {
          width: 24px;
          height: 24px;
          background: red;
          border-radius: 50%;
          position: fixed;
          top: 0;
          left: 0;
          pointer-events: none;
          z-index: 101;
          transition: top 0.5s ease, left 0.5s ease;
        }
        .highlighted-box {
          outline: 6px solid red;
          transition: outline 0.3s;
        }
      `;
      document.head.appendChild(styleElement);
    }

    return () => {
      const styleElement = document.getElementById(styleId);
      if (styleElement?.parentNode) {
        styleElement.parentNode.removeChild(styleElement);
      }
    };
  }, [componentId]);

  // Animate fake cursor on mount
  useEffect(() => {
    
    let shouldCancel = false;
    const cursor = document.createElement('div');
    cursor.className = 'fake-cursor';
    document.body.appendChild(cursor);
    cursorRef.current = cursor;

    const wait = (ms: number) => new Promise(res => setTimeout(res, ms));

    const moveTo = (x: number, y: number) => {
      return new Promise<void>(resolve => {
        requestAnimationFrame(() => {
          cursor.style.left = `${x}px`;
          cursor.style.top = `${y}px`;
        });
        setTimeout(resolve, 500); // match transition duration
      });
    };

    const doAnimateCursor = async (box: HTMLElement) => {
      const rect = box.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;

      await moveTo(centerX, centerY);
      box.classList.add('highlighted-box');

      // Click and wait before unhighlighting
      setTimeout(async () => {
        if (shouldCancel) {
            return;
        }
        box.click(); // Simulate real user click
        await wait(1000);
        box.classList.remove('highlighted-box');
      }, 500);
    };

    const animateCursor = async () => {
      const boxes = containerRef.current?.querySelectorAll('.face-box');
      if (!boxes || boxes.length === 0) return;

      // Move cursor to the initial position first (to avoid jump from 0,0)
      const firstRect = boxes[0].getBoundingClientRect();
      cursor.style.left = `${firstRect.left + firstRect.width / 2}px`;
      cursor.style.top = `${firstRect.top + firstRect.height / 2}px`;

      let maxAttempts = 3;
      let numOfAttempts = 0;
      for (const box of boxes) {
        if (shouldCancel){
            return;
        }
        if (numOfAttempts >= maxAttempts){
            setDidDemoFinish(true)
            demoFinished();
            cancelAnimation();
            return;
        }
        await doAnimateCursor(box as HTMLElement);
        await wait(4000); // total time before moving to next
        numOfAttempts++;
      }
    };

    const cancelAnimation = () => {
        cursorRef.current.remove();
    
        // 🧼 Remove all .highlighted-box classes
        const highlighted = document.querySelectorAll('.highlighted-box');
        highlighted.forEach(el => el.classList.remove('highlighted-box'));

        window.removeEventListener('mousemove', cancelAnimation);
        shouldCancel = true;
    };

    let timeout: any
    if (isDemoMode && !didDemoFinish) { 
        timeout = setTimeout(() => {
            animateCursor();
        }, 2000);
    } else {
        cancelAnimation();
    }

    
    // window.addEventListener('mousemove', cancelAnimation, { once: true });
    
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      cursor.remove();
    //   window.removeEventListener('mousemove', cancelAnimation);
    };
  }, [data, isDemoMode]);

  const NameLabel = styled('span', () => ({
    ...lightTheme.typography.Small10regular,
    marginBottom: '8px'
  }));

  return (
    <div id={componentId} ref={containerRef}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '6px', ...themeStyle }}>
        <div style={{ display: 'flex', gap: '6px', flexWrap: 'wrap', textAlign: 'center' }}>
          {data.map((data, index) => (
            <div key={index}>
              <div
                className={`${css({
                  position: 'relative',
                  width: '92px',
                  height: '92px',
                  borderRadius: '6px',
                  border: '1px solid rgba(0,0,0,0.1)',
                  overflow: 'hidden',
                  ':hover .overlay': {
                    display: 'block!important'
                  },
                  cursor: 'pointer'
                })} face-box`}
                onClick={() => {
                  onFaceSelect(data as FaceSwapDataObject);
                }}
              >
                <img src={data.image} alt="inspiration" style={{ width: '100%', height: '100%' }} />
                <div
                  className="overlay"
                  style={{
                    position: 'absolute',
                    inset: 0,
                    display: 'none',
                    background: 'rgba(0,0,0,0.25)'
                  }}
                ></div>
              </div>
              <NameLabel>{data.name}</NameLabel>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FaceSwapperGrid;
