import { PixelManipulationObjectHandler } from './PixelManipulationObjectHandler'
import { MediaImageRepository } from '@scenes/engine/objects/media-repository/media_image_repository'
import { loadImageFromURL } from '@scenes/engine/utils/image-loader'
import { MediaImageRepositoryProcessing } from '@scenes/engine/objects/media-repository/media_image_repository_processing'
import { MediaImageType } from '@scenes/engine/objects/media-repository/media_image_type'
import { Rectangle } from '@scenes/engine/objects/media-repository/rectangle'
import { fabric } from 'fabric'
import { ObjectType } from '@scenes/engine/common/constants'
import { nanoid } from 'nanoid'
import CanvasImageRenderer from '@scenes/engine/utils/canvasImageRenderer'
import { lightTheme } from '@/customTheme'
import { Size } from '../../objects/media-repository/size'

class MagicBgObjectHandler extends PixelManipulationObjectHandler {
  originalWidth: number
  originalHeight: number
  staticCanvas: fabric.Canvas
  base64_image_mask: string
  clonedObject: fabric.Object

  async setupImage() {
    if (!this.activeObject || !this.isOpen) return

    this.setupInitialPosition()

    let element = this.activeObject._originalElement
    let src = element.src ? element.src : element.toDataURL('image/png')
    this.img = await loadImageFromURL(src)

    const containerElement = document.querySelector(
      '#wrap-canvas-remove-tool .remove-container-class'
    ) as HTMLElement
    containerElement.style.transformOrigin = 'center center'
  }

  setupInitialPosition() {
    if (!this.activeObject || !this.isOpen) {
      return
    }
    const frame = this.frameMainCanvas
    let mainFrameBoundingBox = frame.getBoundingRect()

    this.startWidth = mainFrameBoundingBox.width
    this.startHeight = mainFrameBoundingBox.height

    this.startX = this.root.zoomRemoveHandler.isOpenInspector ? mainFrameBoundingBox.left : mainFrameBoundingBox.left - (+ this.mainContext.editor.handlers.frameHandler.FRAME_PADDING_INSPECTOR / 2 - this.mainContext.editor.handlers.frameHandler.FRAME_PADDING_ADD_MENU / 2)
    this.startY = mainFrameBoundingBox.top
  }

  fabricImage: any

  addImageToCanvas = async () => {
    let guid = nanoid()
    await MediaImageRepository.getInstance().duplicate(this.activeObject.id, guid, this.activeObject.layerAssetStateId)
    console.log(this.activeObject.getBoundingRect());
    const sizeOnCanvas = { width: this.activeObject.getBoundingRect().width / this.startWidth, height: this.activeObject.getBoundingRect().height / this.startHeight}
    
    this.fabricImage = new fabric.StaticImage(this.img, {
      type: ObjectType.STATIC_IMAGE,
      selectable: false,
      hasControls: false,
      evented: false,
      opacity: 1,
      sizeOnCanvas: sizeOnCanvas,
      id: guid,
      layerAssetStateId: this.activeObject.layerAssetStateId,
      bazaartGuid: guid,
      boundingBox: this.activeObject.boundingBox,
      angle: this.activeObject.angle,
      flipX: this.activeObject.flipX,
      flipY: this.activeObject.flipY,
      // ignore filters that effecting on size like shdow and outline on magic bg process if we want it back need to take care to this task - https://app.asana.com/0/1205829032146905/1207733670003983/f
      // filters: this.activeObject.filters.filter(x=> x._padding.height == 0 && x._padding.width == 0), 
      filters: this.activeObject.filters,
      hasTransparency: this.activeObject.hasTransparency,
      _originalScaleX: this.activeObject._originalScaleX,
      _originalScaleY: this.activeObject._originalScaleY
    })
    this.canvas.add(this.fabricImage)
    this.canvas.renderAll()
  }

  getImageWithBg = async () => {
    this.staticCanvas = new fabric.Canvas(null)
    this.canvas.getObjects().forEach(obj => {
      if (obj.type === ObjectType.STATIC_IMAGE) {
        this.staticCanvas.add(obj)
      }
    })
    this.staticCanvas.setWidth(this.canvas.getWidth())
    this.staticCanvas.setHeight(this.canvas.getHeight())
    this.staticCanvas.renderAll()
    return this.staticCanvas.toDataURL({ format: 'image/jpeg' })
  }

  addNewImage = async src => {
    // @ts-ignore
    let suggestionImage = this.canvas.getObjects().find(obj => obj.id === 'suggestion-image')

    const frame = this.frameMainCanvas
    let mainFrameBoundingBox = frame.getBoundingRect()
    let image = (await loadImageFromURL(src)) as any

    const newImage = new fabric.StaticImage(image, {
      type: ObjectType.STATIC_IMAGE,
      selectable: false,
      hasControls: false,
      evented: false,
      opacity: 1,
      scaleX: mainFrameBoundingBox.width / image.width,
      scaleY: mainFrameBoundingBox.height / image.height,
      top: 0,
      left: 0,
      originX: 'left',
      originY: 'top',
      id: 'new-suggestion-image',
    }) as any
    this.canvas.add(newImage)
    this.canvas.getObjects().forEach(obj => {
      // @ts-ignore
      if (obj.id === 'new-suggestion-image') {
        obj.moveTo(0)
        // @ts-ignore
        obj.id = 'suggestion-image'
      }
    })
    if (suggestionImage) {
      this.canvas.remove(suggestionImage)
    }
  }

  addChessBg = async () => {
    const currentZoom = this.root.zoomRemoveHandler.currentZoom
    const minPatternSize = 9 // Minimum square size to maintain visibility
    const maxPatternSize = 15 // Maximum square size to prevent overly large patterns

    const newTransparent = function () {
      const chessStatic = new fabric.StaticCanvas(null)
      const baseSize = 15
      let dynamicBaseSize = baseSize / currentZoom
      dynamicBaseSize = Math.max(minPatternSize, Math.min(maxPatternSize, dynamicBaseSize))
      chessStatic.setHeight(dynamicBaseSize * 2)
      chessStatic.setWidth(dynamicBaseSize * 2)
      chessStatic.renderAll()
      chessStatic.setBackgroundColor('white', () => {})
      const greyRect1 = new fabric.Rect({
        width: dynamicBaseSize,
        height: dynamicBaseSize,
        left: 0,
        top: 0,
        strokeWidth: 0,
      })
      greyRect1.set('fill', lightTheme.colors.grayScale150)
      const greyRect2 = new fabric.Rect({
        width: dynamicBaseSize,
        height: dynamicBaseSize,
        left: dynamicBaseSize,
        top: dynamicBaseSize,
        strokeWidth: 0,
      })
      greyRect2.set('fill', lightTheme.colors.grayScale150)
      chessStatic.add(greyRect1)
      chessStatic.add(greyRect2)

      chessStatic.calcOffset()
      chessStatic.renderAll()

      const canvasDataUrl = chessStatic.toDataURL({ quality: 1 })
      const imageElement = new Image()
      imageElement.src = canvasDataUrl
      const transparentPattern = new fabric.Pattern({
        source: imageElement,
        repeat: 'repeat',
      })
      return transparentPattern
    }
    this.canvas.setBackgroundColor(newTransparent(), () => {})
    this.canvas.requestRenderAll()
  }

  public setInitialPosition() {
    if (!this.activeObject || !this.isOpen) {
      return
    }
    this.startControlVisibility = Object.assign({}, this.activeObject._controlsVisibility);
    const containerElement = document.querySelector(
      '#wrap-canvas-remove-tool .remove-container-class'
    ) as HTMLElement

    containerElement.style.transition = 'none'
    containerElement.style.position = 'absolute'
    containerElement.style.left = `${this.startX}px`
    containerElement.style.top = `${this.startY}px`
    containerElement.style.backgroundColor = 'transparent'

    this.aspectRatio = this.startHeight / this.startWidth

    this.calculateDestinationRatio()
    const frame = this.activeObject.canvas.getObjects().filter(obj => obj.type === ObjectType.FRAME)[0]
    let mainFrameBoundingBox = frame.getBoundingRect()

    // this.canvas.setWidth(mainFrameBoundingBox.width).setHeight(mainFrameBoundingBox.height)

    // let originalScaleX = this.activeObject.scaleX
    // let originalScaleY = this.activeObject.scaleY

    let originalTop = (this.activeObject.top - frame.top) / frame.height
    let originalLeft = (this.activeObject.left - frame.left) / frame.width
    let originalRotation = this.activeObject.angle
    // const pixelRatio = window.devicePixelRatio || 1;
    this.activeObject.set('angle', 0).setCoords()
    
    this.canvas.getObjects().forEach(object => {
      object.originX = 'center'
      object.originY = 'center'

      object.top = originalTop * mainFrameBoundingBox.height
      object.left = originalLeft * mainFrameBoundingBox.width

      object.scaleX =  this.activeObject.getBoundingRect().width / object.width 
      object.scaleY = this.activeObject.getBoundingRect().height / object.height
      
      // @ts-ignore
      this.fabricImage.sizeOnCanvas = { width: (object.scaleX * object.width) / mainFrameBoundingBox.width }
    })
    this.activeObject.set('angle', originalRotation).setCoords()
    
    let bottomContainerElement = document.querySelector('.canvas-container') as HTMLElement
    bottomContainerElement.style.opacity = '0.0'
  }

  handleRemoveToolResize = () => {
    super.handleRemoveToolResize()

    let containerElement = document.querySelector(
      '#wrap-canvas-remove-tool .remove-container-class'
    ) as HTMLElement
    let originalWidth = parseInt(containerElement.style.width)
    let originalHeight = parseInt(containerElement.style.height)
    this.scaleX = this.destinationWidth / originalWidth
    this.scaleY = this.destinationHeight / originalHeight
    let minScale = Math.min(this.scaleX, this.scaleY)
    const { top, left } = this.getCenterPosition(containerElement)

    containerElement.style.transform = `scale(${
      minScale
    }, ${minScale})`
    containerElement.style.left = `${left}px`
    containerElement.style.top = `${top}px`
    this.root.zoomRemoveHandler.calculateScrollPosition()
  }

  FinishToolState(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if(this.clonedObject) {
        this.mainContext.canvas.remove(this.clonedObject)
        this.clonedObject = null
      }
      await this.root.eventsRemoveHandler.processMagicBgTool()
      let bottomContainerElement = document.querySelector(
        '.canvas-container'
      ) as HTMLElement
  
      bottomContainerElement.style.opacity = '1.0'
      this.resetActiveObjectState()
      this.canvas.isDrawingMode = true
      resolve()
    })
  }

  public reset() {
    this.isOpen = false
    this.resetTransforms()
  }

  resetTransforms() {
    let containerElement = document.querySelector(
      '#wrap-canvas-remove-tool .remove-container-class'
    ) as HTMLElement
    containerElement.style.transformOrigin = '';
    containerElement.style.transform = '';
    containerElement.style.left = '';
    containerElement.style.top = '';
  }

  async inpaintImage(imageBase64, maskBase64){
    // add outline to image
    let renderer = CanvasImageRenderer.getInstance();
    let imageProcessing = MediaImageRepository.getInstance()._mediaImageRepositoryProcessing;
    let mask = await imageProcessing.extractAlphaMask(maskBase64);

    let maskStaticImage = new fabric.StaticImage(mask, {
      type: ObjectType.STATIC_IMAGE,
      selectable: false,
      hasControls: false,
      evented: false,
      opacity: 1,
      transformation: {
        horizontalFlip: false,
        verticalFlip: false,
      },
      effects: {
        hasFillImage: false,
        outline: {
          thickness: 0.02,
          color: '#ffffff'
        },
        overlay: {
          color: '#ffffff'
        }
      }
    });
    let canvasSize = new Size(this.originalWidth, this.originalHeight);
    await renderer.render(maskStaticImage, canvasSize, []);

    let image = await imageProcessing.loadImage(imageBase64);

    // @ts-ignore
    let maskWithOutlineBase64 = maskStaticImage._element.toDataURL();
    let maskWithOutline = await imageProcessing.loadImage(maskWithOutlineBase64);


    let inpaintingResult = await this.root.removeLogicHandler.inpaint(image, maskWithOutline);
    return inpaintingResult;
  }

  isZoomAvaliable = () =>{
    return false
  }
}

export default MagicBgObjectHandler