import { styled } from 'baseui'
import { SHAPE, KIND } from 'baseui/button'

import Icons from '../../Icons'
import { useEffect, useState } from 'react'
import { useEditorContext } from '@/scenes/engine'

import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { useLocation } from 'react-router-dom'
import { t } from 'i18next'
import useAppContext from '@/hooks/useAppContext'
const Container = styled('div', props => ({
  display: 'flex',
  alignItems: 'center',
}))

function History() {
  const editor = useEditorContext().editor
  const location = useLocation()
  const { disableEditorState } = useAppContext()

  const [historyStatus, setHistoryStatus] = useState({ hasUndo: false, hasRedo: false })
  useEffect(() => {
    const handleHistoryChange = (data: any) => {
      setHistoryStatus({ ...historyStatus, hasUndo: data.hasUndo, hasRedo: data.hasRedo })
    }
    if (editor) {
      setHistoryStatus({
        hasUndo: editor.handlers.transactionHandler.hasUndo(),
        hasRedo: editor.handlers.transactionHandler.hasRedo(),
      })
      editor.on('history:changed', handleHistoryChange)
    }
    return () => {
      if (editor) {
        editor.off('history:changed', handleHistoryChange)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor])

  if (location.pathname.includes('create')) {
    return null
  }

  return (
    <Container>
      <ButtonCustom
        style={{
          opacity: historyStatus.hasUndo && !disableEditorState.disableNavbarHistory ? 1 : 0.55,
          pointerEvents: historyStatus.hasUndo && !disableEditorState.disableNavbarHistory ? 'auto' : 'none',
        }}
        onClick={() => {
          editor.undo()
        }}
        type={SizeButton.STANDARD}
        kind={KIND.minimal}
        shape={SHAPE.square}
        tooltip={{
          content: t('Undo'),
          placement: 'bottom',
        }}
      >
        <Icons.Undo size={24} />
      </ButtonCustom>
      <ButtonCustom
        style={{
          opacity: historyStatus.hasRedo && !disableEditorState.disableNavbarHistory ? 1 : 0.55,
          pointerEvents: historyStatus.hasRedo && !disableEditorState.disableNavbarHistory ? 'auto' : 'none',
        }}
        onClick={() => {
          editor.redo()
        }}
        type={SizeButton.STANDARD}
        kind={KIND.minimal}
        shape={SHAPE.square}
        tooltip={{
          content: t('Redo'),
          placement: 'bottom',
        }}
      >
        <Icons.Redo size={24} />
      </ButtonCustom>
    </Container>
  )
}
export default History
