//import axios from 'axios';
//import { GoogleGenerativeAIDescribeItemsOnImage, GoogleGenAIGenerateImageBaseOnImage } from './geminiContentService'; // Import your new function
import api from '../api';


/**
 * Describe an image using OpenAI or Gemini
 */
export async function describeImage(
  imageData: string,
  prompt: string,
  provider: 'openai' | 'gemini'
): Promise<string> {
  if (provider === 'openai') {
    try {
        // const openaiResponse = await openAIDescribeItemsOnImage(prompt, imageData)
        const openaiResponse = await api.openApiWithImage(prompt, imageData)
        return openaiResponse.data.choices[0].message.content;;
    } catch (error: any) {
      console.error('OpenAI describeImage error:', error?.response?.data || error);
      return 'Failed to describe the image (OpenAI).';
    }
  }

  if (provider === 'gemini') {
    try {
      
      // const geminiResponse = await GoogleGenerativeAIDescribeItemsOnImage(prompt, imageData);
      
      // return geminiResponse;
      return 'Failed to describe the image (Gemini).';
    } catch (error: any) {
      console.error('Gemini describeImage error:', error?.response?.data || error);
      return 'Failed to describe the image (Gemini).';
    }
  }

  return 'Provider not recognized.';
}

export async function generateImageBaseOnImage(
  imageData: string,
  prompt: string,
  provider: 'openai' | 'gemini'
): Promise<{success: boolean, image?: string}> {
  if (provider === 'openai') {
    try {
        // const openaiResponse = await openAIGenerateImageBaseOnImage(imageData,prompt)
        // return openaiResponse
    } catch (error: any) {
      console.error('OpenAI enhanceImage error:', error?.response?.data || error);
      return {success: false };
    }
  } else if (provider === 'gemini') {
    const geminiResponse = await api.gemini(prompt, imageData);
    // const geminiResponse = await GoogleGenAIGenerateImageBaseOnImage(imageData,prompt)
    return geminiResponse;
  }
  return {success: false };
}
