import { getDatabase, ref, DatabaseReference, off, DataSnapshot, onValue, set } from 'firebase/database';
import { CloudProcess } from "./CloudProcess";
import { CloudProgress } from "./CloudProgress";
import { CloudProjectFileTransfer } from "./CloudProjectFileTransfer";
import { DeviceCloudMetadata } from "./DeviceCloudMetadata";
import { DraftSyncVersion } from "./DraftSyncVersion";
import { EmptyResponse, CloudResponse, SuccesfulResponse } from "./CloudResponse";
import { ProjectFileMetaData } from "./ProjectFileMetaData";
import { UserDevice } from "./UserDevice";
import { VersionScreenshotInfo } from "./VersionScreenshotInfo";
import { CloudFontFileTransfer } from "./CloudFontFileTransfer";
import { CloudUrl } from "./CloudUrl";
import { StorageReference, listAll } from 'firebase/storage';
import { TemplateConfig } from "@/interfaces/editor";
import { BtDiskFileUtils } from "./BtDiskFileUtils";
import { FontsManager } from "./FontsManager";
import { CloudTempProject } from "./CloudTempProject";
import { RealTimeDelegate, RealTimeDictionary } from './dataTypes/RealTimeDictionary';
import { ProcessType } from './ProcessType';
import { FolderObject } from './FolderObject';
import { FoldersManager } from './FoldersManager';
import { BtDraftMigrator } from './BtDraftMigrator';
import { SyncStatus } from './SyncStatus';
import { BtUtils } from './dataTypes/BtUtils';
import { WaitingList } from './dataTypes/WaitingList';
import { ScreenshotProvider } from './ScreenshotProvider';
import { AtomicDictionary } from './dataTypes/AtomicDictionary';
import { ThrottleManager } from './dataTypes/ThrottleManager';
import { SignInManager } from '@/scenes/Editor/components/Navbar/components/SignInManager';
import { FirebaseWrapper } from '@/firebase/FirebaseBaseWrapper';
import { SyncDecoder } from './SyncDecoder';
import { MediaImageRepository } from '@/scenes/engine/objects/media-repository/media_image_repository';

const minDraftVersion = 1

export interface CloudManagerDelegate {
    cloudManagerDidUploadMetaData(): void;
    cloudManagerDidDetectMetaDataChange(): void;
    cloudManagerDidReceivedCloudFolders(folders: FolderObject[]): void;
}

export class CloudManager {
    delegate?: CloudManagerDelegate;
    private static _shared: CloudManager;
    static get shared(): CloudManager {
        if (!this._shared) {
            this._shared = new CloudManager();
        }
        return this._shared;
    }

    async setup(){
        await FirebaseWrapper.FirebaseBaseWrapper.shared.signInUser(SignInManager.getInstance().firebase_token)
    }

    private get cloudUserId(): string | undefined {
        let auth = FirebaseWrapper.FirebaseBaseWrapper.shared.firebaseAuth
        return auth.currentUser?.uid;
    }

    get userId(): string {
        return this.cloudUserId ? this.cloudUserId : SignInManager.getInstance()?.username
    }

    get isLoggedIn(): boolean {
        return !!this.cloudUserId;
    }

    private fetchingByDraftId = new AtomicDictionary<string, { process: CloudProcess, progress?: CloudProgress }>();
    private uploadingByDraftId = new AtomicDictionary<string, { process: CloudProcess, progress?: CloudProgress }>();
    private versionScreenshotInfoById: AtomicDictionary<string, VersionScreenshotInfo>;

    private metadataThrottle = new ThrottleManager<EmptyResponse>();
    private foldersThrottle = new ThrottleManager<EmptyResponse>();
    private uploadList = new WaitingList<ProjectFileMetaData[] | void>(3, "sync: upload project", false);

    private constructor() {
        CloudProjectFileTransfer.clearAllTempProjects();

        const data = localStorage.getItem("kVersionScreenshotUrl");
        const dict: { [key: string]: VersionScreenshotInfo } = data ? VersionScreenshotInfo.fromJSONDictionary(data) : {};
        this.versionScreenshotInfoById = new AtomicDictionary(dict);
    }

    ////////////////////////////////
    ///////// realtime db //////////
    ////////////////////////////////
    //MARK: realtime db

    private get baseChangesReference(): DatabaseReference {
        return ref(getDatabase(), `/${CloudUrl.baseUrl()}/cloud_storage/users/${this.userId}`);
    }

    private get baseProjectsChangesReference(): DatabaseReference {
        // @ts-ignore
        return ref(getDatabase(), `${this.baseChangesReference._path.toString()}/projectUpdates`);
    }

    private get baseFoldersChangesReference(): DatabaseReference {
        // @ts-ignore
        return ref(getDatabase(), `${this.baseChangesReference._path.toString()}/folders`);
    }

    private get baseRecycleBinChangesReference(): DatabaseReference {
        // @ts-ignore
        return ref(getDatabase(), `${this.baseChangesReference._path.toString()}/recycleBin`);
    }

    private get deviceLogReference(): DatabaseReference | undefined {
        // TODO DROR: fix this
        const deviceId = "web" 
        // const deviceId = Device.identifier; // Make sure Device.identifier is defined
        return deviceId ? ref(getDatabase(), `/logs/devices/${deviceId}`) : undefined;
    }

    startListenToChanges() {
        off(this.baseProjectsChangesReference);
        off(this.baseFoldersChangesReference);

        onValue(this.baseProjectsChangesReference, async (snapshot: DataSnapshot) => {
            const dict = snapshot.val();
            if (dict && dict.device && dict.device !== UserDevice.current.id) {
                this.logWithTimestamp(`sync: did received status change on other device: ${dict.device}`);
                this.delegate?.cloudManagerDidDetectMetaDataChange();
            } else {
                this.logWithTimestamp("sync: did receive an empty status on other devices");
            }
        });

        onValue(this.baseFoldersChangesReference, async (snapshot: DataSnapshot) => {
            const dict = snapshot.val();
            if (dict && dict.device && dict.device !== UserDevice.current.id) {
                this.logWithTimestamp(`sync: did received folders change on other device: ${dict.device}`);
                const folders = await this.fetchFoldersMetaDataArray();
                this.delegate?.cloudManagerDidReceivedCloudFolders(folders.value || []);
            } else {
                this.delegate?.cloudManagerDidReceivedCloudFolders([]);
                this.logWithTimestamp("sync: did receive an empty folders update on other devices");
            }
        });
    }

    getRecycleBinDictionary(delegate: RealTimeDelegate | null): RealTimeDictionary<Number> | null {
        if (!this.cloudUserId) return null;
        return new RealTimeDictionary(this.baseRecycleBinChangesReference, delegate);
    }

    notifyStatusChange() {
        BtUtils.throttle(300, async () => {
            try {
                await set(this.baseProjectsChangesReference, {
                    lastUpdate: Date.now() / 1000,
                    device: UserDevice.current.id
                });
            } catch (error) {
                console.warn(`sync: failed to mark real time database with the change.error: ${error}`);
            }
        }, "cloudManager_notifyOtherDevices");
    }

    notifyFoldersChange() {
        BtUtils.throttle(300, async () => {
            try {
                await set(this.baseFoldersChangesReference, {
                    lastUpdate: Date.now() / 1000,
                    device: UserDevice.current.id
                });
            } catch (error) {
                console.warn(`sync: failed to mark real time database with the folders change. error: ${error}`);
            }
        }, "cloudManager_notifyOtherDevices_foldersChange");
    }

    ////////////////////////
    ///////// api //////////
    ////////////////////////
    //MARK: api

    async downloadCloudMetadata(): Promise<CloudResponse<DeviceCloudMetadata[]> | EmptyResponse> {
        // await this.updateFonts();

        this.logWithTimestamp("sync: fetching devices list");
        const cloudDevicesResponse = await this.downloadDeviceIds();
        if (cloudDevicesResponse.error) {
            return cloudDevicesResponse as EmptyResponse;
        }
        let devices = (cloudDevicesResponse as CloudResponse<string[]>).value

        this.logWithTimestamp(`sync: fetching ${devices.length} devices metadata`);
        const response = await Promise.all(devices.map(deviceId => this.downloadMetaData(deviceId)));
        this.logWithTimestamp("sync: did complete fetching devices metadata");

        const errors = response.filter(res => res.error);
        if (errors.length > 0) {
            console.error(`sync: ${errors.map(e => e.error).join(", ")})}`)
        }
        return new SuccesfulResponse(response.flatMap(res => res.value).filter(value => !!value)) as CloudResponse<DeviceCloudMetadata[]>;
    }

    async downloadCloudUnsafeFeed(): Promise<CloudResponse<DraftSyncVersion[]> | EmptyResponse> {
        try {
            const data = await CloudUrl.get(CloudUrl.unsafeFeedMetaData());
            let decoder = new SyncDecoder<DraftSyncVersion>();
            const metadata = decoder.convertArray(data);
            return new SuccesfulResponse(metadata)
        } catch (error) {
            return EmptyResponse.sendError(`sync: couldn't fetch metadata of feed list. error: ${error}`);
        }
    }

    async uploadFolders(): Promise<EmptyResponse> {
        const folders = FoldersManager.shared.folders;
        return await this.foldersThrottle.throttle(0.1, async () => {
            try {
                await CloudUrl.putJson(CloudUrl.foldersMetaData(), folders);
                this.logWithTimestamp("sync: did upload folders to cloud");
                return EmptyResponse.success();
            } catch (error) {
                return EmptyResponse.sendError(`sync: failed to upload folders. error: ${error}`);
            }
        });
    }

    async fetchFoldersMetaDataArray(): Promise<CloudResponse<FolderObject[]>> {
        try {
            const data = await CloudUrl.get(CloudUrl.foldersMetaData());
            let decoder = new SyncDecoder<FolderObject>();
            const folders = decoder.convertArray(data);
            this.logWithTimestamp("sync: did received cloud folders");
            return new SuccesfulResponse(folders);
        } catch (error) {
            EmptyResponse.sendError(`sync: couldn't fetch folders. error: ${error}`);
        }
    }

    async uploadProjectsMetaDataArray(projects: DraftSyncVersion[], unsafeFeed?: DraftSyncVersion[]): Promise<EmptyResponse> {
        this.logWithTimestamp("sync: throttle uploading metadata of device");
        const res = await this.metadataThrottle.throttle(100, async () => {
            return this.throttledUploadProjectsMetaDataArray(projects, unsafeFeed);
        });
        return res;
    }

    private async throttledUploadProjectsMetaDataArray(projects: DraftSyncVersion[], unsafeFeed?: DraftSyncVersion[]): Promise<EmptyResponse> {
        const device = UserDevice.current;

        this.logWithTimestamp(`sync: uploading ${projects.length} projects metadata of device: ${device.id}`);

        const metadata = new DeviceCloudMetadata(device, projects);

        try {
            await CloudUrl.putJson(CloudUrl.deviceMetaData(device.id), metadata);
        } catch (error) {
            return EmptyResponse.sendError(`sync: failed to upload metadata of device: ${metadata.device.id}. error: ${error}`);
        }

        if (unsafeFeed) {
            try {
                let unsafeFeedWithPrefix = [];
                for (let feedItem of unsafeFeed) {
                    unsafeFeedWithPrefix.push({ 'bazaart.DraftSyncVersion': feedItem })
                }
                await CloudUrl.putJson(CloudUrl.unsafeFeedMetaData(), unsafeFeedWithPrefix);
            } catch (error) {
                this.logWithTimestamp(`sync: failed to upload unsafe feed. error: ${error}`);
            }
        }

        this.delegate?.cloudManagerDidUploadMetaData();
        return EmptyResponse.success();
    }

    async cancelFetchingProject(projectId: string) {
        const action = this.fetchingByDraftId.get(projectId);
        if (!action) return;
        if (action.progress) action.progress.isCanceled = true;
        await action.process.cancel();
        this.fetchingByDraftId.delete(projectId);
    }

    async cancelUploadingProject(projectId: string) {
        const action = this.uploadingByDraftId.get(projectId);
        if (!action) return;
        if (action.progress) action.progress.isCanceled = true;
        await action.process.cancel();
        this.uploadingByDraftId.delete(projectId);
    }

    async cancelAllUploads() {
        this.logWithTimestamp("sync: cancel all uploads");
        for (const projectId of this.uploadingByDraftId.keys()) {
            await this.cancelUploadingProject(projectId);
        }
    }

    async fetchProject(projectId: string, device: UserDevice, refetch?: CloudTempProject): Promise<CloudResponse<DraftSyncVersion | DeviceCloudMetadata | CloudTempProject> | EmptyResponse> {
        await this.cancelFetchingProject(projectId);

        const process = new CloudProcess(projectId, ProcessType.Download);
        this.fetchingByDraftId.set(projectId, { process });

        const response = await this.fetchProjectUpdatedVersion(projectId, device);

        const clearDownloading = () => {
            this.fetchingByDraftId.delete(projectId)
        }

        if (response.error) {
            clearDownloading()
            return response;
        }
        
        const version = response.value! as DraftSyncVersion;
        if (version.minVersion < minDraftVersion) {
            clearDownloading()
            return EmptyResponse.sendError(`fetch project failed: project: ${projectId} isn't supported on this device` )
        }

        if (version.syncStatus !== SyncStatus.Updated) {
            this.delegate?.cloudManagerDidDetectMetaDataChange();
            clearDownloading()

            if (version.syncStatus === SyncStatus.Deleted) {
                return EmptyResponse.sendError(`sync: fetch project failed: project: ${projectId}, was deleted during fetching from device: ${device.id}, retrying` );
            } else if (version.syncStatus === SyncStatus.Uploading) {
                return EmptyResponse.sendError(`sync: fetch project failed: project: ${projectId}, was changed during fetching and still uploading to device: ${device.id}, retrying`);
            }
            return EmptyResponse.sendError(`fetch project failed: ${version} isn't synced`);
        }

        const draftResponse = await this.downloadProjectFiles(projectId, device, process, refetch);

        if (draftResponse.error) {
            clearDownloading()
            return draftResponse;
        }

        const updatedResponse = await this.fetchProjectUpdatedVersion(projectId, device);
        if (updatedResponse.error) {
            clearDownloading()
            return updatedResponse;
        }

        const updatedVersion = updatedResponse.value! as DraftSyncVersion;
        if (Math.abs(updatedVersion.statusUpdateDate.getTime() - version.statusUpdateDate.getTime()) > 100) {
            this.delegate?.cloudManagerDidDetectMetaDataChange();

            if (updatedVersion.syncStatus === SyncStatus.Deleted) {
                return EmptyResponse.sendError(`sync: fetch project failed: project: ${projectId}, was deleted during fetching from device: ${device.id}, retrying`);
            } else if (updatedVersion.syncStatus === SyncStatus.Uploading || updatedVersion.syncStatus === SyncStatus.WaitingForUpload) {
                return EmptyResponse.sendError(`sync: fetch project failed: project: ${projectId}, was changed during fetching and still uploading to device: ${device.id}, retrying`);
            } else if (updatedVersion.syncStatus !== SyncStatus.Updated) {
                console.warn(`sync: fetch project failed: project: ${projectId}, was changed during fetching from device: ${device.id}, state is: ${updatedVersion.syncStatus}`);
            }
            console.warn(`sync: fetch project failed: project: ${projectId}, was changed during fetching from device: ${device.id}, retrying`);
            
            let tempProject = (draftResponse as CloudResponse<CloudTempProject>).value

            clearDownloading()
            return this.fetchProject(projectId, device, tempProject);
        }

        clearDownloading()
        return draftResponse;
    }

    async deleteProject(projectId: string): Promise<EmptyResponse> {
        this.logWithTimestamp(`sync: deleting project: ${projectId}`);

        await this.cancelUploadingProject(projectId);
        await this.cancelFetchingProject(projectId);

        const filesReq = await this.allCloudProjectsFiles(projectId, UserDevice.current.id);

        if (!filesReq.succeed) {
            return filesReq as EmptyResponse;
        }

        try {
            await CloudUrl.deleteFile(CloudUrl.projectFilesList(projectId));
        } catch (error) {
            return EmptyResponse.sendError(`sync: failed to delete cloud project: ${projectId}, error: ${error}`);
        }

        let projectsFilesMetaData = (filesReq as CloudResponse<ProjectFileMetaData[]>).value
        for (const file of projectsFilesMetaData!) {
            try {
                await CloudUrl.deleteFile(CloudUrl.projectUrl(file.relativePath));
            } catch (error) {
                if (error.code === "storage/object-not-found") {
                    this.logWithTimestamp(`sync: file ${file.relativePath} was deleted already, and doesn't exist anymore on cloud`);
                } else {
                    EmptyResponse.sendError(`sync: failed to delete cloud project: ${projectId}, file: ${file.relativePath}, error: ${error}`);
                }
            }
        }
        return EmptyResponse.success();
    }

    async deleteProjects(projectIds: string[], projectCompletion?: (id: string) => void) {
        for (const id of projectIds) {
            const res = await this.deleteProject(id);
            if (res.success && projectCompletion) {
                projectCompletion(id);
            }
        }
    }

    async uploadProject(project: TemplateConfig, didStartBlock?: () => void): Promise<EmptyResponse> {
        this.logWithTimestamp(`sync: cloud manager - upload project ${project.draftGuid}`);

        let that = this;
        return this.uploadList.runTask(() => new Promise(async function(resolve, reject) {
            const migrator = new BtDraftMigrator();
            const migrateResult = await migrator.asyncMigrate(project);
            if (!migrateResult.success) {
                resolve(EmptyResponse.sendError(`sync: failed to migrate project ${project.draftGuid} before uploading to cloud`));
                return;
            }

            if (didStartBlock) {
                didStartBlock();
            }

            const projectId = project.draftGuid!;
            const process = new CloudProcess(projectId, ProcessType.Upload);
            that.uploadingByDraftId.set(projectId, { process });

            const res = await that.uploadProjectAssets(project, process);
            if (!res.succeed) {
                resolve(EmptyResponse.sendError(`sync: failed to upload project ${project.draftGuid}`));
                return;
            }

            that.uploadingByDraftId.delete(projectId);
            resolve(EmptyResponse.success());
        })).then((result) => {
            return result as EmptyResponse;
        });
    }

    async fetchFont(fileName: string): Promise<EmptyResponse> {
        const transfer = new CloudFontFileTransfer(fileName, ProcessType.Download, undefined);
        const fontCloudUrl = await CloudUrl.getDownloadUrl(transfer.cloudUrl);

        let font = {
            name: fileName,
            url: fontCloudUrl,
            options: { weight: '400' },
        }

        return new Promise(async function(resolve, reject){
            let fontFaceP = new FontFace(font.name, `url(${font.url})`, font.options)
            let fontFace = await fontFaceP.load().catch(err => {
                resolve(EmptyResponse.sendError(err));
            })

            // @ts-ignore
            document.fonts.add(fontFace)
            resolve(EmptyResponse.success())
        })
    }

    async uploadFont(fileName: string, shouldCheckIfExist = true): Promise<EmptyResponse> {
        this.logWithTimestamp(`sync: uploading font: ${fileName}`);
        if (shouldCheckIfExist) {
            const fontsRes = await this.downloadFontsFileNames();
            if (fontsRes.error) {
                return fontsRes as EmptyResponse;
            }

            let fonts = (fontsRes as CloudResponse<string[]>).value
            if (fonts.includes(fileName)) {
                this.logWithTimestamp(`sync: uploading font: ${fileName} - font already exists`);
                return EmptyResponse.success();
            }
        }

        const res = await new CloudFontFileTransfer(fileName, ProcessType.Upload, undefined).run(2, null);
        if (res.success) {
            this.notifyStatusChange();
        }
        return res;
    }

    async getCachedScreenshotUrl(ofVersion?: DraftSyncVersion): Promise<string> | undefined {
        if (!ofVersion) return undefined;

        if (ofVersion.isLocal) {
            let screenshotURL = ScreenshotProvider.screenshotProjectUrl(ofVersion.draftId);
            if (screenshotURL){
                let base64Screenshot = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.blobUrlToBase64(screenshotURL)
                return base64Screenshot
            }
        }

        const versionId = VersionScreenshotInfo.versionId(ofVersion);
        const urlInfo = this.versionScreenshotInfoById.get(versionId);
        if (urlInfo && urlInfo.isMatching(ofVersion)) {
            return urlInfo.url;
        }

        this.versionScreenshotInfoById.delete(versionId);
        return undefined;
    }

    async setCachedScreenshotUrl(base64: string | undefined, ofVersion: DraftSyncVersion) {
        const versionId = VersionScreenshotInfo.versionId(ofVersion);
        const screenshotInfo = base64 ? new VersionScreenshotInfo(ofVersion.draftId, ofVersion.updateDeviceId, ofVersion.roundedTimestamp, base64) : undefined;
        this.versionScreenshotInfoById.set(versionId, screenshotInfo);
        const dict = this.versionScreenshotInfoById.dictionary;
        // for (let key in dict) {
        //     let screenshotInfo = dict[key]
        //     await screenshotInfo.persistLocalBlobs()
        // }
        localStorage.setItem("kVersionScreenshotUrl", JSON.stringify(dict));
    }

    async getScreenshotUrl(ofVersion?: DraftSyncVersion): Promise<string | undefined> {
        if (!ofVersion) return undefined;

        const cachedUrl = await this.getCachedScreenshotUrl(ofVersion);
        if (cachedUrl) return cachedUrl;

        const relative = ScreenshotProvider.screenshotRelativeToProject(ofVersion.draftId);
        const cloudUrl = CloudUrl.create(relative, ofVersion.deviceOwnerId);

        
        let that = this;
        return new Promise(async function(resolve, reject){
            try {
                let url = await CloudUrl.getDownloadUrl(cloudUrl)
                await that.setCachedScreenshotUrl(url, ofVersion);
                resolve(url);
            } catch (error) {
                console.warn(`sync: failed to get screenshot of project: ${ofVersion.draftId}, at device: ${ofVersion.deviceOwnerId}. error: ${error}`);
                resolve(undefined);
                return;
            }
        })
    }

    isDownloading(projectId: string): boolean {
        const action = this.fetchingByDraftId.get(projectId);
        if (!action) return false;
        if (action.progress && action.progress.isCanceled) return false;
        return true;
    }

    progressPercent(projectId: string): number {
        const tuple = this.fetchingByDraftId.get(projectId) ?? this.uploadingByDraftId.get(projectId);
        if (!tuple) return -1;
        if (!tuple.progress) return 0;
        return tuple.progress.percent;
    }

    async removeDeviceFromCloud(device: UserDevice): Promise<EmptyResponse> {
        try {
            await CloudUrl.deleteAllFiles(CloudUrl.deviceBaseUrl(device.id));
            this.notifyStatusChange();
        } catch (error) {
            EmptyResponse.sendError(`sync: failed to delete device data. error: ${error}`);
        }
        return EmptyResponse.success();
    }

    async removeUserFromCloud(): Promise<EmptyResponse> {
        try {
            await CloudUrl.deleteAllFiles(CloudUrl.userBaseUrl());
            this.notifyStatusChange();
        } catch (error) {
            EmptyResponse.sendError(`sync: failed to delete user data. error: ${error}`);
        }
        return EmptyResponse.success();
    }

    async cleanDeviceCloudOldFiles(currentVersions: DraftSyncVersion[]) {
        try {
            const childs = await listAll(CloudUrl.projectBaseUrl());

            const shouldDeleteFile = (projectId: string | null) => {
                if (!projectId) return false;
                const version = currentVersions.find(v => v.draftId === projectId);
                return !version || SyncStatus.isDeleted(version.syncStatus);
            };
            
            const projects = childs.prefixes.map(prefix => prefix as CloudUrl.ExtendedStorageReference).filter(url => shouldDeleteFile(CloudUrl.relativeToProjectsPath(url)));
            const projectsFilesList = childs.items.map(prefix => prefix as CloudUrl.ExtendedStorageReference).filter(url => shouldDeleteFile(CloudUrl.relativeToProjectsPath(url as CloudUrl.ExtendedStorageReference)));

            this.logWithTimestamp(`sync: cleaning old cloud files. ${projects.length} projects, and ${projectsFilesList.length} files lists`);

            for (const url of projects) {
                this.logWithTimestamp(`sync: clean old project files: ${CloudUrl.lastComponent(url) ?? "?"}`);
                await CloudUrl.deleteAllFiles(url as CloudUrl.ExtendedStorageReference);
            }

            for (const url of projectsFilesList) {
                this.logWithTimestamp(`sync: clean old project files metadata: ${CloudUrl.lastComponent(url) ?? "?"}`);
                await CloudUrl.deleteAllFiles(url);
            }
        } catch (error) {
            console.warn(`Sync: failed to clean project list of device: ${UserDevice.current}, error: ${error}`);
        }
    }

    ////////////////////////////
    ///////// private //////////
    ////////////////////////////
    //MARK: private

    private async uploadProjectAssets(project: TemplateConfig, process: CloudProcess): Promise<EmptyResponse | CloudResponse<ProjectFileMetaData[]>> {
        const projectId = project.draftGuid!;

        const tempProject = new CloudTempProject(projectId);
        await tempProject.prepareForUpload()

        if (!tempProject.originalProject) {
            return EmptyResponse.sendError(`sync: failed to upload project ${projectId}, project doesn't exist on disk`);
        }

        const cloudUrlsRes = await this.allCloudProjectsFiles(projectId, UserDevice.current.id);
        // if (cloudUrlsRes.error) {
        //     return cloudUrlsRes;
        // }

        const cloudUrls = (cloudUrlsRes as CloudResponse<ProjectFileMetaData[]>).value!.filter ((f) => (!!f));
        const localUrls = this.allLocalFiles(tempProject.projectUrl, cloudUrls);

        try {
            await CloudUrl.deleteFile(CloudUrl.projectFilesList(projectId));
        } catch (error) {
            EmptyResponse.sendError(`sync: failed to delete files list of project before updating: ${projectId}, error: ${error}`);
        }

        const filesToSync = this.fileTransfer(cloudUrls, localUrls, ProcessType.Upload);
        const progress = new CloudProgress(filesToSync);

        this.uploadingByDraftId.set(projectId, { process, progress });

        const uploading = filesToSync.filter(file => !file.shouldDelete);
        const linking = uploading.filter(file => file.isHardLink);
        const size = uploading.reduce((total, file) => total + (file.size || 0), 0);

        this.logWithTimestamp(`sync: deleting ${filesToSync.length - uploading.length} files, uploading ${uploading.length - linking.length} files, linking: ${linking.length} files, size: ${size}`);

        const res = await process.runFileTransfers(filesToSync, progress);
        if (res.error) return res;

        try {
            await CloudUrl.putJson(CloudUrl.projectFilesList(projectId), localUrls);
        } catch (error) {
            return EmptyResponse.sendError(`sync: failed to upload files list of project: ${projectId}, error: ${error}`);
        }

        if (await process.shouldCancel()) {
            return EmptyResponse.sendError(`sync: uploading project files was canceled: ${projectId}`);
        }

        return EmptyResponse.success();
    }

    private async allCloudProjectsFiles(projectId: string, ofDeviceId: string): Promise<CloudResponse<ProjectFileMetaData[]> | EmptyResponse> {
        try {
            const filesList = CloudUrl.projectFilesList(projectId, ofDeviceId);
            const data = await CloudUrl.get(filesList);
            let decoder = new SyncDecoder<ProjectFileMetaData>();
            const files = decoder.convertArray(data);
            return new SuccesfulResponse(files);
        } catch {
            this.logWithTimestamp(`sync: couldn't read cloud files out of json data of project: ${projectId}`);
        }

        try {
            const cloudUrls = await listAll(CloudUrl.projectUrl(projectId));

            if (cloudUrls.items.length === 0) {
                this.logWithTimestamp(`sync: didn't get any cloud project files at: ${projectId}`);
                return new SuccesfulResponse([]);
            }
            let actualCloudUrls = cloudUrls.items.map(cloudUrl => CloudUrl.projectFileMetaData(cloudUrl, ofDeviceId))
            const filesResponse = await Promise.all(actualCloudUrls);
            const errors = filesResponse.filter(res => res.error);
            if (errors.length) {
                return EmptyResponse.sendError(`sync: failed to fetch cloud project files list from: ${projectId}, error: ${errors.map(e => e.error).join(", ")}`);
            }
            let flatFilesResponse: ProjectFileMetaData[] = filesResponse.flatMap (r  => r.value|| []).filter((f) => !!f);
            return new SuccesfulResponse(flatFilesResponse);
        } catch (error) {
            return EmptyResponse.sendError(`sync: failed to receive cloud project files list from: ${projectId}, error: ${error}`);
        }
    }

    private allLocalFiles(url: URL, cloudFiles: ProjectFileMetaData[]): ProjectFileMetaData[] {
        if (!BtDiskFileUtils.exists(url)) return [];

        const records = BtDiskFileUtils.list(url).filter( r => !r[1].isFolder);
        const res = ProjectFileMetaData.fileLinksMetaDataOutOfLocalUrls(records);
        const count = res.reduce((total, links) => total + links.length, 0);

        if (count !== records.length) {
            console.warn("sync: local files links count doesn't match the given urls");
            return [];
        }

        const cloudFilesByPath = new Map(cloudFiles.map(file => [file.relativePath, file]));

        for (const links of res) {
            if (links.length > 1) {
                const existOnCloud = links.find(link => cloudFilesByPath.has(link.relativePath) && !cloudFilesByPath.get(link.relativePath)!.isHardLink);
                const sourceFile = existOnCloud || links[0];
                const hardLinks = links.filter(link => link !== sourceFile);
                for (const link of hardLinks) {
                    link.linkTo(sourceFile);
                }
            }
        }

        return res.flat();
    }

    private fileTransfer(cloudUrls: ProjectFileMetaData[], localUrls: ProjectFileMetaData[], processType: ProcessType, deviceId: string | null = null): CloudProjectFileTransfer[] {
        const sources = processType === ProcessType.Upload ? localUrls : cloudUrls;
        const targets = processType === ProcessType.Upload ? cloudUrls : localUrls;

        const targetDict = new Map(targets.map(file => [file.relativePath, file]));

        const missingFiles: CloudProjectFileTransfer[] = [];
        const needToUpdateFiles: CloudProjectFileTransfer[] = [];
        const needToDelete: CloudProjectFileTransfer[] = [];

        for (const source of sources) {
            const relativePath = source.relativePath;
            const file = new CloudProjectFileTransfer(source, processType, source.modifiedDate, deviceId);

            const targetFile = targetDict.get(relativePath);
            if (targetFile) {
                if (targetFile.isHardLink !== source.isHardLink) {
                    this.logWithTimestamp(`sync - file link attribute was changed somehow: ${relativePath}`);
                    if (source.isHardLink) {
                        file.shouldDelete = true;
                        needToDelete.push(file);
                    } else {
                        missingFiles.push(file);
                    }
                } else if (targetFile.timeStamp !== source.timeStamp) {
                    needToUpdateFiles.push(file);
                }
            } else {
                missingFiles.push(file);
            }
            targetDict.delete(relativePath);
        }

        for (const targetFile of targetDict.values()) {
            needToDelete.push(new CloudProjectFileTransfer(targetFile, processType, null, deviceId, true));
        }

        return [...missingFiles, ...needToUpdateFiles, ...needToDelete];
    }

    private lastPathComponent(url: URL): string {
        return url.href.split("/").pop();
    }

    private async updateFonts() {
        const cloudFontsRes = await this.downloadFontsFileNames();
        if (cloudFontsRes.error) {
            console.warn("sync: failed to get cloud font list, can't update custom fonts");
            return;
        }

        const cloudFonts = (cloudFontsRes as CloudResponse<string[]>).value;
        const localFonts = FontsManager.shared.getFonts(font => font.isCustomFont).map(font => this.lastPathComponent(font.fileURL));

        const dictLocalFonts = new Set(localFonts);
        const dictCloudFonts = new Set(cloudFonts);

        const uploadFonts = localFonts.filter(font => !dictCloudFonts.has(font));
        const downloadFonts = cloudFonts.filter(font => !dictLocalFonts.has(font) && !FontsManager.shared.isHiddenCustomFont(font));

        this.logWithTimestamp(`sync: updating custom fonts: uploading ${uploadFonts.length} fonts, downloading ${downloadFonts.length} fonts.`);

        for (const font of uploadFonts) {
            await this.uploadFont(font, false);
        }

        for (const font of downloadFonts) {
            await this.fetchFont(font);
        }

        if (downloadFonts.length > 0) {
            FontsManager.shared.fetchLocalCustomFonts();
        }
    }

    private async downloadProjectFiles(projectId: string, device: UserDevice, process: CloudProcess, reDownload?: CloudTempProject): Promise<CloudResponse<CloudTempProject> | EmptyResponse> {
        const tempProject = reDownload || new CloudTempProject(projectId);
        
        if (!tempProject.loadProject()) {
            return EmptyResponse.sendError(`sync: failed to create temp project ${projectId}`);
        }

        const cloudUrlsRes = await this.allCloudProjectsFiles(projectId, device.id);
        if (cloudUrlsRes.error) {
            return cloudUrlsRes as EmptyResponse;
        }

        const cloudUrls = (cloudUrlsRes as CloudResponse<ProjectFileMetaData[]>).value!;
        const localUrls = this.allLocalFiles(tempProject.projectUrl, cloudUrls);

        const filesToSync = this.fileTransfer(cloudUrls, localUrls, ProcessType.Download, device.id);

        const progress = new CloudProgress(filesToSync);
        this.fetchingByDraftId.set(projectId, { process, progress });

        const res = await process.runFileTransfers(filesToSync, progress);
        if (res.error) {
            return res as EmptyResponse;
        }
        if (!tempProject.loadProject()) {
            return EmptyResponse.sendError(`sync: failed to load draft from temp folder: ${projectId}`);
        }
        return new SuccesfulResponse(tempProject);
    }
    
    private async fetchProjectUpdatedVersion(projectId: string, device: UserDevice): Promise<CloudResponse<DraftSyncVersion | DeviceCloudMetadata> | EmptyResponse> {
        const res = await this.downloadMetaData(device.id);
        if (res.error) return res;
        let draftSyncVersionResponse = res as CloudResponse<DeviceCloudMetadata>;
        const projectMetaData = draftSyncVersionResponse.value.draftSyncVersions.find(version => version.draftId === projectId);
        if (!projectMetaData) {
            this.delegate?.cloudManagerDidDetectMetaDataChange();
            return {
                error: `sync: missing project: ${projectId} from device metaData: ${device.id}`,
                succeed: false 
             };
        }

        return { 
            value: projectMetaData,
            succeed: true
        };
    }

    
    
    private async downloadMetaData(deviceId: string): Promise<CloudResponse<DeviceCloudMetadata> | EmptyResponse> {
        const data = await CloudUrl.get(CloudUrl.deviceMetaData(deviceId));
        try {
            let decoder = new SyncDecoder<DeviceCloudMetadata>();
            const metadata = decoder.convertSingle(data);
            return new SuccesfulResponse(metadata);
        } catch (error) {
            let decoder = new SyncDecoder<DeviceCloudMetadata>();
            const metadata = decoder.convertSingle(data);
            return EmptyResponse.sendError(`sync: couldn't fetch metadata of device: ${deviceId}. error: ${error}`);
        }
    }

    private async downloadDeviceIds(): Promise<CloudResponse<string[]> | EmptyResponse> {
        try {
            const res = await listAll(CloudUrl.devicesBaseUrl());
            let webOnlyDevice = res.prefixes.map(prefix => prefix.name).filter( r => r.toLowerCase() == 'web');
            return new SuccesfulResponse(webOnlyDevice);
        } catch (error) {
            return EmptyResponse.sendError(`sync: failed to receive cloud devices. error: ${error}`);
        }
    }

    async didHaveSync(): Promise<CloudResponse<boolean> | EmptyResponse> {
        const req = await this.downloadDeviceIds();
        if (req.error) return req as EmptyResponse;
        let values = req.value as string[]
        return new SuccesfulResponse(values.length > 0);
    }

    async downloadFontsFileNames(): Promise<CloudResponse<string[]> | EmptyResponse> {
        try {
            const res = await listAll(CloudUrl.fontsBaseUrl());
            return new SuccesfulResponse(res.items.map(item => item.name));
        } catch (error) {
            return EmptyResponse.sendError(`sync: failed to receive cloud fonts list. error: ${error}`);
        }
    }

    logWithTimestamp(...messages: any[]): void {
        if(process.env.NODE_ENV !== 'production') {
            const timestamp = new Date().toISOString();
            console.log(`[${timestamp}]`, ...messages);
        }
    }
}