import { lightTheme } from '@/customTheme'
import { useStyletron } from 'baseui'
import { useTranslation } from 'react-i18next'
import INSPIRATION_1 from '@assets/images/inspiration/1.png'
import INSPIRATION_2 from '@assets/images/inspiration/2.png'
import INSPIRATION_3 from '@assets/images/inspiration/3.png'
import INSPIRATION_4 from '@assets/images/inspiration/4.png'
import INSPIRATION_5 from '@assets/images/inspiration/5.png'
import INSPIRATION_6 from '@assets/images/inspiration/6.png'
import INSPIRATION_7 from '@assets/images/inspiration/7.png'
import INSPIRATION_8 from '@assets/images/inspiration/8.png'
import INSPIRATION_9 from '@assets/images/inspiration/9.png'

const DATAS = [
    {
        src: INSPIRATION_1,
        prompt: 'Dog with sunglasses in a spa'
    },
    {
        src: INSPIRATION_2,
        prompt: 'Closeup photo of a bald black woman model'
    },
    {
        src: INSPIRATION_3,
        prompt: 'Digital art of a couple cooking in an island'
    },
    {
        src: INSPIRATION_4,
        prompt: '3D render of an abstract shape in a blue and purple gradient'
    },
    {
        src: INSPIRATION_5,
        prompt: 'Illustration of a Surreal colorful desert'
    },
    {
        src: INSPIRATION_6,
        prompt: 'Armchair made of white paper cut'
    },
    {
        src: INSPIRATION_7,
        prompt: '"I Love You" text made of pink clouds on a blue sky'
    },
    {
        src: INSPIRATION_8,
        prompt: '"Happy Hour" text as a neon sign'
    },
    {
        src: INSPIRATION_9,
        prompt: 'A raccoon holding a sign saying "The Millers"'
    },

]

function Inspiration({setPrompt, style}) {
    const { t } = useTranslation()
    const [css] = useStyletron()
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', ...style }}>
            <h5 style={{ ...lightTheme.typography.Small14Semibold, color: lightTheme.colors.blackGray }}>
                {t('Inspiration')}
            </h5>
            <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                {DATAS.map((data, index) => {
                    return (
                        <div
                            key={index}
                            className={css({
                                position: 'relative',
                                width: '92px',
                                height: '92px',
                                borderRadius: '8px',
                                border: '1px solid rgba(0,0,0,0.1)',
                                overflow: 'hidden',
                                ':hover .overlay': {
                                    display: 'block!important',
                                },
                                cursor: 'pointer',
                            })}
                            onClick={() => {
                                setPrompt(data.prompt)
                                const aiPromptTextarea = document.getElementById('ai-image-textarea')
                                if(aiPromptTextarea) {
                                    aiPromptTextarea.focus()
                                }
                            }}
                        >
                            <img src={data.src} alt="inspiration" style={{ width: '100%', height: '100%' }} />
                            <div className="overlay" style={{ position: 'absolute', inset: 0, display: 'none', background: 'rgba(0,0,0,0.25)' }}></div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Inspiration
