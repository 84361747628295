import { IEditorContext } from '.'
import { ObjectType } from './common/constants'
import { EditorOptions } from './common/interfaces'
import Handlers from './handlers'
import EventManager from './utils/EventManager'
import objectToFabric from './utils/objectToFabric'
import { MediaImageRepository } from '@scenes/engine/objects/media-repository/media_image_repository'
import { MediaImageType } from '@scenes/engine/objects/media-repository/media_image_type'
import { fabric } from 'fabric'
import { nanoid } from 'nanoid'
import { CompositedRemoteAssetProviderCancellationToken, MediaResult, RemoteAssetProvider, RemoteResourceProvider, RemoteTextLoader } from './objects/asset-providers/RemoteAssetProvider'
import CanvasImageRenderer from '@/scenes/engine/utils/canvasImageRenderer'
import { findSizeId } from '../Editor/components/Navbar/components/Resize'
import { customAmplitude } from '@/utils/customAmplitude'
import { Size } from './objects/media-repository/size'
import { FrameOptions } from './objects'
import api from '@/services/api'
import * as Sentry from '@sentry/react'

class EditorEventManager extends EventManager {
  public handlers: Handlers
  public context: IEditorContext
  public canvasImageRenderer: CanvasImageRenderer

  compositedRemoteAssetProviderCancellationToken: CompositedRemoteAssetProviderCancellationToken
  constructor(props: EditorOptions) {
    super()
    this.context = props.context
    this.handlers = new Handlers({ ...props, editorEventManager: this })
    this.canvasImageRenderer = CanvasImageRenderer.getInstance();
  }

  public destroy = () => {
    this.handlers.destroy()
  }

  public undo = () => {
    const eventProperties = {
      Tool: 'bazaart.undo',
    }
    customAmplitude('Selected tool', eventProperties)
    this.handlers.transactionHandler.undo()
  }
  public redo = () => {
    const eventProperties = {
      Tool: 'bazaart.redo',
    }
    customAmplitude('Selected tool', eventProperties)
    this.handlers.transactionHandler.redo()
  }

  renderLayer(newElement: any, object: any) {
    if (!newElement) {
      console.log('UNABLE TO LOAD OBJECT: ', object)
      return null;
    }
    newElement.set('lockScalingFlip', true)
    if (newElement.type === ObjectType.STATIC_TEXT) {
      newElement.set('minScaleLimit', 0.9)
    }

    // element.set('bazaartGuid', object.bazaartGuid)
    // if (this.config.clipToFrame) {
    const frame = this.handlers.frameHandler.get()
    newElement.clipPath = frame

    // @ts-ignore
    let objIdx = this.handlers.canvasHandler.canvas.getObjects().map((obj) => obj.bazaartGuid).indexOf(object.bazaartGuid)
    let placeholderObj = this.handlers.canvasHandler.canvas.getObjects()[objIdx];
    this.handlers.canvasHandler.canvas.remove(placeholderObj);

    this.handlers.canvasHandler.canvas.add(newElement)
    if (newElement.type === ObjectType.BAZAART_BG) {
      this.emit('background:changed', {})
    }

    newElement.moveTo(objIdx)
  }

  async preloadAssets(
    templateObjects: any,
    templateLatestAssets: any,
    template: any,
    frameOptions: FrameOptions,
    cancellationToken: CompositedRemoteAssetProviderCancellationToken,
    hasClear = true): Promise<{ postProcessAssets: any, addedElementsById: any }> {

    let postProcessAssets = []
    let addedElementsById = {}
    let promises: Promise<boolean>[] = [];
    let fonts = this.getFonts(template);

    for (const object of templateObjects) {
      if (cancellationToken.isCancelled()) {
        break;
      }

      let assetStateId = nanoid()
      object.layerAssetStateId = assetStateId;

      let remoteAssetProvider: RemoteResourceProvider
      if (object.itemType == ObjectType.BAZAART_TEXT) {
        remoteAssetProvider = new RemoteTextLoader(template.templateId, object.bazaartGuid, assetStateId, object, fonts);
      } else {
        remoteAssetProvider = new RemoteAssetProvider(template.templateId, object.bazaartGuid, assetStateId, object, templateLatestAssets);
      }
      cancellationToken.addCancellationToken(remoteAssetProvider.remoteAssetProviderCancellationToken);


      let promise = remoteAssetProvider.downloadRequiredAsset().then((complexResult) => {
        if (!complexResult || cancellationToken.isCancelled()) {
          return;
        }
        let result = complexResult.parameters ?? complexResult;
        if (!result) {
          return;
        }

        postProcessAssets.push(result);

        if (result.object.itemType != ObjectType.BAZAART_TEXT) {
          // let placeholderObj = this.handlers.canvasHandler.canvas.getObjects()[objIdx];
          // this.handlers.canvasHandler.canvas.remove(placeholderObj);
          if (result.object.type === ObjectType.BAZAART_BG) {
            this.handlers.frameHandler.setBackgroundIdentifier(result.object);
          }
        }

        result.object.isLatest = true;
        // result.object.isTemplateLayer = false; // TODO: yadid why is this required?
        return objectToFabric.run(result.object, frameOptions).then(newElement => {
          if (cancellationToken.isCancelled()) {
            return null;
          }

          newElement.isIntializedNormalizeMask = false
          newElement.isIntializedNormalizedImage = false
          if (newElement.type === ObjectType.BAZAART_TEXT) {
            newElement.set({
              // @ts-ignore
              _originalHeight: newElement.height,
              _originalWidth: newElement.width,
              splitByGrapheme: true,
              isResized: true
            })
          }

          this.renderLayer(newElement, result.object)
          addedElementsById[newElement.bazaartGuid] = newElement
        }).catch(function (err) {
          console.error(`failed to load layer with error ${err}`);
        });

      });
      promises.push(promise);
    }

    return Promise.all(promises).then((results) => {
      if (cancellationToken.isCancelled()) {
        return { postProcessAssets: [], addedElementsById: {} }
      }
      this.handlers.frameHandler.removePlaceholderBgImage();
      this.handlers.zoomHandler.zoomToFit()

      if (template?.config?.hasOpacity) {
        this.handlers.frameHandler.setTransparentBg(false)
      }
      if (hasClear) {
        this.handlers.transactionHandler.clear()
      }

      return { postProcessAssets: postProcessAssets, addedElementsById: addedElementsById }
    });
  }

  async checkTrimedTransparency(mask: string, cancellationToken: CompositedRemoteAssetProviderCancellationToken): Promise<boolean> {
    // figuring out if a latest image has transparency is tricky because of various bugs we inherited from iOS projects that might appear through sync or templates
    // A latest image might not have been correctly trimmed so there’s is some minor opacity on its border, though it is an artifact and we’d like to ignore it
    // on the other hand there might be a scenario in which an image does have transparency in it even though it’s trimmed, for example a circle
    // this is a work around to perform a hard trim and then check if there there transparent pixels in the image.
    let trimedLatest = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.trim(mask, true, 254, cancellationToken.abortToken.signal)
    let isLatestHasTransparency = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.hasTransparency(trimedLatest)
    return isLatestHasTransparency
  }

  async loadAllAssets(template, postProcessAssets, addedElementsById, cancellationToken: CompositedRemoteAssetProviderCancellationToken) {
    let maskTypes: MediaImageType[] = [MediaImageType.mask, MediaImageType.fittedMask, MediaImageType.bzrtBgMask];
    let assets = await api.listAllTemplateAsset(template.templateId, cancellationToken.abortToken.signal);

    let nonTextAssets = postProcessAssets.filter(a => a.object.itemType != ObjectType.BAZAART_TEXT);
    var promises = [];

    const fixMask = async (downloadedItem: MediaResult): Promise<boolean> => {
      let object = downloadedItem?.object?.object;
      if (!object || !object.isTemplateLayer) {
        return false;
      }
      let objectId = object.bazaartGuid
      let layerAssetStateId = object.layerAssetStateId;

      let hasFitted = await MediaImageRepository.getInstance().hasRecord(objectId, layerAssetStateId, MediaImageType.fitted);
      let hasFittedMask = await MediaImageRepository.getInstance().hasRecord(objectId, layerAssetStateId, MediaImageType.fittedMask);
      if (!hasFittedMask || !hasFitted) {
        return false;
      }
      let fittedMask = await MediaImageRepository.getInstance().getImage(objectId, layerAssetStateId, MediaImageType.fittedMask)
      let hasTransparency = await this.checkTrimedTransparency(fittedMask, cancellationToken)
      if (hasTransparency) {
        let mask = await MediaImageRepository.getInstance().getImage(objectId, layerAssetStateId, MediaImageType.mask)
        let maskImage = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(mask);
        let boundingBox = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.extractBoundingBox(mask, true, null, cancellationToken.abortToken.signal)
        let relativeBB = boundingBox;
        relativeBB.x /= maskImage.width;
        relativeBB.width /= maskImage.width;
        relativeBB.y /= maskImage.height;
        relativeBB.height /= maskImage.height;

        addedElementsById[object.bazaartGuid].boundingBox = relativeBB;
        addedElementsById[object.bazaartGuid].hasTransparency = true;

        return true;
      }

      let fittedSrc = await MediaImageRepository.getInstance().getImage(objectId, layerAssetStateId, MediaImageType.fitted)
      let fittedImage = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(fittedSrc);
      let emptyMaskBase64 = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.createColorImage('#000000', fittedImage.width, fittedImage.height)
      let decodedMask = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.extractMask(emptyMaskBase64, null, false, cancellationToken.abortToken.signal)
      await MediaImageRepository.getInstance().storeImageBlobString(
        object.bazaartGuid,
        layerAssetStateId,
        MediaImageType.mask,
        decodedMask.blob
      )
      await MediaImageRepository.getInstance().storeImageBlobString(
        object.bazaartGuid,
        layerAssetStateId,
        MediaImageType.fittedMask,
        decodedMask.blob
      )
      addedElementsById[object.bazaartGuid].boundingBox = {x: 0, y: 0, width: 1, height: 1}
      addedElementsById[object.bazaartGuid].hasTransparency = false;

      return true;
    }

    for (let itemAsset of nonTextAssets) {
      if (cancellationToken.isCancelled()) {
        return null;
      }
      let remoteAssetProvider = new RemoteAssetProvider(template.templateId, itemAsset.object.bazaartGuid, itemAsset.layerAssetId, itemAsset, assets);
      cancellationToken.addCancellationToken(remoteAssetProvider.remoteAssetProviderCancellationToken);

      let promise = remoteAssetProvider.downloadAllAssets().then((allLoadedAssets) => {
        if (!allLoadedAssets || cancellationToken.isCancelled()) {
          return;
        }

        allLoadedAssets.forEach(async downloadedItem => {

          if (cancellationToken.isCancelled()) {
            return null;
          }

          let object = downloadedItem?.object?.object;
          if (!object) {
            return null;
          }
          let layerAssetStateId = object.layerAssetStateId;

          if (downloadedItem.asset.mediaItem == MediaImageType.fitted || downloadedItem.asset.mediaItem == MediaImageType.latest) {
            await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.resizeBase64ToMaxEdgeSize(downloadedItem.mediaBase64, 1280).then((resizedOriginal) => {
              return MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.normalizePngImage(resizedOriginal, cancellationToken.abortToken.signal).then(async (normalizedImage) => {
                await MediaImageRepository.getInstance().storeImageBase64(
                  object.bazaartGuid,
                  layerAssetStateId,
                  downloadedItem.asset.mediaItem,
                  normalizedImage
                )

                if (downloadedItem.asset.mediaItem == MediaImageType.fitted) {
                  await MediaImageRepository.getInstance().storeImageBase64(
                    object.bazaartGuid,
                    layerAssetStateId,
                    MediaImageType.original,
                    normalizedImage
                  )

                  this.handlers.objectsHandler.setIntializedNormalized(addedElementsById[object.bazaartGuid])

                  let fixedMask = await fixMask(downloadedItem);
                  if (fixedMask) {
                    this.handlers.objectsHandler.setIntializedNormalizeMask(addedElementsById[object.bazaartGuid])
                  }
                }
              })
            });

          }

          if (maskTypes.includes(downloadedItem.asset.mediaItem)) {
            //we need to extact binary mask(white/black) from tranparent mask (black/tranparent)
            MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.resizeBase64ToMaxEdgeSize(downloadedItem.mediaBase64, 1280).then((resizedMask) => {
            MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.extractMask(resizedMask, null, false, cancellationToken.abortToken.signal).then(async (decodedMask) => {
              
                let loadedFittedMask = await MediaImageRepository.getInstance().getImage(
                  object.bazaartGuid,
                  layerAssetStateId,
                  MediaImageType.fittedMask
                )
                let loadedMask = await MediaImageRepository.getInstance().getImage(
                  object.bazaartGuid,
                  layerAssetStateId,
                  MediaImageType.mask
                )

                if (!(loadedFittedMask || loadedMask)) {
                  await MediaImageRepository.getInstance().storeImageBlobString(
                    object.bazaartGuid,
                    layerAssetStateId,
                    downloadedItem.asset.mediaItem,
                    decodedMask.blob
                  )
                  if (downloadedItem.asset.mediaItem == MediaImageType.fittedMask) {
                    await MediaImageRepository.getInstance().storeImageBlobString(
                      object.bazaartGuid,
                      layerAssetStateId,
                      MediaImageType.mask,
                      decodedMask.blob
                    )
                    let fixedMask = await fixMask(downloadedItem);
                    if (fixedMask){
                      this.handlers.objectsHandler.setIntializedNormalizeMask(addedElementsById[object.bazaartGuid])
                    }
                  }
                } else {
                  this.handlers.objectsHandler.setIntializedNormalizeMask(addedElementsById[object.bazaartGuid])
                }
              })
            })
          }
        })
      });
      promises.push(promise);
    }
    return Promise.all(promises)
  }

  getFonts(config) {
    let fonts = [];
    if (config.fonts) {
      config.fonts.forEach(font => {
        fonts.push({
          name: font.keySystemName,
          url: font.keyFileURL,
          options: { weight: '400' },
        })
      })
    }
    const filteredFonts = fonts.filter(f => !!f.url)
    return filteredFonts;
  }

  async importFromJSON(template, abortSignal?: AbortSignal, isTemplate: boolean = true, hasClear = true) {
    if (abortSignal?.aborted) {
      return;
    }

    let compositedRemoteAssetProviderCancellationToken = this.compositedRemoteAssetProviderCancellationToken;
    compositedRemoteAssetProviderCancellationToken?.cancel()

    compositedRemoteAssetProviderCancellationToken = new CompositedRemoteAssetProviderCancellationToken(template.config.draftGuid as string);

    abortSignal?.addEventListener('abort', () => {
      compositedRemoteAssetProviderCancellationToken?.cancel();
    })

    this.compositedRemoteAssetProviderCancellationToken = compositedRemoteAssetProviderCancellationToken;

    const frameParams = template.config.originalCanvasSize
    const { sizeId, icon } = findSizeId(frameParams.width, frameParams.height, true)
    this.handlers.frameHandler.update({ ...frameParams, sizeId, icon }, false)


    const frameOptions = this.handlers.frameHandler.getOptions()

    let templateObjects = template.objects.map(t => Object.values(t)[0]).filter(x => x.isHidden != true);
    for (let obj of templateObjects) {
      // @ts-ignore
      let placeholderElement = new fabric.Rect({ opacity: 0, bazaartGuid: obj.bazaartGuid })
      this.handlers.canvasHandler.canvas.add(placeholderElement);
      if (obj.center !== undefined) {
        obj.centerPoint = obj.center;
        delete obj.center;
      }
    }

    if (isTemplate) {
      await this.loadTemplate(template, templateObjects, frameOptions, compositedRemoteAssetProviderCancellationToken, hasClear)
    } else {
      await this.loadProject(template, templateObjects, frameOptions, compositedRemoteAssetProviderCancellationToken, hasClear)
    }
  }

  private async loadProject(
    template,
    templateObjects,
    frameOptions,
    cancellationToken: CompositedRemoteAssetProviderCancellationToken,
    hasClear = true) {
    if (template?.config?.hasOpacity) {
      this.handlers.frameHandler.setTransparentBg(false)
    }
    for (let object of templateObjects) {
      if (object.itemType != ObjectType.BAZAART_TEXT) {
        if (object.type === ObjectType.BAZAART_BG) {
          this.handlers.frameHandler.setBackgroundIdentifier(object);
        }
        // ignore latest property for text  layers
        object.isLatest = true;
      }
      await objectToFabric.run(object, frameOptions).then(async newElement => {
        if (cancellationToken.isCancelled()) {
          return null;
        }
        this.renderLayer(newElement, object)
      }).catch(function (err) {
        console.error(`failed to load layer with error ${err}`);
      });
      setTimeout(() => {
        this.handlers.canvasHandler.canvas.requestRenderAll()
      });
    }
  }

  private async loadTemplate(
    template,
    templateObjects,
    frameOptions,
    compositedRemoteAssetProviderCancellationToken: CompositedRemoteAssetProviderCancellationToken,
    hasClear = true
  ) {

    this.preloadAssets(templateObjects, template.latestAssets, template.config, frameOptions, compositedRemoteAssetProviderCancellationToken, hasClear).then((preloadedAssets) => {

      setTimeout(() => {
        if (compositedRemoteAssetProviderCancellationToken.isCancelled()) {
          return;
        }
        this.handlers.objectsHandler.createReplaceableLayer()
      }, 100);

      let { postProcessAssets, addedElementsById } = preloadedAssets

      setTimeout(() => {
        this.loadAllAssets(template.config, postProcessAssets, addedElementsById, compositedRemoteAssetProviderCancellationToken);
      }, 1000);

      if (compositedRemoteAssetProviderCancellationToken.isCancelled()) {
        this.handlers.frameHandler.removePlaceholderBgImage()
      }
    });
  }

  public exportPNG = async (preventWatermark: boolean = false, format = 'png') => {
    return this.handlers.designHandler.toDataURL(null, preventWatermark, format)
  }
}

export default EditorEventManager
