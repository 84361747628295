import { lightTheme } from '@/customTheme'
import React, { useEffect, useRef, useState } from 'react'
import UploadsIcon from '../Editor/components/Icons/Uploads'
import { ThemeProvider } from 'baseui'
import { useTranslation } from 'react-i18next'
import { useStyletron } from 'styletron-react'
import { customAmplitude } from '@/utils/customAmplitude'
import { StatefulTooltip } from 'baseui/tooltip'
import RemoveBgImage from '@assets/images/remove-bg.png'
import RemoveObjectsImage from '@assets/images/remove-objects.jpg'
import MagicBackgroundImage from '@assets/images/magic-background.jpg'
import Icons from '../Editor/components/Icons'
import { useAppDispatch } from '@/store/store'
import { setOpenModalTryPremium } from '@/store/slices/user/actions'
import { selectUserIsPremium } from '@/store/slices/user/selectors'
import { useSelector } from 'react-redux'
// @ts-ignore
import UploadPhotoAnimation from '@assets/lottie/upload_photo.lottie'
import LottiePlayerWrapper from '@/libs/dotLottiePlayer/LottiePlayerWrapper'
import ScrollButton from './components/ScrollButton'
import RemoveBgIntro from '@assets/video/intro-remove-bg-thumb.mp4'
import RemoveObjectIntro from '@assets/video/intro-remove-thumb.mp4'
import MagicBgIntroThumb from '@assets/video/intro-magic-bg-thumb.mp4'

function DropZoneV4({ handleDropFiles }) {
  const inputFileRef = useRef<HTMLInputElement>(null)
  const dispatch = useAppDispatch()
  const hasPremium = useSelector(selectUserIsPremium)
  const [aciton, setAction] = useState<'remove-bg' | 'remove-obj' | 'magic-background' | 'enhance' | 'add'>(
    null
  )
  const [visibleScrollBtn, setVisibleScrollBtn] = useState({ left: false, right: false })
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleResize = () => {
      // 864 is the width of 4 items
      if (containerRef.current.clientWidth < 864) {
        containerRef.current.style.justifyContent = 'start'
        setVisibleScrollBtn({ left: false, right: true })
      } else {
        setVisibleScrollBtn({ left: false, right: false })
        containerRef.current.style.justifyContent = 'center'
      }
      if (containerRef.current.scrollLeft === 0) {
        setVisibleScrollBtn(pre => ({ ...pre, left: false }))
      } else {
        containerRef.current.style.justifyContent = 'start'
        setVisibleScrollBtn(pre => ({ ...pre, left: true }))
      }
    }
    handleResize()
    // Add the event listener
    window.addEventListener('resize', handleResize)

    // Remove the event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const handleScroll = (direction: 'left' | 'right') => {
    const widthMagicItem = document.querySelector('.magic-tool-item').clientWidth ?? 192
    const gap = 32
    let itemsVissible = Math.floor(containerRef.current.clientWidth / (widthMagicItem + gap))
    if (direction === 'left') {
      if (
        containerRef.current.scrollLeft + containerRef.current.clientWidth ===
        containerRef.current.scrollWidth
      ) {
        containerRef.current.scrollLeft -= containerRef.current.clientWidth
      } else {
        containerRef.current.scrollLeft -= (widthMagicItem + gap) * itemsVissible
      }
      const isReachEnd = containerRef.current.scrollLeft >= containerRef.current.scrollWidth
      setVisibleScrollBtn(pre => ({
        left: containerRef.current.scrollLeft < containerRef.current.clientWidth ? false : true,
        right: isReachEnd ? false : true,
      }))
    } else {
      let newValue = containerRef.current.scrollLeft + (widthMagicItem + gap) * itemsVissible
      containerRef.current.scrollLeft = newValue
      const isReachEnd = newValue + containerRef.current.clientWidth >= containerRef.current.scrollWidth
      setVisibleScrollBtn(pre => ({ left: true, right: isReachEnd ? false : true }))
    }
  }

  const handleMagicToolClick = (files: FileList) => {
    handleDropFiles(files, aciton)
  }

  const { t } = useTranslation()
  const [css] = useStyletron()

  const onClickUploadPhoto = () => {
    setAction('add')
    inputFileRef.current.click()
    customAmplitude('Create', {
      Source: 'Upload photo',
    })
  }
  const onClickRemoveBg = () => {
    setAction('remove-bg')
    inputFileRef.current.click()
    customAmplitude('Click', {
      Source: 'usecase.magic',
    })
  }

  const onClickRemoveObj = () => {
    // @ts-ignore
    window.dataLayer.push({ event: 'remove_from_create_page' })
    if (!hasPremium) {
      dispatch(
        setOpenModalTryPremium({
          isOpen: true,
          source: 'BtSubscriptionHeal',
          callback: () => {
            setAction('remove-obj')
            inputFileRef.current.click()
            customAmplitude('Click', {
              Source: 'usecase.remove',
            })
          },
          opened: true,
        })
      )
      const eventProperties = {
        Source: 'BtSubscriptionHeal',
        Type: 'Standard',
      }
      // @ts-ignore
      window.dataLayer.push({ event: 'premium_prompt', ...eventProperties })
      customAmplitude('Premium Prompt', eventProperties)
    } else {
      setAction('remove-obj')
      inputFileRef.current.click()
      customAmplitude('Click', {
        Source: 'usecase.remove',
      })
    }
  }
  const onClickMagicBg = () => {
    setAction('magic-background')
    inputFileRef.current.click()
    // @ts-ignore
    window.dataLayer.push({ event: 'magic_bg_click_from_create_page' })
    customAmplitude('Click', {
      Source: 'usecase.magicbg',
    })
  }
  return (
    <ThemeProvider theme={lightTheme}>
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '350px',
          borderRadius: '16px',
          overflow: 'hidden',
          padding: '54px 32px',
          background: 'linear-gradient(241deg, #FF0861 4.79%, #E30693 46.6%, #C604C9 88.41%)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '32px',
        }}
      >
        <h1
          style={{
            ...lightTheme.typography.Title32Bold,
            color: lightTheme.colors.white,
            lineHeight: '36px'
          }}
          className="title"
        >
          {t('What will you design now?')}
        </h1>
        <div style={{ display: 'flex', gap: '32px', flexDirection: 'row', overflow: 'hidden', scrollBehavior: 'smooth', width: '100%', height: '174px', justifyContent: 'center' }} ref={containerRef}>
          <WrapButtonAction
            title="Upload photo"
            content="Upload your photo or simply drag and drop"
            handleClick={onClickUploadPhoto}
            isPremiumContent={false}
            imageSrc={UploadPhotoAnimation}
            hasAnimation={true}
          ></WrapButtonAction>
          <WrapButtonAction
            title="Remove background"
            content="Remove the background from any photo"
            handleClick={onClickRemoveBg}
            isPremiumContent={false}
            imageSrc={RemoveBgImage}
            videoSrc={RemoveBgIntro}
          ></WrapButtonAction>
          <WrapButtonAction
            title="Magic background"
            content="Create stunning AI backgrounds"
            handleClick={onClickMagicBg}
            isPremiumContent={false}
            imageSrc={MagicBackgroundImage}
            videoSrc={MagicBgIntroThumb}
          ></WrapButtonAction>
          <WrapButtonAction
            title="Remove objects"
            content="Easily remove people and objects."
            handleClick={onClickRemoveObj}
            isPremiumContent={!hasPremium}
            imageSrc={RemoveObjectsImage}
            videoSrc={RemoveObjectIntro}
          ></WrapButtonAction>
        </div>
        <input
          onChange={e => handleMagicToolClick(e.target.files)}
          type="file"
          ref={inputFileRef}
          style={{ display: 'none' }}
          accept="image/png, image/jpg, image/jpeg"
          id="input-ref-on-create-page"
        />
                  {visibleScrollBtn.left ? (
            <ScrollButton
              direction="left"
              handle={() => handleScroll('left')}
              styles={{ left: '12px', top: '189px' }}
            />
          ) : null}
          {visibleScrollBtn.right ? (
            <ScrollButton
              direction="right"
              handle={() => handleScroll('right')}
              styles={{ right: '12px', top: '189px' }}
            />
          ) : null}
      </div>
    </ThemeProvider>
  )
}

export default DropZoneV4

function WrapButtonAction({ title, content, imageSrc, videoSrc = null, handleClick, isPremiumContent, hasAnimation = false }) {
  const { t } = useTranslation()
  const [isHover, setIsHover] = useState(false)
  const [css] = useStyletron()
  return (
    <StatefulTooltip
      onMouseEnterDelay={100}
      onMouseLeaveDelay={100}
      accessibilityType={'tooltip'}
      content={content}
      showArrow
      placement="bottom"
      popoverMargin={4}
      overrides={{
        Inner: {
          style: ({ $theme }) => ({
            fontFamily: $theme.typography.font250.fontFamily,
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '16px',
            backgroundColor: lightTheme.colors.blackGray,
            padding: '9px 14px',
          }),
        },
        Body: {
          style: ({ $theme }) => ({
            zIndex: 110,
            backgroundColor: lightTheme.colors.blackGray,
            width: '170px',
            textAlign: 'center',
          }),
        },
        Arrow: {
          style: ({ $theme }) => ({
            zIndex: 110,
            backgroundColor: lightTheme.colors.blackGray,
          }),
        },
      }}
      triggerType="hover"
      // @ts-ignore
      onMouseEnter={() => {
        setIsHover(true)
      }}
      // @ts-ignore
      onMouseLeave={() => {
        setIsHover(false)
      }}
    >
      <div
        className={`${css({
          position: 'relative',
          width: '192px',
          minWidth: '192px',
          height: '174px',
          padding: '16px',
          background: lightTheme.colors.white,
          borderRadius: '16px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '16px',
          flexDirection: 'column',
          cursor: 'pointer',
          ':hover': {
            boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.12)',
          }
        })} magic-tool-item`}
        onClick={handleClick}
      >
        {hasAnimation ? (
          <div
            style={{
              width: '100%',
              height: '102px',
              background: lightTheme.colors.grayScale50,
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <LottiePlayerWrapper
              play={isHover}
              src={imageSrc}
              loop
              style={{
                width: '56px',
                height: '56px',
              }}
            ></LottiePlayerWrapper>
          </div>
        ) : (
          <div style={{ width: '100%', height: '102px', borderRadius: '8px', position: 'relative' }} >
            {isHover ? (
              <video
                src={videoSrc}
                width="100%"
                height="100%"
                style={{
                  position: 'absolute',
                  inset: 0,
                  zIndex: 2,
                  borderRadius: '8px',
                  objectFit: 'cover',
                }}
                autoPlay
                muted
                loop
                poster={imageSrc}
              >
                <source src={videoSrc} type='video/mp4; codecs=hevc"' />
              </video>
            ) : (
              <></>
            )}
            <img src={imageSrc} alt="thumb" style={{ width: '100%', height: '100%', borderRadius: '8px', objectFit: 'cover', }} />
          </div>
        )}

        <p style={{ ...lightTheme.typography.Small14Semibold }}>{t(title)}</p>
        {isPremiumContent ? (
          <div style={{ position: 'absolute', top: '7px', right: '7px' }}>
            <Icons.PremiumIcon />
          </div>
        ) : null}
      </div>
    </StatefulTooltip>
  )
}
