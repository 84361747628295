/**
 * Returns the number of transparent pixels
 * @memberof Image
 * @instance
 * @param {object} [options]
 * @param {number} [options.alpha=1] - Value of the alpha value to count.
 * @return {number} Number of transparent pixels
 */
export default function hasTransparency(options = {}) {
    this.checkProcessable('countAlphaPixels', {
      bitDepth: [8, 16]
    });

    const channel = this.channels - 1;
    for (let y = this.height - 1; y >= 0; y--) {
      for (let x = 0; x < this.width; x++) {
        // if (signal?.aborted) return -1;
        if (compare(this, x, y, channel, 127)) {
          return true;
        }
      }
    }
    return false
}

function compare(image, x, y, channel, threshold) {
  let value = image.getPixelXY(x, y)[channel];
  return value > threshold;
}