import { useEffect, useRef } from 'react'
import BackgroundImage from '@assets/images/bg_login.jpg'
import Icons from '../../../Icons'
import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { KIND } from 'baseui/button'
import { lightTheme } from '@/customTheme'
import { useAppDispatch } from '@store/store'
import { setOpenModalTryPremium, setUser } from '@/store/slices/user/actions'
import { SignInManager } from '../SignInManager'
import { useTranslation } from 'react-i18next'
import { customAmplitude } from '@/utils/customAmplitude'
import { useLocation } from 'react-router-dom'
import LogoIcon from '@assets/images/logo_only.svg'
import useAppContext from '@/hooks/useAppContext'
import { captureException } from '@sentry/react'
import { useSelector } from 'react-redux'
import { selectOpenModalTryPremium } from '@/store/slices/user/selectors'
import { ShowModalType } from '@/scenes/engine/common/constants'

function SignIn1st({ isOpen, close, setLoggin, handleOpenTutorial }) {
  const inputRef = useRef<HTMLDivElement>(null)
  const openModalTryPremium = useSelector(selectOpenModalTryPremium)
  const { setShowModalType } = useAppContext()
  useEffect(() => {
    SignInManager.getInstance().setupUI(inputRef.current)
  }, []) // Note the empty dependency array

  const location = useLocation()

  useEffect(() => {
    if(isOpen){
      customAmplitude('Sign up Prompt', {
        Source: 'signup-1st',
      })
    }
  }, [isOpen]) // Note the empty dependency array

  const login = async social => {
    let user

    try {
      if (social === 'facebook') {
        user = await SignInManager.getInstance().loginWithFacebook()
      } else if (social === 'google') {
        user = await SignInManager.getInstance().loginWithGoogle()
      } else if (social === 'apple') {
        user = await SignInManager.getInstance().loginWithApple()
      } else {
        return
      }
    }
    catch (error) {
      captureException(error, {
        level: 'fatal',
        extra: { customMessage: 'Social login error' },
        tags: { module: 'login' },
      });
      customAmplitude('Sign up error', {
        error: 'Social login error'
      })
      return
    }

    setLoggin(!!user)
    if (!!user) {
      dispatch(setUser(user))
      if (user.userIsPremium) {
        dispatch(
          setOpenModalTryPremium({
            ...openModalTryPremium,
            isOpen: false,
          })
        )
        openModalTryPremium.callback && openModalTryPremium.callback()
      } else {
        if (openModalTryPremium.source) {
          dispatch(
            setOpenModalTryPremium({
              ...openModalTryPremium,
              isOpen: true,
            })
          )
        }
      }
      if(user.new_user) {
        setShowModalType(ShowModalType.ONBOARDING)
      } else {
        setShowModalType(null)
      }
    } else {
      setShowModalType(ShowModalType.ONBOARDING)
    }
  }

  const dispatch = useAppDispatch()
  const { t } = useTranslation()  
  return (<>
    <div
      id="signin"
      style={{
        width: '100vw',
        height: '100vh',
        maxWidth: '920px ',
        maxHeight: '652px',
        flexDirection: 'row',
        borderRadius: '16px',
        overflow: 'hidden',
        position: 'absolute',
        display: 'flex'
      }}
    >
      <div
        ref={inputRef}
        style={{
          width: '50%',
          padding: '102px 64px',
          background: 'rgba(255, 255, 255, 1)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <img 
         alt="logo"
         style={{
           width: 'fite-content',
           height: '54px',
         }}
        src={LogoIcon}/>

        <h2 style={{ marginBottom: 0, marginTop: '32px', ...lightTheme.typography.Header26bold }}>
          {t('Welcome to Bazaart')}
        </h2>
        <h3 style={{ marginBottom: 0, marginTop: '8px', ...lightTheme.typography.Small14regular }}>
          {t('Join a million+ people who create professional-looking designs every month.')}
        </h3>

        <ButtonCustom
          type={SizeButton.LARGE}
          kind={KIND.secondary}
          style={{
            position: 'relative',
            ...lightTheme.typography.Small14Semibold,
            color: lightTheme.colors.blackGray,
            borderRadius: '100px',
            marginBottom: 0,
            marginTop: '32px',
          }}
          onClick={async () => {
            await login('google')
          }}
        >
          <Icons.GGIcon style={{ position: 'absolute', left: '12px' }} />
          <>{t('Continue with Google')}</>
        </ButtonCustom>
        <ButtonCustom
          type={SizeButton.LARGE}
          kind={KIND.secondary}
          style={{
            position: 'relative',
            ...lightTheme.typography.Small14Semibold,
            background: 'rgba(57, 117, 234, 1)',
            color: '#fff',
            borderRadius: '100px',
            marginBottom: 0,
            marginTop: '16px',
            ':hover': {
              background: 'rgba(51, 105, 211, 1)',
            },
          }}
          onClick={async () => {
            await login('facebook')
          }}
        >
          <Icons.FbIcon
            style={{
              position: 'absolute',
              left: '12px',
            }}
          />
          <>{t('Continue with Facebook')}</>
        </ButtonCustom>
        <ButtonCustom
          type={SizeButton.LARGE}
          kind={KIND.secondary}
          style={{
            position: 'relative',
            ...lightTheme.typography.Small14Semibold,
            background: lightTheme.colors.blackGray,
            color: '#fff',
            borderRadius: '100px',
            marginBottom: 0,
            marginTop: '16px',
            ':hover': {
              background: 'rgba(26, 26, 26, 1)',
            },
          }}
          onClick={async () => {
            await login('apple')
          }}
        >
          <Icons.AppleIcon style={{ position: 'absolute', left: '12px' }} />
          <>{t('Continue with Apple')}</>
        </ButtonCustom>
        <h4
          style={{
            marginBottom: 0,
            marginTop: '16px',
            ...lightTheme.typography.Small12medium,
            color: 'rgba(153, 153, 153, 1)',
            fontWeight: 400,
            padding: '0 16px',
          }}
        >
          {t('By signing up, you agree to our')}{' '}
          <span
            style={{ fontWeight: 500, cursor: 'pointer' }}
            onClick={() => {
              window.open('https://www.bazaart.me/terms/#page', '_blank')
            }}
          >
            {t('Terms of service')}
          </span>{' '}
          {t('and')}{' '}
          <span
            style={{ fontWeight: 500, cursor: 'pointer' }}
            onClick={() => {
              window.open('https://www.bazaart.me/terms/#privacy', '_blank')
            }}
          >
            {t('Privacy policy')}
          </span>
        </h4>
      </div>
      <div style={{ width: '50%' }}>
        <img src={BackgroundImage} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </div>
      <div
        style={{
          position: 'absolute',
          top: '16px',
          right: '16px',
          width: '32px',
          height: '32px',
          borderRadius: '50%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => {
          customAmplitude('Sign up closed')
          setShowModalType(ShowModalType.ONBOARDING)
        }}
      >
        <Icons.WhiteCloseIcon size={24} />
      </div>
    </div>
  </>)
}

export default SignIn1st