import useAppContext from '@/hooks/useAppContext'
import ModalCustom from '@/scenes/Editor/components/Modal/Modal'
import { useEditorContext, useRemoveEditorContext } from '@/scenes/engine'
import { ShowModalType } from '@/scenes/engine/common/constants'
import { customAmplitude } from '@/utils/customAmplitude'
import { clearBrushAnimation, clearTutorial } from '@/utils/tutorial'
import { useTour } from '@reactour/tour'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { InspectorPanelType, useInspector } from '@/scenes/Editor/components/Toolbox/InspectorDataProvider'

const ModalSkipTutorial = () => {
  const { showModalType, setShowModalType, setIsOpenTutorial, cancelPixelManipulation } = useAppContext()
  const { t } = useTranslation()
  const { setIsOpen, currentStep, setCurrentStep, setSteps } = useTour()
  const history = useHistory()
  const { editor } = useEditorContext()
  const removeEditor = useRemoveEditorContext().editor
  const { hideInspector } = useInspector()


  const handleCloseModal = () => {
    const rootElement = document.getElementById('root')
    const descElm = document.getElementById('desc-remove-obj-clone')
    if (descElm) {
      rootElement.removeChild(descElm)
    }
    let showedRemoveObjectTutorial = localStorage.getItem('showedRemoveObjectTutorial') ? JSON.parse(localStorage.getItem('showedRemoveObjectTutorial')) : false
    const isFromCreatePage = showedRemoveObjectTutorial && showedRemoveObjectTutorial.from !== '/'
    if(isFromCreatePage) {
      history.push(showedRemoveObjectTutorial.from)
    } else {
      // pixel manipulation is opening
      if(currentStep > 0) {
        removeEditor.handlers.pixelManipulationObjectHandler.FinishToolState().then(() => {
          removeEditor.handlers.pixelManipulationObjectHandler.reset()
          removeEditor.handlers.canvasRemoveHandler.canvas.clear()
          const cursorPreview = document.querySelector('.custom-cursor') as HTMLElement;
          cursorPreview.style.display = 'none'
          cancelPixelManipulation()
          clearTutorial(editor.handlers, isFromCreatePage)
          editor.handlers.scrollbarHandler.updateScrollPosition()
          const editorCanvas = document.getElementById('uibox-editor-container')
          editorCanvas.style.zIndex = '1'
          let canvasRemoveTool = document.getElementById('wrap-canvas-remove-tool')
          if (canvasRemoveTool) {
            canvasRemoveTool.style.zIndex = '-1'
          }
          removeEditor.handlers.eventsRemoveHandler.destroy();
        })
      } else {
        clearTutorial(editor.handlers, isFromCreatePage)
      }
      hideInspector(InspectorPanelType.RemoveObjects)
    }
    clearBrushAnimation()
    setIsOpenTutorial(false)
    setShowModalType(null)
    setIsOpen(false)
    setSteps([])
    const containerElement = document.querySelector(
      '#wrap-canvas-remove-tool .remove-container-class'
    ) as HTMLElement
    containerElement.style.borderRadius = '0px'  
    customAmplitude('Tutorial Completed', { 'Tutorial Name': 'Remove', State: 'Canceled' })
    // hideInspector(InspectorPanelType.RemoveObjects)
  }

  const handleContinueTutorial = () => {
    setShowModalType(null)
    setIsOpen(true)
    setCurrentStep(currentStep)
    const rootElement = document.querySelector('#root') as HTMLElement
    const descElm = document.getElementById('desc-remove-obj-clone')
    if (descElm) {
      rootElement.removeChild(descElm)
    }
  }

  return (
    <ModalCustom
      isOpen={showModalType === ShowModalType.CANCEL_TUTORIAL}
      onClose={() => handleContinueTutorial()}
      content={{
        header: t('Skip tutorial'),
        desc: t('Are you sure you want to skip this tutorial?'),
      }}
      action={{
        primary: {
          name: t('Continue tutorial'),
          handle: () => {
            handleContinueTutorial()
          }
        },
        secondary: {
          name: t('Skip tutorial'),
          handle: () => handleCloseModal(),
        },
      }}
    ></ModalCustom>
  )
}

export default ModalSkipTutorial