import { lightTheme } from '@/customTheme'
import { styled, useStyletron } from 'styletron-react'
import Icons from '../Editor/components/Icons'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectUser } from '@/store/slices/user/selectors'
import { useAppDispatch } from '@/store/store'
import { setOpenModalTryPremium } from '@/store/slices/user/actions'
import { customAmplitude } from '@/utils/customAmplitude'

function Sidebar() {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const user = useSelector(selectUser)
  const dispatch = useAppDispatch()

  const handleReactive = () => {
    dispatch(setOpenModalTryPremium({
      isOpen: true,
      source: 'Comeback',
      callback: () => {
      }
    }))
    const eventProperties = {
      Source: 'Comeback',
      Type: 'Standard',
    }
    customAmplitude('Premium Prompt', eventProperties)
    // @ts-ignore
    window.dataLayer.push({ event: 'premium_prompt', ...eventProperties })    
  }

  return (
    <div
      style={{
        height: '100%',
        width: '260px',
        padding: '16px',
        borderRight: '1px solid #e5e5e5',
        position: 'fixed',
        top: '64px',
        left: 0,
        zIndex: 1,
        backgroundColor: '#fff',
      }}
    >
      <SidebarItem name="Home" icon={<Icons.HouseIcon />} handle={() => history.push('/create')} active={!location.pathname.includes('projects')} />
      <SidebarItem name="Projects" icon={<Icons.ProjectsIcon />} handle={() => history.push('/create/projects')} active={location.pathname.includes('projects')} />
      {user && !user.userIsPremium && user.userHadAFreeTrial ? <div style={{
        background: lightTheme.colors.grayScale50,
        borderRadius: '16px',
        bottom: '16px',
        left: '24px',
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 0,
        marginTop: '24px'
      }}>
        <p style={{...lightTheme.typography.Small14Semibold, color: lightTheme.colors.blackGray, textAlign: 'center'}}>{t('Come back to Bazaart Premium')}</p>
        <p style={{...lightTheme.typography.Small12regular, color: lightTheme.colors.blackGray, lineHeight: '20px', textAlign: 'center', marginTop: '4px'}}>{t('Get extra design superpowers!')}</p>
        <ButtonGetTheApp onClick={handleReactive}>{t('Reactivate now')}</ButtonGetTheApp>
      </div> : null}
    </div>
  )
}

export default Sidebar

function SidebarItem({ name, icon, handle, active }) {
  const [css] = useStyletron()
  const { t } = useTranslation()
  return (
    <div
      className={css({
        display: 'flex',
        padding: '8px',
        flexDirection: 'row',
        gap: '8px',
        alignItems: 'center',
        width: '100%',
        height: '48px',
        borderRadius: '8px',
        cursor: 'pointer',
        marginBottom: '8px',
        backgroundColor: active ? lightTheme.colors.grayScale100 : 'transparent',
        ':hover': {
            backgroundColor: lightTheme.colors.grayScale50,
        },
        ':active': {
            backgroundColor: lightTheme.colors.grayScale100,
        },
      })}
      onClick={handle}
    >
      {icon}
      <p style={{ ...lightTheme.typography.Small14regular, color: lightTheme.colors.blackGray }}>{t(name)}</p>
    </div>
  )
}

const ButtonGetTheApp = styled('div', (props: any) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '8px 16px',
  height: '40px',
  width: '100%',
  borderRadius: '20px',
  cursor: 'pointer',
  ...lightTheme.typography.Small14Semibold,
  color: '#fff',
  transition: 'all 0.3s',
  marginTop: '16px',
  backgroundColor: lightTheme.colors.blackGray,
  ':hover': {
    backgroundColor: lightTheme.colors.grayScale800,
  },
  ':active': {
    backgroundColor: lightTheme.colors.grayScale700,
  },
}))
