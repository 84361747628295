import { fabric } from 'fabric'
import { drawCircleIcon } from '../../utils/drawer';
// @ts-ignore
const { scaleCursorStyleHandler } = fabric.controlsUtils; 

export function calcScale(newPoint: { x: number, y: number }, height: number, width: number, flipX?: boolean, flipY?: boolean) {
    const scaleX = Math.abs(newPoint.x / width);
    const scaleY = Math.abs(newPoint.y / height);
    if (flipX || flipY) {
        return Math.max(scaleX, scaleY);
    }
    return Math.min(scaleX, scaleY);
}

// @ts-ignore
function updateEditingImage(target, fullWidth, fullHeight) {
    const angleInRadians = fabric.util.degreesToRadians(target.angle || 0);
    const sinAngle = Math.sin(angleInRadians);
    const cosAngle = Math.cos(angleInRadians);

    // Calculate the top-left corner of the editing image based on rotation
    const offsetX = target.flipX 
        ? (fullWidth - target.width - target.cropX) * target.scaleX 
        : target.cropX * target.scaleX;

    const offsetY = target.flipY 
        ? (fullHeight - target.height - target.cropY) * target.scaleY 
        : target.cropY * target.scaleY;

    const rotatedOffsetX = offsetX * cosAngle - offsetY * sinAngle;
    const rotatedOffsetY = offsetX * sinAngle + offsetY * cosAngle;

    const newLeft = target.left - rotatedOffsetX;
    const newTop = target.top - rotatedOffsetY;

    target.__editingImage.set({
        width: fullWidth,
        height: fullHeight,
        scaleX: target.scaleX,
        scaleY: target.scaleY,
        left: newLeft,
        top: newTop,
    });

    target.__editingImage.setCoords();
    target.__editingImage.__setSourceControlsVisibility()
}

function getBoundedScale(target, unboundedScale, top=false, left=false) {
  const currentScale = target.scaleX;
  const fullWidth = target.__editingImage.width;
  const fullHeight = target.__editingImage.height;
  const targetWidth = target.width;
  // fullWidth is containing the following: target.cropX + target.width + (remainderX)
  // if scaling the full image would end up that within the image
  const targetHeight = target.height;
  const remainderX = fullWidth - targetWidth - target.cropX;
  const remainderY = fullHeight - targetHeight - target.cropY;
  
  // Calculate the change factor between current and unbounded scale
  let scaleChangeUnbounded = unboundedScale / currentScale;
  
  // Calculate what dimensions would be with unbounded scale
  let tentativeWidth = targetWidth / scaleChangeUnbounded;
  let tentativeHeight = targetHeight / scaleChangeUnbounded;
  let tentativeRemainderX = Math.max(0, remainderX / scaleChangeUnbounded)
  let tentativeRemainderY = Math.max(0, remainderY / scaleChangeUnbounded)
  
  // Calculate crop positions with unbounded scale
  let tentativeCropX = Math.max(0, fullWidth - tentativeWidth - tentativeRemainderX)
  let tentativeCropY = Math.max(0, fullHeight - tentativeHeight - tentativeRemainderY)

  if (tentativeCropX < 0) {
    tentativeRemainderX = Math.max(0, fullWidth - tentativeWidth)
  }
  if (tentativeCropY < 0) {
    tentativeRemainderY = Math.max(0, fullHeight - tentativeHeight)
  }

  const minWidthScale = (targetWidth + (left ? remainderX : 0) + (!left ? target.cropX : 0)) / fullWidth * currentScale;
  const minHeightScale = (targetHeight + (top ? remainderY : 0) + (!top ? target.cropY : 0)) / fullHeight * currentScale;
  return Math.max(unboundedScale, minWidthScale, minHeightScale)
}

function scaleEquallyCropTL(eventData, transform, x, y) {
    const { target } = transform;
    const fullWidth = target.__editingImage.width;
    const fullHeight = target.__editingImage.height;
    const remainderX = fullWidth - target.width - target.cropX;
    const remainderY = fullHeight - target.height - target.cropY;
    const anchorOriginX = 1 + (remainderX / target.width);
    const anchorOriginY = 1 + (remainderY / target.height);
    const centerPoint = target.getCenterPoint();
    const constraint = target.translateToOriginPoint(centerPoint, anchorOriginX, anchorOriginY);
    // @ts-ignore
    let newPoint = fabric.controlsUtils.getLocalPoint(transform, anchorOriginX, anchorOriginY, x, y);

    let scale = calcScale(newPoint, fullHeight, fullWidth, target.flipX, target.flipY);
    scale = getBoundedScale(target, scale, true, true)

    const scaleChangeX = scale / target.scaleX;
    const scaleChangeY = scale / target.scaleY;
    const scaledRemainderX = remainderX / scaleChangeX;
    const scaledRemainderY = remainderY / scaleChangeY;
    const newWidth = target.width / scaleChangeX;
    const newHeight = target.height / scaleChangeY;
    const newCropX = fullWidth - newWidth - scaledRemainderX;
    const newCropY = fullHeight - newHeight - scaledRemainderY;
  
    target.scaleX = scale;
    target.scaleY = scale;
    target.width = newWidth;
    target.height = newHeight;
    target.cropX = newCropX;
    target.cropY = newCropY;
    if (target.clippingPath) {
      target.clippingPath.scaleX /= scaleChangeX;
      target.clippingPath.scaleY /= scaleChangeY;
    }
    const newAnchorOriginX = 1 + (scaledRemainderX / newWidth);
    const newAnchorOriginY = 1 + (scaledRemainderY / newHeight);
    target.setPositionByOrigin(constraint, newAnchorOriginX, newAnchorOriginY);
    updateEditingImage(target, fullWidth, fullHeight)  
    return true;
}
  // @ts-ignore
export function scaleEquallyCropTLFlip(eventData, transform, x, y) {
    const { target } = transform;
    const fullWidth = target.__editingImage.width
    const fullHeight = target.__editingImage.height
    const extraOriginX = 1 + (target.cropX / target.width);
    const extraOriginY = 1 + (target.cropY / target.height);
    const centerPoint = target.getCenterPoint();
    const constraint = target.translateToOriginPoint(centerPoint, extraOriginX, extraOriginY);
    // @ts-ignore
    const newPoint = fabric.controlsUtils.getLocalPoint(transform, extraOriginX, extraOriginY, x, y);
    const oldScaleX = target.scaleX;
    const oldScaleY = target.scaleY;
    let scale = calcScale(newPoint, fullHeight, fullWidth, target.flipX, target.flipY);
    scale = getBoundedScale(target, scale, false, false)
    const scaleChangeX = scale / oldScaleX;
    const scaleChangeY = scale / oldScaleY;
    const newWidth = target.width / scaleChangeX;
    const newHeight = target.height / scaleChangeY;
    const newCropX = target.cropX / scaleChangeX;
    const newCropY = target.cropY / scaleChangeY;
  
    target.scaleX = scale;
    target.scaleY = scale;
  
    target.height = newHeight;
    target.width = newWidth;
    target.cropX = newCropX;
    target.cropY = newCropY;
    if (target.clippingPath) {
      target.clippingPath.scaleX /= scaleChangeX;
      target.clippingPath.scaleY /= scaleChangeY;
    }
    const newExtraOriginX = 1 + (target.cropX / target.width);
    const newExtraOriginY = 1 + (target.cropY / target.height);
    target.setPositionByOrigin(constraint, newExtraOriginX, newExtraOriginY);

    updateEditingImage(target, fullWidth, fullHeight)
    return true;
  }  

  // @ts-ignore
  export function scaleEquallyCropTR(eventData, transform, x, y) {
    const { target } = transform;
    const fullWidth = target.__editingImage.width
    const fullHeight = target.__editingImage.height
    const remainderY = fullHeight - target.height - target.cropY;
    const remainderX = fullWidth - target.width - target.cropX;
    const anchorOriginX = target.cropX / target.width;
    const anchorOriginY = remainderY / target.height;
    const centerPoint = target.getCenterPoint();
    const constraint = target.translateToOriginPoint(centerPoint, -anchorOriginX, 1 + anchorOriginY);
    // @ts-ignore
    const newPoint = fabric.controlsUtils.getLocalPoint(transform, -anchorOriginX, 1 + anchorOriginY, x, y);
    const oldScaleX = target.scaleX;
    const oldScaleY = target.scaleY;
    let scale = calcScale(newPoint, fullHeight, fullWidth, target.flipX, target.flipY);
    scale = getBoundedScale(target, scale, true, false)
    const scaleChangeX = scale / oldScaleX;
    const scaleChangeY = scale / oldScaleY;
    const newWidth = target.width / scaleChangeX;
    const newHeight = target.height / scaleChangeY;
    const newRemainderY = remainderY / scaleChangeY;
    const newCropX = target.cropX / scaleChangeX;
    const newCropY = fullHeight - newHeight - newRemainderY;
  
    target.scaleX = scale;
    target.scaleY = scale;
    target.height = newHeight;
    target.width = newWidth;
    target.cropX = newCropX;
    target.cropY = newCropY;
    if (target.clippingPath) {
      target.clippingPath.scaleX /= scaleChangeX;
      target.clippingPath.scaleY /= scaleChangeY;
    }
    const newExtraAnchorY = newRemainderY / target.height;
    const newExtraAnchorX = target.cropX / target.width;
    target.setPositionByOrigin(constraint, -newExtraAnchorX, 1 + newExtraAnchorY);

    updateEditingImage(target, fullWidth, fullHeight)
    return true;
  }
  
  // @ts-ignore
  export function scaleEquallyCropTRFlip(eventData, transform, x, y) {
    const { target } = transform;
    const fullWidth = target.__editingImage.width
    const fullHeight = target.__editingImage.height
    const remainderX = fullWidth - target.width - target.cropX;
    const anchorOriginX = remainderX / target.width;
    const anchorOriginY = target.cropY / target.height;
    const centerPoint = target.getCenterPoint();
    const constraint = target.translateToOriginPoint(centerPoint, -anchorOriginX, 1 + anchorOriginY);
    // @ts-ignore
    const newPoint = fabric.controlsUtils.getLocalPoint(transform, -anchorOriginX, 1 + anchorOriginY, x, y);
    const oldScaleX = target.scaleX;
    const oldScaleY = target.scaleY;
    let scale = calcScale(newPoint, fullHeight, fullWidth, target.flipX, target.flipY);
    scale = getBoundedScale(target, scale, false, true)
    const scaleChangeX = scale / oldScaleX;
    const scaleChangeY = scale / oldScaleY;
    const newWidth = target.width / scaleChangeX;
    const newHeight = target.height / scaleChangeY;
    const scaledRemainderX = remainderX / scaleChangeX;
    const newCropX = fullWidth - newWidth - scaledRemainderX;
    const newCropY = target.cropY / scaleChangeY;
  
    target.scaleX = scale;
    target.scaleY = scale;
    target.height = newHeight;
    target.width = newWidth;
    target.cropX = newCropX;
    target.cropY = newCropY;
    if (target.clippingPath) {
      target.clippingPath.scaleX /= scaleChangeX;
      target.clippingPath.scaleY /= scaleChangeY;
    }
    const newAnchorOriginX = scaledRemainderX / newWidth;
    const newAnchorOriginY = newCropY / newHeight;
    target.setPositionByOrigin(constraint, -newAnchorOriginX, 1 + newAnchorOriginY);

    updateEditingImage(target, fullWidth, fullHeight)
    return true;
  }
  
  // @ts-ignore
  export function scaleEquallyCropBR(eventData, transform, x, y) {
    const { target } = transform;
    const fullWidth = target.__editingImage.width;
    const fullHeight = target.__editingImage.height;
    const extraOriginX = target.cropX / target.width;
    const extraOriginY = target.cropY / target.height;
    const centerPoint = target.getCenterPoint();
    const constraint = target.translateToOriginPoint(centerPoint, -extraOriginX, -extraOriginY);
    // @ts-ignore
    const newPoint = fabric.controlsUtils.getLocalPoint(transform, -extraOriginX, -extraOriginY, x, y);
    const oldScaleX = target.scaleX;
    const oldScaleY = target.scaleY;
    
    let scale = calcScale(newPoint, fullHeight, fullWidth, target.flipX, target.flipY);
    scale = getBoundedScale(target, scale, false, false)
    const scaleChangeX = scale / oldScaleX;
    const scaleChangeY = scale / oldScaleY;
    let newWidth = target.width / scaleChangeX;
    let newHeight = target.height / scaleChangeY;
    let newCropX = target.cropX / scaleChangeX;
    let newCropY = target.cropY / scaleChangeY;
  
    target.scaleX = scale;
    target.scaleY = scale;
  
    target.height = newHeight;
    target.width = newWidth;
    target.cropX = newCropX;
    target.cropY = newCropY;
    if (target.clippingPath) {
      target.clippingPath.scaleX /= scaleChangeX;
      target.clippingPath.scaleY /= scaleChangeY;
    }
    const newExtraOriginX = target.cropX / target.width;
    const newExtraOriginY = target.cropY / target.height;
    target.setPositionByOrigin(constraint, -newExtraOriginX, -newExtraOriginY);

    updateEditingImage(target, fullWidth, fullHeight)
    return true;
  }

  // @ts-ignore
  export function scaleEquallyCropBRFlip(eventData, transform, x, y) {
    const { target } = transform;
    const fullWidth = target.__editingImage.width
    const fullHeight = target.__editingImage.height
    const remainderX = fullWidth - target.width - target.cropX;
    const remainderY = fullHeight - target.height - target.cropY;
    const anchorOriginX = remainderX / target.width;
    const anchorOriginY = remainderY / target.height;
    const centerPoint = target.getCenterPoint();
    const constraint = target.translateToOriginPoint(centerPoint, -anchorOriginX, -anchorOriginY);
    // @ts-ignore
    const newPoint = fabric.controlsUtils.getLocalPoint(transform, -anchorOriginX, -anchorOriginY, x, y);
    let scale = calcScale(newPoint, fullHeight, fullWidth, target.flipX, target.flipY);
    scale = getBoundedScale(target, scale, true, true)
    const oldScaleX = target.scaleX;
    const oldScaleY = target.scaleY;
    const scaleChangeX = scale / oldScaleX;
    const scaleChangeY = scale / oldScaleY;
    const scaledRemainderX = remainderX / scaleChangeX;
    const scaledRemainderY = remainderY / scaleChangeY;
    const newWidth = target.width / scaleChangeX;
    const newHeight = target.height / scaleChangeY;
    const newCropX = fullWidth - newWidth - scaledRemainderX;
    const newCropY = fullHeight - newHeight - scaledRemainderY;
  
    target.scaleX = scale;
    target.scaleY = scale;
    target.width = newWidth;
    target.height = newHeight;
    target.cropX = newCropX;
    target.cropY = newCropY;
    if (target.clippingPath) {
      target.clippingPath.scaleX /= scaleChangeX;
      target.clippingPath.scaleY /= scaleChangeY;
    }
    const newAnchorOriginX = scaledRemainderX / newWidth;
    const newAnchorOriginY = scaledRemainderY / newHeight;
    target.setPositionByOrigin(constraint, -newAnchorOriginX, -newAnchorOriginY);

    updateEditingImage(target, fullWidth, fullHeight)
    return true;
  }
  
  // @ts-ignore
  export function scaleEquallyCropBL(eventData, transform, x, y) {
    const { target } = transform;
    const fullWidth = target.__editingImage.width
    const fullHeight = target.__editingImage.height
    const remainderX = fullWidth - target.width - target.cropX;
    const remainderY = fullHeight - target.height - target.cropY;
    const anchorOriginX = remainderX / target.width;
    const anchorOriginY = target.cropY / target.height;
    const centerPoint = target.getCenterPoint();
    const constraint = target.translateToOriginPoint(centerPoint, 1 + anchorOriginX, -anchorOriginY);
    // @ts-ignore
    const newPoint = fabric.controlsUtils.getLocalPoint(transform, 1 + anchorOriginX, -anchorOriginY, x, y);
    const oldScaleX = target.scaleX;
    const oldScaleY = target.scaleY;
    let scale = calcScale(newPoint, fullHeight, fullWidth, target.flipX, target.flipY)
    scale = getBoundedScale(target, scale, false, true)
    const scaleChangeX = scale / oldScaleX;
    const scaleChangeY = scale / oldScaleY;
    const newWidth = target.width / scaleChangeX;
    const newHeight = target.height / scaleChangeY;
    const scaledRemainderX = remainderX / scaleChangeX;
    const scaledRemainderY = remainderY / scaleChangeY
    const newCropX = fullWidth - newWidth - scaledRemainderX;
    const newCropY = target.cropY / scaleChangeY;
  
    target.scaleX = scale;
    target.scaleY = scale;
    target.height = newHeight;
    target.width = newWidth;
    target.cropX = newCropX;
    target.cropY = newCropY;
    if (target.clippingPath) {
      target.clippingPath.scaleX /= scaleChangeX;
      target.clippingPath.scaleY /= scaleChangeY;
    }
    const newAnchorOriginX = scaledRemainderX / newWidth;
    const newAnchorOriginY = newCropY / newHeight;
  
    target.setPositionByOrigin(constraint, 1 + newAnchorOriginX, -newAnchorOriginY);
    
    updateEditingImage(target, fullWidth, fullHeight)
    return true;
  }
  
  // @ts-ignore
  export function scaleEquallyCropBLFlip(eventData, transform, x, y) {
    const { target } = transform;
    const fullWidth = target.__editingImage.width
    const fullHeight = target.__editingImage.height
    const remainderY = fullHeight - target.height - target.cropY;
    const anchorOriginX = target.cropX / target.width;
    const anchorOriginY = remainderY / target.height;
    const centerPoint = target.getCenterPoint();
    const constraint = target.translateToOriginPoint(centerPoint, 1 + anchorOriginX, -anchorOriginY);
    // @ts-ignore
    const newPoint = fabric.controlsUtils.getLocalPoint(transform, 1 + anchorOriginX, -anchorOriginY, x, y);
    const oldScaleX = target.scaleX;
    const oldScaleY = target.scaleY;
    let scale = calcScale(newPoint, fullHeight, fullWidth, target.flipX, target.flipY);
    scale = getBoundedScale(target, scale, true, false)
    const scaleChangeX = scale / oldScaleX;
    const scaleChangeY = scale / oldScaleY;
    const newWidth = target.width / scaleChangeX;
    const newHeight = target.height / scaleChangeY;
    const newRemainderY = remainderY / scaleChangeY;
    const newCropX = target.cropX / scaleChangeX;
    const newCropY = fullHeight - newHeight - newRemainderY;
  
    target.scaleX = scale;
    target.scaleY = scale;
    target.height = newHeight;
    target.width = newWidth;
    target.cropX = newCropX;
    target.cropY = newCropY;
    if (target.clippingPath) {
      target.clippingPath.scaleX /= scaleChangeX;
      target.clippingPath.scaleY /= scaleChangeY;
    }
    const newExtraAnchorY = newRemainderY / target.height;
    const newExtraAnchorX = target.cropX / target.width;
    target.setPositionByOrigin(constraint, 1 + newExtraAnchorX, -newExtraAnchorY);
    
    updateEditingImage(target, fullWidth, fullHeight)
    return true;
  }
    
// @ts-ignore
function imageCornerTL(dim, finalMatrix, fabricObject /* currentControl */) {
    const matrix = fabricObject.calcTransformMatrix();
    const vpt = fabricObject.getViewportTransform();
    const _finalMatrix = fabric.util.multiplyTransformMatrices(vpt, matrix);
    const point = new fabric.Point(
      (-fabricObject.width / 2 - fabricObject.cropX),
      (-fabricObject.height / 2 - fabricObject.cropY),
    )
    return fabric.util.transformPoint(point, _finalMatrix);
  }
  
  // @ts-ignore
  function imageCornerTR(dim, finalMatrix, fabricObject /* currentControl */) {
    const matrix = fabricObject.calcTransformMatrix();
    const vpt = fabricObject.getViewportTransform();
    const _finalMatrix = fabric.util.multiplyTransformMatrices(vpt, matrix);
    const fullWidth = fabricObject.getElement().width
    const point = new fabric.Point(
      (fullWidth - fabricObject.width / 2 - fabricObject.cropX),
      (-fabricObject.height / 2 - fabricObject.cropY),
    )
    return fabric.util.transformPoint(point, _finalMatrix);
  }
  
  // @ts-ignore
  function imageCornerBR(dim, finalMatrix, fabricObject /* currentControl */) {
    const matrix = fabricObject.calcTransformMatrix();
    const vpt = fabricObject.getViewportTransform();
    const _finalMatrix = fabric.util.multiplyTransformMatrices(vpt, matrix);
    const fullWidth = fabricObject.getElement().width;
    const fullHeight = fabricObject.getElement().height;
    const point = new fabric.Point(
      (fullWidth - fabricObject.width / 2 - fabricObject.cropX),
      (fullHeight - fabricObject.height / 2 - fabricObject.cropY),
    )
    return fabric.util.transformPoint(point, _finalMatrix);
  }
  
  // @ts-ignore
  function imageCornerBL(dim, finalMatrix, fabricObject /* currentControl */) {
    const matrix = fabricObject.calcTransformMatrix();
    const vpt = fabricObject.getViewportTransform();
    const _finalMatrix = fabric.util.multiplyTransformMatrices(vpt, matrix);
    const fullHeight = fabricObject.getElement().height;
    const point = new fabric.Point(
      (-fabricObject.width / 2 - fabricObject.cropX),
      (fullHeight - fabricObject.height / 2 - fabricObject.cropY),
    )
    // @ts-ignore
    return fabric.util.transformPoint(point, _finalMatrix);
  }
  
  export const croppingControlSet = {
    tlS: new fabric.Control({
      x: -0.5, y: -0.5,
      actionName: 'tlS',
      cursorStyleHandler: scaleCursorStyleHandler,
      positionHandler: imageCornerTL,
      actionHandler: scaleEquallyCropTL,
      render: drawCircleIcon
    }),
    trS: new fabric.Control({
      x: 0.5, y: -0.5,
      actionName: 'trS',
      cursorStyleHandler: scaleCursorStyleHandler,
      positionHandler: imageCornerTR,
      actionHandler: scaleEquallyCropTR,
      render: drawCircleIcon,
    }),
    blS: new fabric.Control({
      x: -0.5, y: 0.5,
      actionName: 'blS',
      cursorStyleHandler: scaleCursorStyleHandler,
      positionHandler: imageCornerBL,
      actionHandler: scaleEquallyCropBL,
      render: drawCircleIcon,
    }),
    brS: new fabric.Control({
      x: 0.5, y: 0.5,
      actionName: 'brS',
      cursorStyleHandler: scaleCursorStyleHandler,
      positionHandler: imageCornerBR,
      actionHandler: scaleEquallyCropBR,
      render: drawCircleIcon,
    }),
  };

  export const flipXCropControls = {
    tlS: new fabric.Control({
      x: -0.5,
      y: -0.5,
      actionName: 'tlS',
      cursorStyleHandler: scaleCursorStyleHandler,
      positionHandler: imageCornerTR,
      actionHandler: scaleEquallyCropTR,
      render: drawCircleIcon,
    }),
    trS: new fabric.Control({
      x: 0.5,
      y: -0.5,
      actionName: 'trS',
      cursorStyleHandler: scaleCursorStyleHandler,
      positionHandler: imageCornerTL,
      actionHandler: scaleEquallyCropTL,
      render: drawCircleIcon,
    }),
    blS: new fabric.Control({
      x: -0.5,
      y: 0.5,
      actionName: 'blS',
      cursorStyleHandler: scaleCursorStyleHandler,
      positionHandler: imageCornerBR,
      actionHandler: scaleEquallyCropBR,
      render: drawCircleIcon,
    }),
    brS: new fabric.Control({
      x: 0.5,
      y: 0.5,
      actionName: 'brS',
      cursorStyleHandler: scaleCursorStyleHandler,
      positionHandler: imageCornerBL,
      actionHandler: scaleEquallyCropBL,
      render: drawCircleIcon,
    }),
  };
  
  export const flipYCropControls = {
    tlS: new fabric.Control({
      x: -0.5,
      y: -0.5,
      actionName: 'tlS',
      cursorStyleHandler: scaleCursorStyleHandler,
      positionHandler: imageCornerBL,
      actionHandler: scaleEquallyCropBL,
      render: drawCircleIcon,
    }),
    trS: new fabric.Control({
      x: 0.5,
      y: -0.5,
      actionName: 'trS',
      cursorStyleHandler: scaleCursorStyleHandler,
      positionHandler: imageCornerBR,
      actionHandler: scaleEquallyCropBR,
      render: drawCircleIcon,
    }),
    blS: new fabric.Control({
      x: -0.5,
      y: 0.5,
      actionName: 'blS',
      cursorStyleHandler: scaleCursorStyleHandler,
      positionHandler: imageCornerTL,
      actionHandler: scaleEquallyCropTL,
      render: drawCircleIcon,
    }),
    brS: new fabric.Control({
      x: 0.5,
      y: 0.5,
      actionName: 'brS',
      cursorStyleHandler: scaleCursorStyleHandler,
      positionHandler: imageCornerTR,
      actionHandler: scaleEquallyCropTR,
      render: drawCircleIcon,
    }),
  };
  
  export const flipXYCropControls = {
    tlS: new fabric.Control({
      x: -0.5,
      y: -0.5,
      actionName: 'tlS',
      cursorStyleHandler: scaleCursorStyleHandler,
      positionHandler: imageCornerBR,
      actionHandler: scaleEquallyCropTLFlip,
      render: drawCircleIcon,
    }),
    trS: new fabric.Control({
      x: 0.5,
      y: -0.5,
      actionName: 'trS',
      cursorStyleHandler: scaleCursorStyleHandler,
      positionHandler: imageCornerBL,
      actionHandler: scaleEquallyCropTRFlip,
      render: drawCircleIcon,
    }),
    blS: new fabric.Control({
      x: -0.5,
      y: 0.5,
      actionName: 'blS',
      cursorStyleHandler: scaleCursorStyleHandler,
      positionHandler: imageCornerTR,
      actionHandler: scaleEquallyCropBLFlip,
      render: drawCircleIcon,
    }),
    brS: new fabric.Control({
      x: 0.5,
      y: 0.5,
      actionName: 'brS',
      cursorStyleHandler: scaleCursorStyleHandler,
      positionHandler: imageCornerTL,
      actionHandler: scaleEquallyCropBRFlip,
      render: drawCircleIcon,
    }),
  };
  