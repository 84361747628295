import React from 'react';
import { styled } from 'baseui';
import { t } from 'i18next';
import ButtonCustom from '@/components/ButtonCustom';
import overlayImage from './overlay-image.png'; // adjust the path if needed
import Icons from '../Icons';
import { LabelLarge } from 'baseui/typography';
import { SizeButton } from '@/constants/sizeButton';
import { KIND } from 'baseui/button';
import { lightTheme } from '@/customTheme';

// If you have a local image file, you can import it:
// import overlayImage from '@/assets/images/your-overlay-image.png';

const OverlayContainer = styled('div', {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${overlayImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px',
    boxSizing: 'border-box',
    borderRadius: '16px',
});

const OverlayTitle = styled('h2', {
    ...lightTheme.typography.Small16Bold,
    textAlign: 'center',
    margin: 0,
});

const OverlaySubtitle = styled('p', {
    ...lightTheme.typography.Small12medium,
    lineHeight: '20px',
    textAlign: 'center',
    marginTop: '8px',
    marginBottom: '16px',
    maxWidth: '260px',
});


interface LoginOverlaySimpleProps {
    onSignIn: () => void;
}

const LoginOverlaySimple: React.FC<LoginOverlaySimpleProps> = ({ onSignIn }) => {
    return (
        <OverlayContainer>
            <OverlayTitle>{t('Edit with AI')}</OverlayTitle>
            <OverlaySubtitle>
                {t('Welcome to the future of image editing one click, endless possibilities.')}
            </OverlaySubtitle>
            <ButtonCustom
              type={SizeButton.LARGE}
              kind={KIND.primary}
              style={{ 
                width: '117px',
                height: '40px',
                minWidth: 'fit-content'
             }}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onSignIn()
              }}
            >
              {t('Try Premium')}
            </ButtonCustom>
            {/* <ButtonCustom
                type={SizeButton.LARGE}
                kind={KIND.secondary}
                style={{
                    width: '122px',
                    height: '40px',
                    minWidth: 'fit-content',
                    gap: '6px',
                    background: lightTheme.colors.blackGray,
                    transition: 'all 0s',
                    ':hover': {
                      backgroundColor: lightTheme.colors.grayScale800,
                    },
                    ':active': {
                      backgroundColor: lightTheme.colors.grayScale800,
                    },
                    pointerEvents : 'auto',
                }}
                onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    onSignIn()
                }}
            >
                <Icons.DefaultAvatar fill={lightTheme.colors.white}
                />
                <LabelLarge
                    $style={{
                        ...lightTheme.typography.Small14Semibold,
                        color: lightTheme.colors.white,
                    }}
                >
                    {t('Sign in')}
                </LabelLarge>
            </ButtonCustom> */}
        </OverlayContainer>
    );
};

export default LoginOverlaySimple;
