import useAppContext from '@/hooks/useAppContext'
import { useContext, useEffect, useRef } from 'react'
import { fabric } from 'fabric'
import { FabricCanvas } from './common/interfaces'
import RemoveEditorEventManager from './RemoveEditorEventManager'
import { EditorContext, RemoveEditorContext } from './context'
import { getTourState } from '@/utils/tutorial'

function RemoveToolCanvas() {
  const { isOpenInspector, toolType } = useAppContext()
  const context = useContext(RemoveEditorContext)
  const containerRef = useRef(null)
  const mainContext = useContext(EditorContext)

  useEffect(() => {
    const container = containerRef.current as unknown as HTMLDivElement
    const canvas = new fabric.Canvas('canvas-remove-tool', {
      backgroundColor: 'transparent',
      preserveObjectStacking: true,
      isDrawingMode: true,
      freeDrawingCursor: "",
      containerClass: 'remove-container-class',
    }) as FabricCanvas

    const editor = new RemoveEditorEventManager({
      context: context,
      canvas: canvas,
      mainContext: mainContext
    })
    context.setEditor(editor)
    context.setCanvas(canvas)

    const resizeObserver = new ResizeObserver(async (entries) => {
      const containerElement = document.querySelector(
        '#wrap-canvas-remove-tool .remove-container-class'
      ) as HTMLElement
      if(!containerElement || !editor.handlers.canvasRemoveHandler.isOpenPixelManipulationObject) { return }
      const tourState = getTourState()
      if(tourState.isOpen) {
        const tourProvider = document.querySelector('.tour-provider') as HTMLElement
        tourProvider.style.opacity = '0'
      }
      setTimeout(() => {
        editor.handlers.pixelManipulationObjectHandler.handleRemoveToolResize()
      }, 0);
    })
    resizeObserver.observe(container)
   
    let customCursor = document.querySelector('custom-cursor') as HTMLElement
    if (!customCursor) {
      customCursor = document.createElement('div');
      customCursor.classList.add('custom-cursor');
      container.appendChild(customCursor)
    }
    createRemoveOverlay()
    canvas.renderAll()

    return () => {
      editor.destroy()
    }
  }, [])

  const createRemoveOverlay = () => { 
    let removeOverlay = document.querySelector('#remove-overlay') as HTMLElement
    if(removeOverlay) { return }
    removeOverlay = document.createElement('div')
    removeOverlay.id = 'remove-overlay';
    removeOverlay.style.display = 'none'
    removeOverlay.style.position = 'fixed';
    removeOverlay.style.top = '0';
    removeOverlay.style.left = '0';
    removeOverlay.style.width = '100%';
    removeOverlay.style.height = '100%';
    removeOverlay.style.backgroundColor = 'rgba(0, 0, 0, 0)'; // Semi-transparent black
    removeOverlay.style.zIndex = '2';
    document.body.appendChild(removeOverlay);
  }

  return (
    <div
      id="wrap-canvas-remove-tool"
      ref={containerRef}
      style={{
        boxSizing: 'border-box',
        position: 'absolute',
        overflow: 'hidden',
        backgroundColor: 'transparent',
        left: 0,
        width: isOpenInspector ? '100%' : '100vw',
        height: '100%',
        zIndex: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: toolType !== 'MagicBg' && 'none'
      }}
    >
      <div id="wrap-canvas-remove-zoom-tool" style={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          top: 0,
          right: 0,
        }}>

        <img id='eraser-background' alt='' style={{
          position: 'absolute',
          display: 'none',
        }}></img>

        <div id="wrap-canvas-remove-bg">
            <div id="wrap-canvas-remove-transform-rotate">
              <canvas id="canvas-remove-tool" style={{
                borderRadius: toolType === 'MagicBg' ? '8px' : 0
              }}></canvas>
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default RemoveToolCanvas