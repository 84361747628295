import { createReducer } from '@reduxjs/toolkit'
import { setAIImages } from './actions'

export interface AIImageState {
  aiImages: string[]
}

const initialState: AIImageState = {
  aiImages: []
}

export const aiImageReducer = createReducer(initialState, builder => {
  builder.addCase(setAIImages, (state, { payload }) => {
    state.aiImages = payload
  })
})
