import { panelListItems, PopoverType } from '@/constants/app-options'
import useAppContext from '@/hooks/useAppContext'
import PanelListItem from '../../Panels/PanelListItem'
import AIImage from '../../Panels/PanelItems/AIImage'
import { customAmplitude } from '@/utils/customAmplitude'
import { lightTheme } from '@/customTheme'
function AddAIImage() {
  const { popoverActive, setPopoverActive, activePanel } = useAppContext()
  const panelListItem = panelListItems.find(x => x.id === 'ai-image')

  const handleCloseAiImagePanel = () => {
    setPopoverActive(null)
  }
  return (
    <div>
      <div
        onClick={() => {
          if (popoverActive === PopoverType.AI_IMAGE) {
            setPopoverActive(null)
          } else {
            customAmplitude('Click', {
              Source: 'add.ai-image'
            })
            setPopoverActive(PopoverType.AI_IMAGE)
          }
        }}
        id="popover-ai-image"
        style={{ position: 'relative' }}
      >
        <PanelListItem
          disable={panelListItem.disable}
          label={panelListItem.name}
          name={panelListItem.name}
          key={panelListItem.name}
          icon={'AIImage'}
          activePanel={activePanel}
          panelSelected={popoverActive === PopoverType.AI_IMAGE}
        />
        <div
          style={{
            width: '30px',
            height: '14px',
            position: 'absolute',
            top: '2px',
            right: '2px',
            background: 'linear-gradient(158deg, #FD0260 8.47%, #EE0186 51.78%, #C201CF 91.54%)',
            borderRadius: '4px',
            fontSize: '9px',
            fontWeight: 700,
            color: lightTheme.colors.white,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
          }}
        >
          NEW
        </div>
      </div>
      <AIImage isOpen={popoverActive === PopoverType.AI_IMAGE} handleClosePanel={handleCloseAiImagePanel} />
    </div>
  )
}

export default AddAIImage
