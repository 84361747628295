function MagicStars({ size }: { size: number }) {
  return (
    <svg height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.418 1.00006C12.6294 1.00006 12.7528 1.11019 12.788 1.33046C12.8673 1.87672 12.9466 2.2666 13.0259 2.50009C13.1052 2.73357 13.2704 2.89656 13.5215 2.98908C13.7726 3.08159 14.1889 3.18512 14.7704 3.29967C14.9907 3.34371 15.1008 3.46706 15.1008 3.66969C15.1008 3.88998 14.9863 4.01333 14.7572 4.03975C14.1757 4.11905 13.7616 4.20056 13.5149 4.28427C13.2682 4.36796 13.1052 4.53316 13.0259 4.77986C12.9466 5.02656 12.8673 5.43186 12.788 5.99574C12.7616 6.22482 12.6382 6.33936 12.418 6.33936C12.1977 6.33936 12.0744 6.22042 12.0479 5.98254C11.9862 5.45389 11.9158 5.07282 11.8365 4.83932C11.7572 4.60585 11.5898 4.44505 11.3343 4.35694C11.0787 4.26883 10.6558 4.1631 10.0655 4.03975C9.84524 3.9957 9.73511 3.87235 9.73511 3.66969C9.73511 3.46706 9.85845 3.34371 10.1051 3.29967C10.6778 3.20275 11.0853 3.11024 11.3276 3.02213C11.5699 2.93401 11.7329 2.77101 11.8166 2.53311C11.9003 2.29523 11.9774 1.89875 12.0479 1.34367C12.0744 1.1146 12.1977 1.00006 12.418 1.00006Z" fill="url(#paint0_linear_6591_33826)" />
      <path d="M19.1199 6.35796C19.4725 6.35796 19.6708 6.53424 19.7149 6.88682C19.803 7.54791 19.889 8.06356 19.9727 8.43378C20.0564 8.80399 20.1931 9.08386 20.3826 9.27337C20.5721 9.46288 20.863 9.61052 21.2552 9.71629C21.6475 9.82207 22.2006 9.93667 22.9145 10.0601C23.2583 10.1218 23.4302 10.3201 23.4302 10.655C23.4302 11.0076 23.2539 11.206 22.9013 11.25C22.205 11.347 21.6607 11.4395 21.2684 11.5277C20.8762 11.6158 20.5831 11.7547 20.3892 11.9442C20.1953 12.1337 20.0564 12.4202 19.9727 12.8036C19.889 13.187 19.803 13.7225 19.7149 14.41C19.6708 14.7714 19.4725 14.9521 19.1199 14.9521C18.7673 14.9521 18.569 14.767 18.5249 14.3968C18.4456 13.7445 18.364 13.2333 18.2803 12.8631C18.1966 12.4929 18.0599 12.213 17.8704 12.0235C17.6809 11.834 17.3878 11.6885 16.9912 11.5872C16.5945 11.4858 16.0392 11.3734 15.3252 11.25C14.9815 11.1883 14.8096 10.99 14.8096 10.655C14.8096 10.3113 15.0035 10.1129 15.3913 10.0601C16.0789 9.96312 16.6166 9.86616 17.0044 9.7692C17.3922 9.67222 17.6809 9.52678 17.8704 9.33286C18.0599 9.13894 18.1966 8.85247 18.2803 8.47345C18.364 8.09442 18.4456 7.57437 18.5249 6.91328C18.569 6.54306 18.7673 6.35796 19.1199 6.35796Z" fill="url(#paint1_linear_6591_33826)" />
      <path d="M8.30518 9.24999C8.52673 9.24999 8.71886 9.32269 8.88156 9.46808C9.04427 9.61348 9.14293 9.80041 9.17754 10.0289C9.30216 10.8666 9.43544 11.5694 9.57737 12.1371C9.71931 12.7048 9.89932 13.1721 10.1174 13.5391C10.3355 13.906 10.6245 14.1968 10.9846 14.4115C11.3446 14.6261 11.7981 14.7957 12.345 14.9203C12.892 15.0449 13.567 15.1522 14.3702 15.2423C14.6055 15.2699 14.7994 15.3669 14.9517 15.533C15.104 15.6992 15.1802 15.8965 15.1802 16.125C15.1802 16.3466 15.1057 16.5404 14.9569 16.7066C14.808 16.8727 14.6159 16.9731 14.3805 17.0077C13.5843 17.1254 12.9145 17.2483 12.371 17.3764C11.8275 17.5045 11.3809 17.6759 11.0313 17.8905C10.6817 18.1051 10.3978 18.3907 10.1797 18.7473C9.96162 19.1038 9.77642 19.5625 9.6241 20.1233C9.47179 20.6841 9.32294 21.3834 9.17754 22.2211C9.136 22.4496 9.03561 22.6365 8.87637 22.7819C8.71713 22.9273 8.52673 23 8.30518 23C8.08363 23 7.8915 22.9273 7.72881 22.7819C7.5661 22.6365 7.46744 22.4496 7.43283 22.2211C7.30821 21.3834 7.17493 20.6806 7.033 20.1129C6.89106 19.5452 6.71105 19.0779 6.49296 18.7109C6.27487 18.344 5.98582 18.0532 5.62581 17.8385C5.26578 17.6239 4.81229 17.4543 4.26534 17.3297C3.71839 17.2051 3.04335 17.0978 2.24022 17.0077C2.00482 16.9801 1.81096 16.8831 1.65865 16.717C1.50633 16.5508 1.43018 16.3535 1.43018 16.125C1.43018 15.9034 1.5046 15.7096 1.65346 15.5434C1.80231 15.3773 1.99444 15.2769 2.22984 15.2423C3.03296 15.1315 3.70453 15.0103 4.24456 14.8788C4.78459 14.7472 5.22942 14.5741 5.57906 14.3595C5.9287 14.1449 6.21257 13.8593 6.43066 13.5027C6.64874 13.1462 6.83394 12.6875 6.98627 12.1267C7.13859 11.5659 7.28744 10.8666 7.43283 10.0289C7.47436 9.80041 7.57648 9.61348 7.73919 9.46808C7.9019 9.32269 8.09056 9.24999 8.30518 9.24999Z" fill="url(#paint2_linear_6591_33826)" />
      <defs>
        <linearGradient id="paint0_linear_6591_33826" x1="15.1008" y1="6.33936" x2="15.1008" y2="1.00006" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF1866" />
          <stop offset="0.27027" stopColor="#FD0167" />
          <stop offset="0.496094" stopColor="#F00188" />
          <stop offset="0.649414" stopColor="#DD01A0" />
          <stop offset="0.754883" stopColor="#D203B4" />
          <stop offset="1" stopColor="#C811CB" />
        </linearGradient>
        <linearGradient id="paint1_linear_6591_33826" x1="23.4302" y1="14.9521" x2="23.4302" y2="6.35796" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF1866" />
          <stop offset="0.27027" stopColor="#FD0167" />
          <stop offset="0.496094" stopColor="#F00188" />
          <stop offset="0.649414" stopColor="#DD01A0" />
          <stop offset="0.754883" stopColor="#D203B4" />
          <stop offset="1" stopColor="#C811CB" />
        </linearGradient>
        <linearGradient id="paint2_linear_6591_33826" x1="15.1802" y1="23" x2="15.1802" y2="9.24999" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF1866" />
          <stop offset="0.27027" stopColor="#FD0167" />
          <stop offset="0.496094" stopColor="#F00188" />
          <stop offset="0.649414" stopColor="#DD01A0" />
          <stop offset="0.754883" stopColor="#D203B4" />
          <stop offset="1" stopColor="#C811CB" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default MagicStars
