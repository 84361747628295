import { useEffect, useState } from 'react'
import { PopoverType, panelListItems } from '@/constants/app-options'
import PanelListItem from '../../Panels/PanelListItem'
import useAppContext from '@/hooks/useAppContext'
import api from '@/services/api'
import Uploads from '../../Panels/PanelItems/Uploads'
import { customAmplitude } from '@/utils/customAmplitude'

function Photos() {
  const { setPopoverActive, popoverActive } = useAppContext()

  const panelListItem = panelListItems.find(x => x.name === 'Photos')

  const { activePanel } = useAppContext()

  return (
    <div>
      <div
        onClick={() => {
          if(popoverActive !== PopoverType.PHOTOS) {
            customAmplitude('Click', {
              Source: 'add.photos'
            })
          }
          setPopoverActive(popoverActive === PopoverType.PHOTOS ? null : PopoverType.PHOTOS)
        }}
        id="popover-photo"
      >
        <PanelListItem
          disable={panelListItem.disable}
          label={panelListItem.name}
          name={panelListItem.name}
          key={panelListItem.name}
          icon={panelListItem.name}
          activePanel={activePanel}
          panelSelected={popoverActive === PopoverType.PHOTOS}
        />
      </div>
      <Uploads
        name="Upload"
        setIsOpen={() => setPopoverActive(null)}
        isOpen={popoverActive === PopoverType.PHOTOS}
      />
    </div>
  )
}

export default Photos
