import { lightTheme } from "@/customTheme"
import * as React from "react"
const SvgComponent = (props) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.1666 11C19.1666 15.5103 15.5102 19.1667 10.9999 19.1667C6.48959 19.1667 2.83325 15.5103 2.83325 11C2.83325 6.48967 6.48959 2.83333 10.9999 2.83333C15.5102 2.83333 19.1666 6.48967 19.1666 11Z" stroke="#111111" stroke-width="2" />
    <path d="M10.9999 10.0833V14.6667" stroke="#111111" stroke-width="2" stroke-linecap="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.9999 8.24999C11.5062 8.24999 11.9166 7.83958 11.9166 7.33332C11.9166 6.82706 11.5062 6.41666 10.9999 6.41666C10.4937 6.41666 10.0833 6.82706 10.0833 7.33332C10.0833 7.83958 10.4937 8.24999 10.9999 8.24999Z" fill="#111111" />
  </svg>
)
export default SvgComponent
