import { useEffect, useRef } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import useAppContext from '@hooks/useAppContext'
import Loading from './components/Loading'
import { customAmplitude } from './utils/customAmplitude'
import { LIST_LANGUAGE, SAVE_PROJECT_STATE } from './scenes/engine/common/constants'
import { getLocalStorage, setLocalStorage } from './utils/localStorage'
import { useTranslation } from 'react-i18next'
import api from './services/api'
import LoadingSaveProject from './components/LoadingSaveProject'
import ContactUs from './components/ContactUs'
import { useFrontChatBoot } from './libs/front-chat-sdk/hooks/use-front-chat-boot'
// import { isMobile } from 'react-device-detect'

function Container({ children }) {
  const containerRef = useRef<HTMLDivElement>()
  // const { isMobile, setIsMobile } = useAppContext()
  // const dispatch = useAppDispatch()
  // const updateMediaQuery = (value: number) => {
  //   if (!isMobile && value >= 800) {
  //     setIsMobile(false)
  //   } else if (!isMobile && value < 800) {
  //     setIsMobile(true)
  //   } else {
  //     setIsMobile(false)
  //   }
  // }
  const chatId = '196a6f45d0e4514761f59cd3554f6ea8';
  const {initialize, isInitialized} = useFrontChatBoot(document.body);
  useEffect(() => {
    console.log("initialized")
    if (!initialize || isInitialized) {
      return;
    }

    initialize({chatId});
  }, [isInitialized, initialize]);

  const { i18n } = useTranslation()
  // 
  useEffect(() => {
    const currURl = window.location.href
    const urlParams = new URLSearchParams(window.location.search)
    const referrerParam = urlParams.get('referrer')

    const eventProperties = {
      Type: currURl.includes('create') ? 'Create' :  currURl.includes('edit') ? 'Template' : 'Scratch',
    }
    customAmplitude('Open', eventProperties)
    
    if(referrerParam) {
      customAmplitude('Deep Link Opened', {
        Source: referrerParam
      })
    }
    
    const containerElement = containerRef.current
    const containerWidth = containerElement.clientWidth
    // updateMediaQuery(containerWidth)
    const resizeObserver = new ResizeObserver(entries => {
      const { width = containerWidth } = (entries[0] && entries[0].contentRect) || {}
      // updateMediaQuery(width)
    })
    resizeObserver.observe(containerElement)

    // block on mobile
    if (/Mobi|Android|iPhone|iPad|iPod|Mobile Safari/.test(navigator.userAgent)) {
      window.location.href = 'https://www.bazaart.com/email-app-redirection-desktop/'
    }

    // change language
    loadLanguage()

    api.getContentTranslation().then((result)=>{
      result.forEach(langResource=>{
        let langUrl =langResource.file
        let lang = langResource.name.split('/')[1]
        fetch(langUrl)
        .then((res) => res.json())
          .then((data) => {
            i18n.addResourceBundle(lang, 'translation', data);
          });
      })
    })
    return () => {
      if (containerElement) {
        resizeObserver.unobserve(containerElement)
      }
    }
    
  }, [i18n])

  // Load the language
function loadLanguage() {
  const defaultLanguage = i18n.options.lng || 'en'; // Get the default language from i18n
  const localStorageLanguage = getLocalStorage('language');
  
  if (localStorageLanguage && LIST_LANGUAGE.some(({ value }) => value === localStorageLanguage)) {
    // Use language from localStorage if valid
    i18n.changeLanguage(localStorageLanguage);
    return;
  }

  const browserLanguage = window.navigator.language;
  
  if (browserLanguage) {
    const matchedLanguage = LIST_LANGUAGE.find(lang =>
      browserLanguage.includes(lang.value)
    );

    if (matchedLanguage) {
      // Use the browser language if supported
      setLocalStorage('language', matchedLanguage.value);
      i18n.changeLanguage(matchedLanguage.value);
      return;
    }
  }

  // Fallback to default language
  setLocalStorage('language', defaultLanguage);
  i18n.changeLanguage(defaultLanguage);
}

  const { isLoadedJsonSuccess, isOpenInspector, saveProjectState} = useAppContext()

  return (
    <div
      ref={containerRef}
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
      }}
    >
      {children}
      {!isLoadedJsonSuccess && (
        <div
          style={{
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            background: 'rgba(255, 255, 255, 0.7)',
            zIndex: 10000,
            cursor: 'auto',
          }}
          id="overlay-loading"
          onClick={e => {
            e.stopPropagation()
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 'calc(50% + 32px)',
              transform: 'translateY(-50%)',
              left: isOpenInspector ? '88px' : 0,
              right: isOpenInspector ? '356px' : 0,
              zIndex: 1000,
              transition: 'all .4s ease-in',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Loading />
          </div>
        </div>
      )}
      {(saveProjectState !== SAVE_PROJECT_STATE.END && saveProjectState !== SAVE_PROJECT_STATE.INIT) ? <LoadingSaveProject /> : null}
      <canvas id="canvas-confetti" style={{ background: 'transparent', position: 'fixed', inset: 0 }}></canvas>
      {/* <ContactUs /> */}
    </div>
  )
}

export default Container

