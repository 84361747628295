import { Filter, settings } from '@pixi/core'
import { BasePixiFilter } from '@scenes/engine/utils/PixijsFilters/BasePixiFilter'
import { Inset } from '@scenes/engine/objects/media-repository/inset'
import { Size } from '@scenes/engine/objects/media-repository/size'
import { DropShadowHDFilter } from '@scenes/engine/utils/PixijsFilters/DropShadowHDFilter'
import { ColorCubeMapFilter } from '@scenes/engine/utils/PixijsFilters/ColorMapCube/ColorMapCubeFilter'
import { BasicFilterFactory, FilterPresetType } from '@scenes/engine/utils/PixijsFilters/BasicFilterFactory'
import { AdjustmentFilterFactory } from '@scenes/engine/utils/PixijsFilters/AdjustmentFilterFactory'
import { OutlineHDFilter } from './PixijsFilters/OutlineHD/OutlineHdFilter'
import { ColorMap } from '../common/interfaces'
import { ColorOverlayFilter } from 'pixi-filters'
import { MediaImageRepository } from '../objects/media-repository/media_image_repository'
import { MediaImageType } from '../objects/media-repository/media_image_type'
import { CanvasLayerOutlineEffect } from '@/interfaces/CanvasLayerOutlineEffect'
import { CanvasLayerShadowEffect } from '@/interfaces/CanvasLayerShadowEffect'
import { Rectangle } from '../objects/media-repository/rectangle'
import ApiService from '@services/api'
import * as PIXI from 'pixi.js'
import { Point } from '../objects/media-repository/point'

class CanvasImageRenderer {
  adjustmentFilterFactory = new AdjustmentFilterFactory()
  outlineFilter = new OutlineHDFilter()
  shadowFilter = new DropShadowHDFilter()

  blurToImageRatio = 3;

  private static instance: CanvasImageRenderer

  public static getInstance(): CanvasImageRenderer {
    if (!CanvasImageRenderer.instance) {
      CanvasImageRenderer.instance = new CanvasImageRenderer()
    }

    return CanvasImageRenderer.instance
  }

  isAdjustDefault(adjustValues: [string: number]): boolean {
    let keys = Array.from(this.adjustmentFilterFactory.rangeValueForProperty.keys())
    for (let key of keys) {
      if (adjustValues[key] &&  adjustValues[key] != this.adjustmentFilterFactory.rangeValueForProperty.get(key).defaultValue) {
        return false
      }
    }
    return true
  }

  async getFilters(imageElement, colorMaps: ColorMap[]): Promise<BasePixiFilter[]> {
    let pixijsFilters: Filter[] = []
    let pixijsPaddings: Size[] = []
    let pixijsInsets: Inset[] = []

    let zeroPadding = new Size(0, 0)
    let zeroInset = new Inset(0, 0, 0, 0)

    let w = imageElement.width
    let h = imageElement.height

    let filterName: string = imageElement.filter
    let filterIntensity = imageElement.filterIntensity
    let colorMap

    let overlay = imageElement.effects?.overlay
    if (overlay) {
      const colorNumber = new PIXI.Color(overlay.color).toNumber()
      const colorOverlayFilter = new ColorOverlayFilter(colorNumber, overlay.alpha)
      pixijsFilters.push(colorOverlayFilter)
      pixijsPaddings.push(zeroPadding)
      pixijsInsets.push(zeroInset)
    }

    if (filterName) {
      if (filterName.startsWith('CI')) {
        let filterType = mapCIFilterToFilterPreset(filterName)
        let factory = new BasicFilterFactory(filterType)
        let filter = await factory.geFilter(filterIntensity)

        pixijsFilters.push(filter)
        pixijsPaddings.push(zeroPadding)
        pixijsInsets.push(zeroInset)
      }
      const packID = Number(filterName.split('_')[0])
      if (packID && !isNaN(packID)) {
        colorMap = colorMaps.find(cm => cm.id === packID)
      }
    }

    if (colorMap) {
      const texture = await PIXI.Texture.fromURL(ApiService.prepareUrl(colorMap.image))
      if (texture) {
        const colorMapFilter = new ColorCubeMapFilter(texture, false, filterIntensity)
        pixijsFilters.push(colorMapFilter)
        pixijsPaddings.push(zeroPadding)
        pixijsInsets.push(zeroInset)
      } else {
        console.log('No texture is created')
      }
    }
    // imageElement.effects.adjustments = {brightness: 2, contrast: 1, saturation: 1};
    let adjustments = imageElement.adjustments
    if (adjustments) {
      adjustments = Object.assign(adjustments, {
        imageHeight: h,
        imageWidth: w,
      })
      let filters = this.adjustmentFilterFactory.parse(adjustments)
      for (let filter of filters) {
        pixijsFilters.push(filter)
        pixijsPaddings.push(zeroPadding)
        pixijsInsets.push(zeroInset)
      }
    }

    let outline = imageElement.effects?.outline
    if (outline && outline.thickness > 0) {
      // outline is relative to image size
      // let thickness = Math.floor((outline.thickness / 100) * Math.max(h, w))
      let thickness = Math.floor(outline.thickness * Math.max(h, w))
      this.outlineFilter.thickness = thickness
      this.outlineFilter.color = parseInt(outline.color.substring(1), 16)
      pixijsFilters.push(this.outlineFilter)
      let padding = new Size(thickness * 2, thickness * 2)
      pixijsPaddings.push(padding)

      let inset = new Inset(thickness, thickness, thickness, thickness)
      pixijsInsets.push(inset)
      this.outlineFilter = new OutlineHDFilter()
    }

    let shadow = imageElement.effects?.shadow
    if (shadow) {
      let outlineThickness = outline?.thickness ?? 0;
      // let outlinePercent = 1 / (1 + outlineThickness * 2);
      let outlinePercent = (1 + outlineThickness * 2);
      let maxEdge = Math.max(h * outlinePercent, w * outlinePercent)
      let adjustedBlurSize = Math.floor(shadow.blur * maxEdge);
      
      // offsets are relative to image size
      let offsetX 
      let offsetY 
      
      if(shadow.offsetX === null || shadow.offsetX === undefined || shadow.offsetY === null || shadow.offsetY === undefined) {
        let angle = shadow.angle;
        offsetX = Math.floor(Math.cos(angle ?? 0) * shadow.distance * maxEdge)
        offsetY = Math.floor(Math.sin(angle ?? 0) * shadow.distance * maxEdge)
      } else {
        offsetX = Math.floor(shadow.offsetX * maxEdge)
        offsetY = Math.floor(shadow.offsetY * maxEdge)
      }

      // for some reason I can't set the blur values after initializing the filter
      this.shadowFilter = new DropShadowHDFilter({ blur: adjustedBlurSize, resolution: 1, quality: 40 })
      this.shadowFilter.color = parseInt(shadow.color.substring(1), 16)
      this.shadowFilter.alpha = shadow.opacity
      this.shadowFilter.offset = {
        x: offsetX,
        y: offsetY,
      }

      let padding = new Size(
        Math.abs(offsetX) + adjustedBlurSize * 2,
        Math.abs(offsetY) + adjustedBlurSize * 2
      )
      let inset = new Inset(
        adjustedBlurSize - Math.min(0, offsetX),
        adjustedBlurSize - Math.min(0, offsetY),
        adjustedBlurSize + Math.max(0, offsetX),
        adjustedBlurSize + Math.max(0, offsetY)
      )

      pixijsFilters.push(this.shadowFilter)
      pixijsPaddings.push(padding)
      pixijsInsets.push(inset)
      this.shadowFilter = new DropShadowHDFilter()
    }

    let filters: BasePixiFilter[] = []
    for (let i = 0; i < pixijsFilters.length; i++) {
      let f = new BasePixiFilter(pixijsFilters[i], pixijsPaddings[i], pixijsInsets[i])
      filters.push(f)
    }
    return filters;
  }

  async render(imageElement, canvasSize: Size, colorMaps: ColorMap[], filterBackend?: fabric.WebglFilterBackend, optimize: boolean = true) {
    let filters = await this.getFilters(imageElement, colorMaps)
    
    if (imageElement.isLatest){
      imageElement.set('isLatest', false);

      let sourceBounds = new Rectangle(0, 0, imageElement.width, imageElement.height);
      
      let fittedSrc = await MediaImageRepository.getInstance().getImage(imageElement.bazaartGuid, imageElement.layerAssetStateId, MediaImageType.fitted);
      let fittedMaskSrc = await MediaImageRepository.getInstance().getImage(imageElement.bazaartGuid, imageElement.layerAssetStateId, MediaImageType.fittedMask);
      
      if (fittedSrc && fittedMaskSrc) {
        let fittedImage = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(fittedSrc);
        let fittedMask = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(fittedMaskSrc);
        let fittedMaskedSrc = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.applyMaskToImage(fittedImage, fittedMask)
        let fittedMasked = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(fittedMaskedSrc);
  
        imageElement.setElement(fittedMasked)
      } else {
        let originalSrc = await MediaImageRepository.getInstance().getImage(imageElement.bazaartGuid, imageElement.layerAssetStateId, MediaImageType.original);
        let original = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(originalSrc);
        imageElement.setElement(original)
      }

      let shadowEffects = new CanvasLayerShadowEffect();
      let targetBoundsWithoutShadow = shadowEffects.revertEffectBounds(imageElement,  canvasSize, false, sourceBounds);
      let nonSymmetricOffset = new Point(targetBoundsWithoutShadow.x, targetBoundsWithoutShadow.y) ;

      let outlineEffects = new CanvasLayerOutlineEffect();
      let targetSizeWithoutOutline = outlineEffects.revertEffectBounds(imageElement, canvasSize, false, targetBoundsWithoutShadow);
      
      
      let originalSizeOnCanvas = {
        // @ts-ignore
        width: targetSizeWithoutOutline.width * imageElement.scaleX * imageElement._filterScalingX / canvasSize.width,
        // @ts-ignore
        height: targetSizeWithoutOutline.height * imageElement.scaleY * imageElement._filterScalingY / canvasSize.height
      }

      imageElement._filterScalingX = 1;
      imageElement._filterScalingY = 1; 

      // TODO: handle the case in which the element has been rotated before playing around with shadow color

      let offsetX = nonSymmetricOffset.x * imageElement.scaleX
      let offsetY = nonSymmetricOffset.y * imageElement.scaleY
      const angleInRadians = (imageElement.angle * Math.PI) / 180;

      const rotatedOffsetX = offsetX * Math.cos(angleInRadians) - offsetY * Math.sin(angleInRadians);
      const rotatedOffsetY = offsetX * Math.sin(angleInRadians) + offsetY * Math.cos(angleInRadians);

      imageElement.left -= rotatedOffsetX;
      imageElement.top -= rotatedOffsetY;

      // imageElement.left -= nonSymmetricOffset.x * imageElement.scaleX
      // imageElement.top -= nonSymmetricOffset.y * imageElement.scaleY;

      imageElement.scaleX = originalSizeOnCanvas.width / (imageElement.width * imageElement._filterScalingX / canvasSize.width);
      imageElement.scaleY = originalSizeOnCanvas.height / (imageElement.height * imageElement._filterScalingY / canvasSize.height);

      imageElement._originalScaleX = imageElement.scaleX;
      imageElement._originalScaleY = imageElement.scaleY;
    }
    imageElement.set('dirty', true)
    
    imageElement.applyFilters(filters, filterBackend, optimize)

    return imageElement
  }
}

const mapCIFilterToFilterPreset = (ciFilterName: string): FilterPresetType => {
  switch (ciFilterName) {
    case 'CIPhotoEffectChrome':
      return FilterPresetType.chrome
    case 'CIPhotoEffectFade':
      return FilterPresetType.fade
    case 'CIPhotoEffectTransfer':
      return FilterPresetType.transfer
    case 'CIPhotoEffectProcess':
      return FilterPresetType.process
    case 'CIPhotoEffectInstant':
      return FilterPresetType.instant
    case 'CIPhotoEffectMono':
      return FilterPresetType.monochrome
    case 'CIPhotoEffectTonal':
      return FilterPresetType.tonal
    case 'CIPhotoEffectNoir':
      return FilterPresetType.noir
    default:
      return FilterPresetType.default
  }
}

export default CanvasImageRenderer
